import React from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { REGISTER_USER } from '@/api/authentication/mutations.js';
import { TFashion } from '@/assets/svg';

// import { Button } from '@/components/Button';
import { signUpFormOptions } from './signUpFormOptions';

const Navbar = () => (
  <div className="flex min-h-[5.625rem] w-full border-b border-[#A1A1A1] bg-white px-4 sm:px-[3.75rem]">
    <div className="flex w-[13rem] items-center">
      <TFashion />
    </div>
  </div>
);

const Links = () => (
  <p className="font-regular text-base leading-7 text-[#000000]">
    Do you have an account?
    <NavLink
      className="ml-1 border-b-2 border-primary font-semibold text-primary"
      to="/login"
    >
      Login
    </NavLink>
  </p>
);

const FormInput = ({ label, error, ...props }) => (
  <div className="flex w-full flex-col gap-[4px]">
    <p className="h-[28px] px-[16px] pb-[4px] text-[16px] font-semibold leading-[28px]">
      {label}
    </p>
    <input
      className="h-[47px] border border-charcoal px-[16px] outline-0"
      {...props}
    />
    {error && (
      <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500">
        {error}
      </p>
    )}
  </div>
);

const CheckboxInput = ({ id, label }) => (
  <div className="flex items-center justify-start">
    <input
      className="form-check-input checked:bordercharcoal float-left h-[14px] w-[14px] cursor-pointer appearance-none rounded-none border border-charcoal bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:bg-primary focus:outline-none"
      type="checkbox"
      value=""
      id={id}
    />
    <label
      htmlFor={id}
      className="color-charcoal ml-[8px] flex cursor-pointer justify-center pt-0.5 text-[12px]"
    >
      {' '}
      {label}
    </label>
  </div>
);

const SignUpForm = ({ onSubmit, register, errors, loading, error }) => (
  <form
    className="mb-[22px] flex h-auto w-[404px] flex-col"
    onSubmit={onSubmit}
  >
    <div className="flex flex-col gap-[14px]">
      <FormInput
        label="Full Name"
        placeholder="Write Your Full Name"
        error={errors.fullName?.message}
        {...register('fullName', { required: true, maxLength: 30 })}
      />
      <FormInput
        label="E-mail"
        type="email"
        placeholder="Write Your Work E-mail"
        error={errors.email?.message}
        {...register('email', { required: true })}
      />
      <FormInput
        label="Password"
        type="password"
        autoComplete="on"
        placeholder="Password"
        error={errors.password?.message}
        {...register('password', { required: true })}
      />
      <FormInput
        label="Confirm Password"
        type="password"
        autoComplete="on"
        placeholder="Confirm Password"
        error={errors.confirmPassword?.message}
        {...register('confirmPassword', { required: true })}
      />

      {error && (
        <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500">
          {error.message}
        </p>
      )}

      <CheckboxInput id="privacy" label="Privacy policy" />
      <div className="mb-[22px]">
        <CheckboxInput id="terms" label="Terms & conditions" />
      </div>

      <button className="btn-primary-without-hover h-[46px]">
        {loading ? 'Submitting...' : 'Sign Up'}
      </button>
    </div>
  </form>
);

export const SignUp = () => {
  const navigate = useNavigate();
  const [registerUser, { data, loading, error }] = useMutation(REGISTER_USER, {
    onCompleted: () => {
      const { accessToken, refreshToken, user } = data.registerUser;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify(user));
      navigate('/overview', { replace: true, state: { welcome: true } });
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(signUpFormOptions);

  const onSubmit = (formData) => {
    const { fullName, email, password } = formData;
    const input = {
      fullName,
      email,
      password,
    };
    registerUser({ variables: { input } });
  };

  return (
    <div className="flex h-full w-full flex-col">
      <Navbar />
      <div className="flex w-full flex-1 flex-col items-center justify-center px-4 sm:px-0">
        <div className="flex flex-col items-center">
          <h3 className="mb-4 text-[2rem] font-semibold leading-[1.15] sm:text-[3rem]">
            Sign Up
          </h3>
          {/* <p className="font-regular mb-4 text-sm leading-[173%] text-[#626775]">
            Please request a demo and we will get back to you as soon as
            possible
          </p> */}
        </div>

        {/* <a href={'https://tfashion.ai/request-demo'}>
          <Button className={'my-4 sm:mb-12 sm:mt-6'}>Request a Demo</Button>
        </a> */}

        <SignUpForm
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={errors}
          loading={loading}
          error={error}
        />
        <Links />
      </div>
    </div>
  );
};
