import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import { STUDIO_TOOLS_CONFIG } from '@/constants/studio';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';

import { GenerateButton } from './components/GenerateButton';
import { SidebarButton } from './components/SidebarMenu';

// Move these outside component as they don't need to be state
const DEFAULT_APPAREL_TOOLS = [
  'CREATE_VARIANTS',
  'UPSCALE_IMAGE',
  'ENHANCE_IMAGE',
  'ZOOM_IN_IMAGE',
  'IMAGE_BACKGROUND_REMOVER',
  'IMAGE_CHANGE_BACKGROUND',
  'COLOR_TRANSFER',
  'GENERATE_FLAT_SKETCH',
];

const DEFAULT_PATTERN_TOOLS = [
  'CREATE_PATTERN_VARIANTS',
  'PATTERN_TILE',
  'UPSCALE_IMAGE',
  'ENHANCE_IMAGE',
  'ZOOM_IN_IMAGE',
];

const StudioToolsGrid = ({ isPattern, generatedTaskType, entityType }) => {
  const {
    selectedTool,
    setSelectedTool,
    updateCreateItemDetails,
    setGenerateSize,
    isHidingTool,
    studioTask,
    selectedGeneratedImage,
  } = useStudioContext();

  const [canFlowsToTask, setCanFlowsToTask] = useState({});
  const [tools, setTools] = useState(
    isPattern ? DEFAULT_PATTERN_TOOLS : DEFAULT_APPAREL_TOOLS,
  );

  useEffect(() => {
    if (isPattern) {
      setGenerateSize('SQUARE');
    }
  }, [isPattern, setGenerateSize]);

  // Consolidated tool filtering logic
  useEffect(() => {
    let currentTools = isPattern
      ? DEFAULT_PATTERN_TOOLS
      : DEFAULT_APPAREL_TOOLS;

    // Filter based on hiding tool
    if (isHidingTool.length > 0) {
      currentTools = currentTools.filter((tool) => tool !== isHidingTool[0]);
    }

    // Filter for GENERATE_GRAPHIC case
    if (generatedTaskType === 'GENERATE_GRAPHIC') {
      currentTools = currentTools.filter(
        (tool) => tool !== 'GENERATE_FLAT_SKETCH' && tool !== 'INPAINTING',
      );
    }

    setTools(currentTools);
  }, [isPattern, isHidingTool, generatedTaskType]);

  useEffect(() => {
    if (
      ['UPSCALE_IMAGE', 'ENHANCE_IMAGE'].includes(studioTask?.generateTaskType)
    ) {
      setCanFlowsToTask(
        STUDIO_TOOLS_CONFIG[
          studioTask?.payload?.image?.generateTaskType ||
            studioTask?.generateTaskType
        ]?.canFlowsToTask ?? {},
      );
    } else {
      setCanFlowsToTask(
        STUDIO_TOOLS_CONFIG[studioTask?.generateTaskType]?.canFlowsToTask ?? {},
      );
    }
  }, [studioTask]);
  // Memoize handlers to prevent unnecessary re-renders
  const handleToolSelect = useCallback(
    (tool) => {
      setSelectedTool(tool);
      updateCreateItemDetails(tool);
    },
    [
      setSelectedTool,
      updateCreateItemDetails,
      entityType,
      generatedTaskType,
      studioTask?.images,
      selectedGeneratedImage,
    ],
  );

  const toolsToRender = tools
    .filter((tool) =>
      import.meta.env.VITE_APP_ENV === 'development'
        ? true
        : tool !== 'INPAINTING',
    )
    .map((tool) => {
      const config = STUDIO_TOOLS_CONFIG[tool];
      if (!config.canGenerate) return null;

      return (
        <div
          className="h-full"
          key={tool}
          onClick={() => handleToolSelect(tool)}
        >
          <GenerateButton
            variant="tool"
            text={config.buttonText}
            icon={config.icon}
            tool={tool}
            disabled={canFlowsToTask ? !canFlowsToTask[tool] : false}
          />
        </div>
      );
    });

  const sidebarToolsToRender = (
    isPattern ? DEFAULT_PATTERN_TOOLS : DEFAULT_APPAREL_TOOLS
  ).map(
    (tool, index) =>
      !STUDIO_TOOLS_CONFIG[tool].canGenerate && (
        <SidebarButton
          key={index}
          text={STUDIO_TOOLS_CONFIG[tool].buttonText}
          icon={STUDIO_TOOLS_CONFIG[tool].icon}
          disabled={
            studioTask?.status !== 'COMPLETED' ||
            (canFlowsToTask ? !canFlowsToTask[tool] : true)
          }
          variant="tool"
          active={selectedTool === tool}
          className={cn('shadow-sm')}
          onClick={() => {
            setSelectedTool(tool);
            updateCreateItemDetails(tool);
          }}
        />
      ),
  );

  return (
    <div className="grid min-w-full grid-cols-1 gap-x-4 gap-y-6 lg:grid-cols-2">
      {toolsToRender}
      {sidebarToolsToRender}
    </div>
  );
};

export const StudioTools = () => {
  const {
    openTools,
    setOpenTools,
    studioTaskData,
    studioTask,
    studioTaskLoading,
    refSoloItemType,
    createItem,
    processStudioTaskLoading,
    positivePrompt,
  } = useStudioContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState(
    studioTaskData?.studioTask?.payload?.positivePrompt ||
      studioTask?.payload?.positivePrompt ||
      '',
  );

  const [generatedTaskType, setGeneratedTaskType] = useState();

  const [referenceImages, setReferenceImages] = useState();

  const [orientation, setOrientation] = useState();

  const [isPattern, setIsPattern] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('home')) {
      setOpenTools(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    setIsProcessing(
      studioTaskData?.studioTask?.status === 'PENDING_GENERATION' ||
        studioTaskLoading ||
        processStudioTaskLoading,
    );
  }, [
    studioTaskData?.studioTask?.status,
    studioTaskLoading,
    processStudioTaskLoading,
  ]);

  useEffect(() => {
    if (studioTask?.images?.[0]?.generateTaskType) {
      setGeneratedTaskType(studioTask?.images?.[0]?.generateTaskType);
    } else if (studioTaskData?.studioTask?.generateTaskType) {
      setGeneratedTaskType(studioTaskData?.studioTask?.generateTaskType);
    } else if (createItem.type) {
      setGeneratedTaskType(createItem.type);
    }
  }, [studioTask, studioTaskData, createItem]);

  useEffect(() => {
    if (studioTaskData?.studioTask.status !== 'COMPLETED') {
      setCurrentPrompt(positivePrompt);
    }
  }, [studioTaskData?.studioTask?.status]);

  useEffect(() => {
    setReferenceImages([]);
    if (studioTaskData?.studioTask?.payload?.referenceImages?.length > 0) {
      setReferenceImages(studioTaskData?.studioTask?.payload?.referenceImages);
    }

    if (studioTask?.payload?.image) {
      setReferenceImages([studioTask?.payload?.image]);
    }

    if (studioTask?.payload?.referenceImages?.length > 0) {
      setReferenceImages(studioTask?.payload?.referenceImages);
    }

    if (studioTaskData?.studioTask?.image) {
      setReferenceImages([studioTaskData?.studioTask?.image]);
    }
  }, [
    studioTaskData?.studioTask?.payload?.referenceImages,
    studioTaskData?.studioTask?.payload?.image,
    studioTask?.payload?.image,
    studioTask?.payload?.referenceImages,
    studioTaskData?.studioTask?.images,
    createItem,
  ]);

  useEffect(() => {
    if (studioTaskData?.studioTask?.payload?.orientation) {
      setOrientation(studioTaskData?.studioTask?.payload?.orientation);
    }

    if (studioTask?.payload?.orientation) {
      setOrientation(studioTask?.payload?.orientation);
    }
  }, [
    studioTaskData?.studioTask?.payload?.orientation,
    studioTask?.payload?.orientation,
  ]);

  useEffect(() => {
    const patternTypes = [
      'GENERATE_PATTERN',
      'PATTERN_TILE',
      'CREATE_PATTERN_VARIANTS',
    ];

    if (
      ['UPSCALE_IMAGE', 'ENHANCE_IMAGE', 'ZOOM_IN_IMAGE'].includes(
        generatedTaskType,
      )
    ) {
      setIsPattern(
        patternTypes.includes(
          studioTaskData?.studioTask?.payload?.image?.generateTaskType ||
            generatedTaskType,
        ),
      );
    } else {
      setIsPattern(patternTypes.includes(generatedTaskType));
    }
  }, [generatedTaskType, refSoloItemType, createItem, studioTaskData]);

  useEffect(() => {
    const newPrompt = studioTask?.payload?.positivePrompt || '';
    if (newPrompt !== currentPrompt) {
      setCurrentPrompt(newPrompt);
    }
  }, [studioTask?.payload, currentPrompt]);
  return (
    <div
      className={cn(
        'flex h-full w-full items-start justify-center md:justify-start',
        !openTools && 'hidden',
      )}
    >
      <div className="flex h-full w-full max-w-[36rem] flex-col items-start gap-6 p-6">
        <div className="flex w-full flex-col gap-4 pt-2">
          <h2 className="text-2xl font-bold leading-6 text-night" inert="">
            {isPattern ? 'Pattern Tools' : 'Design Tools'}
          </h2>
        </div>
        <StudioToolsGrid
          isPattern={isPattern}
          generatedTaskType={generatedTaskType}
          entityType={studioTaskData?.studioTask?.images?.[0]?.entityType}
        />
        <div className="flex min-h-0 w-full flex-col gap-4">
          {(studioTaskData?.studioTask?.payload?.positivePrompt ||
            !isProcessing) && (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-lg font-bold leading-8 text-night">Prompt</h3>
              <p
                className={cn(
                  'text-sm text-night min-h-6 rounded',
                  // isProcessing && 'ghost w-full min-w-32 h-12',
                )}
              >
                {currentPrompt}
              </p>
            </div>
          )}
          {!isProcessing && (
            <div className="flex w-full flex-col gap-2">
              <p className="text-xs font-bold text-secondary">
                {dayjs(studioTaskData?.studioTask?.createdAt).format(
                  'D MMMM YYYY',
                )}
              </p>
            </div>
          )}
          {referenceImages?.length > 0 && (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-lg font-bold leading-8 text-night">
                Reference Image
              </h3>
              <img
                src={referenceImages[0].url}
                alt="Reference Image"
                className={cn(
                  'rounded max-h-60 w-max',
                  orientation === 'LANDSCAPE'
                    ? 'aspect-[4/3]'
                    : orientation === 'PORTRAIT'
                      ? 'aspect-[3/4]'
                      : 'aspect-square',
                )}
              />
            </div>
          )}
          {(studioTaskData?.studioTask?.payload?.orientation ||
            !isProcessing) && (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-lg font-bold leading-8 text-night">Size</h3>
              <p
                className={cn(
                  'text-sm font-bold text-secondary h-6 rounded',
                  // isProcessing && 'ghost w-1/2',
                )}
              >
                {orientation}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
