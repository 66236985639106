import { gql } from '@apollo/client';

export const CREATE_FAVORITE_FILTER = gql`
  mutation CreateFavoriteFilter($input: CreateFavoriteFilterInput!) {
    createFavoriteFilter(input: $input) {
      section
      label
      value
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_FAVORITE_FILTER = gql`
  mutation UpdateFavoriteFilter($input: UpdateFavoriteFilterInput!) {
    updateFavoriteFilter(input: $input) {
      id
      label
    }
  }
`;

export const DELETE_FAVORITE_FILTER = gql`
  mutation DeleteFavoriteFilter($deleteFavoriteFilterId: ID!) {
    deleteFavoriteFilter(id: $deleteFavoriteFilterId)
  }
`;
