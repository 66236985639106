import { useEffect, useRef, useState } from 'react';

import { useStudioContext } from '@/contexts/StudioContext';

// Animation configuration
const ANIMATION_DURATION = 300; // ms
const ANIMATION_STEPS = 20;

const minSelectedAreaRatio = {
  COLOR_TRANSFER: 0.1,
  ZOOM_IN_IMAGE: 0.33,
};

const allowedAspectRatios = [
  {
    name: 'square',
    ratio: 1,
  },
  {
    name: 'landscape',
    ratio: 4 / 3,
  },
  {
    name: 'portrait',
    ratio: 3 / 4,
  },
  {
    name: 'custom',
    ratio: 0,
  },
];

const StudioImage = ({
  image,
  onChangeSelectedArea,
  onChangeBrush,
  onClickHook,
  actionType = 'default',
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(false);
  const [imageWidth, setImageWidth] = useState(image?.width || 100);
  const [imageHeight, setImageHeight] = useState(image?.height || 100);

  const animationRef = useRef(null);

  const [drawing, setDrawing] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [stretching, setStretching] = useState(null);

  const [selectedArea, setSelectedArea] = useState({
    x: 0,
    y: 0,
    width: image?.width || 100,
    height: image?.height || 100,
  });

  const [brushCoordinates, setBrushCoordinates] = useState({});
  const [brushPaths, setBrushPaths] = useState([]);
  const [undoBrushPaths, setUndoBrushPaths] = useState([]);

  const {
    selectedAreaType,
    selectedBrushSize,
    setSelectedBrushSize,
    createItem,
  } = useStudioContext();

  useEffect(() => {
    setSelectedArea({
      x: 0,
      y: 0,
      width: image?.width || 100,
      height: image?.height || 100,
    });
  }, [image]);

  useEffect(() => {
    setSelectedArea({
      x: 0,
      y: 0,
      width: image?.width || 100,
      height: image?.height || 100,
    });
    setBrushCoordinates({});
  }, [selectedAreaType, actionType]);

  useEffect(() => {
    if (selectedBrushSize === 0) {
      setBrushCoordinates({});
      setBrushPaths([]);
      setUndoBrushPaths([]);
      setTimeout(() => {
        setSelectedBrushSize(10);
      }, 250);
    }
    if (selectedBrushSize === -1) {
      if (brushPaths.length > 0) {
        const undoBrushPath = brushPaths.pop();
        setUndoBrushPaths((prev) => [...prev, undoBrushPath]);
      }
    }
    if (selectedBrushSize === -2) {
      if (undoBrushPaths.length > 0) {
        setBrushPaths((prev) => [...prev, undoBrushPaths.pop()]);
      }
    }
  }, [selectedBrushSize]);

  const ratio =
    allowedAspectRatios.find((ratioItem) => ratioItem.name === selectedAreaType)
      ?.ratio || 1;

  useEffect(() => {
    if (actionType === 'area') {
      onChangeSelectedArea({
        x: Math.floor(selectedArea.x),
        y: Math.floor(selectedArea.y),
        width: Math.floor(selectedArea.width),
        height: Math.floor(selectedArea.height),
      });
    } else if (actionType === 'brush') {
      onChangeBrush(brushPaths);
    }
  }, [selectedArea, selectedBrushSize, brushPaths]);

  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [startSelectedArea, setStartSelectedArea] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (actionType === 'area') {
      setSelectedArea({
        x: 0,
        y: 0,
        width: image?.width,
        height: image?.height,
      });
    }
  }, []);

  useEffect(() => {
    if (image.url) {
      const imageToLoad = new window.Image();
      imageToLoad.src = image.url;
      imageToLoad.onload = () => {
        setImageLoading(false);
      };

      if (!initialLoad) {
        setImageWidth(imageToLoad.naturalWidth);
        setImageHeight(imageToLoad.naturalHeight);
        setSelectedArea({
          x: 0,
          y: 0,
          width: imageWidth,
          height: imageHeight,
        });

        setInitialLoad(true);
      }
    }
  }, [image, initialLoad]);

  const animateToMinimumArea = (currentArea, targetArea) => {
    let step = 0;
    const stepDuration = ANIMATION_DURATION / ANIMATION_STEPS;

    const animate = () => {
      if (step >= ANIMATION_STEPS) {
        setSelectedArea(targetArea);
        return;
      }

      step++;
      const progress = step / ANIMATION_STEPS;
      const easeProgress = 1 - Math.pow(1 - progress, 3); // Cubic ease-out

      const newArea = {
        x: Math.floor(
          currentArea.x + (targetArea.x - currentArea.x) * easeProgress,
        ),
        y: Math.floor(
          currentArea.y + (targetArea.y - currentArea.y) * easeProgress,
        ),
        width: Math.floor(
          currentArea.width +
            (targetArea.width - currentArea.width) * easeProgress,
        ),
        height: Math.floor(
          currentArea.height +
            (targetArea.height - currentArea.height) * easeProgress,
        ),
      };

      setSelectedArea(newArea);

      // Schedule next frame
      animationRef.current = setTimeout(() => {
        requestAnimationFrame(animate);
      }, stepDuration);
    };

    // Clear any existing animation
    if (animationRef.current) {
      clearTimeout(animationRef.current);
    }

    // Start animation
    requestAnimationFrame(animate);
  };

  // Cleanup animation on unmount
  useEffect(() => {
    return () => {
      if (animationRef.current) {
        clearTimeout(animationRef.current);
      }
    };
  }, []);

  const onMouseDownSelectArea = (event) => {
    const svg = event.currentTarget;
    if (!svg.createSVGPoint) return;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    const ctm = svg.getScreenCTM().inverse();
    const svgPoint = point.matrixTransform(ctm);

    if (
      selectedArea.x > 0 &&
      selectedArea.y > 0 &&
      (Math.abs(svgPoint.x - selectedArea.x) < 15 ||
        Math.abs(svgPoint.x - (selectedArea.x + selectedArea.width)) < 15 ||
        Math.abs(svgPoint.y - selectedArea.y) < 15 ||
        Math.abs(svgPoint.y - (selectedArea.y + selectedArea.height)) < 15)
    ) {
      setStretching({
        corner: getCorner(svgPoint, selectedArea),
      });
    } else if (
      selectedArea.x > 0 &&
      selectedArea.y > 0 &&
      svgPoint.x > selectedArea.x &&
      svgPoint.x < selectedArea.x + selectedArea.width &&
      svgPoint.y > selectedArea.y &&
      svgPoint.y < selectedArea.y + selectedArea.height
    ) {
      setDragging(true);
      setStartPoint({
        x: Math.floor(svgPoint.x - selectedArea.x),
        y: Math.floor(svgPoint.y - selectedArea.y),
      });
    } else {
      setDrawing(true);
      setSelectedArea({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      });
      setStartPoint({
        x: Math.floor(svgPoint.x),
        y: Math.floor(svgPoint.y),
        width: 0,
        height: 0,
      });
    }
  };

  const onMouseMoveSelectArea = (event) => {
    if (!drawing && !dragging && !stretching) return;
    const svg = event.currentTarget;
    if (!svg.createSVGPoint) return;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    const ctm = svg.getScreenCTM().inverse();
    const svgPoint = point.matrixTransform(ctm);

    if (dragging) {
      if (
        Math.floor(svgPoint.x - startPoint.x) < 0 ||
        Math.floor(svgPoint.y - startPoint.y) < 0 ||
        Math.floor(svgPoint.x - startPoint.x) > image.width ||
        Math.floor(svgPoint.y - startPoint.y) > image.height
      ) {
        return;
      }

      setSelectedArea({
        ...selectedArea,
        x: Math.floor(svgPoint.x - startPoint.x),
        y: Math.floor(svgPoint.y - startPoint.y),
      });
    } else if (drawing) {
      const newSelectedArea = {
        x: Math.floor(Math.min(startPoint.x, svgPoint.x)),
        y: Math.floor(Math.min(startPoint.y, svgPoint.y)),
      };

      if (selectedAreaType === 'custom') {
        newSelectedArea.width = Math.floor(Math.abs(startPoint.x - svgPoint.x));
        newSelectedArea.height = Math.floor(
          Math.abs(startPoint.y - svgPoint.y),
        );
      } else {
        const width = Math.floor(Math.abs(startPoint.x - svgPoint.x));
        const height = Math.floor(width / ratio);
        newSelectedArea.width = width;
        newSelectedArea.height = height;
        if (newSelectedArea.y + height > image.height) {
          newSelectedArea.y = image.height - height;
        }
      }
      setSelectedArea(newSelectedArea);
    } else if (stretching) {
      const newSelectedArea = { ...selectedArea };
      const { x: sx, y: sy, width: sw, height: sh } = selectedArea;

      const minWidth =
        createItem.type === 'ZOOM_IN_IMAGE'
          ? image.width * minSelectedAreaRatio.ZOOM_IN_IMAGE
          : 1;
      const minHeight =
        createItem.type === 'ZOOM_IN_IMAGE'
          ? image.height * minSelectedAreaRatio.ZOOM_IN_IMAGE
          : 1;

      if (selectedAreaType === 'custom') {
        switch (stretching.corner) {
          case 'top-left': {
            const newWidth = Math.max(1, Math.floor(sx + sw - svgPoint.x));
            const newHeight = Math.max(1, Math.floor(sy + sh - svgPoint.y));

            // Enforce minimum size if needed
            const minWidthTopLeft =
              createItem.type === 'ZOOM_IN_IMAGE'
                ? image.width * minSelectedAreaRatio.ZOOM_IN_IMAGE
                : 1;
            const minHeightTopLeft =
              createItem.type === 'ZOOM_IN_IMAGE'
                ? image.height * minSelectedAreaRatio.ZOOM_IN_IMAGE
                : 1;

            Object.assign(newSelectedArea, {
              x: Math.min(sx + sw - minWidthTopLeft, Math.floor(svgPoint.x)),
              y: Math.min(sy + sh - minHeightTopLeft, Math.floor(svgPoint.y)),
              width: Math.max(minWidthTopLeft, newWidth),
              height: Math.max(minHeightTopLeft, newHeight),
            });
            break;
          }
          case 'top-right': {
            const newWidth = Math.max(1, Math.floor(svgPoint.x - sx));
            const newHeight = Math.max(1, Math.floor(sy + sh - svgPoint.y));

            const minWidthTopRight =
              createItem.type === 'ZOOM_IN_IMAGE'
                ? image.width * minSelectedAreaRatio.ZOOM_IN_IMAGE
                : 1;
            const minHeightTopRight =
              createItem.type === 'ZOOM_IN_IMAGE'
                ? image.height * minSelectedAreaRatio.ZOOM_IN_IMAGE
                : 1;

            Object.assign(newSelectedArea, {
              y: Math.min(sy + sh - minHeightTopRight, Math.floor(svgPoint.y)),
              width: Math.max(minWidthTopRight, newWidth),
              height: Math.max(minHeightTopRight, newHeight),
            });
            break;
          }
          case 'bottom-left': {
            const newWidth = Math.max(1, Math.floor(sx + sw - svgPoint.x));
            const newHeight = Math.max(1, Math.floor(svgPoint.y - sy));

            const minWidthBottomLeft =
              createItem.type === 'ZOOM_IN_IMAGE'
                ? image.width * minSelectedAreaRatio.ZOOM_IN_IMAGE
                : 1;
            const minHeightBottomLeft =
              createItem.type === 'ZOOM_IN_IMAGE'
                ? image.height * minSelectedAreaRatio.ZOOM_IN_IMAGE
                : 1;

            Object.assign(newSelectedArea, {
              x: Math.min(sx + sw - minWidthBottomLeft, Math.floor(svgPoint.x)),
              width: Math.max(minWidthBottomLeft, newWidth),
              height: Math.max(minHeightBottomLeft, newHeight),
            });
            break;
          }
          case 'bottom-right': {
            const newWidth = Math.max(1, Math.floor(svgPoint.x - sx));
            const newHeight = Math.max(1, Math.floor(svgPoint.y - sy));

            const minWidthBottomRight =
              createItem.type === 'ZOOM_IN_IMAGE'
                ? image.width * minSelectedAreaRatio.ZOOM_IN_IMAGE
                : 1;
            const minHeightBottomRight =
              createItem.type === 'ZOOM_IN_IMAGE'
                ? image.height * minSelectedAreaRatio.ZOOM_IN_IMAGE
                : 1;

            newSelectedArea.width = Math.max(minWidthBottomRight, newWidth);
            newSelectedArea.height = Math.max(minHeightBottomRight, newHeight);
            break;
          }
          case 'top': {
            const newHeightTop = Math.max(
              minHeight,
              Math.floor(sy + sh - svgPoint.y),
            );
            Object.assign(newSelectedArea, {
              y: Math.min(sy + sh - minHeight, Math.floor(svgPoint.y)),
              height: newHeightTop,
            });
            break;
          }
          case 'right': {
            const newWidthRight = Math.max(
              minWidth,
              Math.floor(svgPoint.x - sx),
            );
            newSelectedArea.width = newWidthRight;
            break;
          }
          case 'bottom': {
            const newHeightBottom = Math.max(
              minHeight,
              Math.floor(svgPoint.y - sy),
            );
            newSelectedArea.height = newHeightBottom;
            break;
          }
          case 'left': {
            const newWidthLeft = Math.max(
              minWidth,
              Math.floor(sx + sw - svgPoint.x),
            );
            Object.assign(newSelectedArea, {
              x: Math.min(sx + sw - minWidth, Math.floor(svgPoint.x)),
              width: newWidthLeft,
            });
            break;
          }
          default: {
            break;
          }
        }
      } else {
        // Handle fixed aspect ratio stretching
        const aspectRatio = ratio;
        switch (stretching.corner) {
          case 'top-left': {
            const widthTopLeft = Math.max(1, Math.floor(sx + sw - svgPoint.x));
            const minWidthTopLeft =
              createItem.type === 'ZOOM_IN_IMAGE'
                ? image.width * minSelectedAreaRatio.ZOOM_IN_IMAGE
                : 1;

            newSelectedArea.width = Math.max(minWidthTopLeft, widthTopLeft);
            newSelectedArea.height = Math.floor(
              newSelectedArea.width / aspectRatio,
            );
            newSelectedArea.x = sx + sw - newSelectedArea.width;
            newSelectedArea.y = sy + sh - newSelectedArea.height;
            break;
          }
          case 'top-right': {
            const widthTopRight = Math.max(1, Math.floor(svgPoint.x - sx));
            const minWidthTopRight =
              createItem.type === 'ZOOM_IN_IMAGE'
                ? image.width * minSelectedAreaRatio.ZOOM_IN_IMAGE
                : 1;

            newSelectedArea.width = Math.max(minWidthTopRight, widthTopRight);
            newSelectedArea.height = Math.floor(
              newSelectedArea.width / aspectRatio,
            );
            newSelectedArea.y = sy + sh - newSelectedArea.height;
            break;
          }
          case 'bottom-left': {
            const widthBottomLeft = Math.max(
              1,
              Math.floor(sx + sw - svgPoint.x),
            );
            const minWidthBottomLeft =
              createItem.type === 'ZOOM_IN_IMAGE'
                ? image.width * minSelectedAreaRatio.ZOOM_IN_IMAGE
                : 1;

            newSelectedArea.width = Math.max(
              minWidthBottomLeft,
              widthBottomLeft,
            );
            newSelectedArea.height = Math.floor(
              newSelectedArea.width / aspectRatio,
            );
            newSelectedArea.x = sx + sw - newSelectedArea.width;
            break;
          }
          case 'bottom-right': {
            const widthBottomRight = Math.max(1, Math.floor(svgPoint.x - sx));
            const minWidthBottomRight =
              createItem.type === 'ZOOM_IN_IMAGE'
                ? image.width * minSelectedAreaRatio.ZOOM_IN_IMAGE
                : 1;

            newSelectedArea.width = Math.max(
              minWidthBottomRight,
              widthBottomRight,
            );
            newSelectedArea.height = Math.floor(
              newSelectedArea.width / aspectRatio,
            );
            break;
          }
          case 'top':
          case 'bottom': {
            const newHeight = Math.max(
              minHeight,
              stretching.corner === 'top'
                ? Math.floor(sy + sh - svgPoint.y)
                : Math.floor(svgPoint.y - sy),
            );
            const newWidth = Math.floor(newHeight * aspectRatio);

            newSelectedArea.width = Math.max(minWidth, newWidth);
            newSelectedArea.height = Math.floor(
              newSelectedArea.width / aspectRatio,
            );

            if (stretching.corner === 'top') {
              newSelectedArea.y = sy + sh - newSelectedArea.height;
            }
            break;
          }
          case 'left':
          case 'right': {
            const newWidth = Math.max(
              minWidth,
              stretching.corner === 'left'
                ? Math.floor(sx + sw - svgPoint.x)
                : Math.floor(svgPoint.x - sx),
            );

            newSelectedArea.width = newWidth;
            newSelectedArea.height = Math.floor(
              newSelectedArea.width / aspectRatio,
            );

            if (stretching.corner === 'left') {
              newSelectedArea.x = sx + sw - newSelectedArea.width;
            }
            break;
          }
          default: {
            break;
          }
        }
      }

      // Ensure the area stays within image bounds
      newSelectedArea.x = Math.max(
        0,
        Math.min(newSelectedArea.x, image.width - newSelectedArea.width),
      );
      newSelectedArea.y = Math.max(
        0,
        Math.min(newSelectedArea.y, image.height - newSelectedArea.height),
      );

      setStartSelectedArea(newSelectedArea);
      setSelectedArea(newSelectedArea);
    }
  };

  const onMouseDownBrush = (event) => {
    const svg = event.currentTarget;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    const ctm = svg.getScreenCTM().inverse();
    const svgPoint = point.matrixTransform(ctm);

    setStartPoint({
      x: svgPoint.x,
      y: svgPoint.y,
    });

    setDrawing(true);
  };

  const onMouseMoveBrush = (event) => {
    if (!drawing) return;

    const svg = event.currentTarget;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    const ctm = svg.getScreenCTM().inverse();
    const svgPoint = point.matrixTransform(ctm);

    const newPoint = {
      x: svgPoint.x,
      y: svgPoint.y,
    };
    if (selectedBrushSize !== 0) {
      setBrushCoordinates((prev) => {
        const newPath = `M ${startPoint.x.toFixed(0)} ${startPoint.y.toFixed(0)} L ${newPoint.x.toFixed(0)} ${newPoint.y.toFixed(0)}`;
        const paths = Object.keys(prev);
        return {
          [paths.join(' ') + ' ' + newPath]: true,
        };
      });
    }

    setStartPoint(newPoint);
  };

  const onMouseUp = () => {
    if (actionType === 'default' && onClickHook) {
      onClickHook();
      return;
    }

    if (actionType === 'brush') {
      if (onClickHook) onClickHook();
      setBrushPaths((prev) => [
        ...prev,
        {
          path: Object.keys(brushCoordinates).join(' '),
          size: selectedBrushSize,
        },
      ]);
      setBrushCoordinates({});
      setDrawing(false);
      return;
    }

    const finalizeArea = (area) => {
      if (area.width === 0 || area.height === 0) {
        setStartPoint({ x: 0, y: 0 });
        setDrawing(false);
        setStretching(null);
        setDragging(false);
        return {
          x: 0,
          y: 0,
          width: image.width,
          height: image.height,
        };
      }
      // First ensure the area is within bounds
      const boundedArea = {
        ...area,
        x: Math.max(0, Math.min(area.x, image.width - area.width)),
        y: Math.max(0, Math.min(area.y, image.height - area.height)),
        width: Math.max(1, Math.min(area.width, image.width - area.x)),
        height: Math.max(1, Math.min(area.height, image.height - area.y)),
      };

      // Then enforce minimum size if needed
      if (createItem.type === 'ZOOM_IN_IMAGE') {
        const minRatio = minSelectedAreaRatio.ZOOM_IN_IMAGE;
        const minWidth = image.width * minRatio;
        const minHeight = image.height * minRatio;

        if (boundedArea.width < minWidth || boundedArea.height < minHeight) {
          const currentAspectRatio = boundedArea.width / boundedArea.height;
          let newWidth = Math.max(image.width / 3, minWidth);
          let newHeight = Math.max(image.height / 3, minHeight);

          // Maintain aspect ratio
          if (currentAspectRatio > 1) {
            newHeight = Math.max(minHeight, newWidth / currentAspectRatio);
            newWidth = Math.max(minWidth, newHeight * currentAspectRatio);
          } else {
            newWidth = Math.max(minWidth, newHeight * currentAspectRatio);
            newHeight = Math.max(minHeight, newWidth / currentAspectRatio);
          }

          // Center the new area around the current selection center
          const centerX = boundedArea.x + boundedArea.width / 2;
          const centerY = boundedArea.y + boundedArea.height / 2;

          const targetArea = {
            x: Math.max(
              0,
              Math.min(centerX - newWidth / 2, image.width - newWidth),
            ),
            y: Math.max(
              0,
              Math.min(centerY - newHeight / 2, image.height - newHeight),
            ),
            width: Math.floor(newWidth),
            height: Math.floor(newHeight),
          };

          animateToMinimumArea(boundedArea, targetArea);
          return targetArea;
        }
      }

      return boundedArea;
    };

    if (drawing) {
      const newArea = finalizeArea(selectedArea);
      setSelectedArea(newArea);
      if (onClickHook) onClickHook();
      setDrawing(false);
    }

    if (dragging) {
      const newArea = finalizeArea(selectedArea);
      setSelectedArea(newArea);
      setDragging(false);
    }

    if (stretching) {
      if (startSelectedArea) {
        const newArea = finalizeArea(startSelectedArea);
        setSelectedArea(newArea);
      }
      setStretching(null);
      setStartSelectedArea(null);
    }
  };

  const MouseEventsMap = {
    area: {
      onMouseDown: onMouseDownSelectArea,
      onMouseMove: onMouseMoveSelectArea,
      onMouseUp,
    },
    brush: {
      onMouseDown: onMouseDownBrush,
      onMouseMove: onMouseMoveBrush,
      onMouseUp,
    },
    default: {
      onMouseUp,
    },
  };

  const getCorner = (point, area) => {
    const corners = {
      'top-left': [area.x, area.y],
      'top-right': [area.x + area.width, area.y],
      'bottom-left': [area.x, area.y + area.height],
      'bottom-right': [area.x + area.width, area.y + area.height],
    };

    const edges = {
      top: { x: area.x + area.width / 2, y: area.y },
      right: { x: area.x + area.width, y: area.y + area.height / 2 },
      bottom: { x: area.x + area.width / 2, y: area.y + area.height },
      left: { x: area.x, y: area.y + area.height / 2 },
    };

    // Check corners first
    for (const cornerEl in corners) {
      if (
        Math.abs(point.x - corners[cornerEl][0]) < 15 &&
        Math.abs(point.y - corners[cornerEl][1]) < 15
      ) {
        return cornerEl;
      }
    }

    // Then check edges
    for (const edgeEl in edges) {
      const edge = edges[edgeEl];
      if (edgeEl === 'top' || edgeEl === 'bottom') {
        if (
          Math.abs(point.y - edge.y) < 15 &&
          point.x >= area.x &&
          point.x <= area.x + area.width
        ) {
          return edgeEl;
        }
      } else {
        // left or right
        if (
          Math.abs(point.x - edge.x) < 15 &&
          point.y >= area.y &&
          point.y <= area.y + area.height
        ) {
          return edgeEl;
        }
      }
    }

    return null;
  };

  return imageLoading ? (
    <div></div>
  ) : (
    <div>
      <svg
        className="z-40 h-full w-full max-w-[48rem] shadow-xl"
        viewBox={`0 0 ${image.width} ${image.height}`}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio={'xMidYMid meet'}
        {...MouseEventsMap[actionType]}
      >
        <image
          href={image?.url}
          width={image.width}
          height={image.height}
          className="pointer-events-none"
        />
        <defs>
          <mask id="Mask">
            <rect
              fill="#FAF8F8"
              height="100%"
              rx="0"
              ry="0"
              width="100%"
              x="0"
              y="0"
            ></rect>
            <rect
              height={selectedArea.height}
              id="hole"
              rx="0"
              ry="0"
              width={selectedArea.width}
              x={selectedArea.x}
              y={selectedArea.y}
              style={{
                cursor:
                  selectedArea.height > 0 &&
                  selectedArea.height !== image.height
                    ? 'grab'
                    : 'inherit',
              }}
              onMouseDown={(e) => {
                if (actionType === 'area') {
                  onMouseDownSelectArea(e);
                }
              }}
              onMouseMove={(e) => {
                if (actionType === 'area') {
                  onMouseMoveSelectArea(e);
                }
              }}
              onMouseUp={(e) => {
                if (actionType === 'area') {
                  onMouseUp(e);
                }
              }}
            ></rect>
          </mask>
        </defs>
        <rect
          height="100%"
          mask="url(#Mask)"
          width="100%"
          x="0"
          y="0"
          onMouseDown={(e) => {
            if (actionType === 'area') {
              onMouseDownSelectArea(e);
            }
          }}
          onMouseMove={(e) => {
            if (actionType === 'area') {
              onMouseMoveSelectArea(e);
            }
          }}
          onMouseUp={(e) => {
            if (!drawing && actionType === 'area') {
              setSelectedArea({
                x: 0,
                y: 0,
                width: image.width,
                height: image.height,
              });
            }
            if (actionType === 'area') {
              onMouseUp(e);
            }
          }}
          style={{
            fill: 'rgba(0, 0, 0, 0.5)',
            cursor: drawing ? 'crosshair' : 'default',
          }}
        ></rect>
        <use fill="rgba(0,0,0,0)" href="#hole" stroke="#fff" strokeWidth={2}>
          <rect
            height={selectedArea.height}
            id="hole"
            rx="0"
            ry="0"
            width={selectedArea.width}
            x={selectedArea.x}
            y={selectedArea.y}
          ></rect>
        </use>

        {drawing === false && (
          <>
            <g
              transform={`translate(${selectedArea.x - 12}, ${
                selectedArea.y - 12
              }), rotate(0)`}
              className="cursor-nw-resize"
            >
              <rect fill="rgba(0,0,0,0)" height={30} width={30} x={0} y={0} />
              <path
                d="M24 0H36V12H24C17.37255 12 12 17.37255 12 24V36H0V24C0 10.7452 10.7452 0 30 0Z"
                fill="#FAF8F8"
              />
            </g>

            <g
              transform={`translate(${
                selectedArea.x + selectedArea.width + 12
              }, ${selectedArea.y - 12}), rotate(90)`}
              className="cursor-ne-resize"
            >
              <rect fill="rgba(0,0,0,0)" height={30} width={30} x={0} y={0} />
              <path
                d="M24 0H36V12H24C17.37255 12 12 17.37255 12 24V36H0V24C0 10.7452 10.7452 0 24 0Z"
                fill="#FAF8F8"
              />
            </g>
            <g
              transform={`translate(${selectedArea.x - 12}, ${
                selectedArea.height + selectedArea.y + 12
              }), rotate(270)`}
              className="cursor-sw-resize"
            >
              <rect fill="rgba(0,0,0,0)" height={30} width={30} x={0} y={0} />
              <path
                d="M24 0H36V12H24C17.37255 12 12 17.37255 12 24V36H0V24C0 10.7452 10.7452 0 24 0Z"
                fill="#FAF8F8"
              />
            </g>
            <g
              transform={`translate(${
                selectedArea.width + selectedArea.x + 12
              }, ${selectedArea.height + selectedArea.y + 12}), rotate(180)`}
              className="cursor-se-resize"
            >
              <rect fill="rgba(0,0,0,0)" height={30} width={30} x={0} y={0} />
              <path
                d="M24 0H36V12H24C17.37255 12 12 17.37255 12 24V36H0V24C0 10.7452 10.7452 0 24 0Z"
                fill="#FAF8F8"
              />
            </g>

            <g
              transform={`translate(${selectedArea.x + selectedArea.width / 2 - 15}, ${selectedArea.y - 6})`}
              className="cursor-ns-resize"
            >
              <rect fill="rgba(0,0,0,0)" height={24} width={30} x={0} y={0} />
              <rect width={30} height={4} fill="#FAF8F8" rx={2} />
            </g>

            <g
              transform={`translate(${selectedArea.x + selectedArea.width + 2}, ${selectedArea.y + selectedArea.height / 2 - 15})`}
              className="cursor-ew-resize"
            >
              <rect fill="rgba(0,0,0,0)" height={30} width={24} x={0} y={0} />
              <rect width={4} height={30} fill="#FAF8F8" rx={2} />
            </g>

            <g
              transform={`translate(${selectedArea.x + selectedArea.width / 2 - 15}, ${selectedArea.y + selectedArea.height + 2})`}
              className="cursor-ns-resize"
            >
              <rect fill="rgba(0,0,0,0)" height={24} width={10} x={0} y={0} />
              <rect width={30} height={4} fill="#FAF8F8" rx={2} />
            </g>

            <g
              transform={`translate(${selectedArea.x - 6}, ${selectedArea.y + selectedArea.height / 2 - 15})`}
              className="cursor-ew-resize"
            >
              <rect fill="rgba(0,0,0,0)" height={30} width={24} x={0} y={0} />
              <rect width={4} height={30} fill="#FAF8F8" rx={2} />
            </g>
          </>
        )}

        {actionType === 'brush' && (
          <>
            {brushPaths.map(({ path, size }, index) => (
              <path
                key={index}
                d={path}
                stroke="rgba(0,0,0,0.8)"
                strokeWidth={size}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0.1"
              />
            ))}
            <path
              d={Object.keys(brushCoordinates).join(' ')}
              stroke="rgba(0,0,0,0.8)"
              strokeWidth={selectedBrushSize}
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              fillOpacity="0.1"
            />
          </>
        )}

        <rect
          fill="rgba(0,0,0,0)"
          height="20"
          stroke="none"
          className="cursor-ns-resize"
          width={selectedArea.width}
          x={selectedArea.x}
          y={selectedArea.y - 10}
          onMouseDown={(e) => {
            if (actionType === 'area') {
              setStretching({ corner: 'top' });
              e.stopPropagation();
            }
          }}
        ></rect>

        <rect
          fill="rgba(0,0,0,0)"
          height={selectedArea.height}
          stroke="none"
          width="20"
          className="cursor-ew-resize"
          x={selectedArea.x - 10}
          y={selectedArea.y}
          onMouseDown={(e) => {
            if (actionType === 'area') {
              setStretching({ corner: 'left' });
              e.stopPropagation();
            }
          }}
        ></rect>

        <rect
          fill="rgba(0,0,0,0)"
          height="20"
          stroke="none"
          width={selectedArea.width}
          className="cursor-ns-resize"
          x={selectedArea.x}
          y={selectedArea.y + selectedArea.height - 10}
          onMouseDown={(e) => {
            if (actionType === 'area') {
              setStretching({ corner: 'bottom' });
              e.stopPropagation();
            }
          }}
        ></rect>

        <rect
          fill="rgba(0,0,0,0)"
          height={selectedArea.height}
          stroke="none"
          width="20"
          className="cursor-ew-resize"
          x={selectedArea.x + selectedArea.width - 10}
          y={selectedArea.y}
          onMouseDown={(e) => {
            if (actionType === 'area') {
              setStretching({ corner: 'right' });
              e.stopPropagation();
            }
          }}
        ></rect>
      </svg>
    </div>
  );
};

export default StudioImage;
