import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { debounce, upperFirst } from 'lodash';
import {
  ChevronLeft,
  Copy,
  Earth,
  History,
  Home,
  ImagePlus,
  Lock,
  Plus,
  Sparkles,
  SquareAsterisk,
  Trash,
  X,
} from 'lucide-react';

import {
  NEW_PROMPT_SUGGESTION,
  SUGGESTION_BENCHMARK,
} from '@/api/studio/mutations';
import { GET_STUDIO_TASK_HISTORY } from '@/api/studio/queries';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Textarea } from '@/components/ui/textarea';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import {
  STUDIO_DISPLAY,
  STUDIO_GRAPHIC_TEMPLATES,
  STUDIO_PATTERN_STYLES,
  STUDIO_PATTERN_TEMPLATES,
  STUDIO_PROMPT_TEMPLATES,
  STUDIO_SCENES,
  STUDIO_TOOLS_CONFIG,
} from '@/constants/studio';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useToast } from '@/hooks/useToast';

import { GenerateButton } from './components/GenerateButton';
import ReferenceImageUpload from './components/ReferenceImageUpload';
import {
  CategoryCard,
  SidebarButton,
  SidebarCard,
  SidebarHeader,
  SidebarTitle,
} from './components/SidebarMenu';
import { ToneSelect, UploadBox } from './components/StudioUtils';

const TEMPLATE_CONFIG = {
  GENERATE_PATTERN: STUDIO_PATTERN_TEMPLATES,
  GENERATE_GRAPHIC: STUDIO_GRAPHIC_TEMPLATES,
  GENERATE_APPAREL: STUDIO_PROMPT_TEMPLATES,
};

export const StudioLeftSidebar = () => {
  const [describing, setDescribing] = useState(false);
  const [addNegativeKeywords, setAddNegativeKeywords] = useState(false);
  const [addGraphicText, setAddGraphicText] = useState(false);
  const [selectedAdviceId, setSelectedAdviceId] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [openAccordionItem, setOpenAccordionItem] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState({
    selectedTemplateId: null,
    selectedTemplateIndex: null,
  });
  const [promptId, setPromptId] = useState(null);
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);
  const [descHistory, setDescHistory] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [hidingLayout, setHidingLayout] = useState(false);

  const toast = useToast();

  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isHome = location.pathname.includes('home');

  const {
    referenceError,
    designTask,
    updateCreateItemDetails,
    setIsHidingTool,
    createItem,
    setSelectedTool,
    setGeneratedImages,
    setOrientation,
    setOpenTools,
    setDisplayMode,
    clearDesignTask,
    promptLayoutType,
    setPromptLayoutType,
    positivePrompt,
    setPositivePrompt,
    negativePrompt,
    setNegativePrompt,
    generateSize,
    setGenerateSize,
    generateVisibility,
    setGenerateVisibility,
    referenceImage,
    referenceSketch,
    selectedArea,
    selectedTone,
    setSelectedTone,
    generateNewPromptFromImageData,
    generateNewPromptFromImageLoading,
    selectedScene,
    setSelectedScene,
    imageStrength,
    setImageStrength,
    graphicText,
    setGraphicText,
    selectedPatternStyle,
    setSelectedPatternStyle,
    selectedPatternStyleSubtype,
    setSelectedPatternStyleSubtype,
  } = useStudioContext();

  const { isEnabled: isPrivateImageEnabled } = useFeatureFlag(
    FEATURE_FLAGS.PRIVATE_IMAGE,
  );

  useEffect(() => {
    if (graphicText && graphicText.trim() !== '') {
      setAddGraphicText(true);
    } else {
      setAddGraphicText(false);
    }
  }, [createItem.type]);

  useEffect(() => {
    if (isPrivateImageEnabled) {
      setGenerateVisibility('PRIVATE');
    }
  }, [isPrivateImageEnabled]);

  const [, { refetch: refetchHistory }] = useLazyQuery(GET_STUDIO_TASK_HISTORY);

  const isPositivePromptHidden =
    STUDIO_TOOLS_CONFIG[createItem.type]?.hasReferenceSketch === true &&
    referenceSketch === null;

  useEffect(() => {
    const isActive = () => {
      switch (createItem.type) {
        case 'GENERATE_APPAREL':
        case 'GENERATE_PATTERN':
          return !positivePrompt;
        case 'REALIZE_SKETCH':
          return !positivePrompt || !referenceSketch;
        case 'IMAGE_BACKGROUND_REMOVER':
        case 'UPSCALE_IMAGE':
        case 'ENHANCE_IMAGE':
        case 'CREATE_VARIANTS':
        case 'PATTERN_TILE':
          return !referenceImage;
        case 'ZOOM_IN_IMAGE':
        case 'ZOOM_OUT_IMAGE':
          return !referenceImage || !selectedArea;
        default:
          return true;
      }
    };
    if (createItem.type) {
      setIsActive(isActive());
    }
  }, [
    createItem.type,
    positivePrompt,
    referenceImage,
    referenceSketch,
    selectedArea,
  ]);

  useEffect(() => {
    if (
      createItem?.type === 'GENERATE_APPAREL' ||
      createItem?.type === 'GENERATE_PATTERN'
    ) {
      refetchHistory({
        generationType: createItem.type.toUpperCase(),
      }).then(({ data }) => {
        const positivePrompts = data?.studioTaskHistory?.edges
          ?.map((edge) => edge?.node?.payload?.positivePrompt)
          .filter(Boolean);
        setDescHistory(positivePrompts);
      });
    }
  }, [createItem, refetchHistory]);

  useEffect(() => {
    if (searchParams.has('isGenerating')) {
      setSelectedTemplateId(null);
      setSelectedTemplate({
        selectedTemplateId: null,
        selectedTemplateIndex: null,
      });
      clearDesignTask();
      setDescribing(false);
      setSelectedTool('');
      setGeneratedImages([]);
      setDisplayMode(STUDIO_DISPLAY.GENERATING);
    }
  }, [searchParams.has('isGenerating')]);

  useEffect(() => {
    if (designTask?.error) {
      setDisplayMode(STUDIO_DISPLAY.PENDING);
      toast.createToast({
        message:
          'An error occurred while generating the design. Please try again later.',
        type: 'error',
        showCloseButton: true,
        position: 'top-right',
      });
    }
    if (designTask?.loading === true) {
      setDisplayMode(STUDIO_DISPLAY.GENERATING);
    } else if (!designTask?.error && designTask?.loading === false) {
      setDisplayMode(STUDIO_DISPLAY.RESULT.TILE);
      if (designTask?.payload?.orientation) {
        setOrientation(designTask?.payload?.orientation?.value);
      }
    }
  }, [designTask]);

  useEffect(() => {
    if (createItem.type === 'REALIZE_SKETCH' && referenceSketch === null) {
      setDescribing(false);
    }
  }, [createItem.type, referenceSketch]);

  const [promptSuggestions, { loading: promptLoading, error: promptError }] =
    useMutation(NEW_PROMPT_SUGGESTION, {
      notifyOnNetworkStatusChange: true,
    });

  const debouncedSuggestions = useCallback(
    debounce((value) => {
      if (value.trim()) {
        setPromptLayoutType('ai-gen');
        promptSuggestions({
          variables: {
            prompt: value,
            generationType: createItem.type.toUpperCase(),
            sceneType:
              createItem.type.toUpperCase() === 'GENERATE_APPAREL'
                ? selectedScene
                : undefined,
          },
          onCompleted: (data) => {
            setPromptId(data.studioSuggestNewPrompts.id);
            if (data.studioSuggestNewPrompts.suggestions) {
              setSuggestedPrompts(data.studioSuggestNewPrompts.suggestions);
              setSelectedAdviceId(null);
            } else {
              setSuggestedPrompts([]);
            }
          },
        });
      }
    }, 450),
    [promptSuggestions, createItem, selectedScene],
  );

  const [suggestionBenchmark] = useMutation(SUGGESTION_BENCHMARK);

  useEffect(() => {
    if (generateNewPromptFromImageData) {
      setHidingLayout(true);
    } else {
      setHidingLayout(false);
    }
  }, [generateNewPromptFromImageData]);

  const handlePromptChange = (e) => {
    const value = e.target.value;
    setPositivePrompt(value);
    debouncedSuggestions(value);
    setDescribing(true);
  };

  const handleSelectedPrompt = (index, selectedAdvice, suggestionId) => {
    setSelectedAdviceId(index);
    setPositivePrompt(upperFirst(selectedAdvice));
    suggestionBenchmark({
      variables: {
        payload: {
          promptId,
          suggestionId,
        },
      },
    });
  };

  const getTemplates = (type, templateId = null) => {
    const templates = TEMPLATE_CONFIG[type] || STUDIO_PROMPT_TEMPLATES;
    return templateId !== null ? templates[templateId] : templates;
  };

  if (createItem.type === null) {
    return (
      <div className="flex h-full w-[25rem] flex-col gap-6 border-r border-secondary/50 px-2 py-6 lg:px-6">
        <div
          className={`flex cursor-pointer items-center gap-x-2 rounded ${isHome ? 'bg-primary text-white' : 'border-primary bg-transparent text-black hover:border-primary hover:text-primary'} border border-transparent px-4 py-2 text-[1rem] font-bold leading-[1.75rem]`}
          onClick={() => {
            navigate('/studio/home');
            setOpenTools(false);
          }}
        >
          <Home size={16} />
          <h2>Home</h2>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-sm font-bold text-primary">Create</h3>
          <div className="flex flex-col gap-0">
            <SidebarButton
              variant="nav"
              icon={STUDIO_TOOLS_CONFIG.GENERATE_APPAREL.icon}
              text={STUDIO_TOOLS_CONFIG.GENERATE_APPAREL.text}
              onClick={() => updateCreateItemDetails('GENERATE_APPAREL')}
            />
            <SidebarButton
              variant="nav"
              icon={STUDIO_TOOLS_CONFIG.GENERATE_PATTERN.icon}
              text={STUDIO_TOOLS_CONFIG.GENERATE_PATTERN.text}
              onClick={() => updateCreateItemDetails('GENERATE_PATTERN')}
            />

            <SidebarButton
              variant="nav"
              icon={STUDIO_TOOLS_CONFIG.REALIZE_SKETCH.icon}
              text={STUDIO_TOOLS_CONFIG.REALIZE_SKETCH.text}
              onClick={() => updateCreateItemDetails('REALIZE_SKETCH')}
            />
            <SidebarButton
              variant="nav"
              icon={STUDIO_TOOLS_CONFIG.GENERATE_GRAPHIC.icon}
              text={STUDIO_TOOLS_CONFIG.GENERATE_GRAPHIC.text}
              onClick={() => updateCreateItemDetails('GENERATE_GRAPHIC')}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-sm font-bold text-primary">Edit Tools</h3>
          <div className="flex flex-col gap-0">
            {/* {import.meta.env.VITE_APP_ENV === 'development' && (
              <SidebarButton
                variant="nav"
                icon={STUDIO_TOOLS_CONFIG.applyPattern.icon}
                text={STUDIO_TOOLS_CONFIG.applyPattern.text}
                onClick={() => updateCreateItemDetails('applyPattern')}
              />
            )} */}
            <SidebarButton
              variant="nav"
              icon={STUDIO_TOOLS_CONFIG.IMAGE_BACKGROUND_REMOVER.icon}
              text={STUDIO_TOOLS_CONFIG.IMAGE_BACKGROUND_REMOVER.text}
              onClick={() => {
                setIsHidingTool(['CREATE_VARIANTS']);
                updateCreateItemDetails('IMAGE_BACKGROUND_REMOVER', true);
              }}
            />
            <SidebarButton
              variant="nav"
              icon={STUDIO_TOOLS_CONFIG.UPSCALE_IMAGE.icon}
              text={STUDIO_TOOLS_CONFIG.UPSCALE_IMAGE.text}
              onClick={() => {
                setIsHidingTool(['CREATE_VARIANTS']);
                updateCreateItemDetails('UPSCALE_IMAGE', true);
              }}
            />
            <SidebarButton
              variant="nav"
              icon={STUDIO_TOOLS_CONFIG.ENHANCE_IMAGE.icon}
              text={STUDIO_TOOLS_CONFIG.ENHANCE_IMAGE.text}
              onClick={() => {
                setIsHidingTool(['CREATE_VARIANTS']);
                updateCreateItemDetails('ENHANCE_IMAGE', true);
              }}
            />
            <SidebarButton
              variant="nav"
              icon={STUDIO_TOOLS_CONFIG.IMAGE_CHANGE_BACKGROUND.icon2}
              text={STUDIO_TOOLS_CONFIG.IMAGE_CHANGE_BACKGROUND.text2}
              onClick={() => {
                setIsHidingTool(['CREATE_VARIANTS']);
                updateCreateItemDetails('IMAGE_CHANGE_BACKGROUND', true);
              }}
            />
            <SidebarButton
              variant="nav"
              icon={STUDIO_TOOLS_CONFIG.GENERATE_FLAT_SKETCH.icon}
              text={STUDIO_TOOLS_CONFIG.GENERATE_FLAT_SKETCH.text2}
              onClick={() => {
                setIsHidingTool(['CREATE_VARIANTS']);
                updateCreateItemDetails('GENERATE_FLAT_SKETCH', true);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  const hasGeneratePromptFromImage = [
    'GENERATE_APPAREL',
    'GENERATE_PATTERN',
    'GENERATE_GRAPHIC',
  ].includes(createItem.type);

  return (
    <div className="no-scrollbar flex h-full w-[25.75rem] flex-col justify-between gap-2 overflow-y-auto border-r border-secondary/50">
      <div className="flex flex-col justify-start">
        <div
          className="sticky top-0 z-10 flex flex-col gap-4 bg-white px-2 py-4 lg:px-6"
          id="header"
        >
          <SidebarHeader
            variant="back"
            text="Menu"
            onClick={() => {
              clearDesignTask();
              handlePromptChange({ target: { value: '' } });
              setSuggestedPrompts([]);
              setDescribing(false);
              updateCreateItemDetails(null);
              setSelectedTool('');
              setGenerateSize('PORTRAIT');
              setImageStrength('MEDIUM');
              setHidingLayout(false);
              setAddGraphicText(false);
              setSelectedTemplateId(null);
              setGraphicText('');
              setAddGraphicText(!addGraphicText);
              setPromptLayoutType('templates');
              setSelectedTemplate({
                selectedTemplateId: null,
                selectedTemplateIndex: null,
              });
            }}
          />
          <SidebarHeader icon={createItem.icon} text={createItem.text} />
        </div>
        <div className="flex h-max flex-col justify-start gap-6 px-2 lg:px-6">
          <div
            className={cn(
              'flex flex-col gap-6',
              STUDIO_TOOLS_CONFIG[createItem.type]?.hasReferenceSketch ===
                true && 'flex flex-col-reverse',
            )}
          >
            <div
              className={`flex flex-col gap-6 ${
                STUDIO_TOOLS_CONFIG[createItem.type]?.hasPositivePrompt ===
                false
                  ? 'hidden'
                  : ''
              }`}
            >
              <div className="flex flex-col">
                <div className="mb-2">
                  {!isPositivePromptHidden && (
                    <SidebarTitle text="Describe the Design" />
                  )}
                </div>

                {/* We're adding padding 1px to prevent the align problem caused by the outer top border */}
                <div className="group/textarea flex flex-col overflow-hidden rounded-md rounded-b-none border border-b-0 border-secondary/20 bg-secondary/5">
                  {createItem.type === 'GENERATE_APPAREL' && (
                    <ScrollArea className="max-w-[18rem] border-b border-secondary/20 bg-secondary/5">
                      <ToggleGroup
                        type="single"
                        className="flex w-max justify-start gap-x-1 p-2"
                        value={selectedScene}
                        onValueChange={(value) =>
                          value && setSelectedScene(value)
                        }
                        defaultValue={STUDIO_SCENES[0].value}
                        required
                      >
                        {STUDIO_SCENES.map((scene, i) => (
                          <ToggleGroupItem
                            key={i}
                            active={selectedScene === scene.value}
                            variant="pill"
                            title={scene.title}
                            bgimage={scene.image}
                            value={scene.value}
                          />
                        ))}
                      </ToggleGroup>
                      <ScrollBar orientation="horizontal" />
                    </ScrollArea>
                  )}
                  <div className="max-w-[18rem] border-b border-secondary/20 bg-secondary/5">
                    {createItem.type === 'GENERATE_PATTERN' && (
                      <ScrollArea key={'scroll-area-pattern-style'}>
                        <ToggleGroup
                          type="single"
                          className="flex w-max justify-start gap-x-1 p-2"
                          value={selectedPatternStyle}
                          onValueChange={(value) => {
                            setSelectedPatternStyle(value);
                            if (!value) {
                              setSelectedPatternStyleSubtype(null);
                            }
                          }}
                        >
                          {STUDIO_PATTERN_STYLES.map((style, i) => (
                            <ToggleGroupItem
                              key={i}
                              active={selectedPatternStyle === style.value}
                              variant="pill"
                              title={style.title}
                              bgimage={style.image}
                              value={style.value}
                              allowClear
                            />
                          ))}
                        </ToggleGroup>
                        <ScrollBar orientation="horizontal" />
                      </ScrollArea>
                    )}
                    {createItem.type === 'GENERATE_PATTERN' &&
                      selectedPatternStyle && (
                        <ScrollArea key={'scroll-area-pattern-style-subtype'}>
                          <ToggleGroup
                            type="single"
                            className="flex w-max justify-start gap-x-1 p-2"
                            value={selectedPatternStyleSubtype}
                            onValueChange={setSelectedPatternStyleSubtype}
                          >
                            {STUDIO_PATTERN_STYLES.find(
                              (style) => style.value === selectedPatternStyle,
                            )?.options.map((option, i) => (
                              <ToggleGroupItem
                                key={i}
                                active={selectedPatternStyleSubtype === option}
                                variant="pill"
                                size="small"
                                title={option}
                                value={option}
                                allowClear
                              />
                            ))}
                          </ToggleGroup>
                          <ScrollBar orientation="horizontal" />
                        </ScrollArea>
                      )}
                  </div>
                  <Textarea
                    variant="studio"
                    active={isActive}
                    className={cn(
                      isPositivePromptHidden && 'hidden',
                      'no-scrollbar group focus-visible:none min-h-[6.75rem] resize-none border-night/5 bg-secondary/5 py-2 pr-8 text-night transition duration-300 ease-in-out placeholder:text-night/70  focus:ring-0 focus-visible:outline-none border-none focus:placeholder:text-black ',
                      generateNewPromptFromImageLoading && 'cursor-not-allowed',
                    )}
                    {...(hasGeneratePromptFromImage && { prompt: 'true' })}
                    placeholder={
                      generateNewPromptFromImageLoading
                        ? 'Generating new prompt from reference...'
                        : 'Type a brief description, then wait a moment for AI-generated prompts to appear...'
                    }
                    value={positivePrompt}
                    onChange={handlePromptChange}
                    icon={null}
                    loading={
                      hasGeneratePromptFromImage &&
                      generateNewPromptFromImageLoading
                    }
                    onClick={() => {
                      setDescribing(true);
                      setPromptLayoutType(promptLayoutType || 'templates');
                    }}
                  />
                </div>
                {hasGeneratePromptFromImage && (
                  <UploadBox
                    title="Generate Prompt from Image"
                    icon={ImagePlus}
                    variant="button"
                    type="other"
                    fileEntityType="OTHER"
                    className={cn(
                      'transition-colors duration-300 ease-in-out hover:bg-primary/20 border-secondary/20 border border-night/5',
                      /* isActive && 'border-primary', */
                    )}
                  />
                )}
              </div>
              {describing &&
                STUDIO_TOOLS_CONFIG[createItem.type]?.hasPositivePrompt &&
                STUDIO_TOOLS_CONFIG[createItem.type]?.hasSuggestedPrompt && (
                  <div
                    className={cn(
                      'flex flex-col gap-y-6 transition-all duration-1000 ease-in-out transform opacity-0',
                      describing === true ? 'opacity-100 translate-y-0' : '',
                    )}
                    style={{ transitionProperty: 'opacity, transform' }}
                  >
                    <div id="toggle group">
                      <div className="flex items-center">
                        <ToggleGroup
                          type="single"
                          className={`w-full gap-x-0 transition-opacity duration-500 ease-in-out ${describing ? 'opacity-100' : 'pointer-events-none opacity-0'}`}
                        >
                          {!hidingLayout && (
                            <>
                              {(positivePrompt.length > 0 ||
                                suggestedPrompts.length > 0) && (
                                <ToggleGroupItem
                                  variant="nav"
                                  value="ai-gen"
                                  active={promptLayoutType === 'ai-gen'}
                                  onClick={() => setPromptLayoutType('ai-gen')}
                                >
                                  <Sparkles size={16} />
                                  AI
                                </ToggleGroupItem>
                              )}
                              <ToggleGroupItem
                                variant="nav"
                                value="templates"
                                active={promptLayoutType === 'templates'}
                                onClick={() => setPromptLayoutType('templates')}
                              >
                                <Copy size={16} />
                                Templates
                              </ToggleGroupItem>
                            </>
                          )}
                          <ToggleGroupItem
                            variant="nav"
                            value="history"
                            active={promptLayoutType === 'history'}
                            onClick={() => setPromptLayoutType('history')}
                          >
                            <History size={16} />
                            History
                          </ToggleGroupItem>
                        </ToggleGroup>
                        <button
                          onClick={() => setDescribing(!describing)}
                          className="transition-transform duration-300"
                          style={{
                            transform: describing
                              ? 'rotate(0deg)'
                              : 'rotate(180deg)',
                          }}
                        >
                          <X size={16} />
                        </button>
                      </div>
                    </div>
                    {!hidingLayout && (
                      <>
                        {promptLayoutType === 'ai-gen' && promptLoading && (
                          <div className="flex flex-col gap-y-2">
                            {Array.from({ length: 5 }).map((_, i) => (
                              <SidebarCard
                                key={i}
                                index={i}
                                variant="ghost"
                                value="Your prompt is being generated.."
                              />
                            ))}
                          </div>
                        )}
                        {promptLayoutType === 'ai-gen' && !promptLoading && (
                          <div className="flex flex-col gap-y-2">
                            {suggestedPrompts
                              .filter((advice) => {
                                const trimmedValue = advice.value.trim();
                                return !(
                                  (trimmedValue.startsWith('"') &&
                                    trimmedValue.endsWith('"')) ||
                                  (trimmedValue.startsWith("'") &&
                                    trimmedValue.endsWith("'"))
                                );
                              })
                              .map((advice, i) => (
                                <SidebarCard
                                  key={i}
                                  index={i}
                                  value={upperFirst(advice.value)}
                                  selectedId={selectedAdviceId}
                                  onClick={() =>
                                    handleSelectedPrompt(
                                      i,
                                      advice.value,
                                      advice.key,
                                    )
                                  }
                                />
                              ))}
                          </div>
                        )}
                        {promptLayoutType === 'ai-gen' &&
                          !promptLoading &&
                          !suggestedPrompts.length === 0 &&
                          promptError && (
                            <div className="flex flex-col gap-y-2">
                              <SidebarCard
                                value="Failed to generate prompt"
                                variant="error"
                              />
                            </div>
                          )}
                      </>
                    )}
                    {!hidingLayout && (
                      <>
                        {promptLayoutType === 'templates' && (
                          <div className="flex flex-col gap-y-2">
                            {selectedTemplateId === null ? (
                              getTemplates(createItem.type).map(
                                (template, i) => (
                                  <CategoryCard
                                    key={i}
                                    category={template}
                                    index={i}
                                    setId={setSelectedTemplateId}
                                  />
                                ),
                              )
                            ) : (
                              <div className="flex flex-col gap-y-2">
                                <SidebarHeader
                                  icon={ChevronLeft}
                                  text={
                                    getTemplates(
                                      createItem.type,
                                      selectedTemplateId,
                                    ).title
                                  }
                                  onClick={() => {
                                    setSelectedTemplateId(null);
                                    setSelectedTemplate({
                                      selectedTemplateId: null,
                                      selectedTemplateIndex: null,
                                    });
                                  }}
                                  variant="back"
                                />
                                {getTemplates(
                                  createItem.type,
                                  selectedTemplateId,
                                ).list.map((item, i) => (
                                  <SidebarCard
                                    key={i}
                                    index={i}
                                    value={item}
                                    selectedId={
                                      selectedTemplate.selectedTemplateIndex
                                    }
                                    onClick={() => {
                                      setSelectedTemplate({
                                        selectedTemplateId,
                                        selectedTemplateIndex: i,
                                      });
                                      setPositivePrompt(item);
                                      debouncedSuggestions(item);
                                      setPromptLayoutType('ai-gen');
                                    }}
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {promptLayoutType === 'history' && (
                      <div className="flex flex-col gap-y-2">
                        {descHistory?.slice(0, 5).map((desc, i) => (
                          <SidebarCard
                            key={i}
                            index={i}
                            value={desc}
                            selectedId={selectedAdviceId}
                            onClick={() => {
                              setSelectedAdviceId(i);
                              setPositivePrompt(desc);
                            }}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}
              {/*   {createItem?.type === 'GENERATE_APPAREL' && (
                <div className="flex flex-row">
                  <Accordion
                    className="w-full overflow-hidden"
                    type="single"
                    collapsible
                    value={openItem}
                    onValueChange={(value) =>
                      setOpenItem(value === openItem ? null : value)
                    }
                  >
                    <AccordionItem value="item-1">
                      <AccordionTrigger className="p-0 pb-1">
                        <SidebarTitle text="Scenes" />
                      </AccordionTrigger>
                      <AccordionContent className="w-max pb-1">
                        <ScrollArea className="max-w-[18rem] whitespace-nowrap">
                          <ToggleGroup
                            type="single"
                            className="flex w-max flex-row gap-x-2 py-2"
                          >
                            {STUDIO_SCENES.map((scene, i) => (
                              <div
                                key={i}
                                className={cn(
                                  'bg-white p-1 border border-transparent shadow rounded-md hover:border-primary hover:w-[6.5rem] w-[6rem] transition-all duration-300 ease-in-out',
                                  selectedScene === scene.value &&
                                    'border border-primary shadow w-[6.5rem]',
                                )}
                                onClick={() => setSelectedScene(scene.value)}
                              >
                                <ToggleGroupItem
                                  variant="pill"
                                  title={scene.title}
                                  bgimage={scene.image}
                                  value={scene.value}
                                />
                              </div>
                            ))}
                          </ToggleGroup>
                          <ScrollBar orientation="horizontal" />
                        </ScrollArea>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </div>
              )} */}
              {!addNegativeKeywords ? (
                <SidebarButton
                  icon={Plus}
                  text="Add Negative Keywords"
                  className="hidden gap-2 px-4 py-2"
                  onClick={() => setAddNegativeKeywords(!addNegativeKeywords)}
                />
              ) : (
                <div className="hidden flex-col gap-y-2">
                  <SidebarTitle text="Negative Keywords" />
                  <Textarea
                    variant="studio"
                    icon={Trash}
                    onIconClick={() =>
                      setAddNegativeKeywords(!addNegativeKeywords)
                    }
                    value={negativePrompt}
                    onChange={(e) => {
                      setNegativePrompt(e.target.value);
                    }}
                    className="focus-visible:none h-[6.75rem] resize-none rounded border border-night/5 bg-secondary/5 py-2 pr-8 text-night transition duration-300 ease-in-out placeholder:text-night/70 hover:border-primary hover:bg-[#FFFDFD] focus:border-primary focus:bg-[#FFFDFD] focus:ring-0 focus-visible:outline-none"
                    placeholder="Describe details you don't want in your design"
                  />
                </div>
              )}
              {createItem?.type === 'GENERATE_GRAPHIC' &&
                !addGraphicText &&
                graphicText.length === 0 && (
                  <SidebarButton
                    icon={Plus}
                    text="Add text to graphic (optional)"
                    className="gap-2 rounded border border-primary bg-primary/10 px-4 py-2 text-sm text-primary hover:bg-primary/20"
                    variant="nav"
                    onClick={() => setAddGraphicText(!addGraphicText)}
                  />
                )}
              {createItem?.type === 'GENERATE_GRAPHIC' && addGraphicText && (
                <div className="flex flex-col gap-y-2">
                  <SidebarTitle text="Add text to your graphic" />
                  <Textarea
                    variant="studio"
                    value={graphicText}
                    icon={X}
                    onIconClick={() => {
                      setGraphicText('');
                      setAddGraphicText(!addGraphicText);
                    }}
                    onChange={(e) => setGraphicText(e.target.value)}
                    placeholder="Type your text here"
                    className="focus-visible:none h-[2.4rem] min-h-[2.4rem] resize-none rounded border border-night/5 bg-secondary/5 py-2 pr-8 text-night transition duration-300 ease-in-out placeholder:text-night/70 hover:border-primary hover:bg-[#FFFDFD] focus:border-primary focus:bg-[#FFFDFD] focus:ring-0 focus-visible:outline-none"
                  />
                </div>
              )}
            </div>
            {/* Upload Images */}
            <div className="grid grid-cols-2 gap-4">
              {STUDIO_TOOLS_CONFIG[createItem.type]?.hasReferenceImage ===
                true && (
                <ReferenceImageUpload
                  title="Reference Image"
                  icon={ImagePlus}
                  text="Drop an image or click to upload"
                  fileEntityType="APPAREL"
                  guideText="Upload a high-resolution image of a single person, centered, well-lit, and with a simple background."
                  images={Array.from({ length: 6 }).map(
                    (_, i) =>
                      `/assets/studio/studio-references/image${i + 1}.png`,
                  )}
                />
              )}

              {STUDIO_TOOLS_CONFIG[createItem.type]?.hasReferencePattern ===
                true && (
                <ReferenceImageUpload
                  title="Reference Pattern"
                  icon={SquareAsterisk}
                  text="Drop a pattern or click to upload"
                  fileEntityType="PATTERN"
                  guideText="Upload high resolution pattern images on fabric or flat surfaces, not on worn clothing."
                  images={Array.from({ length: 6 }).map(
                    (_, i) =>
                      `/assets/studio/studio-references/pattern${i + 1}.png`,
                  )}
                />
              )}
              {STUDIO_TOOLS_CONFIG[createItem.type]?.hasReferenceSketch ===
                true && (
                <ReferenceImageUpload
                  title="Reference Sketch"
                  icon={ImagePlus}
                  text="Drop a sketch or click to upload"
                  fileEntityType="SKETCH"
                  guideText="Upload a high-resolution image of a sketch."
                  images={Array.from({ length: 6 }).map(
                    (_, i) =>
                      `/assets/studio/studio-references/sketch${i + 1}.jpg`,
                  )}
                />
              )}

              {STUDIO_TOOLS_CONFIG[createItem.type]?.hasReferenceOtherImage ===
                true && (
                <ReferenceImageUpload
                  title="Background Image"
                  icon={ImagePlus}
                  text="Drop an image or click to upload"
                  fileEntityType="OTHER"
                  guideText="Upload a high-resolution image of a background."
                  images={Array.from({ length: 6 }).map(
                    (_, i) =>
                      `/assets/studio/studio-references/background${i + 1}.jpg`,
                  )}
                />
              )}

              {STUDIO_TOOLS_CONFIG[createItem.type]?.hasReferenceGraphic ===
                true && (
                <ReferenceImageUpload
                  title="Reference Graphic"
                  icon={ImagePlus}
                  text="Drop an image or click to upload"
                  fileEntityType="GRAPHIC"
                  guideText="Upload a high-resolution image of a graphic."
                  images={Array.from({ length: 6 }).map(
                    (_, i) =>
                      `/assets/studio/studio-references/graphic${i + 1}.jpg`,
                  )}
                />
              )}
            </div>

            {STUDIO_TOOLS_CONFIG[createItem.type]?.hasColorToneSelect ===
              true && (
              <ToneSelect
                onChange={(newVal) => {
                  setSelectedTone(newVal);
                }}
                currentValue={selectedTone}
              />
            )}

            {referenceError && (
              <div className="text-sm font-normal text-red-600">
                {referenceError}
              </div>
            )}
          </div>
          <div className="flex flex-row  rounded-md border border-night/5 bg-secondary/5">
            <Accordion
              className="w-full"
              type="single"
              collapsible
              openItem={openAccordionItem}
              setOpenItem={setOpenAccordionItem}
              defaultValue={
                STUDIO_TOOLS_CONFIG[createItem.type]?.canUseImageStrength
                  ? 'item-1'
                  : ''
              }
            >
              <AccordionItem value="item-1" className="mx-4">
                <AccordionTrigger
                  variant="studioAdditionalSettings"
                  openItem={openAccordionItem}
                  setOpenItem={setOpenAccordionItem}
                >
                  <SidebarTitle text="Additional Settings" />
                </AccordionTrigger>
                <AccordionContent>
                  {STUDIO_TOOLS_CONFIG[createItem.type]
                    ?.canUseImageStrength && (
                    <div className="flex flex-col gap-x-2 pt-2">
                      <SidebarTitle
                        text="Image Strength"
                        className="text-xs font-normal"
                      />
                      <ToggleGroup type="single" className="gap-x-3 pt-3">
                        <ToggleGroupItem
                          variant="studio"
                          value="LOW"
                          active={imageStrength === 'LOW'}
                          className="h-8 w-1/3 bg-white text-xs font-semibold"
                          onClick={() => setImageStrength('LOW')}
                        >
                          Low
                        </ToggleGroupItem>
                        <ToggleGroupItem
                          variant="studio"
                          value="MEDIUM"
                          active={imageStrength === 'MEDIUM'}
                          className="h-8 w-1/3 bg-white text-xs font-semibold"
                          onClick={() => setImageStrength('MEDIUM')}
                        >
                          Medium
                        </ToggleGroupItem>
                        <ToggleGroupItem
                          variant="studio"
                          value="HIGH"
                          active={imageStrength === 'HIGH'}
                          className="h-8 w-1/3 bg-white text-xs font-semibold"
                          onClick={() => setImageStrength('HIGH')}
                        >
                          High
                        </ToggleGroupItem>
                      </ToggleGroup>
                    </div>
                  )}
                  {STUDIO_TOOLS_CONFIG[createItem.type]?.canSelectSize && (
                    <div className="pt-5">
                      <SidebarTitle
                        text="Size"
                        className="text-xs font-normal"
                      />
                      <ToggleGroup type="single" className="gap-x-2 pt-4">
                        {STUDIO_TOOLS_CONFIG[
                          createItem.type
                        ]?.canSelectSizeOptions.map((option) => (
                          <ToggleGroupItem
                            key={option.value}
                            variant="studio"
                            value={option.value}
                            disabled={option.disabled}
                            active={generateSize === option.value}
                            onClick={() => {
                              if (!option.disabled) {
                                setGenerateSize(option.value);
                              }
                            }}
                            className={cn(
                              'h-8 w-1/3 bg-white text-xs font-semibold',
                              option.hidden && 'hidden',
                            )}
                          >
                            {option.label}
                          </ToggleGroupItem>
                        ))}
                      </ToggleGroup>
                    </div>
                  )}
                  {isPrivateImageEnabled && (
                    <div className="flex flex-col pt-5">
                      <SidebarTitle text="Visibility" className="text-xs" />
                      <div className="flex flex-row items-center justify-center gap-x-4 [&>*]:w-full">
                        <ToggleGroup type="single" className="gap-x-2 pt-4">
                          <ToggleGroupItem
                            variant="studio"
                            value="PUBLIC"
                            active={generateVisibility === 'PUBLIC'}
                            onClick={() => setGenerateVisibility('PUBLIC')}
                            className="h-8 w-1/2 bg-white text-xs font-semibold"
                          >
                            <Earth size={14} className="mr-1" />
                            Public
                          </ToggleGroupItem>
                          <ToggleGroupItem
                            variant="studio"
                            value="PRIVATE"
                            active={generateVisibility === 'PRIVATE'}
                            onClick={() => setGenerateVisibility('PRIVATE')}
                            className="h-8 w-1/2 bg-white text-xs font-semibold"
                          >
                            <Lock size={14} className="mr-1" />
                            Private
                          </ToggleGroupItem>
                        </ToggleGroup>
                      </div>
                    </div>
                  )}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
      <div
        id="bottom"
        className="sticky bottom-0 z-10 flex flex-col gap-4 bg-white px-2 py-4 lg:px-6"
      >
        {!isPrivateImageEnabled && (
          <div className="flex flex-col gap-4">
            <SidebarTitle text="Visibility" />
            <div className="flex flex-row items-center justify-center gap-x-4 [&>*]:w-full">
              <ToggleGroup type="single" className="gap-x-2">
                <ToggleGroupItem
                  variant="studio"
                  value="PUBLIC"
                  active={generateVisibility === 'PUBLIC'}
                  onClick={() => setGenerateVisibility('PUBLIC')}
                >
                  <Earth size={16} className="mr-1" />
                  Public
                </ToggleGroupItem>
                <ToggleGroupItem
                  variant="studio"
                  value="PRIVATE"
                  active={generateVisibility === 'PRIVATE'}
                  disabled={!isPrivateImageEnabled}
                  onClick={() => {
                    if (isPrivateImageEnabled) {
                      setGenerateVisibility('PRIVATE');
                    }
                  }}
                >
                  <Lock size={16} className="mr-1" />
                  Private
                </ToggleGroupItem>
              </ToggleGroup>
            </div>
          </div>
        )}
        <GenerateButton active={true} />
      </div>
    </div>
  );
};
