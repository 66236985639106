import { useEffect, useMemo, useState } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import { debounce } from 'lodash';

import { ME } from '@/api/authentication';
import { useDemoAccount } from '@/api/authentication/hook';
import { EXPLORE } from '@/api/explore';
import { FlexRow, LikeButton } from '@/components';
import { DemoAccountModal } from '@/components/DemoAccountPopup';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { SaveToCollection } from '@/layouts/common/saveToCollection';

import {
  ExploreLoadingScreen,
  LazyLoadTrigger,
  NoSuchResultsWereFound,
} from './components/utils';
import { Card } from './components';

export const SocialMediaImages = () => {
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [cursorNext, setCursorNext] = useState(null);

  const { data: meData } = useQuery(ME);

  const [showDemoPopup, setShowDemoPopup] = useState(false);

  const { isDemoAccount } = useDemoAccount();

  const {
    hasBannedApparel,
    setHasBannedApparel,
    shouldRefetch,
    setShouldRefetch,
    socialMediaSorting,
    activeSearchTab,
    gridView,
    isGridViewTransitioning,
    selectedSocialMediaColor: selectedTrendsColors,
    selectedSocialMediaSubColor: selectedTrendsSubColors,
    selectedSocialMediaCategory: selectedTrendsCategory,
    selectedSocialMediaTimeframe: selectedSeason,
    selectedSocialMediaAudience: selectedAudience,
    socialMediaFilters,
    selectedSocialMediaPersonCount: selectedPersonCount,
    selectedSocialMediaPersonPosition: selectedPersonPosition,
    selectedSocialMediaInfluencerValues,
    socialMediaSorting: selectedSorting,
    setPosts,
    isExploreMobile,
  } = useExploreContext();

  const moderationEnabled =
    meData?.me?.role === 'admin' || meData?.me?.role === 'moderator';

  const selectedAudienceId = selectedAudience;
  const selectedMarketId = selectedAudience?.marketId;

  // Memoize filters to avoid unnecessary recalculations
  const memoizedApparelFilter = useMemo(() => {
    return selectedTrendsCategory
      ? selectedTrendsCategory.map((category) => ({
          value: category.value,
        }))
      : null;
  }, [selectedTrendsCategory]);

  const memoizedColorFilter = useMemo(() => {
    return selectedTrendsColors?.length > 0
      ? selectedTrendsColors.map((color) => color.value)
      : null;
  }, [selectedTrendsColors]);

  const memoizedSubColorFilter = useMemo(() => {
    return Array.isArray(selectedTrendsSubColors) &&
      selectedTrendsSubColors.length > 0
      ? selectedTrendsSubColors?.map((subColor) => subColor.value)
      : null;
  }, [selectedTrendsSubColors]);

  const apparelFilterOutFields =
    socialMediaFilters?.category?.excludeValue?.map((category) => ({
      value: category.value,
    }));

  const colorFilterOutFields = socialMediaFilters?.colors?.excludeValue?.map(
    (color) => color.value,
  );

  const attributesOutFields = Object.entries(socialMediaFilters)
    .map(([key, val]) => {
      if (key === 'attribute.custom' || key === 'print') {
        return val?.excludeValue?.map((v) => {
          return {
            name: v?.name,
            value: v?.value,
          };
        });
      }

      return false;
    })
    .filter(Boolean)
    .reduce((acc, curr) => {
      acc = [...acc, ...curr];
      return acc;
    }, []);

  const variables = {
    cursor: null,
    filters: {
      apparels: memoizedApparelFilter,
      colors: memoizedColorFilter,
      subColors: memoizedSubColorFilter,
      patterns: null, // patternFilter,
      season: selectedSeason?.label?.toLowerCase() || null,
      tone: null,
      attributes: null,
      // selectedTone
      // ? {
      //     code: selectedTone.value,
      //     similarity: "1"
      //   }
      // : null
      personCount: null,
      personPosition: null,
      influencers: selectedSocialMediaInfluencerValues,
    },
    ...((attributesOutFields.length > 0 ||
      apparelFilterOutFields ||
      colorFilterOutFields) && {
      filterOutFields: {
        apparels: apparelFilterOutFields,
        attributes: attributesOutFields,
        colors: colorFilterOutFields,
      },
    }),
    audiences:
      selectedAudienceId && typeof selectedAudienceId === 'string'
        ? [selectedAudienceId]
        : selectedAudienceId?.[0] || [],
    market: selectedMarketId
      ? !['demo_market_1', 'demo_market_2'].includes(selectedMarketId)
        ? selectedMarketId
        : null
      : null,
    platform: socialMediaFilters.platform.value?.value,
    sorting:
      !socialMediaSorting || !socialMediaSorting?.value
        ? null
        : {
            sortBy: socialMediaSorting.value.split('.')[0],
            sortOrder: socialMediaSorting.value.split('.')[1],
          },
    searchQuery: socialMediaFilters.search?.value,
    searchType: activeSearchTab,
  };

  const { data, networkStatus, error, refetch, fetchMore } = useQuery(EXPLORE, {
    variables,
    onCompleted: (newData) => {
      setPosts(
        newData?.explore?.data.map((d) => ({
          ...d,
          post: {
            ...d.post,
            apparels: d.post.apparels.map((a, i) => ({
              ...a,
              apparel: i === 0 ? d.title : a.apparel,
            })),
          },
        })),
      );
      if (newData?.explore?.cursor === null) {
        setFullyLoaded(true);
      } else {
        setFullyLoaded(false);
        setCursorNext(newData.explore.cursor);
      }
      hasBannedApparel === true && setHasBannedApparel(false);
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useMemo(() => debounce(refetch, 300), [refetch]);

  useEffect(() => {
    if (socialMediaSorting) {
      debouncedRefetch({
        sorting: {
          sortBy: socialMediaSorting?.value?.split('.')[0],
          sortOrder: selectedSorting?.value?.split('.')[1],
        },
      });
    } else {
      debouncedRefetch();
    }
  }, [socialMediaSorting]);

  useEffect(() => {
    if (hasBannedApparel) {
      debouncedRefetch();
    }
  }, [hasBannedApparel]);

  useEffect(() => {
    debouncedRefetch();
  }, [socialMediaFilters.search.value, activeSearchTab]);

  useEffect(() => {
    if (shouldRefetch) {
      const attributes = Object.entries(socialMediaFilters)
        .map(([key, val]) => {
          if (
            (key === 'attribute.custom' || key === 'print') &&
            Array.isArray(val?.value) &&
            val?.value.length
          ) {
            return val?.value?.map((attr) => ({
              name: attr?.name,
              value: attr?.value,
            }));
          }

          return false;
        })
        .filter(Boolean)
        .reduce((acc, curr) => {
          acc = [...acc, ...curr];
          return acc;
        }, []);

      refetch({
        filters: {
          apparels: memoizedApparelFilter,
          colors: memoizedColorFilter,
          subColors: memoizedSubColorFilter,
          patterns: null, // patternFilter,
          season: selectedSeason?.label?.toLowerCase() || null,
          tone: null,
          attributes: attributes.length > 0 ? attributes : null,
          personCount: selectedPersonCount?.value,
          personPosition: selectedPersonPosition?.value,
          influencers: selectedSocialMediaInfluencerValues,
        },
        ...((attributesOutFields.length > 0 ||
          apparelFilterOutFields ||
          colorFilterOutFields) && {
          filterOutFields: {
            apparels: apparelFilterOutFields,
            attributes: attributesOutFields,
            colors: colorFilterOutFields,
          },
        }),
        audiences:
          selectedAudienceId && typeof selectedAudienceId === 'string'
            ? [selectedAudienceId]
            : selectedAudienceId || [],
        market: selectedMarketId
          ? !['demo_market_1', 'demo_market_2'].includes(selectedMarketId)
            ? selectedMarketId
            : null
          : null,
        platform: socialMediaFilters.platform.value?.value,
        sorting:
          !socialMediaSorting || !socialMediaSorting?.value
            ? null
            : {
                sortBy: socialMediaSorting.value.split('.')[0],
                sortOrder: socialMediaSorting.value.split('.')[1],
              },
        searchQuery: socialMediaFilters.search?.value,
        searchType: activeSearchTab,
      });

      setShouldRefetch(false);
    }
  }, [
    shouldRefetch,
    refetch,
    setShouldRefetch,
    selectedSocialMediaInfluencerValues,
  ]);

  if (
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.refetch ||
    !data?.explore
  ) {
    return <ExploreLoadingScreen />;
  }

  if (error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p>{error.message}</p>
      </div>
    );
  }

  const renderLazyLoad =
    networkStatus !== NetworkStatus.fetchMore &&
    data.explore.hasNextPage &&
    fullyLoaded === false;

  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <>
      <DemoAccountModal
        isOpen={showDemoPopup}
        onClose={() => setShowDemoPopup(false)}
      />
      <div className="relative pb-8">
        {isGridViewTransitioning && (
          <div className="absolute inset-0 z-20 w-full bg-white">
            <div className="ghost absolute inset-0 z-20"></div>
          </div>
        )}
        {data?.explore?.data?.length === 0 && <NoSuchResultsWereFound />}

        <div
          className={cn(
            'grid gap-4 pt-1 transition-all duration-300',
            gridView === '3-columns'
              ? 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3'
              : 'grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6',
          )}
        >
          {data?.explore?.data?.map((exploreItem, index) => (
            <div key={'explore_' + index} className="group">
              <Card.Root
                data={exploreItem}
                moderationEnabled={moderationEnabled}
                isCarousel={
                  exploreItem.post?.childrenIds?.length > 1 ||
                  exploreItem.post?.mainPost?.childrenIds?.length > 1
                }
              >
                <Card.Image />

                <Card.HoverContent>
                  <FlexRow
                    justify={!isExploreMobile ? 'between' : 'end'}
                    items="center"
                  >
                    {!isExploreMobile && <Card.Tones />}

                    <FlexRow
                      className="self-end"
                      justify="between"
                      items="center"
                      gap="sm"
                    >
                      <SaveToCollection
                        id={exploreItem?.post?.id}
                        collections={exploreItem?.collections}
                        type="post"
                        variant="explore"
                      />

                      {/* <More /> */}

                      <LikeButton
                        item={exploreItem?.post?.id}
                        isLiked={exploreItem?.isLiked}
                        type="post"
                      />
                    </FlexRow>
                  </FlexRow>
                  <Card.Footer
                    id={exploreItem.post?.id}
                    exploreItemId={exploreItem?.id}
                    post={exploreItem?.post}
                    demoPopupClick={
                      isDemoAccount ? () => setShowDemoPopup(true) : null
                    }
                  />
                </Card.HoverContent>
              </Card.Root>
            </div>
          ))}

          {data.explore.hasNextPage && (
            <div className="grid aspect-[3/6] grid-cols-1 gap-y-2 bg-white text-transparent">
              <div className="ghost row-[span_16_/span_16] w-full"></div>
              <div className="ghost row-span-1 w-full">Load more</div>
            </div>
          )}

          {isLoadingMore &&
            Array.from({ length: 14 }).map((_, i) => (
              <div
                className="grid aspect-[3/6] grid-cols-1 gap-y-2 bg-white text-transparent"
                key={i}
              >
                <div className="ghost row-[span_16_/span_16] w-full"></div>
                <div className="ghost row-span-1 w-full">Load more</div>
              </div>
            ))}

          {renderLazyLoad && (
            <div className="ghost mb-8 grid h-full w-full grid-cols-1">
              <LazyLoadTrigger
                fetchMore={fetchMore}
                loading={networkStatus === NetworkStatus.loading}
                cursorNext={cursorNext}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
