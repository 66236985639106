import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { startCase } from 'lodash';
import { useElementSize } from 'usehooks-ts';

import { useExploreContext } from '@/contexts/ExploreContext';
import { cn, nameFormat } from '@/helpers/utils';

import { CardContext } from '../context';

import { Moderation } from './Moderation';

import styles from '../index.module.css';

function seasonFormat(input) {
  return input
    .split(' ')
    .map((part) => {
      let season = '';
      if (part.includes('fall-winter') || part.includes('spring-summer')) {
        if (part.includes('fall-winter')) {
          season = 'FW';
        } else if (part.includes('spring-summer')) {
          season = 'SS';
        }
        const yearMatch = part.match(/\d{4}/);
        const year = yearMatch ? yearMatch[0].slice(2) : '';

        return `${season}${season ? '' : ''}${year}`;
      } else {
        season = startCase(part.replace('-', ' ').replace(/\d+/g, '').trim());
        const yearMatch = part.match(/\d{4}/);
        const year = yearMatch ? yearMatch[0].slice(2) : '';
        return `${season}${year ? ' ' : ''}${year}`;
      }
    })
    .join(' ');
}

function likeFormat(input) {
  if (input >= 1000 && input < 1000000) {
    const formatted = (input / 1000).toFixed(1);

    return formatted.endsWith('.0')
      ? `${parseInt(formatted, 10)}K`
      : `${formatted}K`;
  } else if (input >= 1000000) {
    const formatted = (input / 1000000).toFixed(1);

    return formatted.endsWith('.0')
      ? `${parseInt(formatted, 10)}M`
      : `${formatted}M`;
  }

  return input.toString();
}

function dateFormat(input) {
  return dayjs(input).format('DD MMM YY');
}

const SocialMediaItemDetails = ({ post }) => {
  return (
    <div className="flex flex-col gap-y-1 bg-white px-1 py-2 text-left">
      <div className="flex flex-row items-center justify-between">
        <p className="truncate text-sm font-semibold leading-4 text-primary">
          @
          {post.influencer?.username ||
            post.influencer?.instagramUsername ||
            post.influencer?.tiktokUsername}
        </p>
        {post.likes > 0 && (
          <p className="flex flex-row text-xs font-semibold leading-4 text-zinc-400">
            <span role="img" aria-label="heart" className="mr-0.5">
              ❤️
            </span>
            {likeFormat(post.likes)}
          </p>
        )}
      </div>
      <div className="flex flex-row items-center justify-between">
        <p className="truncate text-base font-semibold leading-4 text-black">
          {post.influencer.fullName ||
            post.influencer.username ||
            post.influencer.instagramUsername}
        </p>
        <p className="flex flex-row whitespace-nowrap text-xs font-semibold leading-4 text-zinc-400">
          <span role="img" aria-label="calendar" className="mr-0.5">
            📅
          </span>
          {dateFormat(post.publishDate)}
        </p>
      </div>
    </div>
  );
};

const FashionWeekItemDetails = ({ season, city, designer, look }) => {
  return (
    <div className="bg-white px-1 py-2 text-left">
      <p className="text-sm font-semibold leading-4 text-primary">
        {nameFormat(city)} Fashion Week {seasonFormat(season)}
      </p>
      <p
        className="truncate text-[0.825rem] font-semibold leading-4 md:text-base md:leading-6"
        title={nameFormat(designer)}
      >
        {nameFormat(designer)}{' '}
        {look === 0 ? (
          <span className="text-sm" style={{ color: '#A1A1A1' }} />
        ) : (
          <span className="text-sm" style={{ color: '#A1A1A1' }}>
            #{look}
          </span>
        )}
      </p>
    </div>
  );
};

export function Root({ data, children, moderationEnabled = true, isCarousel }) {
  const { isExploreMobile, hoveredCardId, setHoveredCardId } =
    useExploreContext();
  const location = useLocation();

  const [ref, { width }] = useElementSize();

  const [showAddModel, setShowAddModel] = useState(false);
  const [containerHeight, setContainerHeight] = useState(false);
  const isMounted = useRef(true);

  const uniqueId = data.id || data.entityId;
  const isHovered = hoveredCardId === uniqueId;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isExploreMobile) {
      closeAll();
    }
  }, [location, isExploreMobile]);

  function closeAll() {
    if (isMounted.current) {
      setHoveredCardId(null);
      setShowAddModel(false);
    }
  }

  function handleHoverState(visible) {
    if (!visible) closeAll();

    if (isMounted.current) {
      if (!isExploreMobile || (isExploreMobile && visible)) {
        setHoveredCardId(visible ? uniqueId : null);
      }
    }
  }

  return (
    <CardContext.Provider
      value={{
        containerWidth: width,
        setContainerHeight: (height) => {
          if (isMounted.current) {
            setContainerHeight(height);
          }
        },
        hovered: isHovered,
        showAddModel,
        setShowAddModel: (value) => {
          if (isMounted.current) {
            setShowAddModel(value);
          }
        },
        handleHoverState,
        isCarousel,
        data,
      }}
    >
      <div
        className={cn(
          data.disabled ? styles.rootDisabled : 'group/explore-card-root',
        )}
      >
        <div
          className={styles.root}
          ref={ref}
          onClick={() => isExploreMobile && handleHoverState(!isHovered)}
          onMouseEnter={() => !isExploreMobile && handleHoverState(true)}
          onMouseLeave={() => !isExploreMobile && handleHoverState(false)}
          style={containerHeight ? { height: containerHeight + 'px' } : {}}
        >
          <div
            onClick={(e) => {
              if (isHovered) {
                e.stopPropagation();
              }
            }}
          >
            {children}
          </div>
        </div>

        {data.post && <SocialMediaItemDetails post={data.post} />}
        {data.influencer && <SocialMediaItemDetails post={data} />}
        {moderationEnabled && <Moderation apparelId={data.id} />}
        {data.season && data.city && data.designer && (
          <FashionWeekItemDetails
            season={data.season}
            city={data.city}
            designer={data.designer}
            look={data.look}
          />
        )}
      </div>
    </CardContext.Provider>
  );
}
