import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';

import { FlexRow } from '@/components';
import { useExploreContext } from '@/contexts/ExploreContext';

import { ReportButton } from '../../ReportButton';
import { useCard } from '../context';

import styles from '../index.module.css';

const CornerBorder = () => (
  <>
    <span className="absolute left-0 top-0 h-2 w-2 border-l border-t border-white" />
    <span className="absolute right-0 top-0 h-2 w-2 border-r border-t border-white" />
    <span className="absolute bottom-0 left-0 h-2 w-2 border-b border-l border-white" />
    <span className="absolute bottom-0 right-0 h-2 w-2 border-b border-r border-white" />
  </>
);

const MobileDetailsButton = () => (
  <span className="absolute left-1/2 top-1/3 -translate-x-1/2 -translate-y-1/2 text-xs text-white">
    <span className="inline-flex items-center justify-center p-2 text-center">
      Click for details
      <CornerBorder />
    </span>
  </span>
);

export function Footer({ id, exploreItemId, activeTab, post }) {
  const itemType =
    activeTab === 'fashion-weeks' ? 'fashionWeekItem' : 'socialMediaItem';

  const reportItemType =
    activeTab === 'fashion-weeks' ? 'FASHION_WEEK' : 'SOCIAL_MEDIA';
  const { data } = useCard();

  const { isExploreMobile, setActiveTab } = useExploreContext();

  const navigate = useNavigate();

  const navigateId = id;

  const childrenIds = post?.childrenIds || post?.mainPost?.childrenIds;

  const isCarousel = childrenIds?.length > 1;

  const carouselIndex = childrenIds?.indexOf(post?.sourceId);

  const searchParams = new URLSearchParams();

  if (isCarousel && carouselIndex >= 0) {
    searchParams.set('item', carouselIndex);
  }

  if (exploreItemId) {
    searchParams.set('exploreItem', exploreItemId);
  }

  const searchParamsString = searchParams.toString();

  return (
    <FlexRow justify="between" items="center" className="z-[50] h-full pt-6">
      <div
        className="relative flex h-full w-full cursor-pointer items-end justify-between bg-transparent"
        onClick={() => {
          setActiveTab(activeTab);
          navigate(
            `/explore/image-preview/${itemType}/${navigateId}${searchParamsString.length > 0 ? `?${searchParamsString}` : ''}`,
          );
        }}
      >
        {isExploreMobile && <MobileDetailsButton />}

        <div className="flex grow flex-col gap-1">
          <h3
            className={styles.title}
            style={{
              filter:
                'drop-shadow(0 2px 3px rgb(0 0 0 / 0.5)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.3))',
            }}
          >
            {data.title || data.designer?.replaceAll('-', ' ')}
          </h3>

          <div className="flex grow">
            <div className={'block min-w-0 gap-0.5 text-xs font-semibold'}>
              {data?.attributes?.map((attribute, index) => {
                if (attribute.value === 'N/A') return null;

                return (
                  <div className={'mr-1 mt-1 inline-block'} key={index}>
                    <div
                      className={
                        'mt-0.25 text-shadow inline-block rounded-full border border-primary bg-primary/25 px-1.5 py-0.5 text-white drop-shadow '
                      }
                      key={index}
                    >
                      <span className={'leading-none'}>
                        {capitalize(attribute.value.replaceAll('-', ' '))}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <ReportButton id={exploreItemId || id} itemType={reportItemType} />
      </div>
    </FlexRow>
  );
}
