import React, { useRef, useState } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import { IoMdClose } from 'react-icons/io';
import { useMutation } from '@apollo/client';
import { Bookmark, Search } from 'lucide-react';

import {
  ADD_ITEM_TO_COLLECTION,
  COLLECTIONS,
  CREATE_COLLECTION,
  GET_COLLECTION_ITEMS_GROUPED,
  GET_COLLECTION_ITEMS_MERGED,
} from '@/api/collections';
import { useCollectionsQuery } from '@/api/collections/hooks';
import { Button } from '@/components/Button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from '@/components/Drawer';
import { Heading } from '@/components/Heading';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { useFilters } from '@/hooks/filters';
import { useToast } from '@/hooks/useToast';
import { SidebarButton } from '@/pages/general/new-studio/components/SidebarMenu';

import { CollectionItem } from './CollectionItem';

export function CreateNewCollection({ onCreateComplete = () => {} }) {
  const [newCollectionName, setNewCollectionName] = useState('');
  const [showInput, setShowInput] = useState(false);

  const toast = useToast();

  const [createCollection, { loading }] = useMutation(CREATE_COLLECTION, {
    refetchQueries: [
      {
        query: COLLECTIONS,
        variables: {
          cursor: null,
          includeOrganizationViewable: false,
        },
      },
    ],
    onCompleted: (data) => {
      onCreateComplete(data.createCollection.id || null);

      setNewCollectionName('');
      setShowInput(false);

      toast.createToast({
        message: `Collection with the name ${newCollectionName} created!.`,
        type: 'success',
        showCloseButton: true,
        position: 'top-right',
      });
    },
  });

  return (
    <div
      className={cn(
        'flex w-full py-3 rounded items-center transition-colors',
        !showInput &&
          'border md:border-primary-600 hover:bg-primaryHover text-white md:text-primary-600 hover:border-primary bg-primary md:bg-primaryLight flex justify-center cursor-pointer font-semibold rounded md:hover:bg-primaryLight md:hover:text-primary-600 md:hover:border-primary-600',
        loading &&
          'border md:border-secondary md:bg-[#ececec] md:text-secondary cursor-wait',
      )}
      onClick={() => {
        if (!showInput) setShowInput(true);
      }}
    >
      {!showInput && <span>Create New Collection</span>}
      {showInput && (
        <div className="col-span-1 flex w-full flex-col gap-x-1.5">
          <div className="flex items-center gap-2">
            <input
              className="w-full border-b border-black bg-transparent
              px-4 pb-[0.375rem] pl-0 text-sm outline-none"
              placeholder="New collection name"
              value={newCollectionName}
              onChange={(e) => setNewCollectionName(e.target.value)}
            />
            {/* Close Button */}
            <button
              disabled={loading}
              className="flex cursor-pointer items-center justify-center gap-2 rounded
            border border-transparent bg-primary px-2 py-1 text-sm font-semibold text-white transition-all hover:border hover:border-primary hover:bg-white hover:text-primary"
              onClick={() => {
                createCollection({
                  variables: {
                    name: newCollectionName,
                  },
                });
              }}
            >
              {loading && <ImSpinner8 className="animate-spin" />}
              Create
            </button>
            <button
              type="button"
              onClick={() => setShowInput(false)}
              className="flex cursor-pointer items-center justify-center gap-2 rounded border border-transparent bg-white px-2 py-1 text-sm font-semibold text-night transition-all hover:border hover:border-primary hover:bg-white hover:text-primary"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export const SaveToCollection = ({
  id,
  type,
  collections,
  children,
  variant = 'default',
  asChild = false,
  metadata = {},
}) => {
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [search, setSearch] = useState('');

  const { selectedSeason } = useFilters();

  const { isExploreMobile } = useExploreContext();

  const closeButtonRef = useRef();

  const toast = useToast();

  const { data, loading, error } = useCollectionsQuery('collections', {
    variables: {
      cursor: null,
      includeOrganizationViewable: false,
    },
  });

  const [addItem] = useMutation(ADD_ITEM_TO_COLLECTION, {
    refetchQueries: [
      {
        query: COLLECTIONS,
        variables: {
          cursor: null,
          includeOrganizationViewable: false,
        },
      },
      {
        query: GET_COLLECTION_ITEMS_MERGED,
        variables: {
          collection: selectedCollection?.id,
        },
      },
      {
        query: GET_COLLECTION_ITEMS_GROUPED,
        variables: {
          collection: selectedCollection?.id,
        },
      },
    ],
    onCompleted: () => {
      toast.createToast({
        message: `Item has been successfully added to the ${selectedCollection?.name || ''} collection.`,
        type: 'success',
        showCloseButton: true,
        position: 'top-right',
      });

      closeButtonRef.current?.click();
    },
    onError(error) {
      toast.createToast({
        message:
          'An error occurred when adding item to selected collection. Please try again.' +
          ` ${error?.message}`,
        type: 'error',
        showCloseButton: true,
        position: 'top-right',
      });
    },
  });

  function handleSave(collectionId) {
    addItem({
      variables: {
        collection: collectionId,
        season: selectedSeason,
        type,
        value: id,
        metadata,
      },
    });
  }

  const renderData = search
    ? data?.collections?.data.filter((c) =>
        c.name.toLowerCase().includes(search.toLowerCase()),
      )
    : data?.collections?.data;

  if (loading) {
    return (
      <Drawer>
        <DrawerTrigger>
          {typeof children === 'function' ? (
            children(loading)
          ) : variant === 'icon' ? (
            <button
              className="cursor-not-allowed rounded border border-secondary/10 bg-white p-1 text-secondary"
              disabled
            >
              <Bookmark size={20} />
            </button>
          ) : (
            <button className="cursor-not-allowed bg-white px-4 py-1 font-medium text-charcoal">
              Save
            </button>
          )}
        </DrawerTrigger>
      </Drawer>
    );
  }

  if (error) {
    if (variant === 'icon') {
      return (
        <button
          className="cursor-not-allowed rounded border border-secondary/10 bg-white p-1 text-secondary"
          disabled
        >
          <Bookmark size={20} />
        </button>
      );
    }

    return (
      <Button className="cursor-wait rounded-lg bg-secondary/50 py-2 font-bold text-white">
        Save
      </Button>
    );
  }

  return (
    <Drawer>
      <DrawerTrigger>
        {typeof children === 'function'
          ? children(loading)
          : asChild
            ? children
            : variant === 'default' && (
                <Button color="white" size="xs">
                  Save
                </Button>
              )}
        {variant === 'icon' && (
          <button className="rounded border border-secondary/10 bg-white p-1 text-black hover:border-primary hover:bg-white hover:text-primary">
            <Bookmark size={20} />
          </button>
        )}
        {variant === 'studio' && (
          <SidebarButton
            text="Save"
            className="border bg-primary px-4 py-2 font-bold text-white after:border-primary"
          />
        )}
        {variant === 'explore' && (
          <SidebarButton
            text="Save"
            className="border bg-primary px-3 py-1.5 font-bold text-white after:border-primary"
          />
        )}
      </DrawerTrigger>
      <DrawerContent className={cn(isExploreMobile && 'bg-white')}>
        <div className="flex h-full flex-col gap-4">
          <div className="flex items-center justify-between">
            <div
              className={cn(
                'flex items-center gap-2',
                isExploreMobile ? 'justify-between w-full' : 'justify-start',
              )}
            >
              {isExploreMobile && <Heading size="xl">Collections</Heading>}

              <DrawerClose ref={closeButtonRef}>
                <IoMdClose size={isExploreMobile ? 32 : 26} />
              </DrawerClose>

              {!isExploreMobile && (
                <h1 className="text-xl font-semibold">
                  Search for a collection or create a new one
                </h1>
              )}
            </div>
          </div>

          <div
            className={cn(
              'relative my-1.5 flex w-full justify-between border-b border-black',
              isExploreMobile && 'border border-night/50 rounded p-2 m-0',
            )}
          >
            {isExploreMobile && <Search stroke="#666" />}
            <input
              className={cn(
                'font-regular w-full bg-transparent pb-[0.375rem] pl-0 pr-[1.625rem] pt-0 text-[1.1rem] leading-[1.75rem] outline-none',
                isExploreMobile && 'text-base leading-7 p-0 pl-2 font-medium',
              )}
              placeholder="Search in Collections..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {!isExploreMobile && <Search />}
          </div>

          <div className="no-scrollbar flex h-max flex-col gap-4 overflow-auto">
            {renderData.map((collection) => (
              <CollectionItem
                key={collection.id}
                item={collection}
                {...{ selectedCollection, setSelectedCollection, handleSave }}
                alreadySaved={collections?.includes(id)}
              />
            ))}
          </div>
          <CreateNewCollection onCreateComplete={handleSave} />
        </div>
      </DrawerContent>
    </Drawer>
  );
};
