import { useEffect, useState } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useUser, useUserMutation } from '@/api/authentication/hook';
import { TFashion } from '@/assets/svg';
import { isExploreMobilePage } from '@/components/constants';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useAppContext } from '@/contexts/AppContext';
import { cn } from '@/helpers/utils';
import { useFilters } from '@/hooks/filters';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';

const Logo = () => {
  const navigate = useNavigate();
  return (
    <div className="flex basis-[30%] items-center">
      <div
        className="flex w-[13.3125rem] cursor-pointer"
        onClick={() => navigate('/overview', { replace: true })}
      >
        <TFashion />
      </div>
    </div>
  );
};

const Menu = () => {
  const [menuList, setMenuList] = useState([]);
  const { selectedAudienceId, selectedMarketId } = useFilters('explore');
  const { isEnabled: isStudioEnabled } = useFeatureFlag(FEATURE_FLAGS.STUDIO);
  const location = useLocation();

  const { marketId, audienceId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const menu = [
      { name: 'Explore', href: '/explore' },
      { name: 'Collections', href: '/collections' },
    ];

    if (isStudioEnabled) {
      menu.splice(1, 0, { name: 'Studio', href: '/studio/home' });
    }

    setMenuList([...menu]);
  }, [isStudioEnabled]);

  const timeframe = searchParams.get('timeframe');

  const trendsPath =
    !selectedAudienceId && !selectedMarketId
      ? '/overview'
      : `/trends/snapshots/${marketId || selectedMarketId}/${audienceId || selectedAudienceId}?timeframe=${timeframe || 'lastWeek'}`;

  const trendsPaths = [
    trendsPath,
    '/trends/insights',
    '/trends/snapshots',
    '/trends/snapshots/detail',
  ];

  const isTrendsActive = trendsPaths.some((path) =>
    location.pathname.startsWith(path),
  );

  return (
    <div className="flex basis-[40%] items-center justify-evenly">
      <NavLink
        to={trendsPath}
        key="trends"
        className={({ isActive }) =>
          (isActive || isTrendsActive ? 'text-white bg-primary' : '') +
          ' flex items-center justify-center h-[2.5rem] text-[1.125rem] px-[1.125rem] hover:bg-primary hover:text-white ease-in-out duration-200 rounded-sm'
        }
      >
        <span className="font-semi-light">TRENDS</span>
      </NavLink>
      {menuList.map((item, index) => (
        <NavLink
          to={item.href}
          key={index}
          className={({ isActive }) =>
            (isActive ||
            (item.name === 'Studio' && location.pathname.startsWith('/studio'))
              ? 'text-white bg-primary'
              : '') +
            ' flex items-center justify-center h-[2.5rem] text-[1.125rem] px-[1.125rem] hover:bg-primary hover:text-white ease-in-out duration-200 rounded-sm'
          }
        >
          <span className="font-semi-light">{item.name.toUpperCase()}</span>
        </NavLink>
      ))}
    </div>
  );
};

function ChangeOrganizationButton() {
  const { user, moderationEnabled } = useUser();

  const [removeSelectedOrganization, { loading }] = useUserMutation(
    'removeSelectedOrganization',
    {
      refetchQueries: ['me'],
      onCompleted: (data) => {
        if (data?.removeSelectedOrganization?.accessToken) {
          localStorage.setItem(
            'accessToken',
            data?.removeSelectedOrganization?.accessToken,
          );
          localStorage.setItem(
            'refreshToken',
            data?.removeSelectedOrganization?.refreshToken,
          );

          navigate('/explore');

          window.location.reload();
        }
      },
    },
  );

  const navigate = useNavigate();

  if (!moderationEnabled) {
    return <></>;
  }

  if (user?.selectedOrganization?.name) {
    return (
      <div className="group relative flex flex-col items-center justify-center">
        <button className="border-2 border-charcoal px-6 py-[0.640625rem] text-center font-semibold">
          {user.selectedOrganization.name}
        </button>
        <div className="absolute top-10 hidden flex-col border-2 border-charcoal bg-white group-hover:flex">
          <button
            disabled={loading}
            className="flex items-center gap-4 px-6 py-2 text-center font-semibold"
            onClick={() => navigate('/select-organization')}
          >
            <span>Change Organization</span>
            {loading && <ImSpinner8 className="animate-spin" />}
          </button>
          <button
            disabled={loading}
            onClick={() => removeSelectedOrganization()}
            className="flex items-center gap-4 px-6 py-2 text-center font-semibold text-red-600"
          >
            <span>Return to Default</span>
            {loading && <ImSpinner8 className="animate-spin" />}
          </button>
        </div>
      </div>
    );
  }

  return (
    <button
      onClick={() => navigate('/select-organization')}
      className="flex items-center justify-start border-2 border-charcoal px-[1.125rem] py-[0.640625rem] transition-all hover:bg-gray-100"
    >
      <span className="mr-[0.5rem] pt-1 text-[1.125rem] font-medium leading-[1.34375rem]">
        {user?.selectedOrganization?.name || 'Select Organization'}
      </span>
      {/* <Bell /> */}
      {/* <BellNotification/> */}
    </button>
  );
}

const CurrentUser = () => {
  const { user } = useUser();

  const navigate = useNavigate();
  const getName = (fullName) => {
    if (fullName.length < 16) return fullName;

    const splitted = fullName?.split(' ');
    return `Hi, ${splitted[0]} ${
      splitted.length > 1 ? splitted.at(-1)[0] : ''
    }.`;
  };

  return (
    <div className="flex basis-[30%] items-center justify-end gap-4">
      <ChangeOrganizationButton />
      <button
        className="flex items-center justify-start rounded-sm border-2 border-charcoal px-[1.125rem] py-[0.640625rem] transition-all hover:bg-gray-100"
        onClick={() => navigate('/profile/account-details', { replace: true })}
      >
        {user?.fullName && (
          <span className="text-[1.125rem] font-medium leading-[1.34375rem]">
            {getName(user.fullName)}
          </span>
        )}
        {/* <ArrowDownBig/> */}
      </button>
    </div>
  );
};

export const Navbar = () => {
  const location = useLocation();

  const { showUpdatePopup, handleUpdate } = useAppContext();

  const isMenuAvailable =
    location.pathname.includes('/profile') ||
    location.pathname.includes('/invite-co-workers') ||
    location.pathname.includes('/invited-to-organization') ||
    location.pathname.includes('/pricing') ||
    location.pathname.includes('/create-trend-report');

  const [forceUpdateModal, setForceUpdateModal] = useState(false);

  useEffect(() => {
    let updateTimer;
    // Show the popup to force update modal after 5 minutes if update is available
    if (showUpdatePopup) {
      updateTimer = setTimeout(
        () => {
          setForceUpdateModal(true);
        },
        5 * 60 * 1000, // 5 minutes in milliseconds
      );
    }

    // Handle tab switching
    const visibilityChangeHandler = () => {
      if (document.visibilityState === 'hidden') {
        const backgroundRefreshTimer = setTimeout(
          () => {
            // If the tab is still in the background for 5 minutes and
            // an update is available, force refresh
            if (document.visibilityState === 'hidden' && showUpdatePopup) {
              handleUpdate();
            }
          },
          5 * 60 * 1000, // 5 minutes in milliseconds
        );

        // Clear background timer if visibility changes before timeout
        const visibilityChangeCleanup = () => {
          if (document.visibilityState === 'visible') {
            clearTimeout(backgroundRefreshTimer);
            document.removeEventListener(
              'visibilitychange',
              visibilityChangeCleanup,
            );
          }
        };
        document.addEventListener('visibilitychange', visibilityChangeCleanup);
      }
    };

    document.addEventListener('visibilitychange', visibilityChangeHandler);
    return () => {
      if (updateTimer) clearTimeout(updateTimer);
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, [showUpdatePopup, handleUpdate]);

  return (
    <>
      {showUpdatePopup && (
        <div className="fixed top-0 z-[701] flex w-full items-center justify-between border-b border-primary bg-[#f2e9e1] px-[3.75rem] py-2">
          <div className="flex-1">
            <p className="text-center text-base font-medium text-black">
              A new version is available. Update now to access the latest
              features.
            </p>
          </div>
          <button
            onClick={handleUpdate}
            className="rounded bg-primary px-6 py-1 text-base font-medium text-white hover:bg-primary/90"
          >
            Update Now
          </button>
        </div>
      )}
      <Dialog open={forceUpdateModal} onOpenChange={() => {}}>
        <DialogContent className="rounded sm:max-w-[30rem]" variant="no-close">
          <DialogHeader className="space-y-3">
            <DialogTitle className="text-center text-2xl font-bold">
              New Update Available
            </DialogTitle>
            <DialogDescription className="text-center text-base">
              A new version of the application is available. Please update now
              to continue using the application.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex justify-center gap-4 pt-6">
            <div className="mx-auto flex items-center justify-center">
              <button
                onClick={handleUpdate}
                className="min-w-[7.5rem] rounded-md bg-primary px-4 py-2 font-medium text-white transition-colors duration-200 hover:bg-primaryHover"
              >
                Update Now
              </button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div
        id="main-nav"
        className={cn(
          'fixed z-[450] flex min-h-[5.875rem] w-full items-center justify-between border-b border-secondary/50 bg-white px-[3.75rem]',
          showUpdatePopup ? 'top-10' : 'top-0',
          isExploreMobilePage(location.pathname) ? 'hidden' : '',
        )}
      >
        <Logo />
        {!isMenuAvailable && <Menu />}
        <CurrentUser />
      </div>
    </>
  );
};
