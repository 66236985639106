import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
  CREATE_STUDIO_TASK,
  FILL_PAYLOAD_STUDIO_TASK,
  PROCESS_STUDIO_TASK,
} from '@/api/studio/mutations';
import {
  GENERATE_NEW_PROMPT_FROM_IMAGE,
  GET_SIMILAR_IMAGES,
  STUDIO_TASK,
} from '@/api/studio/queries';
import {
  STUDIO_DISPLAY,
  STUDIO_SCENES,
  STUDIO_TOOLS_CONFIG,
} from '@/constants/studio';
import {
  conceptOptions,
  orientationOptions,
} from '@/pages/general/studio/components/options';

export const StudioContext = createContext();

const defaultConcept = conceptOptions[0];
const defaultOrientation = orientationOptions[0];

export function StudioProvider({ children }) {
  const [createItem, setCreateItem] = useState({
    icon: null,
    text: null,
    type: null,
  });
  const [positivePrompt, setPositivePrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [referenceImage, setReferenceImage] = useState(null);
  const [referencePattern, setReferencePattern] = useState(null);
  const [referenceSketch, setReferenceSketch] = useState(null);
  const [referenceGraphic, setReferenceGraphic] = useState(null);
  const [referenceOtherImage, setReferenceOtherImage] = useState(null);
  const [orientation, setOrientation] = useState('PORTRAIT');
  const [generateVisibility, setGenerateVisibility] = useState('PUBLIC');
  const [generateSize, setGenerateSize] = useState('PORTRAIT');
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedTone, setSelectedTone] = useState(null);
  const [selectedBrushPathArray, setSelectedBrushPathArray] = useState([]);
  const [selectedCompareImage, setSelectedCompareImage] = useState('slider');
  const [selectedBrushSize, setSelectedBrushSize] = useState(10);
  const [selectedAreaType, setSelectedAreaType] = useState('square');
  const [imageStrength, setImageStrength] = useState('MEDIUM');
  const [graphicText, setGraphicText] = useState('');
  const [draggingItem, setDraggingItem] = useState(null);
  const [refSoloItemType, setRefSoloItemType] = useState(null);
  const [openTools, setOpenTools] = useState(false);
  const [selectedTool, setSelectedTool] = useState('');
  const [promptLayoutType, setPromptLayoutType] = useState('templates');
  const [activeModal, setActiveModal] = useState(null);

  const [displayMode, setDisplayMode] = useState(STUDIO_DISPLAY.PENDING);

  const [referenceError, setReferenceError] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [selectedGeneratedImage, setSelectedGeneratedImage] = useState(-1);
  const [studioTask, setStudioTask] = useState({
    id: null,
    status: 'LOADING',
  });
  const [selectedScene, setSelectedScene] = useState(STUDIO_SCENES[1].value);
  const [selectedPatternStyle, setSelectedPatternStyle] = useState(null);
  const [selectedPatternStyleSubtype, setSelectedPatternStyleSubtype] =
    useState(null);
  const [isHidingTool, setIsHidingTool] = useState([]);
  const [intervalCheckStatus, setIntervalCheckStatus] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (studioTask?.payload?.orientation) {
      setOrientation(studioTask.payload.orientation);
    }

    if (studioTask?.status === 'PENDING_GENERATION') {
      if (!intervalCheckStatus) {
        const interval = setInterval(async () => {
          const newParams = new URLSearchParams(window.location.search);

          if (!newParams.has('taskId') || newParams.get('taskId') === null) {
            clearInterval(interval);
            setIntervalCheckStatus(null);
            return;
          }

          const { data } = await refetchStudioTask({
            id: newParams.get('taskId'),
          });
          setIntervalCheckStatus(true);

          if (data?.studioTask?.status === 'COMPLETED') {
            clearInterval(interval);
            setIntervalCheckStatus(null);
          }

          setStudioTask(data?.studioTask);
        }, 5000);
      }
    }
  }, [studioTask]);

  const navigate = useNavigate();

  const [payload, setPayload] = useState({
    item: null,
    design: null,
    concept: defaultConcept,
    orientation: defaultOrientation,
    mode: 'GUIDED',
  });

  const [designTask, setDesignTask] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams({
    tab: 'get-inspired',
  });

  const [imagePopup, setImagePopup] = useState({
    isOpen: false,
    designTask: null,
  });

  const updateCreateItemDetails = (type, isMenu) => {
    if (!type || !STUDIO_TOOLS_CONFIG[type]) {
      setCreateItem({
        icon: null,
        text: null,
        pendingAreaBoxCount: null,
        type: null,
      });
      setSelectedTool('');
    } else {
      if (type === 'CREATE_VARIANTS') {
        setCreateItem({
          ...STUDIO_TOOLS_CONFIG[type],
          text: 'Variants',
          type,
        });
      } else {
        setCreateItem({
          ...STUDIO_TOOLS_CONFIG[type],
          type,
          ...(isMenu !== undefined && { isUsingApplyButton: !isMenu }),
        });
      }

      setSelectedTool(type);
    }
    if (type === 'GENERATE_APPAREL') {
      setGenerateSize('PORTRAIT');
    } else if (type === 'GENERATE_PATTERN') {
      setGenerateSize('SQUARE');
    }
  };

  const activeTab = searchParams.get('tab') || 'get-inspired';

  const setActiveTab = (tab) => {
    setSearchParams({ tab });
  };

  const [createNewStudioTask] = useMutation(CREATE_STUDIO_TASK);

  const [fillPayloadStudioTask] = useMutation(FILL_PAYLOAD_STUDIO_TASK);

  const [processStudioTask, { loading: processStudioTaskLoading }] =
    useMutation(PROCESS_STUDIO_TASK);

  const [
    studioTaskQuery,
    {
      data: studioTaskData,
      loading: studioTaskLoading,
      refetch: refetchStudioTask,
    },
  ] = useLazyQuery(STUDIO_TASK, {
    variables: {
      studioTaskId: params.get('taskId'),
    },
  });

  useEffect(() => {
    if (params.get('generationType') === 'apparel') {
      updateCreateItemDetails('GENERATE_APPAREL');
      setRefSoloItemType('APPAREL');
      setActiveModal(null);
      setOpenTools(true);
    } else if (params.get('generationType') === 'pattern') {
      updateCreateItemDetails('pattern');
      setRefSoloItemType('PATTERN');
      setActiveModal(null);
      setOpenTools(true);
    } else if (params.get('generationType') === 'edit') {
      updateCreateItemDetails(null);
      setActiveModal(null);
      setOpenTools(true);
    }
  }, [params.has('generationType')]);

  useEffect(() => {
    setRefSoloItemType(null);
    if (params.get('itemType') === 'fashionWeekItem') {
      return;
    }
    if (studioTaskData?.studioTask) {
      setSelectedGeneratedImage(-1);
      setStudioTask(studioTaskData?.studioTask);
    } else {
      setStudioTask({
        ...studioTask,
        id: null,
        status: studioTask?.status ? 'PENDING' : 'LOADING',
        images: [],
        createdAt: new Date().toISOString(),
      });
    }
  }, [studioTaskData]);

  useEffect(() => {
    if (params.has('taskId')) {
      studioTaskQuery({
        variables: {
          id: params.get('taskId'),
        },
      });
    }
  }, [params.has('taskId'), params.get('taskId')]);

  const {
    data: similarImagesData,
    error: similarImagesError,
    loading: similarImagesLoading,
    refetch: refetchStudioSimilarImages,
  } = useQuery(GET_SIMILAR_IMAGES, {
    skip: !params.has('taskId'),
    variables: {
      studioSimilarImagesId: params.get('taskId'),
    },
  });

  useEffect(() => {
    if (
      (studioTask?.status === 'COMPLETED' ||
        studioTask?.status === 'PENDING_GENERATION') &&
      params.has('taskId')
    ) {
      const variables = {
        studioSimilarImagesId: params.get('taskId'),
      };
      refetchStudioSimilarImages(variables);
    }
  }, [studioTask?.status, params.has('taskId')]);

  const [
    studioGenerateNewPromptFromImageQuery,
    {
      data: generateNewPromptFromImageData,
      error: generateNewPromptFromImageError,
      loading: generateNewPromptFromImageLoading,
    },
  ] = useLazyQuery(GENERATE_NEW_PROMPT_FROM_IMAGE);

  useEffect(() => {
    if (['REALIZE_SKETCH'].includes(createItem.type) && referenceSketch) {
      setPositivePrompt('');
      studioGenerateNewPromptFromImageQuery({
        variables: {
          payload: {
            entityType: referenceSketch.entityType,
            entityId: referenceSketch.entityId,
          },
          generationType: createItem.type,
          sceneType:
            createItem.type.toUpperCase() === 'GENERATE_APPAREL'
              ? selectedScene
              : null,
        },
        onCompleted: (data) => {
          setPositivePrompt(data?.studioGenerateNewPromptFromImage?.prompt);
        },
      });
    }
    if (
      [
        'REALIZE_SKETCH',
        'GENERATE_APPAREL',
        'GENERATE_PATTERN',
        'GENERATE_GRAPHIC',
      ].includes(createItem.type) &&
      referenceOtherImage
    ) {
      studioGenerateNewPromptFromImageQuery({
        variables: {
          payload: {
            entityType: referenceOtherImage.entityType,
            entityId: referenceOtherImage.entityId,
          },
          generationType: createItem.type,
          sceneType:
            createItem.type.toUpperCase() === 'GENERATE_APPAREL'
              ? selectedScene
              : null,
        },
        onCompleted: (data) => {
          setPositivePrompt(data?.studioGenerateNewPromptFromImage?.prompt);
          setReferenceOtherImage(null);
        },
      });
    }
  }, [referenceSketch, referenceOtherImage, createItem]);

  useEffect(() => {
    if (studioTask?.images && studioTask?.images?.length > 0) {
      const selectedImage =
        studioTask?.images[
          selectedGeneratedImage === -1 ? 0 : selectedGeneratedImage
        ];

      if (
        ['IMAGE_CHANGE_BACKGROUND', 'COLOR_TRANSFER', 'ZOOM_IN_IMAGE'].includes(
          selectedTool,
        )
      ) {
        setPositivePrompt('');
        setReferenceImage({
          entityType: selectedImage?.entityType || 'STUDIO_ITEM',
          entityId: selectedImage?.entityId || selectedImage?.studioItemId,
          url: selectedImage?.url,
        });
      }
    }
  }, [selectedTool, selectedGeneratedImage, studioTask]);

  const clearDesignTask = () => {
    setNegativePrompt('');
    setReferenceError(false);
  };

  const handleCreateNewStudioTask = (type) => {
    const { pendingAreaBoxCount, icon, text } = STUDIO_TOOLS_CONFIG[type];
    setCreateItem((prev) => ({
      ...prev,
      type,
      icon,
      text,
      pendingAreaBoxCount,
    }));

    setStudioTask({
      id: null,
      status: 'PENDING',
      images: [],
      createdAt: new Date().toISOString(),
    });

    createNewStudioTask({
      variables: {
        payload: {
          taskType: type,
          visibility: generateVisibility,
        },
      },
      onCompleted: (data) => {
        const { id: taskId, generateTaskType } = data?.createNewStudioTask;
        if (taskId) {
          navigate(`/studio?taskId=${taskId}`);
        }
        setStudioTask(data?.createNewStudioTask);
        handleFillPayloadStudioTask(taskId, generateTaskType);
      },
    });
  };

  const handleFillPayloadStudioTask = async (
    studioTaskId,
    generateTaskType,
  ) => {
    const payload = {
      positivePrompt,
      negativePrompt,
      visibility: generateVisibility,
      orientation: generateSize,
    };

    if (['REALIZE_SKETCH'].includes(generateTaskType)) {
      payload.image = {
        entityType: referenceSketch?.entityType,
        entityId: referenceSketch?.entityId,
      };
    }

    if (['GENERATE_APPAREL'].includes(generateTaskType)) {
      payload.referenceImages = referenceImage
        ? [
            {
              entityType: referenceImage?.entityType,
              entityId: referenceImage?.entityId,
            },
          ]
        : [];

      payload.referencePatterns = referencePattern
        ? [
            {
              entityType: referencePattern?.entityType,
              entityId: referencePattern?.entityId,
            },
          ]
        : [];
      payload.sceneType = selectedScene;
    }

    if (['GENERATE_PATTERN'].includes(generateTaskType)) {
      payload.referencePatterns = referencePattern
        ? [
            {
              entityType: referencePattern?.entityType,
              entityId: referencePattern?.entityId,
            },
          ]
        : [];

      payload.patternStyle = selectedPatternStyle;
      payload.patternStyleSubtype = selectedPatternStyleSubtype;
    }

    if (['GENERATE_GRAPHIC'].includes(generateTaskType)) {
      payload.graphicTexture = graphicText;
      payload.referenceGraphics = referenceGraphic
        ? [
            {
              entityType: referenceGraphic?.entityType,
              entityId: referenceGraphic?.entityId,
            },
          ]
        : [];
    }

    if (
      ['CREATE_VARIANTS', 'CREATE_PATTERN_VARIANTS'].includes(generateTaskType)
    ) {
      const selectedImage =
        studioTaskData?.studioTask?.images[
          selectedGeneratedImage === -1 ? 0 : selectedGeneratedImage
        ];

      payload.image = {
        entityType: selectedImage?.entityType || 'STUDIO_ITEM',
        entityId: selectedImage?.entityId || selectedImage?.studioItemId,
      };
    }

    if (['ZOOM_IN_IMAGE'].includes(generateTaskType)) {
      payload.selectedArea = selectedArea;
      payload.image = {
        entityType: referenceImage?.entityType,
        entityId: referenceImage?.entityId,
      };
    }

    if (['IMAGE_CHANGE_BACKGROUND'].includes(generateTaskType)) {
      payload.background = {
        entityType: referenceOtherImage?.entityType,
        entityId: referenceOtherImage?.entityId,
      };
    }

    if (
      ['GENERATE_APPAREL', 'GENERATE_PATTERN', 'REALIZE_SKETCH'].includes(
        generateTaskType,
      ) &&
      referenceOtherImage
    ) {
      payload.image = {
        entityType: referenceOtherImage?.entityType,
        entityId: referenceOtherImage?.entityId,
      };
    }

    if (['COLOR_TRANSFER'].includes(generateTaskType)) {
      const { code, hex, label, main, name } = selectedTone;
      payload.colorTone = { code, hex, label, main, name };

      payload.selectedArea = selectedArea;
    }

    if (['INPAINTING'].includes(generateTaskType)) {
      const maskBase64 = await exportBrushPath(selectedBrushPathArray);
      payload.maskBase64 = maskBase64;
    }

    if (
      [
        'UPSCALE_IMAGE',
        'ENHANCE_IMAGE',
        'IMAGE_BACKGROUND_REMOVER',
        'IMAGE_CHANGE_BACKGROUND',
        'COLOR_TRANSFER',
        'GENERATE_FLAT_SKETCH',
        'ZOOM_IN_IMAGE',
        'INPAINTING',
      ].includes(generateTaskType)
    ) {
      const selectedImage =
        studioTaskData?.studioTask?.images[
          selectedGeneratedImage === -1 ? 0 : selectedGeneratedImage
        ];

      if (referenceImage) {
        payload.image = {
          entityType: referenceImage?.entityType,
          entityId: referenceImage?.entityId,
        };
      } else {
        payload.image = {
          entityType: selectedImage?.entityType || 'STUDIO_ITEM',
          entityId: selectedImage?.entityId || selectedImage?.studioItemId,
        };
      }
    }

    if (['PATTERN_TILE'].includes(generateTaskType)) {
      const selectedImage =
        studioTaskData?.studioTask?.images[
          selectedGeneratedImage === -1 ? 0 : selectedGeneratedImage
        ];

      if (referencePattern) {
        payload.image = {
          entityType: referencePattern?.entityType,
          entityId: referencePattern?.entityId,
        };
      } else {
        payload.image = {
          entityType: selectedImage?.entityType || 'STUDIO_ITEM',
          entityId: selectedImage?.entityId || selectedImage?.studioItemId,
        };
      }
    }

    if (STUDIO_TOOLS_CONFIG[generateTaskType]?.canUseImageStrength) {
      payload.imageStrength = imageStrength;
    }

    fillPayloadStudioTask({
      variables: { fillPayloadStudioTaskId: studioTaskId, payload },
      onCompleted: (data) => {
        setStudioTask(data?.fillPayloadStudioTask);
        handleProcessStudioTask(studioTaskId);
      },
    });
  };

  const handleProcessStudioTask = (studioTaskId) => {
    // if (openTools) setOpenTools(false);
    if (selectedTool) setSelectedTool('');
    processStudioTask({
      variables: { processStudioTaskId: studioTaskId },
      onCompleted: (data) => {
        setStudioTask(data?.processStudioTask);
      },
    });
  };

  const exportBrushPath = async (arr) => {
    const image = studioTask?.images[0];

    const svgContent = `<?xml version="1.0" encoding="UTF-8"?>
<svg xmlns="http://www.w3.org/2000/svg" width="${image.width}" height="${image.height}">
<rect width="100%" height="100%" fill="black" stroke="black"/>

${arr.reduce(
  (acc, { path, size }, index) =>
    acc +
    `<path
    key="${index}"
    d="${path}"
    stroke="white"
    stroke-width="${size}"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
`,
  '',
)}

</svg>`;

    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;

    const blob = new Blob([svgContent], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);

    const img = new Image();

    return new Promise((resolve) => {
      img.onload = () => {
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const base64 = canvas.toDataURL('image/png').split(',')[1];
        URL.revokeObjectURL(url);
        resolve(base64);
      };

      img.src = url;
    });
  };

  useEffect(() => {
    if (location.pathname === '/studio/home') {
      setReferenceImage(null);
      setReferencePattern(null);
      setReferenceSketch(null);
      setReferenceOtherImage(null);
      setReferenceGraphic(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (referenceImage) {
      setPayload((prevPayload) => ({
        ...prevPayload,
        item: {
          entityType: referenceImage.entityType,
          entityId: referenceImage.entityId,
        },
      }));
    }
  }, [referenceImage]);

  return (
    <StudioContext.Provider
      value={{
        payload,
        setPayload,
        designTask,
        setDesignTask,
        activeTab,
        setActiveTab,
        imagePopup,
        setImagePopup,
        updateCreateItemDetails,
        createItem,
        setCreateItem,

        referenceImage,
        setReferenceImage,
        referencePattern,
        setReferencePattern,
        referenceGraphic,
        setReferenceGraphic,
        referenceSketch,
        setReferenceSketch,
        referenceError,
        setReferenceError,
        openTools,
        setOpenTools,
        selectedTool,
        setSelectedTool,
        generateSize,
        setGenerateSize,
        positivePrompt,
        setPositivePrompt,
        negativePrompt,
        setNegativePrompt,
        generateVisibility,
        setGenerateVisibility,
        draggingItem,
        setDraggingItem,

        activeModal,
        setActiveModal,
        orientation,
        setOrientation,
        generatedImages,
        setGeneratedImages,
        displayMode,
        setDisplayMode,
        promptLayoutType,
        setPromptLayoutType,
        selectedArea,
        setSelectedArea,
        studioTaskData,
        studioTaskLoading,
        studioTask,
        setStudioTask,
        selectedScene,
        setSelectedScene,
        selectedAreaType,
        setSelectedAreaType,

        selectedGeneratedImage,
        setSelectedGeneratedImage,
        clearDesignTask,
        refSoloItemType,
        setRefSoloItemType,
        referenceOtherImage,
        setReferenceOtherImage,
        handleCreateNewStudioTask,
        similarImagesData,
        similarImagesError,
        similarImagesLoading,
        selectedTone,
        setSelectedTone,
        selectedBrushPathArray,
        setSelectedBrushPathArray,
        selectedBrushSize,
        setSelectedBrushSize,
        selectedCompareImage,
        setSelectedCompareImage,
        generateNewPromptFromImageData,
        generateNewPromptFromImageError,
        generateNewPromptFromImageLoading,
        imageStrength,
        setImageStrength,
        processStudioTaskLoading,
        isHidingTool,
        setIsHidingTool,
        graphicText,
        setGraphicText,
        selectedPatternStyle,
        setSelectedPatternStyle,
        selectedPatternStyleSubtype,
        setSelectedPatternStyleSubtype,
      }}
    >
      {children}
    </StudioContext.Provider>
  );
}

export const useStudioContext = () => useContext(StudioContext);
