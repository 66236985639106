import React from 'react';

import { useAppFilters } from '@/contexts/AppFiltersContext';
export const TrendsClearAll = () => {
  const { handleTrendsClearAll } = useAppFilters();

  return (
    <div className="flex items-center border-b border-l border-[#A1A1A1] pr-[60px] text-[0.875rem] font-semibold xl:w-full">
      <button
        className="flex h-full w-full flex-1 cursor-pointer items-center truncate px-[18px] text-[#C29B77] font-semibold outline-none"
        onClick={() => handleTrendsClearAll()}
      >
        Clear All
      </button>
    </div>
  );
};
