import { gql } from '@apollo/client';

export const GENERATE_STUDIO_ITEM = gql`
  mutation GenerateStudioItem($payload: GenerateImageInput!) {
    generateStudioItem(payload: $payload) {
      id
    }
  }
`;

export const UPDATE_FILE_STATUS = gql`
  mutation Mutation($fileId: ID!, $status: STATUS!) {
    updateFileStatus(fileId: $fileId, status: $status)
  }
`;

export const NEW_PROMPT_SUGGESTION = gql`
  mutation StudioSuggestNewPrompts(
    $prompt: String!
    $generationType: STUDIO_TASK_TYPE!
    $sceneType: SCENE_TYPE
  ) {
    studioSuggestNewPrompts(
      prompt: $prompt
      generationType: $generationType
      sceneType: $sceneType
    ) {
      prompt
      id
      suggestions {
        key
        value
      }
    }
  }
`;

export const CREATE_STUDIO_TASK = gql`
  mutation CreateNewStudioTask($payload: GenerateStudioTaskInput!) {
    createNewStudioTask(payload: $payload) {
      id
      status
      createdAt
      generateTaskType
    }
  }
`;

export const FILL_PAYLOAD_STUDIO_TASK = gql`
  mutation FillPayloadStudioTask(
    $fillPayloadStudioTaskId: ID!
    $payload: StudioTaskFillPayloadInput!
  ) {
    fillPayloadStudioTask(id: $fillPayloadStudioTaskId, payload: $payload) {
      id
      status
      createdAt
      generateTaskType
    }
  }
`;

export const PROCESS_STUDIO_TASK = gql`
  mutation ProcessStudioTask($processStudioTaskId: ID!) {
    processStudioTask(id: $processStudioTaskId) {
      id
      status
      createdAt
      generateTaskType
    }
  }
`;

export const SUGGESTION_BENCHMARK = gql`
  mutation StudioSelectSuggestedPrompt($payload: SelectPromptSuggestion!) {
    studioSelectSuggestedPrompt(payload: $payload) {
      id
    }
  }
`;
