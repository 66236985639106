// import React, { useEffect } from 'react'
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
  Navigate,
  Outlet,
  useLocation,
  // Remove createBrowserRouter from react-router-dom
} from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import {
  ErrorBoundary as DatadogErrorBoundary,
  reactPlugin,
} from '@datadog/browser-rum-react';
// Import createBrowserRouter from Datadog's wrapper
import { createBrowserRouter } from '@datadog/browser-rum-react/react-router-v6';
import * as Sentry from '@sentry/react';

import { ApiCacheProvider } from '@/api/context';
import { DialogProvider } from '@/components/ui/dialog';
import { AddToCollectionProvider } from '@/contexts/AddToCollectionContext';
import { AppContextProvider } from '@/contexts/AppContext';
import { AppFiltersProvider } from '@/contexts/AppFiltersContext';
import { CreateReportProvider } from '@/contexts/CreateReportContext';
import { ExploreProvider } from '@/contexts/ExploreContext';
import { FashionWeeksProvider } from '@/contexts/FashionWeeksContext';
import { FiltersProvider } from '@/contexts/FiltersContext';
import { InsightProvider } from '@/contexts/InsightContext';
import { StudioProvider } from '@/contexts/StudioContext';
import { ToastProvider } from '@/contexts/ToastContext';
import { TrendsProvider } from '@/contexts/TrendsContext';
import { AuthMiddleware } from '@/helpers/AuthMiddleware';
import { Layout } from '@/layouts/Layout';
import * as Pages from '@/pages/general';
import { RootErrorBoundary } from '@/pages/general/errorBoundary/RootErrorBoundary';

import * as routes from './';

// Initialize ReactGA
ReactGA.initialize('G-W7CHGF2LYQ', {
  debug: true,
});

// Initialize Datadog with reactPlugin and router: true option
datadogRum.init({
  applicationId: datadogRum.getInitConfiguration()?.applicationId || '',
  clientToken: datadogRum.getInitConfiguration()?.clientToken || '',
  site: datadogRum.getInitConfiguration()?.site || 'datadoghq.com',
  service: datadogRum.getInitConfiguration()?.service || '',
  env: datadogRum.getInitConfiguration()?.env || '',
  version: datadogRum.getInitConfiguration()?.version || '',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  plugins: [reactPlugin({ router: true })],
});

const Providers = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: document.title,
    });
  }, [location]);

  // const [currentParams, setSearchParams] = useSearchParams()

  // useEffect(() => {
  //   if (!location.search) {
  //     console.log('currentParams', currentParams);
  //     const savedSearchParams = localStorage.getItem('filtersContext');
  //     if (savedSearchParams) {
  //       setSearchParams({ payload: savedSearchParams, ...currentParams });
  //     }
  //   } else {
  //     console.log('location.search', location.search);
  //   }
  // }, [location]);

  return (
    <ToastProvider>
      <ApiCacheProvider>
        <AuthMiddleware>
          <FiltersProvider>
            <AppFiltersProvider>
              <AddToCollectionProvider>
                <AppContextProvider>
                  <TrendsProvider>
                    <InsightProvider>
                      <ExploreProvider>
                        <FashionWeeksProvider>
                          <CreateReportProvider>
                            <StudioProvider>
                              <DialogProvider>
                                <Outlet />
                              </DialogProvider>
                            </StudioProvider>
                          </CreateReportProvider>
                        </FashionWeeksProvider>
                      </ExploreProvider>
                    </InsightProvider>
                  </TrendsProvider>
                </AppContextProvider>
              </AddToCollectionProvider>
            </AppFiltersProvider>
          </FiltersProvider>
        </AuthMiddleware>
      </ApiCacheProvider>
    </ToastProvider>
  );
};

export const router = createBrowserRouter([
  ...routes.authentication,
  {
    path: '',
    element: <Providers />,
    children: [
      {
        path: '/',
        element: <Layout />,
        // errorElement: <RootErrorBoundary/>,
        errorElement: (
          <DatadogErrorBoundary>
            <Sentry.ErrorBoundary fallback={RootErrorBoundary}>
              <RootErrorBoundary />
            </Sentry.ErrorBoundary>
          </DatadogErrorBoundary>
        ),
        children: [
          ...routes.account,
          ...routes.explore,
          ...routes.collections,
          ...routes.invite,
          ...routes.report,
          ...routes.trends,
          ...routes.studio,
          ...routes.moderation,
          ...routes.studioLicence,
          // ...routes.imagePreview,
          {
            path: '/',
            element:
              window.innerWidth < 640 ? (
                <Navigate to="/explore" replace />
              ) : (
                <Navigate to="/overview" replace />
              ),
          },
          {
            path: '*',
            element: <Pages.NotFound />,
          },
          // Remove the DatadogWrapper route since it's no longer needed
          // {
          //   path: '/',
          //   element: <DatadogWrapper />,
          // },
        ],
      },
    ],
  },
]);
