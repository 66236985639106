import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { CheckIcon } from '@radix-ui/react-icons';
import * as Select from '@radix-ui/react-select';
import { styled } from '@stitches/react';

import { ME } from '@/api/authentication';
import { CHANGE_PASSWORD, UPDATE_USER_ACCOUNT } from '@/api/user/mutations';
import { useToast } from '@/hooks/useToast';

const ProfileImage = ({ user }) => {
  const [image, setImage] = useState(null);

  // Display first letter of user's name if no image
  const getInitial = () => {
    return user?.fullName ? user.fullName.charAt(0).toUpperCase() : 'K';
  };

  return (
    <div className="flex flex-col items-start gap-y-8">
      <h6 className="text-base font-medium">Profile Photo</h6>
      <div className="flex flex-col gap-6">
        <div className="relative flex">
          <div className="text-6xl flex h-[120px] w-[120px] items-center justify-center rounded-full bg-[#3CA780] text-white">
            {image ? (
              <img
                src={URL.createObjectURL(image)}
                alt="Profile"
                className="h-full w-full rounded-full object-cover"
              />
            ) : (
              getInitial()
            )}
          </div>
        </div>
        <div className="flex gap-4">
          <button
            type="button"
            className="rounded-md border border-gray-300 px-6 py-2.5 text-sm font-medium"
            onClick={() => setImage(null)}
          >
            Remove Photo
          </button>
          <label className="cursor-pointer rounded-md border border-gray-300 px-6 py-2.5 text-sm font-medium">
            Change Photo
            <input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={(e) => {
                if (e.target.files[0]) {
                  setImage(e.target.files[0]);
                }
              }}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

const PurposeSelect = ({ isDisabled = false }) => (
  <Select.Root defaultValue="student">
    <SelectTrigger aria-label="Purpose" disabled={isDisabled}>
      <Select.Value placeholder="Student" />
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="ml-auto"
      >
        <path
          d="M5 7.5L10 12.5L15 7.5"
          stroke="#282928"
          strokeWidth="2"
          strokeLinecap="square"
        />
      </svg>
    </SelectTrigger>
    <Select.Portal>
      <SelectContent>
        <SelectViewport>
          <Select.Group>
            <SelectItem value="student">Student</SelectItem>
            <SelectItem value="personal">Personal</SelectItem>
            <SelectItem value="business">Business</SelectItem>
            <SelectItem value="education">Education</SelectItem>
          </Select.Group>
        </SelectViewport>
      </SelectContent>
    </Select.Portal>
  </Select.Root>
);

const LanguageSelect = ({ isDisabled = false }) => (
  <Select.Root defaultValue="english">
    <SelectTrigger aria-label="Language" disabled={isDisabled}>
      <Select.Value placeholder="English" />
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="ml-auto"
      >
        <path
          d="M5 7.5L10 12.5L15 7.5"
          stroke="#282928"
          strokeWidth="2"
          strokeLinecap="square"
        />
      </svg>
    </SelectTrigger>
    <Select.Portal>
      <SelectContent>
        <SelectViewport>
          <Select.Group>
            <SelectItem value="english">English</SelectItem>
          </Select.Group>
        </SelectViewport>
      </SelectContent>
    </Select.Portal>
  </Select.Root>
);

const SelectTrigger = styled(Select.SelectTrigger, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  padding: '0 18px',
  fontSize: '16px',
  lineHeight: '18px',
  weight: '600',
  border: '1px solid #282928',
  height: '58px',
  color: '#282928',
  width: '100%',
});

const SelectContent = styled(Select.Content, {
  overflow: 'hidden',
  backgroundColor: 'white',
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const SelectViewport = styled(Select.Viewport, {
  padding: 5,
});

// eslint-disable-next-line react/display-name
const SelectItem = React.forwardRef(({ children, ...props }, forwardedRef) => {
  return (
    <StyledItem {...props} ref={forwardedRef}>
      <Select.ItemText>{children}</Select.ItemText>
      <StyledItemIndicator>
        <CheckIcon />
      </StyledItemIndicator>
    </StyledItem>
  );
});

const StyledItem = styled(Select.Item, {
  fontSize: 16,
  lineHeight: 1,
  color: '#282928',
  display: 'flex',
  alignItems: 'center',
  height: '35px',
  padding: '0 35px 0 25px',
  position: 'relative',
  userSelect: 'none',
  '&[data-highlighted]': {
    outline: 'none',
    backgroundColor: '#C29B77',
    color: '#fff',
  },
});

const StyledItemIndicator = styled(Select.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const AccountDetails = () => {
  const { data, refetch } = useQuery(ME);
  const [updateUser] = useMutation(UPDATE_USER_ACCOUNT);
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState('');
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [passwordError, setPasswordError] = useState('');
  const [showPasswords, setShowPasswords] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const toast = useToast();

  const handleNameEdit = () => {
    setNewName(data?.me?.fullName || '');
    setIsEditingName(true);
  };

  const handleNameSave = () => {
    if (newName.trim()) {
      updateUser({
        variables: { input: { fullName: newName } },
      }).then(() => {
        refetch();
        setIsEditingName(false);
        toast.createToast({
          message: 'Name updated successfully',
          type: 'success',
          position: 'top-right',
          showCloseButton: true,
        });
      });
    }
  };

  const handlePasswordChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePasswordSubmit = () => {
    setPasswordError('');

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setPasswordError('New passwords do not match');
      return;
    }

    changePassword({
      variables: {
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword,
        confirmPassword: passwordData.confirmPassword,
      },
    })
      .then(() => {
        setShowPasswordForm(false);
        setPasswordData({
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
        setShowPasswords({
          currentPassword: false,
          newPassword: false,
          confirmPassword: false,
        });
        toast.createToast({
          message: 'Password changed successfully',
          type: 'success',
          position: 'top-right',
          showCloseButton: true,
        });
      })
      .catch((error) => {
        toast.createToast({
          message:
            error.message === 'AUTHENTICATION_ERROR'
              ? 'Invalid current password'
              : 'Failed to change password',
          type: 'error',
          position: 'top-right',
          showCloseButton: true,
        });
      });
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords({
      ...showPasswords,
      [field]: !showPasswords[field],
    });
  };

  return (
    <div className="flex w-full flex-col">
      <h1 className="mb-8 text-4xl font-bold">Account Details</h1>
      <p className="font-regular w-[80%] text-[16px] leading-[18px]">
        Manage your T-Fashion account with ease, ensuring your profile details
        are up to date and secure.
      </p>

      <div className="flex flex-col gap-10">
        {false && <ProfileImage user={data?.me} />}

        <div className="flex flex-col border-gray-200 pt-6">
          <div className="mb-4 flex items-center justify-between">
            <span className="text-base font-medium">Name</span>
            {!isEditingName ? (
              <button
                className="rounded-md border border-primary bg-primary px-4 py-2 text-sm font-medium text-white"
                onClick={handleNameEdit}
              >
                Edit
              </button>
            ) : (
              <div className="flex gap-4">
                <button
                  className="rounded-md border border-gray-300 px-4 py-2 text-sm font-medium"
                  onClick={() => setIsEditingName(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-md border border-primary bg-primary px-4 py-2 text-sm font-medium text-white"
                  onClick={handleNameSave}
                >
                  Save
                </button>
              </div>
            )}
          </div>
          {!isEditingName ? (
            <p className="text-lg">{data?.me?.fullName}</p>
          ) : (
            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              className="h-[58px] border border-charcoal px-[18px] outline-none"
            />
          )}
        </div>

        <div className="flex flex-col border-t border-gray-200 pt-6">
          <div className="mb-4 flex items-center justify-between">
            <span className="text-base font-medium">E-mail address</span>
          </div>
          <p className="text-lg">{data?.me?.email}</p>
        </div>
        <div className="flex flex-col border-t border-gray-200 pt-6">
          <div className="mb-4 flex items-center justify-between">
            <span className="text-base font-medium">Account Type</span>
          </div>
          <p className="text-lg capitalize">{data?.me?.role}</p>
        </div>

        <div className="flex flex-col border-t border-gray-200 pt-6">
          <div className="mb-4 flex items-center justify-between">
            <span className="text-base font-medium">Password</span>
          </div>

          {!showPasswordForm && (
            <button
              className="w-44 rounded-md border border-primary bg-primary py-2 text-sm font-medium text-white"
              onClick={() => setShowPasswordForm(true)}
            >
              Change Password
            </button>
          )}

          {showPasswordForm && (
            <div className="flex flex-col gap-4">
              <div className="relative">
                <input
                  type={showPasswords.currentPassword ? 'text' : 'password'}
                  name="currentPassword"
                  value={passwordData.currentPassword}
                  onChange={handlePasswordChange}
                  placeholder="Current Password"
                  className="h-[58px] w-full border border-charcoal px-[18px] pr-[50px] outline-none"
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('currentPassword')}
                  className="absolute right-4 top-1/2 -translate-y-1/2"
                >
                  {showPasswords.currentPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24" />
                      <line x1="1" y1="1" x2="23" y2="23" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                      <circle cx="12" cy="12" r="3" />
                    </svg>
                  )}
                </button>
              </div>

              <div className="relative">
                <input
                  type={showPasswords.newPassword ? 'text' : 'password'}
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={handlePasswordChange}
                  placeholder="New Password"
                  className="h-[58px] w-full border border-charcoal px-[18px] pr-[50px] outline-none"
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('newPassword')}
                  className="absolute right-4 top-1/2 -translate-y-1/2"
                >
                  {showPasswords.newPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24" />
                      <line x1="1" y1="1" x2="23" y2="23" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                      <circle cx="12" cy="12" r="3" />
                    </svg>
                  )}
                </button>
              </div>

              <div className="relative">
                <input
                  type={showPasswords.confirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  value={passwordData.confirmPassword}
                  onChange={handlePasswordChange}
                  placeholder="Confirm New Password"
                  className="h-[58px] w-full border border-charcoal px-[18px] pr-[50px] outline-none"
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('confirmPassword')}
                  className="absolute right-4 top-1/2 -translate-y-1/2"
                >
                  {showPasswords.confirmPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24" />
                      <line x1="1" y1="1" x2="23" y2="23" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                      <circle cx="12" cy="12" r="3" />
                    </svg>
                  )}
                </button>
              </div>

              {passwordError && <p className="text-red-500">{passwordError}</p>}

              <div className="flex gap-4">
                <button
                  type="button"
                  className="rounded-md border border-gray-300 px-6 py-2.5 text-sm font-medium"
                  onClick={() => {
                    setShowPasswordForm(false);
                    setPasswordData({
                      currentPassword: '',
                      newPassword: '',
                      confirmPassword: '',
                    });
                    setShowPasswords({
                      currentPassword: false,
                      newPassword: false,
                      confirmPassword: false,
                    });
                  }}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="rounded-md bg-[#3CA780] px-6 py-2.5 text-sm font-medium text-white"
                  onClick={handlePasswordSubmit}
                >
                  Save Password
                </button>
              </div>
            </div>
          )}
        </div>

        {false && (
          <div className="flex flex-col border-t border-gray-200 pt-6">
            <span className="mb-4 text-base font-medium">
              What is your purpose for using T-Fashion?
            </span>
            <PurposeSelect />
            <div className="mt-4 rounded-md bg-blue-50 p-4 text-sm">
              We customize your experience to meet your needs as a student. You
              can change this setting at any time.
            </div>
          </div>
        )}

        {false && (
          <div className="flex flex-col border-t border-gray-200 pt-6">
            <span className="mb-4 text-base font-medium">Language</span>
            <LanguageSelect />
          </div>
        )}
      </div>
    </div>
  );
};
