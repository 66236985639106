import { gql } from '@apollo/client';

export const UPDATE_USER_ACCOUNT = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      fullName
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $currentPassword: String
    $newPassword: String
    $confirmPassword: String
  ) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      message
    }
  }
`;
