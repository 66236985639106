import { useState } from 'react';

const SeeMore = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!text) return '';

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const words = text.split(' ');

  const truncatedText =
    words.length > maxLength ? words.slice(0, maxLength).join(' ') : text;

  return (
    <p className="text-md font-normal italic text-black">
      {isExpanded ? text : truncatedText}
      {words.length > maxLength && !isExpanded && (
        <span
          onClick={toggleExpansion}
          className="cursor-pointer font-bold italic text-[#C15F2A]"
        >
          {' '}
          see more...
        </span>
      )}
      {isExpanded && (
        <span
          onClick={toggleExpansion}
          className="cursor-pointer font-bold italic text-[#C15F2A]"
        >
          {' '}
          see less
        </span>
      )}
    </p>
  );
};

export default SeeMore;
