import { useEffect, useRef, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
// import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import * as Tabs from '@radix-ui/react-tabs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { camelCase, capitalize, startCase } from 'lodash';
import { Check, Pencil } from 'lucide-react';
import numeral from 'numeral';

import { useUser } from '@/api/authentication/hook';
import { EXPLORE, EXPLORE_SOCIALWATCH_FILTERS } from '@/api/explore/queries';
import { GET_EXPLORE_FILTERS } from '@/api/filters/queries';
import { useTrendsQuery } from '@/api/trends/hook';
import { EDIT_SNAPSHOT_ITEM } from '@/api/trends/mutations';
import { Button, FlexRow, LikeButton } from '@/components';
import { FormInput } from '@/components/FormInput';
import { MetricPill } from '@/components/Pills';
import { SelectDropdown } from '@/components/SelectDropdown';
// import SeeMore from '@/components/SeeMore';
import { TabHeading } from '@/components/TabHeading';
import { Text } from '@/components/Typography/index';
import { colorMap, trendItemTypeMapping } from '@/constants/index';
import { useExploreContext } from '@/contexts/ExploreContext';
import { useTrendsContext } from '@/contexts/TrendsContext';
import {
  cn,
  stringFormatter,
  // timeframeExploreFiltersMapping,
  timeframeLabelMapping,
  timeframeMapping,
} from '@/helpers/utils';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { SaveToCollection } from '@/layouts/common';
import { Card } from '@/pages/general/explore/components';
import { Watermark } from '@/pages/general/explore/components/Card/components/Image';
import {
  subColorOptions,
  subColorsNumerate,
} from '@/pages/general/explore/components/subColorOptions';
import { Comments } from '@/pages/general/imagePreview/components/Comments';
import tones from '@/tones.json';

import { SnapsCard } from '../../components/Card';

import { DetailBreakDown } from './components/DetailBreakdown';
import { Forecast } from './components';

dayjs.extend(relativeTime);

const itemTypeToFields = {
  snapshotItem: {
    color: 'ssiColor',
    colorTone: 'ssiColor',
    fabric: 'ssiFabric',
    pattern: 'ssiPattern',
    attributeKeyA: 'ssiAttributeKeyA',
    attributeKeyB: 'ssiAttributeKeyB',
    attributeValueA: 'ssiAttributeValueA',
    attributeValueB: 'ssiAttributeValueB',
  },
  trendItem: {
    color: 'tiColor',
    colorTone: 'tiColorTone',
    fabric: 'tiFabric',
    pattern: 'tiPattern',
    attributeKeyA: 'tiAttributeKeyA',
    attributeKeyB: 'tiAttributeKeyB',
    attributeValueA: 'tiAttributeValueA',
    attributeValueB: 'tiAttributeValueB',
  },
};

function ImageSelector({ item, itemType }) {
  const query = {
    attributes: [],
  };

  const color = item[itemTypeToFields[itemType].color];
  const colorTone = item[itemTypeToFields[itemType].colorTone];
  const fabric = item[itemTypeToFields[itemType].fabric];
  const pattern = item[itemTypeToFields[itemType].pattern];

  const attributeKeyA = item[itemTypeToFields[itemType].attributeKeyA];
  const attributeValueA = item[itemTypeToFields[itemType].attributeValueA];
  const attributeKeyB = item[itemTypeToFields[itemType].attributeKeyB];
  const attributeValueB = item[itemTypeToFields[itemType].attributeValueB];

  if (color) {
    query.colors = [color];
  }

  if (colorTone) {
    query.tone = {
      tone: colorTone,
      similarity: 3, // TODO: make this dynamic
    };
  }

  if (fabric) {
    query.attributes.push({
      name: 'fabric',
      value: fabric,
    });
  }

  if (pattern) {
    query.patterns = [pattern];
  }

  if (attributeKeyA && attributeValueA) {
    query.attributes.push({
      name: attributeKeyA,
      value: attributeValueA,
    });
  }

  if (attributeKeyB && attributeValueB) {
    query.attributes.push({
      name: attributeKeyB,
      value: attributeValueB,
    });
  }

  if (item.apparel) {
    query.apparels = [
      {
        value: item.apparel.toString(),
        items: [],
      },
    ];
  }

  if (query.attributes.length === 0) {
    delete query.attributes;
  }

  const [selectedItems, setSelectedItems] = useState(
    item.featuredImages.map((item) => ({
      ...item,
      image: item.entity.image,
      post: item.entity,
      entity: {
        entityType: 'post',
        entityId: item.entity.id,
      },
    })),
  );

  function onItemCoverChange(item) {
    const isItemSelected = checkIsSelected(item.post.id);

    if (isItemSelected) {
      setSelectedItems((prev) => [
        {
          ...item,
          entity: {
            entityType: 'post',
            entityId: item.post.id,
          },
        },
        ...prev.filter(
          (selectedItem) => selectedItem.entity.entityId !== item.post.id,
        ),
      ]);
    } else {
      setSelectedItems([
        {
          ...item,
          entity: {
            entityType: 'post',
            entityId: item.post.id,
          },
        },
        ...selectedItems,
      ]);
    }
  }

  const [fetchSocialWatchFilters] = useLazyQuery(EXPLORE_SOCIALWATCH_FILTERS);

  const [fetchExploreFilters] = useLazyQuery(GET_EXPLORE_FILTERS);

  function checkIsSelected(itemId) {
    return selectedItems.some((item) => item.entity.entityId === itemId);
  }

  function onItemSelect(item) {
    const isSelected = checkIsSelected(item.post.id);

    if (isSelected) {
      setSelectedItems(
        selectedItems.filter(
          (selectedItem) => selectedItem.entity.entityId !== item.post.id,
        ),
      );
    } else {
      setSelectedItems([
        ...selectedItems,
        {
          ...item.image,
          entity: {
            entityType: 'post',
            entityId: item.post.id,
          },
        },
      ]);
    }
  }

  const [filters, setFilters] = useState({});
  const [exploreQuery, setExploreQuery] = useState(query);

  const { data, loading, error, refetch, fetchMore } = useQuery(EXPLORE, {
    variables: {
      filters: exploreQuery,
      audiences: [item.audience.id],
    },
  });

  const [editSnapshotItem, { loading: editSnapshotLoading }] =
    useMutation(EDIT_SNAPSHOT_ITEM);

  useEffect(() => {
    fetchSocialWatchFilters({
      variables: {
        audienceId: item.audience.id,
      },
      onCompleted: (data) => {
        const socialWatchFilters = data.socialWatchFilters.filters;

        if (socialWatchFilters) {
          const filters = Object.entries(socialWatchFilters)
            .filter(
              ([key]) => key.startsWith('attributes') && key !== 'apparel',
            )
            .reduce((obj, [key, value]) => {
              const pattern = startCase(
                key.split('.').at(-1).replaceAll('-', ' '),
              );
              const options = value
                ?.map((item) => ({
                  label:
                    pattern + ': ' + startCase(item.value.replaceAll('-', ' ')),
                  name: key.split('.').at(-1),
                  value: item.value,
                  group: pattern,
                }))
                .filter((item) => item.value !== 'N/A');

              if (!obj['attribute.custom']) {
                obj['attribute.custom'] = {
                  label: 'Design',
                  placeholder: 'Select Design',
                  isClearable: true,
                  isMulti: true,
                  isSearchable: true,
                  options: [],
                };
              }

              obj['attribute.custom'].options.push({
                label: pattern,
                options,
              });

              return obj;
            }, {});

          setFilters(filters);
        }
      },
    });

    fetchExploreFilters({
      variables: {
        audiences: [item.audience.id],
      },
      onCompleted: (data) => {
        const exploreFilters = data.exploreFilters;

        const newFilters = Object.entries(exploreFilters).reduce(
          (acc, [key, value]) => {
            if (key === '__typename') return acc;

            acc[key] = {
              label: capitalize(key),
              placeholder: capitalize(`Select a ${key}`),
              isClearable: true,
              isMulti: true,
              isSearchable: true,
              options: value,
            };

            return acc;
          },
          {},
        );

        const tonesFilter = {
          label: 'Tones',
          placeholder: 'Select a tone',
          options: tones.map((tone) => ({
            label: capitalize(tone.label).replaceAll('-', ' '),
            value: tone.value,
            hex: tone.hex,
          })),
          isClearable: true,
          isMulti: true,
          isSearchable: true,
          value: [],
        };

        setFilters((prev) => ({ ...prev, ...newFilters, tones: tonesFilter }));
      },
    });
  }, []);

  useEffect(() => {
    const newQuery = {};

    if (filters['attribute.custom']?.value?.length > 0) {
      newQuery.attributes = filters['attribute.custom'].value.map((item) => ({
        name: item.name,
        value: item.value,
      }));
    }

    setExploreQuery(newQuery);
  }, [filters]);

  useEffect(() => {
    refetch();
  }, [exploreQuery]);

  function handleEditSnapshotItem() {
    editSnapshotItem({
      variables: {
        id: item.id,
        input: {
          gallery: selectedItems.map((item) => ({
            url: item.image.urlKey,
            width: item.image.width,
            height: item.image.height,
            blurhash: item.image.blurhash,
            entity: item.entity,
          })),
        },
      },
      onCompleted: (data) => {
        alert('Snapshot item updated');
      },
    });
  }

  function isCoverSelected(itemId) {
    const idx = selectedItems.findIndex(
      (item) => item.entity.entityId === itemId,
    );

    return idx !== -1 && idx <= 2;
  }

  if (!Object.keys(itemTypeToFields).includes(itemType)) {
    // eslint-disable-next-line no-console
    console.warn(`Unsupported item type: ${itemType}`);

    return null;
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    return null;
  }

  if (loading) {
    return <FeaturedImageSkeleton />;
  }

  const triggerClassName =
    'border-b-2 border-primary px-4 py-2 font-semibold data-[state=active]:bg-primary data-[state=active]:text-white data-[state=active]:rounded-t-lg';

  return (
    <div className="flex flex-col gap-2">
      <Tabs.Root defaultValue="current">
        <div className="flex items-center justify-between">
          <Tabs.List aria-label="Image Selector" className="mb-4">
            <Tabs.Trigger value="current" className={triggerClassName}>
              Current Images
            </Tabs.Trigger>
            <Tabs.Trigger className={triggerClassName} value="search">
              Search Images
            </Tabs.Trigger>
            <Tabs.Trigger className={triggerClassName} value="final">
              Final Look
            </Tabs.Trigger>
          </Tabs.List>

          <div className="flex items-center gap-2">
            <span className="text-lg font-semibold">
              Total Selected Image Count: {selectedItems.length}
            </span>
            <button
              onClick={handleEditSnapshotItem}
              disabled={editSnapshotLoading}
              className="rounded-xl bg-primary px-4 py-2 font-semibold text-white"
            >
              Save
            </button>
          </div>
        </div>
        <Tabs.Content value="current">
          <FeaturedImages
            editMode
            images={selectedItems}
            onItemSelect={onItemSelect}
            isSelected={checkIsSelected}
            isCoverSelected={isCoverSelected}
            onItemCoverChange={onItemCoverChange}
          />
        </Tabs.Content>
        <Tabs.Content value="search">
          <div className="grid grid-cols-5 gap-2">
            {Object.entries(filters).map(([filterKey, filter], i) => (
              <div key={i} className="z-[300]  mb-4">
                <FormInput key={i}>
                  <SelectDropdown
                    placeholder={filter?.placeholder}
                    isSearchable={filter?.isSearchable}
                    options={filter?.options}
                    multiselect={false}
                    isClearable={filter?.isClearable}
                    isMulti={filter?.isMulti}
                    value={filter?.value}
                    key={filterKey}
                    onChange={(e) =>
                      setFilters((prev) => ({
                        ...prev,
                        [filterKey]: {
                          ...prev[filterKey],
                          value: e,
                        },
                      }))
                    }
                    {...(filterKey === 'color'
                      ? {
                          formatOptionLabel: (option) => {
                            const labelLowerCase = option.label.toLowerCase();
                            const tone = exploreQuery.color?.value.find(
                              (tone) => tone.value === option.value,
                            );
                            const backgroundColor = Object.keys(
                              colorMap,
                            ).includes(labelLowerCase)
                              ? colorMap[labelLowerCase]
                              : tone
                                ? tone.hex
                                : option.value;

                            return (
                              <div
                                className={'flex flex-row items-center gap-3'}
                                style={{
                                  margin: '1px 0',
                                }}
                              >
                                <div
                                  className={cn(
                                    'h-4 w-4 border-charcoal rounded-full',
                                    option.value === 'white' && 'border-2',
                                  )}
                                  style={{
                                    background: backgroundColor,
                                  }}
                                ></div>
                                <span>{option.label}</span>
                              </div>
                            );
                          },
                        }
                      : {})}
                    {...(filterKey === 'tones'
                      ? {
                          formatOptionLabel: (option) => {
                            return (
                              <div
                                className={
                                  '-mx-4 -my-2 flex h-full flex-row items-center'
                                }
                              >
                                <div className="flex flex-row items-center gap-2 px-4 py-2">
                                  <div
                                    className={cn(
                                      'h-4 w-4 border-charcoal rounded-full',
                                    )}
                                    style={{
                                      background: option.hex,
                                      border: '1px solid black',
                                    }}
                                  ></div>
                                  <span className="p-1">
                                    {stringFormatter(option.label)} -{' '}
                                    {option.code || option.value}
                                  </span>
                                </div>
                              </div>
                            );
                          },
                        }
                      : {})}
                  />
                </FormInput>
              </div>
            ))}
          </div>
          <FeaturedImages
            editMode
            images={data.explore.data}
            onItemSelect={onItemSelect}
            isSelected={checkIsSelected}
            isCoverSelected={isCoverSelected}
            onItemCoverChange={onItemCoverChange}
            onLoadMore={() => {
              fetchMore({
                variables: {
                  cursor: data.explore.cursor,
                },
              });
            }}
          />
        </Tabs.Content>
        <Tabs.Content value="final">
          <FeaturedImages
            editMode
            images={selectedItems}
            onItemSelect={onItemSelect}
            isSelected={checkIsSelected}
            isCoverSelected={isCoverSelected}
            onItemCoverChange={onItemCoverChange}
          />
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
}

function FeaturedImageSkeleton() {
  return (
    <div className="ghost flex h-[480px] w-[200px] flex-col justify-items-start gap-y-[1rem] " />
  );
}

function FeaturedImages({
  images,
  skeleton,
  filter,
  editMode,
  isSelected,
  onItemSelect,
  isCoverSelected,
  onItemCoverChange,
  isLiked,
  itemId,
}) {
  // const columnsCountBreakPoints = {
  //   350: 2,
  //   1024: 3,
  //   1280: 4,
  //   1600: 5,
  //   1920: 6,
  // };

  const hasPost = images.map((item) =>
    !editMode ? !!item.entity.influencer : !!item?.influencer,
  );

  const { data: meData } = useUser();

  const moderationEnabled =
    meData?.me?.role === 'admin' || meData?.me?.role === 'moderator';

  const navigate = useNavigate();
  const location = useLocation();

  const { setActiveTab } = useExploreContext();

  const handleViewMoreButton = () => {
    filter('sm');
    setActiveTab('social-media');
    navigate('/explore/social-media', {
      state: { previousPath: location.pathname },
    });
  };

  const renderImages = () =>
    skeleton
      ? Array.from({ length: 8 }).map((_, index) => (
          <FeaturedImageSkeleton key={index} />
        ))
      : images.map((item, index) => {
          const imageItem = !editMode ? item.entity : item;

          const isItemSelected =
            editMode && typeof isSelected === 'function'
              ? isSelected(imageItem?.post?.id)
              : isSelected;

          const isItemCover = editMode && isCoverSelected(imageItem?.post?.id);

          return (
            <div key={'explore_' + index} className="group">
              <Card.Root
                data={!editMode ? item.entity : item}
                key={'explore_' + index}
                moderationEnabled={moderationEnabled}
              >
                <Card.Image />
                {editMode && (
                  <>
                    <div
                      className={cn(
                        'absolute top-0 left-0 z-[100] rounded-full p-2 transition-all',
                        isItemCover &&
                          'bg-green text-white hover:text-green hover:bg-white',
                        !isItemCover && 'bg-white hover:text-green',
                      )}
                      onClick={() => onItemCoverChange(imageItem)}
                    >
                      <span>Cover</span>
                    </div>
                    <div
                      className={cn(
                        'absolute top-0 right-0 z-[100] rounded-full p-2 transition-all',
                        isItemSelected &&
                          'bg-green text-white hover:text-green hover:bg-white',
                        !isItemSelected && 'bg-white hover:text-green',
                      )}
                      onClick={() => onItemSelect(imageItem)}
                    >
                      <Check />
                    </div>
                  </>
                )}
                {hasPost ? (
                  <Card.HoverContent>
                    <FlexRow justify="between" items="center">
                      <Card.Tones />

                      {!editMode && (
                        <FlexRow
                          className="self-end"
                          justify="between"
                          items="center"
                        >
                          <SaveToCollection
                            id={imageItem?.id}
                            collections={imageItem?.collections}
                            type="post"
                            variant="explore"
                          />

                          {/* <More /> */}

                          <LikeButton
                            item={imageItem?.id}
                            isLiked={isLiked}
                            type="post"
                          />
                        </FlexRow>
                      )}
                    </FlexRow>
                    <Card.Footer
                      id={imageItem?.id}
                      activeTab={'social-media'}
                      post={imageItem}
                    />
                  </Card.HoverContent>
                ) : (
                  <Card.HoverContent gradientDegree={'0deg'}>
                    <div style={{ zIndex: 50 }}>
                      <FlexRow justify="self-end" items="end">
                        <Watermark />
                      </FlexRow>
                    </div>
                    <Card.Footer
                      id={imageItem?.id}
                      activeTab={'fashion-weeks'}
                    />
                  </Card.HoverContent>
                )}
              </Card.Root>
            </div>
          );
        });

  return (
    <div className="relative w-full">
      {/* Blurred overlay div */}
      <div className="absolute inset-0 bg-white/30 backdrop-blur-md"></div>

      {/* Image grid */}
      <div className="relative">
        {/* <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
          <Masonry gutter={'1.625rem'}>{renderImages()}</Masonry>
        </ResponsiveMasonry> */}
        <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
          {renderImages()}
        </div>

        {editMode && (
          <div className="mt-4 flex items-center justify-center gap-4">
            <Button>Load More</Button>
          </div>
        )}
      </div>

      {/* "View More" button */}
      {!editMode && (
        <div className="absolute bottom-0 left-1/2 z-10 flex h-96 w-full -translate-x-1/2 items-center justify-center">
          <Button
            onClick={handleViewMoreButton}
            className="relative top-20 z-20 rounded-full border-2 border-primary bg-primary/80 px-10 py-4 text-white"
          >
            <Text
              className="text-2xl [text-shadow:_0_4px_8px_rgb(0_0_0_/_30%)]"
              style={{
                fontWeight: 500,
              }}
            >
              View More in Explore
            </Text>
          </Button>
          <div className="absolute inset-0 top-72 block h-24 bg-gradient-to-t from-white via-white/70 to-white/0"></div>
        </div>
      )}
    </div>
  );
}

function Wrapper({ children, imagePreview = true }) {
  const [wrapperHeight, setWrapperHeight] = useState(0);

  useEffect(() => {
    function handleSizeChange() {
      const navbarEl = document.getElementById('main-nav');
      const bodyEl = document.querySelector('body');

      setWrapperHeight(bodyEl.offsetHeight - navbarEl.offsetHeight);
    }

    handleSizeChange();

    window.addEventListener('resize', handleSizeChange);

    return () => window.removeEventListener('resize', handleSizeChange);
  }, []);

  return (
    <div
      className={cn(
        'absolute right-0 left-0 z-[500] grid animate-fade-in overflow-y-auto overflow-x-hidden bg-white',
        imagePreview ? 'grid-cols-[67%_33%]' : 'grid-cols-[33%_67%]',
      )}
      style={{
        height: `${wrapperHeight}px`,
      }}
    >
      {children}
    </div>
  );
}

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center gap-x-2 text-lg font-semibold leading-[1.125rem] text-black">
        Analysis
      </div>
      <RxCross1
        size={'2rem'}
        onClick={() => {
          if (location.key === 'default') {
            navigate('/overview');
          } else {
            navigate(-1);
          }
        }}
        className="cursor-pointer"
      />
    </div>
  );
}

export function SnapshotsDetail() {
  const [editCoverState, setEditCoverState] = useState(false);
  const [currentAudienceId, setCurrentAudienceId] = useState(null);
  const [categoryList, setCategoryList] = useState([]);

  const [editTitleState, setEditTitleState] = useState(false);
  const [newTitle, setNewTitle] = useState('Black Shoe');

  const editTitleRef = useRef(null);

  const { moderationEnabled } = useUser();

  const [editSnapshotItem, { loading: editSnapshotLoading }] =
    useMutation(EDIT_SNAPSHOT_ITEM);

  // const moderationEnabled = false;

  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const itemId = searchParams.get('id');

  const { activeChart, setActiveChart } = useTrendsContext();

  const { isEnabled: isSnapshotTotalQualityEngagementEnabled } = useFeatureFlag(
    FEATURE_FLAGS.TOTAL_ENGAGEMENT,
  );

  const { handleSocialMediaFiltersOnChange, handleSocialMediaSortOnChange } =
    useExploreContext();

  const { data: similarData } = useTrendsQuery('similarSnapshotItem', {
    variables: {
      similarSnapshotItemId: itemId,
      cursor: null,
    },
  });

  const { marketsData } = useTrendsContext();

  // const [
  //   fetchMarkets,
  //   {
  //     data: _marketsData,
  //     // loading: marketsLoading
  //     // error: marketsError
  //   },
  // ] = useLazyQuery(GET_MARKETS);

  useEffect(() => {
    function closePage(e) {
      if (e.key === 'Escape') {
        if (location.key === 'default') {
          return navigate('/overview');
        } else {
          return navigate(-1);
        }
      }
      return null;
    }

    window.addEventListener('keydown', closePage);

    return () => window.removeEventListener('keydown', closePage);
  }, []);

  const selectedTimeframe = searchParams.get('timeframe');

  const { data, loading, error } = useTrendsQuery('snapshotItem', {
    variables: {
      snapshotItemId: itemId,
      timeframe: selectedTimeframe,
    },
    onCompleted: (data) => {
      if (data?.snapshotItem?.node.title) {
        setNewTitle(data.snapshotItem.node.title);
      }

      if (data?.snapshotItem?.node.groupId) {
        setCurrentAudienceId(data?.snapshotItem?.node.groupId);
      }
    },
  });

  // useEffect(() => {
  //   if (currentAudienceId && filters?.source?.options) {
  //     if (filters?.source?.value?.value === currentAudienceId) return;

  //     const audience = filters?.source?.options
  //       .map((option) => option?.options || option)
  //       .flat()
  //       .find((audience) => audience.value === currentAudienceId);

  //     if (audience) {
  //       handleSourceOnChange(audience);
  //     }
  //   }
  // }, [currentAudienceId, filters, handleSourceOnChange]);

  useEffect(() => {
    if (marketsData) {
      const market = marketsData.markets[0];
      setCategoryList(market.apparels);
    }
  }, [marketsData]);

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {error.message}</span>
      </div>
    );
  }

  if (loading || currentAudienceId === null || !data) {
    return (
      <div className="absolute inset-x-0 z-[500] grid  h-full w-full animate-fade-in grid-cols-[67%_33%] overflow-hidden bg-white">
        <div className="flex h-full w-full flex-col overflow-hidden px-[3.75rem] py-8">
          <div className="grid grid-cols-3 gap-8 lg:grid-cols-5">
            {Array.from({ length: 20 }).map((_, index) => (
              <div
                key={index}
                className="ghost flex aspect-[7/11] flex-col justify-items-start gap-y-[1rem] rounded-md"
              />
            ))}
          </div>
        </div>
        <div className="flex h-[90vH] w-full flex-col justify-between overflow-hidden border-l border-secondary px-[3.75rem] py-9">
          <div className="flex flex-col gap-y-8">
            <div className="ghost flex h-12 flex-wrap items-center justify-start gap-4"></div>
            <div className="ghost flex h-16 flex-wrap items-center justify-start gap-4"></div>
            <div className="ghost flex h-20 flex-wrap items-center justify-start gap-4"></div>
            <div className="ghost flex h-28 flex-wrap items-center justify-start gap-4"></div>
          </div>
          <div className="flex flex-col gap-y-8">
            <div className="ghost flex h-12 flex-col gap-y-4"></div>
            <div className="ghost flex h-12 flex-col gap-y-4"></div>
          </div>
        </div>
      </div>
    );
  }

  const selectedMarket = marketsData?.markets?.find((market) =>
    market.audiences.find((audience) => audience.groupId === currentAudienceId),
  );

  const snapshotItem = data?.snapshotItem?.node;
  const gender = snapshotItem?.audience?.[0]?.gender === '1' ? 'Men' : 'Women';
  const locationName = selectedMarket?.location?.name || 'London';

  const ageMin = snapshotItem?.audience?.[0]?.ageMin;
  const ageMax = snapshotItem?.audience?.[0]?.ageMax;

  const selectedAudienceName =
    selectedMarket?.audiences.find(
      (audience) => audience.groupId === currentAudienceId,
    )?.name || `${locationName}, ${gender}, ${ageMin}-${ageMax}`;

  const selectedAudience = selectedMarket?.audiences.find(
    (audience) => audience.groupId === currentAudienceId,
  );

  const charts = [
    {
      label: 'Visibility',
      value: 'visibility',
      component: (
        <Forecast.Root>
          <Forecast.Chart />
        </Forecast.Root>
      ),
    },
    {
      label: 'Engagement',
      value: 'qualityEngagement',
      component: (
        <Forecast.Root>
          <Forecast.Chart />
        </Forecast.Root>
      ),
    },
  ];

  const forecastData =
    snapshotItem?.[timeframeMapping[selectedTimeframe]] ||
    snapshotItem?.weeklyData;

  const visibility = Number((forecastData.at(-1).visibility * 100).toFixed(1));
  const lastVisibility = Number(
    (forecastData.at(-2).visibility * 100).toFixed(1),
  );

  const qualityEngagement = numeral(forecastData.at(-1).qualityEngagement)
    .format('0.0a')
    .toUpperCase();

  const lastQualityEngagement = numeral(forecastData.at(-2).qualityEngagement)
    .format('0.0a')
    .toUpperCase();

  const totalEngagement = numeral(forecastData.at(-1).totalEngagement)
    .format('0.0a')
    .toUpperCase();

  const lastTotalEngagement = numeral(forecastData.at(-2).totalEngagement)
    .format('0.0a')
    .toUpperCase();

  const trendType = forecastData.at(-1).trendType;

  const ssiType = snapshotItem?.ssiType;

  const filterType = trendItemTypeMapping[ssiType];

  const ssiTone = tones.find((item) => item.value === snapshotItem?.colorTone);

  const colorHex = colorMap[snapshotItem?.ssiColor];

  const categoryFilter = (() => {
    if (categoryList.length === 0) {
      return { label: '', value: '' };
    }

    const selectedCategory = categoryList.find(
      (item) => item.value === snapshotItem?.apparel,
    );

    if (!selectedCategory) {
      return { label: '', value: '' };
    }

    const { label, value } = selectedCategory;
    const formattedLabel =
      label === 'pant/trouser' ? 'Pant Trouser' : stringFormatter(label);

    return {
      label: formattedLabel,
      value: value.toString() || '',
    };
  })();

  const audienceFilter = currentAudienceId.split('_');

  const marketFilter = [
    {
      label: selectedAudienceName,
      location: locationName,
      value: selectedAudience?.id,
      groups: selectedAudience?.groups,
      marketId: selectedMarket.id,
    },
  ];

  const foundAudience = selectedMarket?.audiences.find(
    (audience) => audience.groupId === currentAudienceId,
  );

  const platformFilter = {
    id: foundAudience?.groups?.length === 2 ? 0 : foundAudience?.groups[0]?.id,
    label:
      foundAudience?.groups?.length === 2
        ? 'ALL'
        : foundAudience?.groups[0]?.platform.toUpperCase(),
    value:
      foundAudience?.groups?.length === 2
        ? 'ALL'
        : foundAudience?.groups[0]?.platform.toUpperCase(),
  };

  // TODO: this value should be handled in the explore context
  const sorting = {
    label: 'Freshness',
    value: 'takenAt.desc',
  };

  const colorFilter = {
    hex: colorHex,
    label: startCase(camelCase(snapshotItem?.ssiColor)),
    value: snapshotItem?.ssiColor,
  };

  const toneFilter = {
    hex: ssiTone?.hex,
    label: startCase(camelCase(ssiTone?.label)),
    value: ssiTone?.value,
  };

  const attributeFilter = [
    {
      group: startCase(camelCase(snapshotItem?.attributeKeyA)),
      label:
        startCase(camelCase(snapshotItem?.attributeKeyA)) +
        ': ' +
        startCase(camelCase(snapshotItem?.attributeValueA)),
      name: snapshotItem?.attributeKeyA,
      value: snapshotItem?.attributeValueA,
    },
  ];

  const attributesFilter = [
    {
      group: startCase(camelCase(snapshotItem?.attributeKeyA)),
      label:
        startCase(camelCase(snapshotItem?.attributeKeyA)) +
        ': ' +
        startCase(camelCase(snapshotItem?.attributeValueA)),
      name: snapshotItem?.attributeKeyA,
      value: snapshotItem?.attributeValueA,
    },
    {
      group: startCase(camelCase(snapshotItem?.attributeKeyB)),
      label:
        startCase(camelCase(snapshotItem?.attributeKeyB)) +
        ': ' +
        startCase(camelCase(snapshotItem?.attributeValueB)),
      name: snapshotItem?.attributeKeyB,
      value: snapshotItem?.attributeValueB,
    },
  ];

  const patternFilter = [
    {
      group: 'Pattern',
      label: 'Pattern: ' + startCase(camelCase(snapshotItem?.ssiPattern)),
      name: 'pattern',
      value: snapshotItem?.ssiPattern,
    },
  ];

  const fabricFilter = [
    {
      group: 'Fabric',
      label: 'Fabric: ' + startCase(camelCase(snapshotItem?.ssiFabric)),
      name: 'fabric',
      value: snapshotItem?.ssiFabric,
    },
  ];

  const subColorLabel = snapshotItem.subcolor
    ? subColorsNumerate[snapshotItem?.subcolor?.toString()]?.name.trim()
    : null;

  const subColorValue = subColorLabel
    ? subColorOptions.find((color) => color.value === subColorLabel)
    : null;

  const subColorFilter = subColorValue ? [subColorValue] : [];

  const tiersOfMarkets = marketFilter
    .flatMap((market) => market.groups.map((group) => group.tiers))
    .flat();

  const tierAudience = tiersOfMarkets.find((item) =>
    audienceFilter.includes(item.audienceId),
  );

  const tierFilter = tierAudience
    ? {
        label: tierAudience.label,
        value: tierAudience.id,
      }
    : {
        label: 'ALL',
        value: 'ALL',
      };

  // const timeFrameFilter =
  //   timeframeExploreFiltersMapping[selectedTimeframe || 'lastWeek'];

  const handleExploreFilter = () => {
    const filterHandlers = {
      apparelLabelId: { filter: categoryFilter, key: 'category' },
      'tones.main': { filter: colorFilter, key: 'color' },
      'tones.code': { filter: toneFilter, key: 'tone' },
      'attributes.value': {
        filter: snapshotItem?.attributeKeyB
          ? attributesFilter
          : attributeFilter,
        key: 'attribute.custom',
      },
      pattern: { filter: patternFilter, key: 'attribute.custom' },
      fabric: { filter: fabricFilter, key: 'attribute.custom' },
      subcolor: { filter: subColorFilter, key: 'subcolor' },
    };

    // General filter handling function
    const handleFilterChange = (filter, key) => {
      return handleSocialMediaFiltersOnChange(filter, key, true);
      /*   if (key !== 'audience') {
      } */
      // return handleSocialMediaFiltersOnChange(currentAudienceId, key, true);
    };

    handleFilterChange(audienceFilter, 'audience');
    handleFilterChange(marketFilter, 'market');
    handleFilterChange(platformFilter, 'platform');
    handleFilterChange(tierFilter, 'tier');
    // handleFilterChange(timeFrameFilter, 'timeframe');
    handleSocialMediaSortOnChange(sorting, 'sort');

    // Iterate over filterType and apply the corresponding handler
    filterType.forEach((item) => {
      const handler = filterHandlers[item];
      if (handler) {
        if (handler.key === 'attribute.custom') {
          const combinedFilters = [
            ...(snapshotItem?.ssiPattern &&
            snapshotItem?.attributeKeyA !== 'pattern' &&
            snapshotItem?.attributeKeyB !== 'pattern'
              ? patternFilter
              : []),
            ...(snapshotItem?.ssiFabric &&
            snapshotItem?.attributeKeyA !== 'fabric' &&
            snapshotItem?.attributeKeyB !== 'fabric'
              ? fabricFilter
              : []),
            ...(snapshotItem?.attributeKeyA
              ? snapshotItem?.attributeKeyB
                ? attributesFilter
                : attributeFilter
              : []),
          ];
          handleFilterChange(combinedFilters, 'attribute.custom');
        } else {
          if (handler.key === 'category') {
            handleFilterChange([handler.filter], handler.key);
          } else if (handler.key === 'subcolor') {
            handler.filter.forEach((item) => {
              item.value = Object.values(subColorsNumerate)
                .find((color) => color.name === item.value)
                ?.id?.toString();
            });
            handleFilterChange(handler.filter, handler.key);
          } else {
            handleFilterChange(handler.filter, handler.key);
          }
        }
      } else {
        console.warn(`Unhandled filter type: ${item}`);
      }
    });
  };

  return (
    <Wrapper>
      <div className="flex w-full flex-col gap-y-6 overflow-auto px-[3.75rem] py-8">
        {/* {editCoverState && moderationEnabled && (
          <Modal
            isOpen={editCoverState}
            onClose={() => setEditCoverState(false)}
          >
            <EditGallery
              item={snapshotItem}
              onClose={() => setEditCoverState(false)}
            />
          </Modal>
        )} */}
        <div className="flex flex-col">
          <div className="flex flex-row items-start justify-between gap-4">
            <div className="flex flex-row items-end gap-4">
              <div className="flex flex-col gap-2.5">
                <h3 className="text-lg font-semibold leading-5 text-primary">
                  Snapshots: {locationName || 'London'},{' '}
                  {selectedAudienceName ||
                    `${gender || 'Women'}, ${ageMin || '20'}-${ageMax || '35'}`}
                </h3>
                <div className="flex items-center gap-4">
                  {moderationEnabled && (
                    <button
                      onClick={() => {
                        editTitleRef.current?.focus();
                        setEditTitleState(true);
                      }}
                    >
                      <Pencil className="text-primary" />
                    </button>
                  )}
                  {moderationEnabled ? (
                    <input
                      ref={editTitleRef}
                      disabled={!moderationEnabled || !editTitleState}
                      className="text-4xl font-bold capitalize text-black"
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                    />
                  ) : (
                    <div className="flex flex-row items-center gap-4">
                      <h2 className="text-4xl font-bold capitalize text-black">
                        {newTitle}
                      </h2>
                    </div>
                  )}
                  {moderationEnabled && editTitleState && (
                    <Button
                      className="rounded-lg py-2 font-bold text-white"
                      loading={editSnapshotLoading}
                      onClick={() => {
                        setEditTitleState(false);
                        editSnapshotItem({
                          variables: {
                            id: snapshotItem.id,
                            input: {
                              title: newTitle,
                            },
                          },
                          onCompleted: () => {
                            setEditTitleState(false);
                            alert('Title updated');
                          },
                        });
                      }}
                    >
                      Save Title
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-end gap-2">
              <SaveToCollection
                id={snapshotItem.itemId}
                type="snapshotItem"
                metadata={{
                  audienceName: selectedAudienceName,
                }}
              >
                {() => (
                  <div className="flex gap-x-2">
                    <Button className="rounded-lg bg-primary py-2 font-bold text-white">
                      Save
                    </Button>
                  </div>
                )}
              </SaveToCollection>
              {moderationEnabled && (
                <Button
                  className={`${loading ? 'ghost' : ''} rounded-lg py-2 font-bold text-white`}
                  onClick={() => setEditCoverState((prev) => !prev)}
                >
                  Edit Gallery
                </Button>
              )}
            </div>
          </div>
          {/* {import.meta.env.VITE_APP_ENV === 'development' && (
            <div className="mt-4 flex flex-col items-center justify-start gap-4">
              <SeeMore
                text="The Black Loose Leather Jacket has emerged as the undisputed
                star of the fashion scene in London. Recording the highest
                visibility among all trending items, it has been embraced by a
                diverse array of influencers, signaling its universal appeal.
                The Black Loose Leather Jacket has emerged as the undisputed
                star of the fashion scene in London. Recording the highest
                visibility among all trending items, it has been embraced by a
                diverse array of influencers, signaling its universal appeal."
                maxLength={40}
              />
            </div>
          )} */}
        </div>
        {snapshotItem?.tabs?.filter((tab) => tab?.data?.length > 0).length >
          0 && (
          <div
            className={cn(
              'grid gap-x-6',
              `grid-cols-${snapshotItem.tabs.filter((tab) => tab?.data?.length > 0).length === 1 ? 2 : snapshotItem.tabs.filter((tab) => tab?.data?.length > 0).length}`,
            )}
          >
            {snapshotItem.tabs.map(
              (tab, index) =>
                tab?.data?.length > 0 && (
                  <DetailBreakDown key={index} tab={tab} />
                ),
            )}
          </div>
        )}
        {moderationEnabled && editCoverState && (
          <ImageSelector item={snapshotItem} itemType="snapshotItem" />
        )}
        {snapshotItem?.featuredImages && !editCoverState && (
          <FeaturedImages
            images={snapshotItem.featuredImages}
            isLiked={snapshotItem.isLiked}
            skeleton={false}
            filter={handleExploreFilter}
          />
        )}
        {similarData?.similarSnapshotItem && (
          <div className="flex flex-col gap-y-4">
            <h2 className="mt-8 text-center text-3xl font-bold text-black">
              Similar Trends
            </h2>
            <div className="my-4 grid grid-cols-3 gap-8">
              {similarData?.similarSnapshotItem?.edges.map((item, index) => (
                <SnapsCard key={index} variant="sm" snapshotItem={item.node} />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between border-l border-secondary/50 px-[3.75rem] pb-4 pt-9">
        <div className="flex flex-col gap-y-8">
          <Header />
          <div
            className={cn(
              `grid gap-1`,
              isSnapshotTotalQualityEngagementEnabled
                ? 'grid-cols-3'
                : 'grid-cols-2',
            )}
          >
            {/* <HoverTooltip
              side="top"
              className="w-48 text-left text-xs leading-3"
              tooltipContent={
                <p>
                  A product quickly gaining popularity, often thanks to
                  celebrity endorsements or viral social media. Early adopters
                  can see major growth.
                </p>
              }
            >
              <div>
                <MetricPill
                  color={trendType === 'FLAT' ? '#C29B77' : null}
                  isIncrease={trendType === 'UP'}
                  metric="Trend Type"
                  metricValue={trendType}
                  timePeriod={{ value: null, label: null }}
                />
              </div>
            </HoverTooltip>
            <HoverTooltip
              side="top"
              className="w-48 text-left text-xs leading-3"
              tooltipContent={
                <p>
                  A product quickly gaining popularity, often thanks to
                  celebrity endorsements or viral social media. Early adopters
                  can see major growth.
                </p>
              }
            >
              <div>
                <MetricPill
                  isIncrease={visibility >= lastVisibility}
                  metric="Visibility"
                  metricValue={visibility.toString()}
                  timePeriod={{ value: lastVisibility, label: 'Last Week' }}
                />
              </div>
            </HoverTooltip>
            <HoverTooltip
              side="top"
              className="w-48 text-left text-xs leading-3"
              tooltipContent={
                <p>
                  A product quickly gaining popularity, often thanks to
                  celebrity endorsements or viral social media. Early adopters
                  can see major growth.
                </p>
              }
            >
              <div>
                <MetricPill
                  isIncrease={qualityEngagement >= lastQualityEngagement}
                  metric="Quality Engagement"
                  metricValue={qualityEngagement.toString()}
                  timePeriod={{
                    value: lastQualityEngagement,
                    label: 'Last Week',
                  }}
                />
              </div>
            </HoverTooltip> */}
            <MetricPill
              color={trendType === 'FLAT' ? '#C29B77' : null}
              isIncrease={trendType === 'UP'}
              metric="Trend Type"
              metricValue={trendType}
              timePeriod={{ value: null, label: null }}
            />
            <MetricPill
              isIncrease={visibility >= lastVisibility}
              metric="Visibility"
              metricValue={visibility.toString()}
              timePeriod={{
                value: lastVisibility,
                label: timeframeLabelMapping[selectedTimeframe],
              }}
            />
            <MetricPill
              isIncrease={
                forecastData.at(-1).qualityEngagement >=
                forecastData.at(-2).qualityEngagement
              }
              metric="Quality Engagement"
              metricValue={qualityEngagement.toString()}
              timePeriod={{
                value: lastQualityEngagement,
                label: timeframeLabelMapping[selectedTimeframe],
              }}
            />
            {!isSnapshotTotalQualityEngagementEnabled && (
              // <HoverTooltip
              //   side="top"
              //   className="w-48 text-left text-xs leading-3"
              //   tooltipContent={
              //     <p>
              //       A product quickly gaining popularity, often thanks to
              //       celebrity endorsements or viral social media. Early adopters
              //       can see major growth.
              //     </p>
              //   }
              // >
              //   <div>
              //     <MetricPill
              //       isIncrease={totalEngagement >= lastTotalEngagement}
              //       metric="Total Engagement"
              //       metricValue={totalEngagement.toString()}
              //       timePeriod={{
              //         value: lastTotalEngagement,
              //         label: 'Last Week',
              //       }}
              //     />
              //   </div>
              // </HoverTooltip>
              <MetricPill
                isIncrease={totalEngagement >= lastTotalEngagement}
                metric="Total Engagement"
                metricValue={totalEngagement.toString()}
                timePeriod={{
                  value: lastTotalEngagement,
                  label: timeframeLabelMapping[selectedTimeframe],
                }}
              />
            )}
          </div>
          <div className="w-full">
            <Tabs.Root
              value={activeChart}
              onValueChange={(chart) => {
                setActiveChart(chart);
              }}
              className={'flex flex-col gap-y-4'}
            >
              <div className="flex flex-row items-start justify-between">
                <h4 className="text-lg font-bold leading-[1.125rem]">Chart</h4>
                <Tabs.List
                  aria-label="trends"
                  className={'flex items-start justify-start'}
                >
                  <div className={'flex gap-x-2.5'}>
                    {charts.map((chart, i) => (
                      <TabHeading
                        size={'md'}
                        label={chart.label}
                        value={chart.value}
                        variant="snapshots"
                        className="pb-2 text-sm leading-4"
                        key={i}
                      />
                    ))}
                  </div>
                </Tabs.List>
              </div>
              <p className="text-xs font-normal leading-[0.875rem]">
                This chart about how users interact with content. For example,
                shares, comments, saves, long-term, etc.
              </p>
              <div>
                {charts.map((chart, i) => (
                  <Tabs.Content key={i} value={chart.value}>
                    {chart.component}
                  </Tabs.Content>
                ))}
              </div>
            </Tabs.Root>
          </div>
        </div>
        <div className="flex flex-col gap-y-8">
          <Comments itemId={snapshotItem?.itemId} isSnapshotItem />
        </div>
      </div>
    </Wrapper>
  );
}
