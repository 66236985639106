/* eslint react/jsx-indent:0 */

import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  ChevronDown,
  LoaderCircle,
  Pencil,
  RefreshCcw,
  Trash,
  X,
} from 'lucide-react';

import { useUserQuery } from '@/api/authentication/hook';
import { useFiltersQuery } from '@/api/filters/hook';
import {
  useOrganizationMutation,
  useOrganizationQuery,
} from '@/api/organization/hook';
import { Button, Modal, Text } from '@/components';
import { Checkbox } from '@/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn } from '@/helpers/utils';
import { useToast } from '@/hooks/useToast';

dayjs.extend(utc);

function returnAudiencesForGroup({ groupId, marketsData, exclude = false }) {
  const audiences = marketsData?.markets.flatMap((market) => {
    return market.audiences
      .filter((audience) =>
        exclude
          ? !audience.userGroups.some((group) => group.id === groupId)
          : audience.userGroups.some((group) => group.id === groupId),
      )
      .map((audience) => ({
        ...audience,
        name:
          audience.name ||
          audience.label ||
          `${market.location.name}, ${audience.ageMin}-${audience.ageMax}, ${audience.gender === '2' ? 'Woman' : 'Man'}`,
        market: market.id,
      }));
  });

  return audiences || [];
}

function PendingInvitationRow({ invitation }) {
  const toast = useToast();

  const [resendInvitation, { loading: resendInvitationLoading }] =
    useOrganizationMutation('resendInvitation', {
      refetchQueries: ['Organization'],
      onCompleted: () => {
        toast.createToast({
          message: 'Invitation resent successfully',
          type: 'success',
          position: 'top-right',
          showCloseButton: true,
        });
      },
      onError: () => {
        toast.createToast({
          message: 'Failed to resend invitation',
          type: 'error',
          position: 'top-right',
          showCloseButton: true,
        });
      },
    });

  function handleResendInvitation() {
    resendInvitation({
      variables: {
        invitationId: invitation.id,
      },
    });
  }

  return (
    <div key={invitation.id} className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <div className="flex aspect-square h-8 items-center justify-center rounded-full bg-primary-100 font-semibold capitalize text-primary-900">
          {invitation.email.charAt(0)}
        </div>
        <div className="text-sm font-medium">{invitation.email}</div>
      </div>

      <div className="flex items-center gap-2">
        <span className="text-sm font-medium capitalize">
          {invitation.role.toLowerCase()}
        </span>
        {dayjs(invitation.updatedAt)
          .utc()
          .diff(dayjs().utc().subtract(15, 'minutes')) < 0 && (
          <Button
            variant="ghost"
            icon
            size="xs"
            onClick={handleResendInvitation}
            disabled={resendInvitationLoading}
          >
            <RefreshCcw
              size={18}
              className={cn(resendInvitationLoading && 'animate-spin')}
            />
          </Button>
        )}
      </div>
    </div>
  );
}

function UserGroupRow({ userGroup, onSelect, isSelected }) {
  return (
    <div
      key={userGroup.id}
      className="group flex items-center justify-between px-4 py-2 hover:bg-primary-200"
      onClick={() => onSelect(userGroup.id)}
    >
      <div className="flex items-center gap-2">
        <Checkbox
          checked={isSelected}
          onCheckedChange={() => onSelect(userGroup.id)}
        />

        <div className="flex aspect-square h-8 items-center justify-center rounded-full bg-primary-100 font-semibold capitalize text-primary-900">
          {userGroup.name.charAt(0)}
        </div>
        <div className="text-sm font-medium group-hover:text-white">
          {userGroup.name}
        </div>
      </div>

      <div className="flex items-center gap-2 group-hover:text-white">
        <span className="text-sm font-medium capitalize">
          {userGroup.users.length} users
        </span>
      </div>
    </div>
  );
}

function SendInvitationsModal({ isOpen, onClose }) {
  const { data } = useOrganizationQuery('organization');

  const remainingInviteLimit =
    data?.organization?.inviteLimit - data?.organization?.members?.length || 0;

  const userGroups = data?.organization?.userGroups;

  const [selectedRole, setSelectedRole] = useState('MEMBER');
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [selectedTab, setSelectedTab] = useState('pending-invitations');

  const [invitations, setInvitations] = useState([]);

  const toast = useToast();

  function handleCleanup() {
    setSelectedRole('MEMBER');
    setSelectedUserGroups([]);
    setSelectedTab('pending-invitations');
    setInvitations([]);
  }

  const [sendInvitations, { loading }] = useOrganizationMutation(
    'sendInvitations',
    {
      refetchQueries: ['Organization'],
      onCompleted: (data) => {
        handleCleanup();

        if (data.sendInvitations.failedEmails.length > 0) {
          toast.createToast({
            message: `Some invitations sent failed, failed emails: ${data.sendInvitations.failedEmails
              .map(
                ({ email, reason }) =>
                  `"${email}": ${reason
                    .toLowerCase()
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}`,
              )
              .join(', ')}`,
            type: 'error',
            position: 'top-right',
            showCloseButton: true,
          });

          setInvitations((prev) =>
            prev.filter((email) =>
              data.sendInvitations.failedEmails
                .map(({ email }) => email)
                .includes(email),
            ),
          );

          return;
        }

        toast.createToast({
          message: 'Invitations sent successfully',
          type: 'success',
          position: 'top-right',
          showCloseButton: true,
        });

        if (!data.sendInvitations.failedEmails.length > 0) {
          onClose();
        }
      },
      onError: () => {
        handleCleanup();

        toast.createToast({
          message: 'Something went wrong',
          type: 'error',
          position: 'top-right',
          showCloseButton: true,
        });
      },
    },
  );

  useEffect(() => {
    if (remainingInviteLimit === 0 && data?.organization?.members) {
      setInvitations([]);
    }
  }, [remainingInviteLimit, data?.organization?.members]);

  function handleSendInvitations() {
    if (invitations.length === 0) {
      toast.createToast({
        message: 'Please enter valid email addresses',
        type: 'error',
        position: 'top-right',
        showCloseButton: true,
      });
      return;
    }

    sendInvitations({
      variables: {
        invitations: invitations.map((email) => ({
          email,
          role: selectedRole,
          userGroups: selectedUserGroups,
        })),
      },
    });
  }

  function handleUserGroupSelect(userGroupId) {
    if (selectedUserGroups.includes(userGroupId)) {
      return setSelectedUserGroups((prev) => [
        ...prev.filter((userGroup) => userGroup !== userGroupId),
      ]);
    }

    return setSelectedUserGroups((prev) => [...prev, userGroupId]);
  }

  const pendingInvites = data?.organization.invitations
    .filter((invitation) => invitation.status === 'PENDING')
    .sort((a, b) => dayjs(b.updatedAt).diff(dayjs(a.updatedAt)));

  return (
    <Modal isOpen={isOpen} onClose={onClose} fluid={false}>
      <div className="no-scrollbar flex max-h-[60vh] w-full flex-col gap-2 overflow-y-auto p-8">
        <div className="flex w-full items-center justify-between">
          <h1 className="text-lg font-semibold">Invite Co-Workers</h1>
          <button onClick={onClose}>
            <X />
          </button>
        </div>

        <span>You can paste multiple emails separated by commas or spaces</span>

        <div className="my-2">
          <EmailInput emails={invitations} setEmails={setInvitations} />
          <div className="my-3 flex items-center justify-between">
            <h1 className="font-semibold">Members Role</h1>

            <DropdownMenu>
              <DropdownMenuTrigger className="flex items-center gap-2 px-3 py-0.5">
                <span className="capitalize">
                  {selectedRole.toLocaleLowerCase()}
                </span>
                <ChevronDown />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => setSelectedRole('ADMIN')}>
                  <span>Admin</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setSelectedRole('MEMBER')}>
                  <span>Member</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <Button
            size="sm"
            className="flex w-full items-center justify-center gap-2 rounded-lg"
            onClick={handleSendInvitations}
            disabled={loading || invitations.length === 0}
          >
            {loading && <LoaderCircle className="animate-spin" />}
            <span>Send Invitations</span>
          </Button>
        </div>

        <div className="mt-4 flex items-center gap-4 text-lg font-bold">
          <button
            className={cn(
              'transition-all duration-100 ease-in-out',
              selectedTab === 'pending-invitations' &&
                'text-primary border-b border-b-primary',
            )}
            onClick={() => setSelectedTab('pending-invitations')}
          >
            Pending Invitations
          </button>
          <button
            className={cn(
              'transition-all duration-100 ease-in-out',
              selectedTab === 'groups' &&
                'text-primary border-b border-b-primary',
            )}
            onClick={() => setSelectedTab('groups')}
          >
            Groups
          </button>
        </div>

        {selectedTab === 'pending-invitations' && (
          <div className="flex flex-col gap-2">
            {pendingInvites?.map((invitation) => (
              <PendingInvitationRow
                key={invitation.id}
                invitation={invitation}
              />
            ))}
          </div>
        )}

        {selectedTab === 'groups' && (
          <div className="flex flex-col gap-2">
            {userGroups.map(
              (userGroup) =>
                userGroup.name !== 'Everyone' && (
                  <UserGroupRow
                    key={userGroup.id}
                    isSelected={selectedUserGroups.includes(userGroup.id)}
                    onSelect={handleUserGroupSelect}
                    userGroup={userGroup}
                  />
                ),
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

function CreateUserGroupModal({ isOpen, onClose }) {
  const { data } = useOrganizationQuery('organization');
  const { data: meData } = useUserQuery('me');

  const members = data?.organization?.members || [];
  const toast = useToast();

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [groupName, setGroupName] = useState('');

  const [createUserGroup, { loading }] = useOrganizationMutation(
    'createUserGroup',
    {
      refetchQueries: ['Organization'],
      onCompleted: () => {
        toast.createToast({
          message: 'User group created successfully',
          type: 'success',
          position: 'top-right',
          showCloseButton: true,
        });

        setSelectedMembers([]);
        setGroupName('');

        onClose();
      },
      onError: () => {
        toast.createToast({
          message: 'Something went wrong',
          type: 'error',
          position: 'top-right',
          showCloseButton: true,
        });
      },
    },
  );

  function handleSelectMember(memberId) {
    setSelectedMembers((prev) =>
      prev.includes(memberId)
        ? prev.filter((id) => id !== memberId)
        : [...prev, memberId],
    );
  }

  function handleCreateUserGroup() {
    if (groupName.length === 0) {
      toast.createToast({
        message: 'Please enter a group name',
        type: 'error',
        position: 'top-right',
        showCloseButton: true,
      });
      return;
    }

    createUserGroup({
      variables: {
        input: {
          name: groupName,
          users: selectedMembers,
        },
      },
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} fluid={false}>
      <div className="no-scrollbar flex max-h-[50vh] w-full flex-col gap-2 overflow-y-auto p-8">
        <div className="flex w-full items-center justify-between">
          <h1 className="text-lg font-semibold">Invite Co-Workers</h1>
          <button onClick={onClose}>
            <X />
          </button>
        </div>

        <span>
          Create a user group for your organization, enter the name of the group
          and the members
        </span>

        <div className="my-2">
          <input
            type="text"
            placeholder="Enter group name"
            className="w-full rounded-lg border border-gray-300 p-2 focus:border-primary-200 focus:outline-none"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />

          <h1 className="mb-2 mt-4 text-lg font-semibold">Select Members</h1>

          <div className="flex max-h-[18vh] flex-col gap-2 overflow-y-auto">
            {members?.map(
              (member) =>
                member.id !== meData.me.id && (
                  <div
                    key={member.id}
                    className="flex cursor-pointer items-center justify-between px-4 py-2 hover:bg-primary-100/60"
                    onClick={() => handleSelectMember(member.id)}
                  >
                    <div className="flex items-center gap-2">
                      <Checkbox
                        checked={selectedMembers.includes(member.id)}
                        onCheckedChange={() => handleSelectMember(member.id)}
                      />
                      <div className="flex aspect-square h-8 items-center justify-center rounded-full bg-primary-100 font-semibold capitalize text-primary-900">
                        {member.fullName?.charAt(0)}
                      </div>
                      <div className="text-sm font-medium">
                        {member.fullName}
                      </div>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="text-sm font-medium capitalize">
                        {member.role.toLowerCase()}
                      </span>
                    </div>
                  </div>
                ),
            )}
          </div>

          <Button
            size="sm"
            className="mt-4 flex w-full items-center justify-center gap-2 rounded-lg"
            onClick={handleCreateUserGroup}
            disabled={loading || groupName.length === 0}
          >
            {loading && <LoaderCircle className="animate-spin" />}
            <span>Create Group</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function EditUserGroupRow({
  name,
  value,
  onChange,
  selected,
  role,
  email = '',
}) {
  return (
    <div
      className={cn(
        'flex cursor-pointer items-center justify-between px-4 py-2 hover:bg-primary-100/60 transition-all duration-100 ease-in-out',
      )}
      onClick={() => onChange(value)}
    >
      <div className="flex items-center gap-2">
        <Checkbox checked={selected} />
        <div
          className={cn(
            'flex aspect-square h-8 items-center justify-center rounded-full font-semibold capitalize bg-complementary-brown-light text-complementary-brown',
          )}
        >
          {name.charAt(0)}
        </div>
        <span>{name}</span>
        <span className="mb-0 justify-end pb-0 text-sm text-gray-500">
          {email}
        </span>
      </div>

      <div className="flex items-center gap-2">
        <span className="text-sm font-medium capitalize">
          {role?.toLowerCase()}
        </span>
      </div>
    </div>
  );
}

function EditUserGroupModal({ isOpen, onClose, groupId }) {
  const { data: organizationData } = useOrganizationQuery('organization');
  const { data: marketsData } = useFiltersQuery('marketsWithUserGroups');

  const [resources, setResources] = useState([]);
  const [users, setUsers] = useState([]);
  const [name, setName] = useState('');
  const [memberSearchTerm, setMemberSearchTerm] = useState('');
  const [existingMemberSearchTerm, setExistingMemberSearchTerm] = useState('');

  const toast = useToast();

  const selectedGroup = useMemo(() => {
    const curr = organizationData?.organization.userGroups.find(
      (group) => group.id === groupId,
    );

    setUsers(curr?.users.map((user) => ({ userId: user.id })) || []);
    return curr;
  }, [organizationData, groupId]);

  const [selectedTab, setSelectedTab] = useState('members');

  const audiences = useMemo(() => {
    const curr = returnAudiencesForGroup({
      groupId,
      marketsData,
    });

    setResources(
      curr.map((audience) => ({
        audienceId: audience.id,
        remove: false,
        marketId: audience.market,
      })),
    );

    return curr;
  }, [groupId, marketsData]);

  const newAudiences = useMemo(
    () =>
      returnAudiencesForGroup({
        groupId,
        marketsData,
        exclude: true,
      }),
    [groupId, marketsData],
  );

  const filteredExistingMembers = selectedGroup?.users.filter(
    (member) =>
      member.fullName
        ?.toLowerCase()
        .includes(existingMemberSearchTerm.toLowerCase()) ||
      member.email
        ?.toLowerCase()
        .includes(existingMemberSearchTerm.toLowerCase()),
  );

  const organizationMembers = organizationData?.organization.members.filter(
    (member) => !selectedGroup?.users.some((user) => user.id === member.id),
  );

  const filteredOrganizationMembers = organizationMembers?.filter(
    (member) =>
      member.fullName?.toLowerCase().includes(memberSearchTerm.toLowerCase()) ||
      member.email?.toLowerCase().includes(memberSearchTerm.toLowerCase()),
  );

  function handleSelectResource({ audienceId, marketId }) {
    const isSelected = resources.find(
      (resource) => resource.audienceId === audienceId,
    );
    const isRemove = isSelected?.remove;

    const filteredResources = resources.filter(
      (resource) => resource.audienceId !== audienceId,
    );

    setResources([
      ...filteredResources,
      {
        audienceId,
        marketId,
        remove: !isSelected ? false : !isRemove,
      },
    ]);
  }

  function handleSelectUser(userId) {
    const isSelected = users.find((user) => user.userId === userId);
    const isRemove = isSelected?.remove;

    const filteredUsers = users.filter((user) => user.userId !== userId);

    setUsers([
      ...filteredUsers,
      { userId, remove: !isSelected ? false : !isRemove },
    ]);
  }

  const [updateUserGroup, { loading: updateUserGroupLoading }] =
    useOrganizationMutation('updateUserGroup', {
      refetchQueries: ['Organization', 'Markets'],
      onCompleted: () => {
        toast.createToast({
          message: 'User group updated successfully',
          type: 'success',
          position: 'top-right',
          showCloseButton: true,
        });

        setUsers([]);
        setResources([]);
        setName('');

        onClose();
      },
      onError: () => {
        toast.createToast({
          message: 'Something went wrong',
          type: 'error',
          position: 'top-right',
          showCloseButton: true,
        });
      },
    });

  function handleUpdateUserGroup() {
    const filterItems = (
      items,
      referenceArray,
      itemKey,
      referenceKey = 'id',
    ) => {
      return items.filter((item) => {
        const existsInReference = referenceArray.some(
          (ref) => ref[referenceKey] === item[itemKey],
        );
        return item.remove ? existsInReference : !existsInReference;
      });
    };

    const filteredUsers = filterItems(users, selectedGroup.users, 'userId');

    const filteredResources = filterItems(resources, audiences, 'audienceId');

    updateUserGroup({
      variables: {
        input: {
          groupId,
          name: name !== '' ? name : selectedGroup?.name,
          users: filteredUsers,
          audiences: filteredResources,
        },
      },
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} fluid={false}>
      <div className="no-scrollbar flex max-h-[90vh] w-full flex-col gap-2 overflow-y-auto p-8">
        <div className="flex w-full items-center justify-between">
          <h1 className="text-lg font-semibold">Edit User Group</h1>
          <button onClick={onClose}>
            <X />
          </button>
        </div>

        <p>
          Edit the name and audiences for the user group. You can also add or
          remove members from the group.
        </p>

        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <h1 className="text-lg font-semibold">Name</h1>
            <input
              type="text"
              placeholder={selectedGroup?.name || 'Enter group name'}
              className="w-full rounded-lg border border-gray-300 p-2 focus:border-primary-200 focus:outline-none"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={updateUserGroupLoading}
            />
          </div>

          <div className="mt-4 flex items-center gap-4 text-lg font-bold">
            <button
              className={cn(
                'transition-all duration-100 ease-in-out',
                selectedTab === 'members' &&
                  'text-primary border-b border-b-primary',
              )}
              onClick={() => setSelectedTab('members')}
            >
              Members
            </button>
            <button
              className={cn(
                'transition-all duration-100 ease-in-out',
                selectedTab === 'audiences' &&
                  'text-primary border-b border-b-primary',
              )}
              onClick={() => setSelectedTab('audiences')}
            >
              Audiences
            </button>
          </div>

          {selectedTab === 'members' && (
            <div className="group mt-2 flex flex-col gap-2">
              <h1 className="text-lg font-semibold">Current Members</h1>

              <input
                type="text"
                placeholder="Search existing members..."
                className="mb-2 w-full rounded-lg border border-gray-300 p-2 focus:border-primary-200 focus:outline-none"
                value={existingMemberSearchTerm}
                onChange={(e) => setExistingMemberSearchTerm(e.target.value)}
                disabled={updateUserGroupLoading}
              />

              <div className="flex max-h-[25vh] flex-col gap-2 overflow-y-auto">
                {filteredExistingMembers?.map((member) => (
                  <EditUserGroupRow
                    key={member.id}
                    name={member.fullName}
                    value={member.id}
                    email={member.email}
                    onChange={handleSelectUser}
                    selected={
                      users.some((user) => user.userId === member.id) &&
                      !users.find((user) => user.userId === member.id)?.remove
                    }
                    role={member.role}
                    disabled={updateUserGroupLoading}
                  />
                ))}
              </div>

              <h1 className="my-2 text-lg font-semibold">Add Members</h1>

              <input
                type="text"
                placeholder="Search members to add..."
                className="mb-2 w-full rounded-lg border border-gray-300 p-2 focus:border-primary-200 focus:outline-none"
                value={memberSearchTerm}
                onChange={(e) => setMemberSearchTerm(e.target.value)}
                disabled={updateUserGroupLoading}
              />

              <div className="flex max-h-[25vh] flex-col gap-2 overflow-y-auto">
                {filteredOrganizationMembers?.map((member) => (
                  <EditUserGroupRow
                    key={member.id}
                    name={member.fullName}
                    value={member.id}
                    email={member.email}
                    onChange={handleSelectUser}
                    selected={
                      users.some((user) => user.userId === member.id) &&
                      !users.find((user) => user.userId === member.id)?.remove
                    }
                    role={member.role}
                    disabled={updateUserGroupLoading}
                  />
                ))}
              </div>
            </div>
          )}

          {selectedTab === 'audiences' && (
            <div className="mt-2 flex flex-col gap-2">
              <div className="flex max-h-[25vh] flex-col gap-2 overflow-y-auto">
                {audiences?.map((audience) => (
                  <EditUserGroupRow
                    key={audience.id}
                    name={audience.name}
                    value={{
                      audienceId: audience.id,
                      marketId: audience.market,
                    }}
                    onChange={handleSelectResource}
                    selected={
                      resources.some(
                        (resource) => resource.audienceId === audience.id,
                      ) &&
                      !resources.find(
                        (resource) => resource.audienceId === audience.id,
                      )?.remove
                    }
                    disabled={updateUserGroupLoading}
                  />
                ))}
              </div>

              <h1 className="my-2 text-lg font-semibold">Add Audiences</h1>

              <div className="flex max-h-[25vh] flex-col gap-2 overflow-y-auto">
                {newAudiences?.map((audience) => (
                  <EditUserGroupRow
                    key={audience.id}
                    name={audience.name}
                    value={{
                      audienceId: audience.id,
                      marketId: audience.market,
                    }}
                    onChange={handleSelectResource}
                    selected={
                      resources.some(
                        (resource) => resource.audienceId === audience.id,
                      ) &&
                      !resources.find(
                        (resource) => resource.audienceId === audience.id,
                      )?.remove
                    }
                    disabled={updateUserGroupLoading}
                  />
                ))}
              </div>
            </div>
          )}

          <Button
            size="sm"
            className="mt-4 flex w-full items-center justify-center gap-2 rounded-lg"
            onClick={handleUpdateUserGroup}
            disabled={updateUserGroupLoading}
          >
            {updateUserGroupLoading && (
              <LoaderCircle className="animate-spin" />
            )}
            <span>Save</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function EmailInput({ emails, setEmails }) {
  const toast = useToast();

  const [input, setInput] = useState('');

  function checkEmail(email) {
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      toast.createToast({
        message: 'Please Enter Valid Email Addresses, Invalid Email: ' + email,
        type: 'error',
        position: 'top-right',
        showCloseButton: true,
      });

      return false;
    }

    if (emails.includes(email)) {
      return setInput('');
    }

    return true;
  }

  function handleAddEmail(e) {
    if (e.key === 'Enter' && input.trim() !== '') {
      if (!checkEmail(input.trim())) return;

      setEmails([...emails, input.trim()]);
      setInput('');
    }
  }

  function handlePaste(e) {
    e.preventDefault();

    const pastedEmails = e.clipboardData.getData('text').split(/[\s,]+/);

    const validEmails = pastedEmails.filter(checkEmail);

    if (validEmails.length === 0) return;

    setEmails([...emails, ...validEmails]);
  }

  function handleRemoveEmail(emailToRemove) {
    setEmails(emails.filter((email) => email !== emailToRemove));
  }

  function handleInputChange(e) {
    setInput(e.target.value);
  }

  return (
    <div className="flex w-full max-w-lg flex-col">
      <div className="relative flex flex-wrap gap-2 rounded-xl border border-gray-300 p-2 focus-within:border focus-within:border-primary-200">
        {emails.map((email, index) => (
          <Pill key={index} dot={false}>
            {email}
            <span
              className="ml-2 cursor-pointer text-primary-900 hover:text-red-500"
              onClick={() => handleRemoveEmail(email)}
            >
              &times;
            </span>
          </Pill>
        ))}
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleAddEmail}
          onPaste={handlePaste}
          placeholder="Enter email address"
          className="flex-1 bg-transparent text-gray-800 outline-none placeholder:text-gray-400"
        />
      </div>
    </div>
  );
}

function LimitExceededModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} fluid={false}>
      <div className="flex flex-col gap-2 px-8 py-4 xl:max-w-2xl">
        <Text weight="bold" size="2xl">
          Limit Exceeded
        </Text>
        <div className="flex items-center justify-between"></div>

        <Text>
          Member limit for this organization has reached its maximum. Please
          contact{' '}
          <a
            className="font-semibold text-primary"
            href="mailto:customer-support@tfashion.ai"
          >
            support@tfashion.ai
          </a>{' '}
          to increase the limit.
        </Text>

        <div className="my-2 flex items-center gap-2 self-end">
          <Button variant="secondary" outline onClick={onClose} size="sm">
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
}

const colorMap = {
  green: {
    dot: 'bg-complementary-green',
    text: 'text-complementary-green  bg-complementary-green-light',
  },
  brown: {
    dot: 'bg-complementary-brown',
    text: 'text-complementary-brown  bg-complementary-brown-light',
  },
  blue: {
    dot: 'bg-blue-500',
    text: 'text-blue-500 border-blue-500 bg-blue-300',
  },
  red: {
    dot: 'bg-red-500',
    text: 'text-red-500 border-red-500 bg-red-300',
  },
  primary: {
    dot: 'bg-primary',
    text: 'text-primary-900 bg-primary-100',
  },
};

function Pill({ text, color = 'primary', children, dot = true }) {
  return (
    <div
      className={cn(
        'flex items-center justify-center gap-2 rounded-full px-3 py-1 font-semibold',
        colorMap[color].text,
      )}
    >
      {dot && (
        <div className={cn('h-2 w-2 rounded-full', colorMap[color].dot)} />
      )}
      {children || <span>{text}</span>}
    </div>
  );
}

function MemberTableRow({ member }) {
  const toast = useToast();

  const isInvitation = member.status === 'PENDING';
  const showResendButton =
    isInvitation &&
    dayjs(member.updatedAt).diff(dayjs().utc().subtract(15, 'minutes')) < 0;

  const [resendInvitation, { loading: resendInvitationLoading }] =
    useOrganizationMutation('resendInvitation', {
      refetchQueries: ['Organization'],
      disabled: !isInvitation,
      onCompleted: () => {
        toast.createToast({
          message: 'Invitation resent successfully',
          type: 'success',
          position: 'top-right',
          showCloseButton: true,
        });
      },
      onError: () => {
        toast.createToast({
          message: 'Failed to resend invitation',
          type: 'error',
          position: 'top-right',
          showCloseButton: true,
        });
      },
    });

  const [deleteUser, { loading: deleteUserLoading }] = useOrganizationMutation(
    'deleteUser',
    {
      refetchQueries: ['Organization'],
      disabled: !isInvitation,
      onCompleted: () => {
        toast.createToast({
          message:
            'User delete request received, this user will be deleted in short amount of time.',
          type: 'success',
          position: 'top-right',
          showCloseButton: true,
        });
      },
      onError: () => {
        toast.createToast({
          message: 'Failed to delete user',
          type: 'error',
          position: 'top-right',
          showCloseButton: true,
        });
      },
    },
  );

  const [deleteInvitation, { loading: deleteInvitationLoading }] =
    useOrganizationMutation('deleteInvitation', {
      refetchQueries: ['Organization'],
      disabled: !isInvitation,
      onCompleted: () => {
        toast.createToast({
          message: 'Invitation deleted successfully',
          type: 'success',
          position: 'top-right',
          showCloseButton: true,
        });
      },
      onError: () => {
        toast.createToast({
          message: 'Failed to delete invitation',
          type: 'error',
          position: 'top-right',
          showCloseButton: true,
        });
      },
    });

  function handleResendInvitation() {
    resendInvitation({
      variables: {
        // member.id is the invitation id for pending invitations
        invitationId: member.id,
      },
    });
  }

  function handleDeleteUser() {
    if (member.__typename !== 'User') {
      return;
    }

    deleteUser({
      variables: {
        user: member.id,
      },
    });
  }

  function handleDeleteInvitation() {
    if (member.__typename !== 'Invitation') {
      return;
    }

    deleteInvitation({
      variables: { invitationId: member.id },
    });
  }

  const loadingState =
    deleteUserLoading || resendInvitationLoading || deleteInvitationLoading;

  return (
    <TableRow key={member.id}>
      <TableCell>
        <div
          className={cn(
            'flex aspect-square h-10 items-center justify-center rounded-full bg-complementary-brown-light font-semibold uppercase text-complementary-brown',
            member.isMarkedForDeletion && 'bg-red-500 text-white',
          )}
        >
          {(member.fullName || member.email)
            ?.split(' ')
            .map((name) => name[0])
            .slice(0, 2)
            .join('')}
        </div>
      </TableCell>
      <TableCell
        className={cn(
          'text-sm font-medium',
          member.isMarkedForDeletion && 'text-red-500 line-through',
        )}
      >
        {member.fullName}
      </TableCell>
      <TableCell
        className={cn(
          'text-sm font-medium',
          member.isMarkedForDeletion && 'text-red-500 line-through',
        )}
      >
        {member.email}
      </TableCell>
      <TableCell className="capitalize">
        <div className="flex items-center">
          <Pill text={member.role.toLowerCase()} />
        </div>
      </TableCell>
      <TableCell>
        <div className="flex flex-wrap items-center gap-2">
          {member.userGroups.map((group, idx) => (
            <Pill key={idx} text={group.name} color="brown" />
          ))}
        </div>
      </TableCell>
      <TableCell>
        <div
          className={cn(
            'text-base font-semibold capitalize',
            member.status === 'PENDING'
              ? 'text-complementary-brown'
              : 'text-complementary-green',
            member.isMarkedForDeletion && 'text-red-500 line-through',
          )}
        >
          {member.isMarkedForDeletion
            ? 'Deleted'
            : member.status?.toLowerCase() || 'Accepted'}
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-center justify-center gap-2">
          {isInvitation && (
            <button
              className="rounded-lg p-3 transition-all duration-100 ease-in-out hover:bg-gray-100 active:bg-primary active:text-white"
              onClick={handleDeleteInvitation}
              disabled={loadingState}
            >
              <Trash size={18} />
            </button>
          )}
          {member.__typename === 'User' && !member.isMarkedForDeletion && (
            <button
              onClick={handleDeleteUser}
              disabled={loadingState}
              className="rounded-lg p-3 transition-all duration-100 ease-in-out hover:bg-gray-100 active:bg-red-500 active:text-white disabled:bg-red-800 disabled:text-white"
            >
              <Trash
                size={18}
                className={cn(deleteUserLoading && 'animate-pulse')}
              />
            </button>
          )}
        </div>
      </TableCell>
      <TableCell>
        <div>
          {isInvitation && showResendButton && (
            <button
              className="rounded-lg p-3 transition-all duration-100 ease-in-out hover:bg-gray-100 active:bg-primary active:text-white"
              onClick={handleResendInvitation}
              disabled={loadingState}
            >
              <RefreshCcw
                size={18}
                className={cn(resendInvitationLoading && 'animate-spin')}
              />
            </button>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}

function MemberTableRowSkeleton() {
  return (
    <TableRow className="animate-pulse text-transparent">
      <TableCell>
        <div className="flex aspect-square h-10 items-center justify-center rounded-full bg-complementary-brown-light font-semibold uppercase text-transparent">
          A
        </div>
      </TableCell>
      <TableCell className="text-sm font-medium">
        <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
      </TableCell>
      <TableCell className="text-sm font-medium">
        <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
      </TableCell>
      <TableCell className="capitalize">
        <div className="flex items-center">
          <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex flex-wrap items-center gap-2">
          <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
          <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
          <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
        </div>
      </TableCell>
      <TableCell>
        <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
      </TableCell>
      <TableCell>
        <div className="flex items-center justify-center gap-2">
          <button className="rounded-lg p-3 transition-all duration-100 ease-in-out hover:bg-gray-100 active:bg-primary active:text-white">
            <RefreshCcw size={18} />
          </button>
          <button className="rounded-lg p-3 transition-all duration-100 ease-in-out hover:bg-gray-100 active:bg-red-500 active:text-white">
            <Trash size={18} />
          </button>
        </div>
      </TableCell>
    </TableRow>
  );
}

/**
 * MembersTable
 * @param {Object} members - The members and invitations to render in the table
 * @returns {JSX.Element} - The MembersTable component
 */
function MembersTable({ members, loading }) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead></TableHead>
          <TableHead>Name</TableHead>
          <TableHead>E-Mail</TableHead>
          <TableHead>Role</TableHead>
          <TableHead>Groups</TableHead>
          <TableHead>Status</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {loading
          ? Array.from({ length: 10 }).map((_, index) => (
              <MemberTableRowSkeleton key={index} />
            ))
          : members?.map((member) => (
              <MemberTableRow key={member.id} member={member} />
            ))}
      </TableBody>
    </Table>
  );
}

function GroupTableRow({ group, onEdit }) {
  const { data: marketsData } = useFiltersQuery('marketsWithUserGroups');

  function returnUserGroupUserAvatars(users) {
    const addPlusAvatar = users.length > 3;

    if (users.length === 0) return null;

    if (users.length === 1) {
      return (
        <div
          className={cn(
            'flex h-8 aspect-square items-center justify-center rounded-full bg-complementary-brown-light font-semibold uppercase text-complementary-brown',
          )}
        >
          {users[0].fullName?.split(' ')[0][0]}
        </div>
      );
    }

    return (
      <div className="relative">
        {users.slice(0, 3).map((user, index) => (
          <div
            key={user.id}
            className={cn(
              'absolute top-0 inset-0 -translate-y-1/2 flex aspect-square h-8 items-center justify-center rounded-full bg-complementary-brown-light font-semibold uppercase text-complementary-brown',
              index === 0 && 'left-0 z-10 ',
              index === 1 && 'left-6 z-20',
              index === 2 && 'left-12 z-30',
            )}
          >
            {user?.fullName?.split(' ')[0][0]}
          </div>
        ))}
        {addPlusAvatar && (
          <div className="absolute inset-0 left-[4.5rem] z-40 flex aspect-square h-8 -translate-y-1/2 items-center justify-center rounded-full bg-complementary-brown-light font-semibold uppercase text-complementary-brown">
            + {users.length - 3}
          </div>
        )}
      </div>
    );
  }

  return (
    <TableRow key={group.id}>
      <TableCell className="flex items-center justify-center">
        <div className="flex aspect-square h-10 items-center justify-center rounded-full bg-complementary-brown-light font-semibold uppercase text-complementary-brown">
          {group.name
            ?.split(' ')
            .map((name) => name[0])
            .slice(0, 2)
            .join('')}
        </div>
      </TableCell>
      <TableCell className="text-sm font-medium">{group.name}</TableCell>
      <TableCell className="text-xs font-medium">
        {returnUserGroupUserAvatars(group.users)}
      </TableCell>
      <TableCell className="capitalize">
        <div className="flex items-center gap-2">
          {returnAudiencesForGroup({
            groupId: group.id,
            markets: marketsData,
          })
            .slice(0, 3)
            .map((audience) => (
              <Pill key={audience.id} text={audience.name} />
            ))}

          {returnAudiencesForGroup({
            groupId: group.id,
            markets: marketsData,
          }).length > 3 && (
            <Pill
              text={`+${
                returnAudiencesForGroup({
                  groupId: group.id,
                  markets: marketsData,
                }).length - 3
              }`}
              color="brown"
            />
          )}
        </div>
      </TableCell>

      <TableCell className="flex items-center">
        {group.name.toLowerCase() !== 'everyone' && (
          <button
            className="rounded-lg p-3 transition-all duration-100 ease-in-out hover:bg-gray-100 active:bg-primary active:text-white"
            onClick={() => onEdit(group.id)}
          >
            <Pencil size={18} />
          </button>
        )}
        {group.name.toLowerCase() !== 'everyone' && (
          <button className="rounded-lg p-3 transition-all duration-100 ease-in-out hover:bg-gray-100 active:bg-red-500 active:text-white">
            <Trash size={18} />
          </button>
        )}
      </TableCell>
    </TableRow>
  );
}

function GroupTableRowSkeleton() {
  return (
    <TableRow className="animate-pulse text-transparent">
      <TableCell className="flex items-center justify-center">
        <div className="flex aspect-square h-10 items-center justify-center rounded-full bg-complementary-brown-light font-semibold uppercase text-transparent">
          A
        </div>
      </TableCell>
      <TableCell className="text-sm font-medium">
        <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
      </TableCell>
      <TableCell className="text-xs font-medium">
        <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
      </TableCell>
      <TableCell className="capitalize">
        <div className="flex items-center gap-2">
          <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
          <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
          <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
          <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
        </div>
      </TableCell>

      <TableCell className="flex items-center">
        <h1 className="h-4 w-24 rounded-lg bg-slate-100"></h1>
      </TableCell>
    </TableRow>
  );
}

function GroupsTable({ groups, loading, onEdit }) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead></TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Members</TableHead>
          <TableHead>Audiences</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {loading
          ? Array.from({ length: 10 }).map((_, index) => (
              <GroupTableRowSkeleton key={index} />
            ))
          : groups?.map((group) => (
              <GroupTableRow key={group.id} group={group} onEdit={onEdit} />
            ))}
      </TableBody>
    </Table>
  );
}

export const Team = () => {
  const { data, loading } = useOrganizationQuery('organization');

  const [modal, setModal] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedTab, setSelectedTab] = useState('members');

  const exceedsLimit =
    data?.organization.members.length >= data?.organization.inviteLimit;

  const [selectedGroupIds, setSelectedGroupIds] = useState([]);

  const selectedGroups =
    data?.organization.userGroups.filter((group) =>
      selectedGroupIds.includes(group.id),
    ) || [];

  const [memberSearch, setMemberSearch] = useState('');
  const [groupSearch, setGroupSearch] = useState('');

  const renderData = [
    ...(data?.organization.members || []),
    ...(
      data?.organization.invitations.filter(
        (invitation) => invitation.status !== 'ACCEPTED',
      ) || []
    ).sort((a, b) => dayjs(b.updatedAt).diff(dayjs(a.updatedAt))),
  ].filter((member) => {
    let groupFilter = false;
    let searchFilter = false;

    if (selectedGroupIds.length === 0) groupFilter = true;
    if (memberSearch.length === 0) searchFilter = true;

    if (memberSearch) {
      searchFilter = (member.fullName || member.email)
        .toLowerCase()
        .includes(memberSearch.toLowerCase());
    }

    if (selectedGroupIds.length > 0) {
      groupFilter = member.userGroups.some((group) =>
        selectedGroupIds.includes(group.id),
      );
    }

    return groupFilter && searchFilter;
  });

  const groupsData = data?.organization.userGroups || [];

  return (
    <>
      <SendInvitationsModal
        isOpen={modal === 'invite'}
        onClose={() => setModal(null)}
      />
      <LimitExceededModal
        isOpen={modal === 'limit'}
        onClose={() => setModal(null)}
      />
      <CreateUserGroupModal
        isOpen={modal === 'create-group'}
        onClose={() => setModal(null)}
      />
      <EditUserGroupModal
        isOpen={modal === 'edit-group'}
        onClose={() => setModal(null)}
        groupId={selectedGroup}
      />
      <div className="flex w-full flex-col">
        <h5 className="mb-[21px] flex text-[36px] font-semibold   leading-[42px]">
          Organization
        </h5>
        <p className="font-regular w-[80%] text-[16px]   leading-[18px]">
          Centralize and streamline your fashion forecasting efforts by managing
          your organization&apos;s account. Adjust member roles, oversee
          collaborative projects, and ensure the right people have the right
          access to our platform.
        </p>

        <div className="mt-12 flex flex-col">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4 text-xl font-semibold">
              <button
                className={cn(
                  'transition-all duration-100 ease-in-out',
                  selectedTab === 'members' &&
                    'text-primary border-b border-b-primary',
                )}
                onClick={() => setSelectedTab('members')}
              >
                Members
              </button>
              <button
                className={cn(
                  'transition-all duration-100 ease-in-out',
                  selectedTab === 'groups' &&
                    'text-primary border-b border-b-primary',
                )}
                onClick={() => setSelectedTab('groups')}
              >
                Groups
              </button>
            </div>

            {selectedTab === 'members' && (
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-3 rounded-lg border border-primary px-3 py-1">
                  <span className="font-medium text-primary">Group</span>
                  <div className="h-4 w-px bg-primary" />
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <div className="flex items-center gap-2 font-medium text-primary">
                        {selectedGroups.length === 0 && (
                          <span className="text-primary">All</span>
                        )}
                        {selectedGroups.length === 1 && (
                          <span>{selectedGroups[0].name}</span>
                        )}
                        {selectedGroups.length > 1 && (
                          <span>{selectedGroups.length} groups</span>
                        )}
                        <ChevronDown size={14} strokeWidth={2.5} />
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      {data?.organization.userGroups?.map((group) => (
                        <DropdownMenuCheckboxItem
                          key={group?.id}
                          checked={selectedGroupIds.includes(group?.id)}
                          onCheckedChange={(checked) =>
                            setSelectedGroupIds(
                              checked
                                ? [...selectedGroupIds, group?.id]
                                : selectedGroupIds.filter(
                                    (id) => id !== group?.id,
                                  ),
                            )
                          }
                        >
                          {group?.name}
                        </DropdownMenuCheckboxItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>

                <input
                  placeholder="Search"
                  className="rounded-lg border border-primary px-3 py-1"
                  value={memberSearch}
                  onChange={(e) => setMemberSearch(e.target.value)}
                />

                <Button
                  size="xs"
                  variant="primary"
                  className="rounded-lg"
                  onClick={() => {
                    setModal(exceedsLimit ? 'limit' : 'invite');
                  }}
                >
                  Invite Co-Workers
                </Button>
              </div>
            )}

            {selectedTab === 'groups' && (
              <div className="flex items-center gap-2">
                <input
                  placeholder="Search"
                  className="rounded-lg border border-primary px-3 py-1"
                  value={groupSearch}
                  onChange={(e) => setGroupSearch(e.target.value)}
                />

                <Button
                  size="xs"
                  variant="primary"
                  className="rounded-lg"
                  onClick={() => {
                    setModal('create-group');
                  }}
                >
                  Create Group
                </Button>
              </div>
            )}
          </div>

          {selectedTab === 'members' && (
            <MembersTable members={renderData} loading={loading} />
          )}

          {selectedTab === 'groups' && (
            <GroupsTable
              groups={groupsData}
              loading={loading}
              onEdit={(groupId) => {
                setModal('edit-group');
                setSelectedGroup(groupId);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
