import React from 'react';

import { useFilters } from '@/hooks/filters';

export const ExploreClearAll = () => {
  const { clearAllFilters } = useFilters('explore');

  return (
    <div className="flex items-center border-b border-l border-[#A1A1A1] pr-[60px] text-[0.875rem] font-semibold xl:w-1/2">
      <button
        className="flex h-full flex-1 cursor-pointer items-center truncate px-[18px] text-[#C29B77] font-semibold outline-none"
        onClick={() => clearAllFilters()}
      >
        Clear All
      </button>
    </div>
  );
};
