'use client';

import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown, Minus, Plus } from 'lucide-react';

import { cn } from '@/helpers/utils.js';

const Accordion = ({ children, openItem, setOpenItem, ...props }) => {
  const [newOpenItem, setNewOpenItem] = React.useState(openItem);
  if (!setOpenItem) {
    openItem = newOpenItem;
    setOpenItem = setNewOpenItem;
  }
  const accordionRef = React.useRef(null);

  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      setOpenItem(null);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [setOpenItem]);

  return (
    <AccordionPrimitive.Root
      ref={accordionRef}
      value={openItem}
      onValueChange={setOpenItem}
      {...props}
    >
      {children}
    </AccordionPrimitive.Root>
  );
};

const AccordionItem = React.forwardRef(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn(className)} {...props} />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef(
  (
    {
      className,
      children,
      variant = 'default',
      openItem,
      setOpenItem,
      ...props
    },
    ref,
  ) => {
    const [newOpenItem, setNewOpenItem] = React.useState(openItem);
    if (!setOpenItem) {
      openItem = newOpenItem;
      setOpenItem = setNewOpenItem;
    }

    const Icon = openItem ? (
      <Minus className="h-5 w-5 shrink-0" />
    ) : (
      <Plus className="h-5 w-5 shrink-0" />
    );
    return (
      <AccordionPrimitive.Header className="flex">
        <AccordionPrimitive.Trigger
          onClick={() => setOpenItem(!openItem)}
          ref={ref}
          className={cn(
            'flex flex-1 items-center justify-between py-4 font-bold transition-all [&[data-state=open]>svg]:rotate-180',
            className,
          )}
          {...props}
        >
          {children}
          {variant === 'default' && (
            <ChevronDown className="h-5 w-5 shrink-0 transition-transform duration-200" />
          )}
          {variant === 'studioAdditionalSettings' && Icon}
        </AccordionPrimitive.Trigger>
      </AccordionPrimitive.Header>
    );
  },
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
      ref={ref}
      className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
      {...props}
    >
      <div className={cn('pb-4 pt-0', className)}>{children}</div>
    </AccordionPrimitive.Content>
  ),
);

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
