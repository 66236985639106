'use client';

import React, { forwardRef } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { css } from '@stitches/react';

import { cn } from '@/helpers/utils.js';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipArrow = TooltipPrimitive.Arrow;

const TooltipContent = React.forwardRef(
  ({ className, sideOffset, side, ...props }, ref) => (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      side={side}
      className={cn(
        'z-50 overflow-hidden rounded-md border bg-white/90 px-3 py-1.5 text-sm text-black shadow-sm animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    />
  ),
);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const Arrow = forwardRef((props, ref) => (
  <TooltipArrow asChild>
    <svg
      width="24"
      height="12"
      viewBox="0 0 24 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <rect width="24" height="2" fill="white" />
      <path
        d="M24 1C18 1 18 11 12 11C6 11 6 1 0 1"
        stroke="#a1a1a140"
        strokeWidth="1"
        strokeLinejoin="miter"
        fill="#ffffff90"
      />
    </svg>
  </TooltipArrow>
));
Arrow.displayName = 'Arrow';

const ReverseArrow = forwardRef((props, ref) => (
  <TooltipArrow asChild>
    <svg
      width="24"
      height="12"
      viewBox="0 0 24 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <defs>
        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feDropShadow
            dx="0"
            dy="2"
            stdDeviation="2"
            floodColor="rgba(0, 0, 0, 0.1)"
          />
        </filter>
      </defs>
      <path d="M12 12L0 0H24L12 12Z" fill="white" filter="url(#shadow)" />
    </svg>
  </TooltipArrow>
));
ReverseArrow.displayName = 'ReverseArrow';

export const arrowStyle = css({
  transform: 'translateY(-1.5px)',
});

export const lastArrowStyle = css({
  transform: 'translateY(-1px) translateX(-7px)',
});

const HoverTooltip = ({
  children,
  tooltipContent,
  variant,
  open,
  ...props
}) => {
  if (variant === 'info') {
    return (
      <TooltipProvider delayDuration={300}>
        <Tooltip open={open}>
          <TooltipTrigger asChild>{children}</TooltipTrigger>
          <TooltipContent
            className="bg-black text-white"
            side="right"
            sideOffset={4}
          >
            {tooltipContent}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return (
    <TooltipProvider delayDuration={300}>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent
          side={variant === 'tonePill' ? 'top' : 'bottom'}
          sideOffset={variant === 'tonePill' ? 4 : -8}
          className={
            variant === 'tonePill' ? 'border-0 bg-transparent shadow-none' : ''
          }
          {...props}
        >
          {tooltipContent}
          {variant !== 'tonePill' && <Arrow className={arrowStyle()} />}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export {
  Arrow,
  HoverTooltip,
  ReverseArrow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
};
