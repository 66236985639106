import { createContext, useContext, useEffect, useState } from 'react';

import defaultVersion from '../version.json';

export const AppContext = createContext();

export function AppContextProvider({ children }) {
  const [collectionsLayout, setCollectionsLayout] = useState('gallery');
  const [currentCollection, setCurrentCollection] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(() => {
    return localStorage.getItem('appVersion') || defaultVersion.version;
  });
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);

  const UPDATE_CHECK_INTERVAL = 60000;

  const changeCollectionsLayout = (layout) => {
    if (!['gallery', 'two-col', 'three-col'].includes(layout)) {
      throw new Error('Invalid layout');
    }
    setCollectionsLayout(layout);
  };

  const checkVersion = async () => {
    try {
      const response = await fetch('/version.json', {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
      });

      if (!response.ok) {
        throw new Error('Version file not found');
      }

      const { version } = await response.json();

      if (currentVersion && currentVersion !== version) {
        setShowUpdatePopup(true);
      }

      setCurrentVersion(version);
      localStorage.setItem('appVersion', version);
    } catch (error) {
      console.error('Version check failed:', error);
    }
  };

  const recoverState = () => {
    const url = new URL(window.location.href);
    const isUpdate = url.searchParams.get('update');

    if (isUpdate) {
      url.searchParams.delete('update');
      window.history.replaceState({}, document.title, url.toString());

      const recoverEditors = () => {
        const editors = document.querySelectorAll('[data-editor="true"]');
        if (editors.length === 0) return;

        editors.forEach((editor) => {
          const editorId = editor.getAttribute('id') || 'defaultEditor';
          const savedContent = localStorage.getItem(
            `editorContent_${editorId}`,
          );

          if (savedContent) {
            editor.textContent = savedContent;
            localStorage.removeItem(`editorContent_${editorId}`);
          }
        });
      };

      const recoverAppState = () => {
        const savedAppState = localStorage.getItem('appState');

        if (savedAppState && window.restoreAppState) {
          const appState = JSON.parse(savedAppState);
          window.restoreAppState(appState);
          localStorage.removeItem('appState');
        }
      };

      setTimeout(() => {
        recoverEditors();
        recoverAppState();
      }, 100); // Small delay to ensure DOM is ready
    }
  };

  const handleUpdate = () => {
    const currentPath = window.location.pathname + window.location.search;
    localStorage.setItem('lastRoute', currentPath);

    const saveEditorContent = () => {
      const editors = document.querySelectorAll('[data-editor="true"]');
      if (editors.length === 0) return;
      editors.forEach((editor) => {
        const editorId = editor.getAttribute('id') || 'defaultEditor';
        const content = editor.textContent || '';
        localStorage.setItem(`editorContent_${editorId}`, content);
      });
    };

    if (window.appState) {
      localStorage.setItem('appState', JSON.stringify(window.appState));
    }

    saveEditorContent();
    const url = new URL(window.location.href);
    url.searchParams.append('update', Math.random().toString(36).substring(7));
    window.location.href = url.toString();
  };

  useEffect(() => {
    if (import.meta.env.VITE_APP_ENV !== 'development') {
      checkVersion();
      const interval = setInterval(checkVersion, UPDATE_CHECK_INTERVAL);

      recoverState();

      return () => clearInterval(interval);
    }
  }, [currentVersion]);

  const contextValue = {
    collectionsLayout,
    changeCollectionsLayout,
    currentCollection,
    setCurrentCollection,
    showUpdatePopup,
    setShowUpdatePopup,
    handleUpdate,
    currentVersion,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }

  return context;
};
