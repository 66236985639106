import { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';

export function PasswordInput({ register, formFieldName, ...props }) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative flex h-[2.938rem] items-center gap-2 border border-charcoal px-4">
      <input
        className="w-full outline-0"
        type={isVisible ? 'text' : 'password'}
        autoComplete="on"
        placeholder="Password"
        {...register(formFieldName || 'password', { required: true })}
      />
      <button
        onClick={(e) => {
          e.preventDefault();
          setIsVisible(!isVisible);
        }}
      >
        {isVisible ? <Eye /> : <EyeOff />}
      </button>
    </div>
  );
}
