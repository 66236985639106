import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { DiamondPlus, Grip } from 'lucide-react';

import { LikeButton } from '@/components/LikeButton';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';
import { SaveToCollection } from '@/layouts/common/index';

import StudioImage from './StudioImage';

export const ImagePreview = ({ images, activeIndex, setActiveIndex }) => {
  const [activeImage, setActiveImage] = useState(images[activeIndex]);

  const {
    selectedTool,
    setSelectedArea,
    setReferenceImage,
    studioTaskData,
    createItem,
    setSelectedBrushPathArray,
  } = useStudioContext();

  const debouncedSetSelectedArea = useMemo(
    () => debounce(setSelectedArea, 100),
    [setSelectedArea],
  );

  return (
    <div className="flex gap-4">
      <div className="flex w-1/6 flex-col gap-4">
        {images.map((image, index) => (
          <TileImage
            key={index}
            imageUrl={image.url}
            variant="preview"
            isActive={activeImage === images[index]}
            onClick={() => {
              setActiveIndex(index);
              setActiveImage(images[index]);
            }}
          />
        ))}
      </div>
      <div className="w-5/6">
        <StudioImage
          id={activeImage?.id}
          image={activeImage}
          actionType={createItem?.canUseEditTools?.[0] || 'default'}
          onChangeSelectedArea={(box) => {
            debouncedSetSelectedArea(box);
            const image = studioTaskData?.studioTask?.images[activeIndex];

            setReferenceImage({
              entityType: image?.entityType || 'STUDIO_ITEM',
              entityId: image?.entityId || image?.studioItemId,
              url: image?.url,
            });

            if (selectedTool === 'zoomOut') {
              debouncedSetSelectedArea({
                x: 0,
                y: 0,
                width: activeImage.height * 0.2,
                height: activeImage.width * 0.2,
              });
            }
          }}
          onChangeBrush={(arr) => {
            setSelectedBrushPathArray(arr);
          }}
        />
      </div>
    </div>
  );
};

export const TileImage = ({
  imageUrl,
  itemId,
  title,
  description,
  alt,
  aspect = 'square',
  variant = 'default',
  isActive = false,
  onClick,
}) => {
  const {
    setOpenTools,
    updateCreateItemDetails,
    referenceImage,
    setReferenceImage,
    setReferencePattern,
    handleCreateNewStudioTask,
    referencePattern,
    setActiveModal,
    studioTaskData,
  } = useStudioContext();

  const [pendingAction, setPendingAction] = useState(null);

  useEffect(() => {
    if (
      (referenceImage?.entityId === itemId ||
        referencePattern?.entityId === itemId) &&
      pendingAction
    ) {
      if (
        pendingAction === 'CREATE_VARIANTS' ||
        pendingAction === 'CREATE_PATTERN_VARIANTS'
      ) {
        handleCreateNewStudioTask(pendingAction);
      }
      if (onClick) onClick();
      setPendingAction(null);
    }
  }, [
    referenceImage,
    referencePattern,
    itemId,
    pendingAction,
    handleCreateNewStudioTask,
    onClick,
  ]);

  const handleClick = useCallback(
    (type) => {
      switch (type) {
        case 'CREATE_PATTERN_VARIANTS':
          updateCreateItemDetails(type);
          break;
        case 'CREATE_VARIANTS':
          updateCreateItemDetails(type);
          break;
        case 'UPSCALE_IMAGE':
          updateCreateItemDetails(type);
          break;
        case 'tools':
          onClick();
          setOpenTools(true);
          break;
        default:
          onClick();
          setOpenTools(true);
          return;
      }
      setActiveModal(null);
      setOpenTools(true);
      setPendingAction(type);
    },
    [
      updateCreateItemDetails,
      setOpenTools,
      setActiveModal,
      setReferenceImage,
      setReferencePattern,
      itemId,
      imageUrl,
    ],
  );

  const handlePreviewClick = useCallback(
    () => handleClick('preview'),
    [handleClick],
  );
  const handleVariantsClick = useCallback(() => {
    if (
      ['GENERATE_PATTERN', 'CREATE_PATTERN_VARIANTS'].includes(
        studioTaskData?.studioTask?.generateTaskType,
      )
    ) {
      handleClick('CREATE_PATTERN_VARIANTS');
    } else {
      handleClick('CREATE_VARIANTS');
    }
  }, [handleClick, studioTaskData?.studioTask?.generateTaskType]);

  const handleToolsClick = useCallback(
    () => handleClick('tools'),
    [handleClick],
  );

  if (variant === 'preview') {
    return (
      <div
        className="relative cursor-pointer overflow-hidden rounded"
        onClick={handlePreviewClick}
      >
        <img src={imageUrl} alt={alt} className="h-auto w-full object-cover" />
        {isActive && <div className="absolute inset-0 bg-night/60"></div>}
      </div>
    );
  }
  return (
    <div
      className="group relative w-full cursor-pointer overflow-hidden rounded"
      style={{ '--container-width': '100%', aspectRatio: aspect }}
    >
      <img
        src={imageUrl}
        alt={alt}
        className="h-full w-full object-cover transition-all duration-300 group-hover:scale-110"
      />
      <div
        className="absolute inset-0 bg-black/30 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
        onClick={handlePreviewClick}
      />
      <div className="absolute right-[5%] top-[5%] flex space-y-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
        <div className="flex flex-col gap-2">
          <LikeButton item={itemId} isLiked={false} variant="icon" />
          <SaveToCollection id={itemId} type="studioItem" variant="icon" />
        </div>
      </div>
      <div className="absolute inset-x-[5%] bottom-[5%] flex justify-between opacity-0 transition-opacity duration-300 group-hover:opacity-100">
        <div className="flex w-full flex-col gap-2 md:flex-row">
          <button
            onClick={handleVariantsClick}
            className="flex w-full items-center justify-center gap-2 rounded border border-secondary/10 bg-white p-[2%] text-center text-[0.725rem] font-bold leading-[1rem] text-black transition-colors duration-300 hover:border-primary hover:bg-white hover:text-primary"
          >
            <DiamondPlus size={14} />
            Variants
          </button>

          <button
            onClick={handleToolsClick}
            className="flex w-full items-center justify-center gap-2 rounded border border-secondary/10 bg-white p-[2%] text-center text-[0.725rem] font-bold leading-[1rem] text-black transition-colors duration-300 hover:border-primary hover:bg-white hover:text-primary"
          >
            <Grip size={14} />
            Tools
          </button>
        </div>
      </div>
      {(title || description) && (
        <div className="absolute bottom-0 left-0 w-full px-[3%] py-[2%]">
          {title && (
            <h3
              className="font-bold leading-5 text-white"
              style={{ fontSize: 'calc(var(--container-width) * 0.04)' }}
            >
              {title}
            </h3>
          )}
          {description && (
            <p
              className="mt-1 text-white"
              style={{ fontSize: 'calc(var(--container-width) * 0.03)' }}
            >
              {description}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export const ImageView = ({
  imageUrl,
  hoverImageUrl,
  title,
  description,
  alt,
  aspect = 'square',
}) => {
  return (
    <div className="group relative w-full cursor-pointer overflow-hidden rounded">
      <img
        src={imageUrl}
        alt={alt}
        className={cn(
          `aspect-${aspect} h-full w-full object-cover transition-all duration-700`,
        )}
      />
      <div className="absolute left-0 top-0 h-full w-full opacity-0 transition-opacity duration-700 group-hover:opacity-100">
        <img
          src={hoverImageUrl}
          alt={alt}
          className={cn(`aspect-${aspect} h-full w-full object-cover`)}
        />
        <div className="absolute bottom-0 left-0 h-full w-full bg-gradient-to-t from-black/50 to-transparent"></div>
      </div>
      {(title || description) && (
        <div className="absolute bottom-0 left-0 w-full translate-y-full px-4 py-2 transition-transform duration-500 delay-200 group-hover:translate-y-0">
          {title && (
            <h3 className="text-base font-bold leading-5 text-white">
              {title}
            </h3>
          )}
          {description && (
            <p className="mt-1 text-sm text-white">{description}</p>
          )}
        </div>
      )}
      {title && (
        <div className="absolute bottom-2 left-4 text-white transition-opacity duration-500 delay-300 group-hover:opacity-0">
          <h3 className="text-base font-bold">{title}</h3>
        </div>
      )}
    </div>
  );
};
