import { gql } from '@apollo/client';

export const ORGANIZATION = gql`
  query Organization {
    organization {
      name
      logo
      address
      id
      userGroups {
        id
        name
        users {
          id
          fullName
          email
          role
        }
      }
      members {
        id
        fullName
        email
        role
        isMarkedForDeletion
        userGroups {
          id
          name
        }
        currentStudioPlan {
          label
          slug
        }
      }
      invitations {
        id
        email
        role
        status
        inviter {
          fullName
          role
          email
          createdAt
          updatedAt
        }
        userGroups {
          name
        }
        createdAt
        updatedAt
      }
      roles {
        name
        label
        permissions {
          editOrganization
          editMarket
          editRoles
          giveRoles
          removeRoles
          inviteMembers
          removeMembers
          createMarket
        }
      }
      inviteLimit
      featureFlags
      marketPlan {
        description
        label
        color
        limitations {
          explore
          trendItem
          trendColor
          invitation
        }
        renewal
        planRenewsAt
        studioPlan
      }
      marketLicence {
        marketPlan {
          description
          label
          color
          limitations {
            explore
            trendItem
            trendColor
            invitation
          }
          renewal
          planRenewsAt
          studioPlan
        }
        licenceType
      }
      studioLicences {
        members {
          fullName
          email
          role
        }
        studioPlan {
          label
          name
          description
          color
          credit
          featureFlags {
            isStudioEnabled
            isStudioExpertModeEnabled
            isPrivateImageEnabled
          }
          price
        }
        licenceType
        quantity
      }
      licences {
        members {
          fullName
          email
          role
        }
        marketPlan {
          description
          label
          color
          limitations {
            explore
            trendItem
            trendColor
            invitation
          }
          renewal
          planRenewsAt
          studioPlan
        }
        studioPlan {
          label
          name
          description
          color
          credit
          featureFlags {
            isStudioEnabled
            isStudioExpertModeEnabled
            isPrivateImageEnabled
          }
          price
        }
        licenceType
        quantity
      }
    }
  }
`;

export const ORGANIZATIONS = gql`
  query Organizations {
    organizations {
      id
      name
      logo
      address
      markets {
        id
        apparels {
          label
          value
        }
        audiences {
          ageMax
          ageMin
          gender
          id
        }
        location {
          name
          type
          locationId
          countryCode
        }
        plan {
          scope {
            permissions
          }
        }
      }
    }
  }
`;

export const VERIFY_INVITATION = gql`
  query VerifyInvitation($token: String!) {
    verifyInvitation(token: $token) {
      email
      organization {
        name
      }
      status
      inviter {
        fullName
        email
      }
    }
  }
`;
export const LATEST_LICENCE = gql`
  query LatestLicence($type: String) {
    latestLicence(type: $type) {
      members {
        fullName
        email
        id
      }
      quantity
      id
      studioPlan {
        label
      }
      marketPlan {
        label
      }
    }
  }
`;

export const LICENCE = gql`
  query Licence($id: String, $checkoutUUID: String) {
    licence(id: $id, checkoutUUID: $checkoutUUID) {
      members {
        fullName
        email
        id
      }
      quantity
      id
      studioPlan {
        label
      }
      marketPlan {
        label
      }
    }
  }
`;
