import React, { useEffect, useMemo, useRef, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import {
  ChevronDown,
  ChevronRight,
  ChevronUp,
  Minus,
  Plus,
} from 'lucide-react';

import { GET_STUDIO_EXPLORE } from '@/api/studio/queries';
import { useDialogContext } from '@/components/ui/dialog';
import { Slider } from '@/components/ui/slider';
import { useStudioContext } from '@/contexts/StudioContext';

import { LazyLoadTrigger } from '../../explore/components/utils';

import { SidebarHeader } from './SidebarMenu';
import { StudioExploreTabs } from './StudioExploreTabs';
import { StudioGeneratedImage } from './StudioHistorySidebar';

const generationType = {
  sketches: 'REALIZE_SKETCH',
  patterns: 'GENERATE_PATTERN',
  designs: 'GENERATE_APPAREL',
  graphics: 'GENERATE_GRAPHIC',
};

const StudioExplore = ({ activeModal, activeTab, columnCount }) => {
  const [edges, setEdges] = useState([]);
  const [noEdges, setNoEdges] = useState(false);
  const { studioTaskData } = useStudioContext();

  const [, { data, error, loading, fetchMore, networkStatus, refetch }] =
    useLazyQuery(GET_STUDIO_EXPLORE, {
      variables: {
        cursor: null,
        generationType: generationType[activeModal],
        entityType: activeTab === 'all' ? null : activeTab,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (newData) => {
        if (newData.studioExplore.edges.length === 0) {
          setNoEdges(true);
        } else {
          setEdges(newData.studioExplore.edges);
        }
      },
    });

  useEffect(() => {
    if (activeModal) {
      refetch({
        cursor: null,
        generationType: generationType[activeModal],
        entityType: activeTab === 'all' ? null : activeTab,
      });
    }
  }, [activeTab, activeModal]);

  const { pageInfo } = data?.studioExplore || {};
  const { endCursor, hasNextPage } = pageInfo || {};

  const loadingMore = networkStatus === 3;

  useEffect(() => {
    if (studioTaskData?.studioTask?.status === 'COMPLETED') {
      refetch();
    }
  }, [studioTaskData?.studioTask?.status, refetch]);

  if (loading && !loadingMore) {
    return <StudioExploreSideBarLoading activeModal={activeModal} />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="h-full px-2 lg:px-6">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: columnCount }}>
        <Masonry gutter="0.5rem">
          {edges.length > 0 &&
            edges.map((edge, i) => (
              <StudioGeneratedImage
                key={i}
                image={edge.node.image}
                studioItemId={edge.node.id}
                entityType={edge.entityType}
                activeModal={activeModal}
                payload={edge.node}
                date={edge.node.createdAt}
                generationType={generationType[activeModal]}
                createdBy={edge.node.createdBy}
              />
            ))}
        </Masonry>
      </ResponsiveMasonry>
      {noEdges && (
        <div className="flex h-full w-full items-center justify-center">
          <h3 className="text-center text-sm text-secondary">
            No {activeModal} found
          </h3>
        </div>
      )}
      {hasNextPage &&
        !loadingMore &&
        Array(10)
          .fill()
          .map((_, index) => (
            <div key={index} className="flex h-12 w-full bg-transparent">
              <LazyLoadTrigger
                fetchMore={() => {
                  fetchMore({
                    variables: {
                      cursor: endCursor,
                    },
                  });
                }}
                cursorNext={endCursor}
                loading={loading}
              />
            </div>
          ))}
    </div>
  );
};

export const StudioExploreSideBar = ({ activeModal, onClose }) => {
  const [columnCount, setColumnCount] = useState(3);
  const [activeTab, setActiveTab] = useState('all');
  const sidebarRef = useRef(null);

  const { isDialogOpen } = useDialogContext();

  useEffect(() => {
    const handleClickOutside = (event) => {
      const asideBar = document.getElementById('aside-bar');
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !asideBar.contains(event.target) &&
        !isDialogOpen
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, isDialogOpen]);

  const handleSliderChange = (value) => {
    setColumnCount(Math.max(1, Math.min(5, 6 - value[0])));
  };

  const sliderValue = useMemo(() => [6 - columnCount], [columnCount]);

  return (
    <div
      ref={sidebarRef}
      style={{
        boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.05)',
        transition: 'right 2s ease',
      }}
      className="absolute right-[7rem] top-0 flex h-full w-[22.5rem] items-start justify-start border-x border-secondary/50"
    >
      <div className="flex h-full w-full flex-col items-start justify-between bg-white py-6">
        <div className="flex h-full w-full flex-col gap-4 overflow-hidden">
          <div className="flex flex-col px-2 lg:px-6">
            <SidebarHeader
              text={activeModal}
              variant="back"
              icon={ChevronRight}
              onClick={onClose}
            />

            <StudioExploreTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div className="flex w-full flex-row items-center justify-between px-2 lg:px-6">
            <h3 className="text-xs font-bold text-secondary">
              {dayjs().format('MMM YYYY')}
            </h3>
            <div className="flex w-1/2 items-center justify-center gap-x-2">
              <div className="flex cursor-pointer items-center justify-center">
                <Minus
                  size={18}
                  strokeWidth={1.5}
                  onClick={() => handleSliderChange([sliderValue[0] - 1])}
                />
              </div>
              <Slider
                value={sliderValue}
                min={1}
                max={5}
                step={1}
                onValueChange={handleSliderChange}
              />
              <div className="flex cursor-pointer items-center justify-center">
                <Plus
                  size={18}
                  strokeWidth={1.5}
                  onClick={() => handleSliderChange([sliderValue[0] + 1])}
                />
              </div>
            </div>
          </div>
          <div className="flex h-full flex-col overflow-auto">
            <StudioExplore
              activeModal={activeModal}
              activeTab={activeTab}
              columnCount={columnCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const StudioExploreSideBarLoading = ({ activeModal }) => {
  return (
    <div className="grid grid-cols-3 gap-2 px-2 lg:px-6">
      {Array.from({ length: 16 }).map((_, i) => (
        <div
          key={i}
          className={`${activeModal === 'patterns' ? 'aspect-square' : 'aspect-[3/4]'} animate-pulse rounded-md bg-gray-200`}
        />
      ))}
    </div>
  );
};

export const StudioHelpSideBar = ({ activeModal, onClose }) => {
  const sidebarRef = useRef(null);
  const { isDialogOpen } = useDialogContext();
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const asideBar = document.getElementById('aside-bar');
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !asideBar.contains(event.target) &&
        !isDialogOpen
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, isDialogOpen]);

  const faqSections = [
    {
      title: 'How can I easily create effective prompts?',
      questions: [
        {
          q: 'AI Prompt Assistant',
          a: 'As you start typing your prompt, the AI Prompt Assistant will automatically suggest ideas and variations based on your input. These suggestions are designed to inspire and enhance your creative process, saving you time and effort.',
        },
        {
          q: 'Prompt Templates',
          a: 'Use the Prompt Templates section in the tool to access a variety of ready-made prompts. You can either apply them directly or customize them according to your needs. This feature simplifies the process by giving you a solid starting point.',
        },
        {
          q: 'Image to Prompt',
          a: 'Take advantage of the Image to Prompt feature located below the prompt input area. Upload an image, and the tool will generate a detailed prompt based on it. You can then edit and personalize this prompt to fit your creative vision.',
        },
      ],
      list: [],
    },
    {
      title: 'What is the purpose of a reference image?',
      questions: [
        {
          q: '',
          a: "A reference image serves as a visual aid to complement the design you describe in your prompt. Here's how it works:",
          list: [
            'The AI uses the reference image as a source of inspiration to guide the generation process.',
            'It incorporates the overall theme and specific details of the design, such as clothing features, from the image to align with your creative vision.',
            'The result is more accurate and visually coherent when you combine your prompt description with elements drawn from the reference image.',
          ],
        },
      ],
    },
    {
      title:
        "Do I need to define a scene in the prompt if I've already selected Scene Selection?",
      questions: [
        {
          q: '',
          a: 'No, selecting a Scene Selection is sufficient. There’s no need to include additional scene descriptions in your prompt, as the tool will automatically apply the chosen scene to the generation process.',
        },
      ],
    },
    {
      title:
        'Do I need to adjust the additional settings every time? How can I work effectively with them?',
      questions: [
        {
          q: '',
          a: "Each tool's additional settings are pre-configured to provide the most functional and optimized output. While you have the option to customize these settings, the default configuration is designed to deliver the best results for that specific feature.",
        },
      ],
    },
    {
      title:
        'How can I ensure a seamless continuation of the pattern I create?',
      questions: [
        {
          q: '',
          a: 'The Pattern Tile feature will help you achieve this. After using the Create Pattern tool, simply click on the automatically displayed Tile option to view the tiled version of your pattern, ensuring a seamless and continuous design.',
        },
      ],
    },
    {
      title:
        'How can I integrate Studio creations with other T-Fashion features?',
      questions: [
        {
          q: 'Access Saved Items from Collections',
          a: 'Studio allows seamless access to items saved in your T-Fashion collections. Any asset you’ve saved within the platform can be easily used in Studio for further creativity and refinement.',
        },
        {
          q: 'Add AI-Generated Images to Collections',
          a: 'Images created in Studio using AI tools can be directly added to your collections, helping you organize and manage your designs effortlessly.',
        },
        {
          q: 'Edit Images from Explore in Studio',
          a: 'Use the Edit button in the Explore section to bring any image directly into Studio. From there, you can refine it further with AI tools, ensuring a smooth and uninterrupted creative workflow.',
        },
      ],
    },
    {
      title: 'Where can I find items I’ve generated but didn’t save?',
      questions: [
        {
          q: '',
          a: 'T-Fashion Studio automatically stores all your assets in a library, even if you haven’t saved them manually.',
        },
        {
          q: 'History Section',
          a: 'Located on the right side of the page, this section shows a chronological list of your generated items.',
        },
        {
          q: 'Specific Categories',
          a: 'Navigate to the relevant category such as Apparel, Pattern, Sketch, or Graphic to find items based on their type.',
        },
      ],
    },
    {
      title: 'Can I use the variants feature for images I upload externally?',
      questions: [
        {
          q: '',
          a: 'No, the variants feature is only available for items generated within the platform. For each item you generate on the platform, you can apply the variants feature to generate up to 4 different variations at a time.',
        },
      ],
    },
    {
      title: 'When should I use the Create Variants feature?',
      questions: [
        {
          q: '',
          a: 'No, the variants feature is only available for items generated within the platform. For each item you generate on the platform, you can apply the variants feature to generate up to 4 different variations at a time.',
        },
      ],
      list: [
        'You can use the Create Variants feature for any item generated within the platform using Create Tools.After generating an item with any Create Tool, you can immediately apply the Create Variants feature.',
        'Note that this feature is not available for Edit Tools.',
        'Additionally, there’s no limitation on using the Create Variants feature—you can generate new variants as many times as needed.',
      ],
    },
    {
      title: 'Can I change the color of the image I’ve generated?',
      questions: [
        {
          q: '',
          a: 'Yes, the Change Tone feature allows you to do exactly that. You can select any Pantone color tone and apply it directly to the image you’ve generated on the platform, giving you full control over its color customization.',
        },
      ],
    },
    {
      title: 'What is the difference between the Upscale and Enhance tools?',
      questions: [
        {
          q: 'Upscale Tool',
          a: 'This tool focuses on increasing the resolution of your image while maintaining its quality. It’s ideal for making your images suitable for larger formats or higher-quality displays without losing detail.',
        },
        {
          q: 'Enhance Tool',
          a: 'This tool improves the overall appearance of your image by refining details, adjusting colors, and reducing noise. It’s designed to elevate the visual quality and make the image look more polished and professional.',
        },
        {
          q: '',
          a: 'In summary, Upscale is for resolution improvement, while Enhance is for overall quality refinement.',
        },
      ],
    },
    {
      title:
        'What is the "More Ideas to Explore" section, and what are the images shown here?',
      questions: [
        {
          q: '',
          a: 'The More Ideas to Explore section showcases images that share a similar vibe to the one you’ve just generated. These may include:',
          list: [
            'Images you’ve previously created.',
            'Publicly available images generated on the platform.',
            'This section is designed to inspire you and provide creative ideas for your next steps.',
          ],
        },
      ],
    },
  ];

  return (
    <div
      ref={sidebarRef}
      style={{
        boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.05)',
        transition: 'right 2s ease',
      }}
      className="absolute right-[7rem] top-0 flex h-full w-[22.5rem] items-start justify-start border-x border-secondary/50"
    >
      <div className="flex h-full w-full flex-col items-start justify-between bg-white py-6">
        <div className="flex h-full w-full flex-col gap-4 overflow-hidden">
          <div className="flex flex-col px-2 lg:px-6">
            <SidebarHeader
              text={activeModal}
              variant="back"
              icon={ChevronRight}
              onClick={onClose}
            />
          </div>

          <div className="flex-1 overflow-y-auto px-2 lg:px-6">
            <h3 className="mb-4 text-sm font-bold text-black">
              Studio Help Content (FAQ)
            </h3>

            <div className="divide-y divide-gray-200">
              {faqSections.map((section, idx) => (
                <div key={idx} className="py-3">
                  <button
                    className="flex w-full items-center justify-between text-left"
                    onClick={() =>
                      setActiveSection(activeSection === idx ? null : idx)
                    }
                  >
                    <h4
                      className={`text-sm font-semibold ${
                        activeSection === idx
                          ? 'text-primary'
                          : 'text-black/80 hover:text-primary'
                      }`}
                      style={{
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        wordBreak: 'keep-all',
                      }}
                    >
                      {section.title}
                    </h4>
                    <div className="flex items-center justify-center">
                      {activeSection === idx ? (
                        <ChevronUp className="h-4 w-4  text-primary" />
                      ) : (
                        <ChevronDown className="h-4 w-4 text-secondary" />
                      )}
                    </div>
                  </button>

                  {activeSection === idx && (
                    <div className="mt-1 space-y-4">
                      {section.questions.map((item, qIdx) => (
                        <div
                          key={qIdx}
                          className="bg-gray-10 rounded-lg pl-3 pt-1"
                        >
                          {item.q && (
                            <p className="mb-1 text-sm font-bold text-black">
                              {item.q}
                            </p>
                          )}
                          <p className="text-sm text-black/80">{item.a}</p>
                          {item.list && (
                            <ul className="mt-2 list-disc space-y-1 pl-2">
                              {item.list.map((listItem, listIdx) => (
                                <li
                                  key={listIdx}
                                  className="text-sm text-black/80"
                                >
                                  {listItem}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                      {section.list && (
                        <div className="bg-gray-10 rounded-lg p-3">
                          <ul className=" list-disc space-y-1 pl-2">
                            {section.list.map((listItem, listIdx) => (
                              <li
                                key={listIdx}
                                className="text-sm text-black/80"
                              >
                                {listItem}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
