import { useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { RxCross1 } from 'react-icons/rx';
import * as Tabs from '@radix-ui/react-tabs';
import { debounce } from 'lodash';
import {
  ChevronDownIcon,
  Columns,
  History,
  LayoutGrid,
  Search,
  Star,
} from 'lucide-react';

import { FormInput } from '@/components/FormInput';
import { Heading } from '@/components/Heading';
import { Input } from '@/components/Input/index';
import { SelectDropdown } from '@/components/SelectDropdown';
import { Sidebar } from '@/components/Sidebar';
import { Spacing } from '@/components/Spacing';
import { TabHeading } from '@/components/TabHeading';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useToast } from '@/hooks/useToast';
import { SocialMediaImages } from '@/pages/general';
import { FashionWeekItems } from '@/pages/general/explore/FashionItems';

const tabs = [
  // {
  //   label: 'All',
  //   value: 'all',
  //   Component: SocialMediaImages
  // },
  {
    label: 'Fashion Weeks',
    value: 'fashion-weeks',
    Component: FashionWeekItems,
  },
  {
    label: 'Social Media',
    value: 'social-media',
    Component: SocialMediaImages,
  },
  // {
  //   label: 'AI Designs',
  //   value: 'ai-designs',
  //   Component: SocialMediaImages
  // }
];

const unwantedFilterKeys = ['audience', 'search'];

function returnPillLabel(key, filter) {
  if (key === 'market') {
    if (filter?.value && Array.isArray(filter.value)) {
      const firstValue = filter.value[0];
      return (
        firstValue?.label?.toLowerCase() ||
        firstValue?.location?.toLowerCase() ||
        filter?.location?.toLowerCase()
      );
    }
    return `${filter?.value?.label?.toLowerCase() || filter?.label?.toLowerCase() || filter?.location?.toLowerCase()}`;
  }

  if (key === 'personPosition') {
    return `Person Position: ${filter?.value?.label?.toLowerCase()}`;
  }

  if (key === 'personCount') {
    return `Person Count: ${filter?.value?.label?.toLowerCase()}`;
  }

  if (key === 'gender' || key === 'platform') {
    return (
      (key === 'gender' ? 'Gender: ' : 'Platform: ') +
      (filter?.value?.label || filter?.value?.name).toLowerCase()
    );
  }

  if (key === 'category') {
    return filter?.type
      ? filter?.type + ': ' + filter?.label
      : filter?.value?.label || filter?.label;
  }

  if (key === 'color') {
    return filter?.value?.label;
  }

  if (key === 'subcolor') {
    return filter?.value?.label;
  }

  if (key === 'designs') {
    return filter?.label?.toLowerCase();
  }

  return (
    filter?.value?.label ||
    filter?.value?.name ||
    filter?.value[0]?.label
  )?.toLowerCase();
}

export function GridViewSwitcher() {
  const { gridView, setGridView, setIsGridViewTransitioning } =
    useExploreContext();

  const handleGridViewChange = (newView) => {
    if (newView !== gridView) {
      setIsGridViewTransitioning(true);
      setGridView(newView);

      // Add a timeout to hide the transition overlay after the layout shift is complete
      setTimeout(() => {
        setIsGridViewTransitioning(false);
      }, 2000); // 2000ms should be enough for the transition to complete
    }
  };

  return (
    <div className="mr-4 flex shrink-0 items-center gap-1">
      <button
        className={`rounded-md p-1.5 ${
          gridView === '6-columns' ? 'bg-gray-200' : 'hover:bg-gray-100'
        }`}
        onClick={() => handleGridViewChange('6-columns')}
        aria-label="Six column view"
      >
        <LayoutGrid size={18} strokeWidth={1.5} />
      </button>
      <button
        className={`rounded-md p-1.5 ${
          gridView === '3-columns' ? 'bg-gray-200' : 'hover:bg-gray-100'
        }`}
        onClick={() => handleGridViewChange('3-columns')}
        aria-label="Three column view"
      >
        <Columns size={18} strokeWidth={1.5} />
      </button>
    </div>
  );
}

export function ExploreSort({ variant = 'default' }) {
  const { isEnabled: exploreAdvanced } = useFeatureFlag(
    FEATURE_FLAGS.EXPLORE_ADVANCED,
  );

  const { socialMediaSort, handleSocialMediaSortOnChange, activeTab } =
    useExploreContext();

  // Only the sorting functionality should be hidden for fashion-weeks, not the grid switcher
  const showSortDropdown = exploreAdvanced && activeTab === 'social-media';

  if (variant === 'mobile') {
    return (
      <div className="flex w-max items-center justify-end gap-2 pr-2">
        <GridViewSwitcher />
        {showSortDropdown && (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <div className="flex min-w-[4rem] items-center justify-end gap-0.5 text-[0.7rem] font-medium xs:min-w-[5rem] xs:text-xs">
                  {socialMediaSort?.sort?.value?.label ||
                    socialMediaSort?.sort?.placeholder}
                  {!socialMediaSort?.sort?.value?.label && (
                    <ChevronDownIcon size={14} strokeWidth={2.5} />
                  )}
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {socialMediaSort?.sort?.options.map((option, i) => (
                  <DropdownMenuItem
                    onClick={() =>
                      handleSocialMediaSortOnChange(option, 'sort')
                    }
                    key={i}
                  >
                    {option.label}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            {socialMediaSort?.sort?.value?.label && (
              <button
                className="flex items-center justify-center pt-0.5"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSocialMediaSortOnChange(null, 'sort');
                }}
              >
                <RxCross1 size={10} strokeWidth={1.5} />
              </button>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <GridViewSwitcher />
      {showSortDropdown &&
        Object.entries(socialMediaSort).map(([sortKey, sort], i) => (
          <FormInput className="relative right-0 w-[8rem] min-w-[8rem]" key={i}>
            <SelectDropdown
              variant="sort"
              placeholder={sort?.placeholder}
              isSearchable={false}
              options={sort?.options}
              multiselect={false}
              isClearable={true}
              value={sort?.value}
              key={sort?.key}
              className="font-normal"
              onChange={(e) => handleSocialMediaSortOnChange(e, sortKey)}
            />
          </FormInput>
        ))}
    </div>
  );
}

// SaveFilterDialog component
function SaveFilterDialog({ open, onOpenChange, onSave }) {
  const [filterName, setFilterName] = useState('');

  const handleSave = () => {
    if (filterName.trim()) {
      onSave(filterName.trim());
      setFilterName('');
      onOpenChange(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange} modal={true}>
      <DialogContent className="fixed top-1/2 left-1/2 z-[1000] h-[266px] min-h-[266px] w-[376px] min-w-[376px] -translate-x-1/2 -translate-y-1/2 rounded-lg border bg-white py-6 px-7">
        <DialogTitle className="font-planck text-lg font-medium text-night">
          Add Filter to Favorites
        </DialogTitle>

        <div className="bg-white">
          <label className="mb-1 block text-sm font-semibold text-gray-700">
            Name
          </label>
          <Input
            placeholder="Type your filter name.."
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSave();
              }
              if (e.key === 'Escape') {
                onOpenChange(false);
              }
            }}
            className="mt-2 w-full rounded-lg bg-gray-50 p-2 pl-4"
          />
        </div>

        <div className="mt-1 flex flex-col gap-4 pb-3">
          <div>
            <button
              onClick={handleSave}
              disabled={!filterName.trim()}
              className="w-full rounded-lg bg-primary p-2 text-center font-semibold text-white disabled:opacity-50"
            >
              Save
            </button>
          </div>
          <div>
            <button
              onClick={() => onOpenChange(false)}
              className="w-full rounded-lg bg-white p-2 text-center font-semibold text-primary hover:bg-gray-50"
            >
              Cancel
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

// DeleteFilterDialog component
function DeleteFilterDialog({ filterName, open, onOpenChange, onDelete }) {
  const handleDelete = () => {
    onDelete();
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange} modal={true}>
      <DialogContent
        className="fixed top-1/2 left-1/2 z-[1000] h-[204px] min-h-[204px] w-[382px] min-w-[382px] -translate-x-1/2 -translate-y-1/2 rounded-lg border bg-white py-6 px-7"
        variant="no-close"
      >
        <DialogTitle className="font-planck mx-auto text-2xl font-semibold text-night">
          Remove from favorites?
        </DialogTitle>

        <div className="bg-white">
          <label className="mb-1 block text-sm font-semibold text-gray-700">
            You are about to remove the filter group{' '}
            <span className="font-bold text-night">
              &quot;{filterName}&quot;
            </span>{' '}
            from your favorites.
          </label>
        </div>

        <div className="mt-1 flex gap-4 pb-3">
          <button
            onClick={() => onOpenChange(false)}
            className="w-full rounded-lg bg-gray-50 p-2 text-center text-sm font-semibold text-night hover:bg-gray-100"
          >
            Cancel
          </button>

          <button
            onClick={handleDelete}
            className="w-full rounded-lg bg-primary p-2 text-center text-sm font-semibold text-white disabled:opacity-50"
          >
            Remove
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function ExploreFilterPills() {
  const {
    includes,
    setIncludes,
    excludes,
    setExcludes,
    activeTab,
    fashionWeekFilters,
    socialMediaFilters,
    handleFashionWeekFiltersOnChange,
    handleSocialMediaFiltersOnChange,
    shouldRefetch,
    triggerRefetch,
    fashionWeekClearAll,
    socialMediaClearAll,
    isExploreMobile,
    activeSearchTab,
    setActiveSearchTab,
    canFavorite,
    setCanFavorite,
    isFavorite,
    setIsFavorite,
    handleAddFilterToFavorites,
    favoriteFilters,
    handleDeleteFavorite,
    selectedFavoriteFilter,
    setSelectedFavoriteFilter,
  } = useExploreContext();

  const toast = useToast();

  const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);
  const [showDeleteFilterModal, setShowDeleteFilterModal] = useState(false);
  const filterRef = useRef(null);

  useEffect(() => {
    if (activeTab === 'fashion-weeks') {
      socialMediaClearAll();
    } else if (activeTab === 'social-media') {
      fashionWeekClearAll();
    }
    if (shouldRefetch) {
      triggerRefetch();
    }
  }, [activeTab]);

  useEffect(() => {
    if (shouldRefetch === true) {
      // For deep copy, cause left side filter when changed triggered here;
      const newPills = JSON.parse(
        JSON.stringify(
          Object.entries(
            activeTab === 'fashion-weeks'
              ? fashionWeekFilters
              : socialMediaFilters,
          ).filter(
            ([key, pill]) => pill.value && !unwantedFilterKeys.includes(key),
          ),
        ),
      );

      setIncludes(newPills);

      const allExclude = JSON.parse(
        JSON.stringify(
          Object.entries(
            activeTab === 'fashion-weeks'
              ? fashionWeekFilters
              : socialMediaFilters,
          ).filter(
            ([key, pill]) =>
              pill.excludeValue && !unwantedFilterKeys.includes(key),
          ),
        ),
      );

      setExcludes(allExclude);
    }
  }, [shouldRefetch, activeTab, fashionWeekFilters, socialMediaFilters]);
  // Effect to update canFavorite based on filter selection
  useEffect(() => {
    // Check if there are any filters applied
    const hasFilters =
      (includes &&
        includes.filter(
          (filterElement) =>
            !['platform', 'gender', 'tier'].includes(filterElement[0]),
        ).length > 0) ||
      (excludes &&
        excludes.filter(
          (filterElement) =>
            !['platform', 'gender', 'tier'].includes(filterElement[0]),
        ).length > 0);

    // Update canFavorite state
    setCanFavorite(hasFilters);

    if (hasFilters) {
      const currentFilter =
        activeTab === 'fashion-weeks' ? fashionWeekFilters : socialMediaFilters;

      const saveToFiltersValue = Object.keys(currentFilter).reduce(
        (acc, key) => {
          if (key === 'market') {
            acc[key] = {
              value: null,
              excludeValue: null,
            };

            const marketValue = currentFilter?.market?.value?.map((item) => {
              const marketOption = currentFilter?.market?.options
                ?.flatMap((market) => market.options)
                .find(
                  (market) =>
                    market.marketId === item.marketId &&
                    item.value === market.audVal,
                );
              return marketOption;
            });
            acc[key].value = marketValue;
            acc[key].excludeValue = null;
          } else {
            acc[key] = {
              value: null,
              excludeValue: null,
            };
            if (currentFilter[key].value) {
              acc[key].value = currentFilter[key].value;
            }
            if (currentFilter[key].excludeValue) {
              acc[key].excludeValue = currentFilter[key].excludeValue;
            }
          }
          return acc;
        },
        {},
      );

      const existsFavoriteFilter = favoriteFilters?.favoriteFilters?.find(
        (filter) => filter.value === JSON.stringify(saveToFiltersValue),
      );
      if (existsFavoriteFilter) {
        setIsFavorite(true);
        setSelectedFavoriteFilter(existsFavoriteFilter);
      } else {
        setIsFavorite(false);
        setSelectedFavoriteFilter(null);
      }
    }
  }, [
    includes,
    excludes,
    setCanFavorite,
    favoriteFilters,
    activeTab,
    fashionWeekFilters,
    socialMediaFilters,
    isFavorite,
    setIsFavorite,
  ]);

  const handleFavoriteClick = () => {
    if (canFavorite) {
      setShowSaveFilterModal(true);
    }
  };

  const handleRemoveFavorite = () => {
    setShowDeleteFilterModal(true);
  };

  const handleDeleteFilter = () => {
    handleDeleteFavorite(selectedFavoriteFilter.id)
      .then(() => {
        setSelectedFavoriteFilter(null);
        setShowDeleteFilterModal(false);
        setIsFavorite(false);

        toast.createToast({
          message: 'Filter deleted successfully',
          type: 'success',
          position: 'top-right',
          showCloseButton: true,
        });
      })
      .catch((error) => {
        toast.createToast({
          message: error.message,
          type: 'error',
          position: 'top-right',
          showCloseButton: true,
        });
      });
  };
  const handleSaveFilter = (filterLabel) => {
    setIsFavorite(true);
    const payload = {
      label: filterLabel,
      section: activeTab === 'fashion-weeks' ? 'FASHION_WEEK' : 'SOCIAL_MEDIA',
    };

    handleAddFilterToFavorites(payload)
      .then((response) => {
        toast.createToast({
          message: 'Filter saved successfully',
          type: 'success',
          position: 'top-right',
          showCloseButton: true,
        });
      })
      .catch((error) => {
        toast.createToast({
          message: error.message,
          type: 'error',
          position: 'top-right',
          showCloseButton: true,
        });
      });
  };

  function handleRemovePill(key, filter) {
    const updatePills = (updatedFilters = null) => {
      setIncludes((prevPills) => {
        if (!updatedFilters) {
          return prevPills.filter(([pillKey]) => pillKey !== key);
        } else {
          return prevPills.map(([pillKey, pill]) =>
            pillKey === key
              ? [pillKey, { ...pill, value: updatedFilters }]
              : [pillKey, pill],
          );
        }
      });
      triggerRefetch();
    };

    if (activeTab === 'fashion-weeks') {
      if (key === 'category') {
        handleFashionWeekFiltersOnChange(filter, key);
        updatePills();
      } else if (key === 'designs') {
        handleFashionWeekFiltersOnChange(filter, key);
        updatePills();
      } else if (key === 'color') {
        handleFashionWeekFiltersOnChange(filter, key);
        updatePills();
      } else {
        handleFashionWeekFiltersOnChange(null, key);
        updatePills();
      }
    } else {
      if (key === 'category') {
        handleSocialMediaFiltersOnChange(filter, key);
        updatePills();
      } else if (key === 'attribute.custom') {
        handleSocialMediaFiltersOnChange(filter, key);
        updatePills();
      } else if (key === 'timeframe') {
        handleSocialMediaFiltersOnChange(null, key);
        updatePills();
      } else if (key === 'tone') {
        handleSocialMediaFiltersOnChange(null, key);
        updatePills();
      } else {
        if (key === 'market') {
          const removeMarket = {
            audVal: filter.value,
            marketId: filter.marketId,
          };
          handleSocialMediaFiltersOnChange(removeMarket, key);
        } else {
          handleSocialMediaFiltersOnChange(filter, key);
        }
        updatePills();
      }
    }
  }

  function handleRemoveExcludePill(key, filter) {
    const updateExcludes = (updatedFilters = null) => {
      setExcludes((prevExcludes) => {
        if (!updatedFilters) {
          return prevExcludes.filter(([pillKey]) => pillKey !== key);
        } else {
          return prevExcludes.map(([pillKey, pill]) =>
            pillKey === key
              ? [pillKey, { ...pill, excludeValue: updatedFilters }]
              : [pillKey, pill],
          );
        }
      });
      triggerRefetch();
    };

    if (activeTab === 'fashion-weeks') {
      if (key === 'category') {
        handleFashionWeekFiltersOnChange(filter, key, true);
        updateExcludes();
      } else if (key === 'designs') {
        handleFashionWeekFiltersOnChange(filter, key, true);
        updateExcludes();
      } else {
        handleFashionWeekFiltersOnChange(null, key, true);
        updateExcludes();
      }
    } else {
      if (key === 'category') {
        handleSocialMediaFiltersOnChange(filter, key, false, true);
        updateExcludes();
      } else if (key === 'attribute.custom' || key === 'print') {
        handleSocialMediaFiltersOnChange(filter, key, false, true);
        updateExcludes();
      } else if (key === 'color' || key === 'subcolor') {
        handleSocialMediaFiltersOnChange(filter, key, false, true);
        updateExcludes();
      } else {
        handleSocialMediaFiltersOnChange(null, key, false, true);
        updateExcludes();
      }
    }
  }

  const includePills = includes.filter(([key, filter]) => {
    if (key === 'gender' || key === 'platform') return false;

    return filter.value && Array.isArray(filter.value)
      ? !filter.value.includes(null)
      : true;
  });

  const excludePills = excludes.filter(([key, filter]) => {
    if (key === 'gender' || key === 'platform') return false;
    return filter.excludeValue && Array.isArray(filter.excludeValue)
      ? !filter.excludeValue.includes(null)
      : true;
  });

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-row items-center justify-between gap-4 rounded border border-backgroundBorder bg-background pr-2">
        <ScrollArea className="w-full whitespace-nowrap">
          <div
            className={cn(
              'flex w-full overflow-y-auto items-center gap-x-4 gap-y-2 pr-4 px-4 py-1 sm:py-3',
            )}
          >
            <div className="flex items-center gap-2">
              <div
                ref={filterRef}
                onClick={
                  isFavorite ? handleRemoveFavorite : handleFavoriteClick
                }
                className={`flex h-7 w-7 items-center justify-center rounded-md border border-backgroundBorder bg-white ${
                  canFavorite ? 'cursor-pointer' : 'cursor-not-allowed'
                }`}
              >
                <Star
                  size={16}
                  className={!canFavorite ? 'opacity-50' : ''}
                  fill={isFavorite && canFavorite ? '#C29B77' : 'none'}
                  stroke={isFavorite && canFavorite ? '#C29B77' : '#1A1A1A'}
                />
              </div>
              <span className="text-sm font-semibold capitalize text-[#565758]">
                Includes
              </span>
            </div>
            {includePills.length === 0 && (
              <div className="flex shrink-0 items-center gap-2 rounded border border-backgroundBorder bg-white p-1 px-2 text-night sm:p-2">
                <span
                  className={cn('text-night text-sm font-semibold capitalize')}
                >
                  {activeTab === 'fashion-weeks'
                    ? 'All Fashion Weeks'
                    : 'All Markets'}
                </span>
              </div>
            )}
            {includePills.map(([key, filter]) =>
              key === 'attribute.custom' ? (
                filter?.value?.map((attribute) => (
                  <div
                    className="flex shrink-0 items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night"
                    key={attribute.name}
                  >
                    <span
                      className={cn(
                        'text-night text-sm font-semibold capitalize',
                      )}
                    >
                      {attribute.name}: {attribute.value}
                    </span>
                    <button onClick={() => handleRemovePill(key, attribute)}>
                      <RxCross1 size={12} />
                    </button>
                  </div>
                ))
              ) : filter.isMulti ? (
                filter.value.map((valueItem, index) => (
                  <div
                    className="flex shrink-0 items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night"
                    key={`${key}-${index}`}
                  >
                    {(key === 'color' ||
                      key === 'subcolor' ||
                      key === 'tone') &&
                      valueItem.hex && (
                        <div
                          className="h-4 w-4 gap-4 rounded-full"
                          style={{
                            background: valueItem.hex,
                          }}
                        />
                      )}
                    <span
                      className={cn(
                        'text-night text-sm font-semibold capitalize',
                      )}
                    >
                      {returnPillLabel(key, valueItem) || valueItem.label}
                    </span>
                    {key !== 'platform' && key !== 'gender' && (
                      <button onClick={() => handleRemovePill(key, valueItem)}>
                        <RxCross1 size={12} />
                      </button>
                    )}
                  </div>
                ))
              ) : (
                <div
                  className="flex shrink-0 items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night"
                  key={key}
                >
                  {(key === 'color' || key === 'tone') &&
                    filter?.value?.hex && (
                      <div
                        className="h-4 w-4 gap-4 rounded-full"
                        style={{
                          background: filter.value.hex,
                        }}
                      />
                    )}
                  <span
                    className={cn(
                      'text-night text-sm font-semibold capitalize',
                    )}
                  >
                    {returnPillLabel(key, filter)}
                  </span>
                  {key !== 'platform' && key !== 'gender' && (
                    <button onClick={() => handleRemovePill(key, filter)}>
                      <RxCross1 size={12} />
                    </button>
                  )}
                </div>
              ),
            )}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        {!isExploreMobile && (
          <div className="shrink-0">
            <ExploreSort />
          </div>
        )}
      </div>
      {excludePills.length > 0 && (
        <ScrollArea className="w-full whitespace-nowrap">
          <div className="flex w-full items-center gap-x-4 gap-y-2 overflow-y-auto rounded border border-backgroundBorder bg-background px-4 py-3">
            <div className="flex items-center gap-2">
              <div className="h-7 w-7 opacity-0"></div>
              <span className="text-sm font-semibold capitalize text-[#565758]">
                Excludes
              </span>
            </div>
            {excludePills.map(([key, filter]) => (
              <div className="flex flex-row gap-2" key={key}>
                {filter.excludeValue.map((value) => (
                  <div
                    className="flex items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night"
                    key={value}
                  >
                    <span
                      className={cn(
                        'text-night text-sm font-semibold capitalize',
                      )}
                    >
                      {value.label}
                    </span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveExcludePill(key, value);
                      }}
                    >
                      <RxCross1 size={12} />
                    </button>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </ScrollArea>
      )}

      {socialMediaFilters.search.value && (
        <div className="sticky top-0 z-50 bg-white">
          <Tabs.Root defaultValue="tab1">
            <Tabs.List className="flex gap-2 [&>*]:w-20">
              <Tabs.Trigger
                key="tab2"
                value="tab2"
                className={cn(
                  'text-sm font-medium p-2 border border-secondary/20 bg-secondary/5 rounded capitalize',
                  activeSearchTab === 'THEMATIC' &&
                    'text-primary border-primary',
                )}
                onClick={() => setActiveSearchTab('THEMATIC')}
              >
                Thematic
              </Tabs.Trigger>
              <Tabs.Trigger
                key="tab1"
                value="tab1"
                className={cn(
                  'text-sm font-medium p-2 border border-secondary/20 bg-secondary/5 rounded capitalize ',
                  activeSearchTab === 'CONTAINS' &&
                    'text-primary border-primary',
                )}
                onClick={() => setActiveSearchTab('CONTAINS')}
              >
                Text
              </Tabs.Trigger>
            </Tabs.List>
          </Tabs.Root>
        </div>
      )}

      <SaveFilterDialog
        open={showSaveFilterModal}
        onOpenChange={setShowSaveFilterModal}
        onSave={handleSaveFilter}
      />

      <DeleteFilterDialog
        open={showDeleteFilterModal}
        onOpenChange={setShowDeleteFilterModal}
        onDelete={handleDeleteFilter}
        filterName={selectedFavoriteFilter?.label}
      />
    </div>
  );
}

export function ExploreSocialMediaSearch() {
  const {
    socialMediaFilters,
    handleSocialMediaFiltersOnChange,
    isSearchOpen,
    isExploreMobile,
    setIsSearchOpen,
    recentSocialMediaSearches,
    saveRecentSearch,
    removeRecentSearch,
  } = useExploreContext();

  const search = socialMediaFilters?.search || {};
  const [inputValue, setInputValue] = useState(search.value || '');
  const [isTyping, setIsTyping] = useState(false);
  const [showRecentSearches, setShowRecentSearches] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        setIsTyping(false);
        if (value.trim()) {
          handleSocialMediaFiltersOnChange(value, 'search');
        }
      }, 1400),
    [handleSocialMediaFiltersOnChange, setIsSearchOpen],
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value?.length > 0) {
      setShowRecentSearches(false);
    } else {
      setShowRecentSearches(true);
    }
    setInputValue(value);

    if (!isExploreMobile) {
      setIsTyping(true);
      debouncedSearch(value);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setIsTyping(false);
      if (inputValue.trim()) {
        handleSocialMediaFiltersOnChange(inputValue, 'search');
        saveRecentSearch(inputValue, 'SocialMedia');
      }
      if (isExploreMobile && isSearchOpen) {
        setIsSearchOpen(false);
      }
      setShowRecentSearches(false);
    }
  };

  const handleClearSearch = () => {
    if (isTyping) setIsTyping(false);
    setInputValue('');
    handleSocialMediaFiltersOnChange('', 'search');
    if (isExploreMobile && isSearchOpen) setIsSearchOpen(false);
  };

  const selectRecentSearch = (searchTerm) => {
    setInputValue(searchTerm);
    handleSocialMediaFiltersOnChange(searchTerm, 'search');
    setShowRecentSearches(false);
  };

  const handleRemoveRecentSearch = (e, searchTerm) => {
    e.stopPropagation();
    removeRecentSearch(searchTerm, 'SocialMedia');
  };

  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowRecentSearches(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  if (isExploreMobile && !isSearchOpen) return null;

  if (isExploreMobile && isSearchOpen) {
    return (
      <>
        <Sidebar
          className="flex h-full w-full flex-col gap-y-6 pt-2"
          variant="mobile"
          style={{
            display: 'block',
            transition: 'display 0.3s ease-in-out',
          }}
        >
          <div className="flex items-center justify-between">
            <Heading size={'xl'}>Search</Heading>
            <button onClick={() => setIsSearchOpen(false)}>
              <RxCross1 size={28} />
            </button>
          </div>
          <Spacing size={1.125} />
          <div className="relative w-full" ref={searchRef}>
            <div className="flex w-full items-center gap-2 rounded-md border-5 border-[#1A1A1A] bg-white px-4 py-2 outline-none">
              <Search size={24} strokeWidth={1.5} stroke="#a1a1a1" />
              <Input
                type="text"
                placeholder={search.placeholder}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                onFocus={() => setShowRecentSearches(true)}
                className="w-full text-[0.875rem] font-medium leading-6 outline-none"
              />
              {inputValue && (
                <RxCross1
                  size={18}
                  className="mt-0.5 cursor-pointer text-black"
                  onClick={handleClearSearch}
                />
              )}
            </div>

            {showRecentSearches && recentSocialMediaSearches.length > 0 && (
              <div className="absolute inset-x-0 top-full z-50 mt-1 rounded-md border border-gray-200 bg-gray-100 shadow-lg">
                <ul className="m-2">
                  {recentSocialMediaSearches.map((term, index) => (
                    <li
                      key={index}
                      className="flex cursor-pointer items-center justify-between rounded-md  px-4 py-2 hover:bg-white"
                      onClick={() => selectRecentSearch(term)}
                    >
                      <div className="flex items-center gap-2">
                        <History size={16} strokeWidth={1.5} stroke="black" />
                        <span className="text-sm font-normal text-night">
                          {term}
                        </span>
                      </div>
                      <RxCross1
                        size={16}
                        className="cursor-pointer text-night"
                        onClick={(e) => handleRemoveRecentSearch(e, term)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Sidebar>
      </>
    );
  }

  return (
    <div className="relative" ref={searchRef}>
      <div className="flex min-w-[20rem] items-center gap-2 rounded-md border-5 border-[#1A1A1A] bg-white px-4 py-2 outline-none">
        <Search size={24} strokeWidth={1.5} stroke="#a1a1a1" />
        <Input
          type="text"
          placeholder={search.placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          onFocus={() => setShowRecentSearches(true)}
          className="w-full text-[0.800rem] font-medium leading-6 outline-none"
        />
        {inputValue && (
          <RxCross1
            size={18}
            className="mt-0.5 cursor-pointer text-black"
            onClick={handleClearSearch}
          />
        )}
      </div>

      {showRecentSearches && recentSocialMediaSearches.length > 0 && (
        <div className="absolute inset-x-0 top-full z-50 mt-1 rounded-md border border-gray-200 bg-gray-100 shadow-lg">
          <ul className="m-2">
            {recentSocialMediaSearches.map((term, index) => (
              <li
                key={index}
                className="flex cursor-pointer items-center justify-between  rounded-md  px-4 py-2 hover:bg-white"
                onClick={() => selectRecentSearch(term)}
              >
                <div className="flex items-center gap-2">
                  <History size={16} strokeWidth={1.5} stroke="black" />
                  <span className="text-sm font-normal text-night">{term}</span>
                </div>
                <RxCross1
                  size={16}
                  className="cursor-pointer text-night"
                  onClick={(e) => handleRemoveRecentSearch(e, term)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export function ExploreFashionWeekSearch() {
  const {
    fashionWeekFilters,
    handleFashionWeekFiltersOnChange,
    loadFashionWeekSearchOptions,
    isExploreMobile,
    isSearchOpen,
    setIsSearchOpen,
    recentFashionWeekSearches,
    saveRecentSearch,
    removeRecentSearch,
  } = useExploreContext();

  const search = fashionWeekFilters?.search;
  const [isTyping, setIsTyping] = useState(false);
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const searchRef = useRef(null);

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    setIsOptionsLoading(true);
    loadFashionWeekSearchOptions(inputValue, (options) => {
      callback(options);
      setIsOptionsLoading(false);
    });
  }, 500);

  const handleInputChange = (selectedOption) => {
    setIsTyping(true);
    handleFashionWeekFiltersOnChange(selectedOption, 'search');

    if (selectedOption && selectedOption.label) {
      saveRecentSearch(selectedOption.label, 'FashionWeek');
    }

    if (isExploreMobile && isSearchOpen) setIsSearchOpen(false);
    setShowRecentSearches(false);
  };

  const selectRecentSearch = (searchTerm) => {
    handleFashionWeekFiltersOnChange(
      { label: searchTerm, value: searchTerm },
      'search',
    );
    setShowRecentSearches(false);
  };

  const handleRemoveRecentSearch = (e, searchTerm) => {
    e.stopPropagation();
    removeRecentSearch(searchTerm, 'FashionWeek');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowRecentSearches(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      debouncedLoadOptions.cancel();
      setIsTyping(false);
    };
  }, [debouncedLoadOptions]);

  if (isExploreMobile && !isSearchOpen) return null;

  if (isExploreMobile && isSearchOpen) {
    return (
      <>
        <Sidebar
          className="flex h-full w-full flex-col gap-y-6 pt-2"
          variant="mobile"
          style={{
            display: 'block',
            transition: 'display 0.3s ease-in-out',
          }}
        >
          <div className="mt-2 flex items-center justify-between">
            <Heading size={'xl'}>Search</Heading>
            <button onClick={() => setIsSearchOpen(false)}>
              <RxCross1 size={28} />
            </button>
          </div>
          <Spacing size={1.125} />
          <div className="relative" ref={searchRef}>
            <FormInput>
              <SelectDropdown
                placeholder={search?.placeholder}
                isSearchable={search?.isSearchable}
                options={search?.options}
                multiselect={false}
                isClearable={search?.isClearable}
                value={search?.value}
                key={'search'}
                onChange={handleInputChange}
                isAsync={true}
                isCreatable={true}
                formatCreateLabel={(inputValue) => {
                  setShowRecentSearches(false);
                  return `${inputValue}`;
                }}
                cacheOptions={false}
                loadOptions={!isTyping ? debouncedLoadOptions : undefined}
                defaultOptions
                classNames={{
                  control: 'w-full',
                  valueContainer: 'min-w-[12rem]',
                }}
                allowCreateWhileLoading={true}
                createOptionPosition={'first'}
                variant="search"
                noOptionsMessage={() => ''}
                loading={isOptionsLoading}
                onFocus={() => setShowRecentSearches(true)}
                icon={<Search size={20} strokeWidth={1.5} stroke="#a1a1a1" />}
                components={{
                  Option: ({
                    selectProps,
                    innerProps,
                    innerRef,
                    data,
                    ...props
                  }) => {
                    return (
                      <div
                        ref={innerRef}
                        {...innerProps}
                        className={props.getClassNames(props.type, props)}
                      >
                        <Highlighter
                          highlightClassName="bg-transparent"
                          unhighlightClassName="font-semibold"
                          searchWords={[selectProps.inputValue]}
                          autoEscape={true}
                          textToHighlight={data.label}
                        />
                      </div>
                    );
                  },
                }}
              />
            </FormInput>

            {showRecentSearches && recentFashionWeekSearches.length > 0 && (
              <div className="absolute inset-x-0 top-full z-50 mt-1 rounded-md border border-gray-200 bg-gray-100 shadow-lg">
                <ul className="m-2">
                  {recentFashionWeekSearches.map((term, index) => (
                    <li
                      key={index}
                      className="flex cursor-pointer items-center justify-between rounded-md  px-4 py-2 hover:bg-white"
                      onClick={() => selectRecentSearch(term)}
                    >
                      <div className="flex items-center gap-2">
                        <History size={16} strokeWidth={1.5} stroke="black" />
                        <span className="text-sm font-normal text-night">
                          {term}
                        </span>
                      </div>
                      <RxCross1
                        size={16}
                        className="cursor-pointer text-night"
                        onClick={(e) => handleRemoveRecentSearch(e, term)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Sidebar>
      </>
    );
  }

  return (
    <div className="relative min-w-[20rem]" ref={searchRef}>
      <FormInput>
        <SelectDropdown
          placeholder={search?.placeholder}
          isSearchable={search?.isSearchable}
          options={search?.options}
          multiselect={false}
          isClearable={search?.isClearable}
          value={search?.value}
          key={'search'}
          onChange={handleInputChange}
          isAsync={true}
          isCreatable={true}
          formatCreateLabel={(inputValue) => {
            setShowRecentSearches(false);
            return `${inputValue}`;
          }}
          cacheOptions={false}
          loadOptions={!isTyping ? debouncedLoadOptions : undefined}
          defaultOptions
          classNames={{
            control: 'w-full',
            valueContainer: 'min-w-[12rem]',
          }}
          allowCreateWhileLoading={true}
          createOptionPosition={'first'}
          variant="search"
          noOptionsMessage={() => ''}
          loading={isOptionsLoading}
          onFocus={() => setShowRecentSearches(true)}
          icon={<Search size={20} strokeWidth={1.5} stroke="#a1a1a1" />}
          components={{
            Option: ({ selectProps, innerProps, innerRef, data, ...props }) => {
              return (
                <div
                  ref={innerRef}
                  {...innerProps}
                  className={props.getClassNames(props.type, props)}
                >
                  {
                    <Highlighter
                      highlightClassName="bg-transparent"
                      unhighlightClassName="font-semibold"
                      searchWords={[selectProps.inputValue]}
                      autoEscape={true}
                      textToHighlight={data.label}
                    />
                  }
                </div>
              );
            },
          }}
        />
      </FormInput>

      {showRecentSearches && recentFashionWeekSearches.length > 0 && (
        <div className="absolute inset-x-0 top-full z-50 mt-1 rounded-md border border-gray-200 bg-gray-100 shadow-lg">
          <ul className="m-2">
            {recentFashionWeekSearches.map((term, index) => (
              <li
                key={index}
                className="flex cursor-pointer items-center justify-between  rounded-md  px-4 py-2 hover:bg-white"
                onClick={() => selectRecentSearch(term)}
              >
                <div className="flex items-center gap-2">
                  <History size={16} strokeWidth={1.5} stroke="black" />
                  <span className="text-sm font-normal text-night">{term}</span>
                </div>
                <RxCross1
                  size={16}
                  className="cursor-pointer text-night"
                  onClick={(e) => handleRemoveRecentSearch(e, term)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export function ExploreTabs({ className }) {
  const {
    activeTab,
    setActiveTab,
    shouldRefetch,
    isExploreMobile,
    isSearchOpen,
    isFiltersOpen,
  } = useExploreContext();
  const contentRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const lastScrollTop = useRef(0);

  useEffect(() => {
    if (!isExploreMobile) return;

    const handleScroll = () => {
      if (!contentRef.current) return;
      const scrollableContent =
        contentRef.current.querySelector('.overflow-y-auto');
      if (scrollableContent) {
        const scrollTop = scrollableContent.scrollTop;
        setShowScrollTopButton(scrollTop > 500);
        if (scrollTop > lastScrollTop.current) {
          setIsVisible(scrollTop <= 50);
        } else {
          setIsVisible(true);
        }
        lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
      }
    };

    const scrollableContent =
      contentRef.current?.querySelector('.overflow-y-auto');
    if (scrollableContent) {
      scrollableContent.addEventListener('scroll', handleScroll);
      return () =>
        scrollableContent.removeEventListener('scroll', handleScroll);
    }
  }, [isExploreMobile]);

  useEffect(() => {
    if (shouldRefetch && contentRef.current) {
      const scrollableContent =
        contentRef.current.querySelector('.overflow-y-auto');
      if (scrollableContent) {
        scrollableContent.scrollTo(0, 0);
      }
    }
  }, [shouldRefetch]);

  const handleScrollToTop = () => {
    const scrollableContent =
      contentRef.current?.querySelector('.overflow-y-auto');
    if (scrollableContent) {
      scrollableContent.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div
      className={cn('h-full w-full overflow-y-auto relative', className)}
      ref={contentRef}
    >
      <Tabs.Root
        value={activeTab}
        onValueChange={(tab) => {
          setActiveTab(tab);
        }}
        className={'relative flex h-full flex-col overflow-y-auto'}
      >
        <div
          className={cn(
            'sticky -top-1 z-[400] bg-white pt-2 transition-transform duration-300 sm:pt-7',
            !isVisible && isExploreMobile && '-translate-y-full',
          )}
        >
          <div
            className={cn(
              'flex items-center justify-between',
              isExploreMobile ? 'px-4 pb-4' : 'px-[3.75rem] pb-7',
            )}
          >
            <Tabs.List
              aria-label="tabs"
              className={'flex w-full items-center justify-between'}
            >
              <div className={cn('flex gap-12', isExploreMobile && 'gap-1')}>
                {tabs.map((tab, i) => (
                  <TabHeading
                    label={tab.label}
                    value={tab.value}
                    size={isExploreMobile ? 'md' : 'xl'}
                    key={i}
                    variant={isExploreMobile ? 'mobile' : ''}
                  />
                ))}
              </div>
            </Tabs.List>
            {isExploreMobile && <ExploreSort variant="mobile" />}

            {!isExploreMobile && (
              <>
                {activeTab === 'social-media' ? (
                  <ExploreSocialMediaSearch />
                ) : (
                  <ExploreFashionWeekSearch />
                )}
              </>
            )}
          </div>

          <div
            className={cn(
              'flex w-full gap-x-6 pb-4',
              isExploreMobile ? 'px-4' : 'px-[3.75rem]',
            )}
          >
            <ExploreFilterPills />
          </div>
        </div>

        {tabs.map((tab, i) => (
          <Tabs.Content
            key={i}
            value={tab.value}
            className={cn(
              'h-full w-full',
              isExploreMobile ? 'px-4' : 'px-[3.75rem]',
            )}
          >
            <tab.Component />
          </Tabs.Content>
        ))}

        {showScrollTopButton && !isSearchOpen && !isFiltersOpen && (
          <button
            onClick={handleScrollToTop}
            className="group fixed bottom-6 right-6 z-[100] cursor-pointer rounded-full border border-primaryLight/60 bg-primaryLight/40 px-4 py-2 font-medium text-white/80 shadow-lg hover:border-primaryHover hover:bg-primary hover:text-white hover:shadow-xl"
          >
            <span className="block transition-all duration-200 ease-in-out group-hover:hidden">
              Top ↑
            </span>
            <span className="hidden group-hover:block group-hover:transition-all group-hover:duration-200 group-hover:ease-in-out">
              Scroll to Top ↑
            </span>
          </button>
        )}
      </Tabs.Root>
    </div>
  );
}
