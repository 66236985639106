import { usePostHog } from 'posthog-js/react';

import { useUserQuery } from '@/api/authentication/hook';

/**
 * Enum for feature flags.
 * @readonly
 * @enum {string}
 */
export const FEATURE_FLAGS = {
  STUDIO: 'isStudioEnabled',
  STUDIO_EXPERT_MODE: 'isStudioExpertModeEnabled',
  EXPLORE_ADVANCED: 'exploreAdvanced',
  TOTAL_ENGAGEMENT: 'isSnapshotTotalQualityEngagementEnabled',
  TRENDS: 'isTrendsPageEnabled',
  PRIVATE_IMAGE: 'isPrivateImageEnabled',
};

export const POSTHOG_FEATURE_FLAGS = {
  EXPLORE_SOCIAL_MEDIA_COLOR_TONE_FILTER:
    'explore-social-media-color-tone-filter',
  TIER_INFLUENCERS: 'explore-tiered-influencers',
};

const sourceMapping = {
  local: FEATURE_FLAGS,
  posthog: POSTHOG_FEATURE_FLAGS,
};

/**
 * A React hook to determine if a specific feature flag is enabled for the current user.
 *
 * This hook queries the current user's data and checks if the specified feature flag is enabled for them.
 * It can be useful in conditionally rendering components or activating features based on user-specific settings.
 *
 * @function
 * @param {FEATURE_FLAGS} flagName - The name of the feature flag to check. Should be a value from FEATURE_FLAGS enum.
 * @returns {Object} - An object containing two properties:
 *  - `isEnabled`: {boolean} Whether or not the feature flag is enabled for the current user.
 *  - `loading`: {boolean} Whether the user data is still being loaded.
 *
 * @example
 * const { isEnabled, loading } = useFeatureFlag(FEATURE_FLAGS.STUDIO);
 * if (loading) {
 *   // Show a loader or something similar
 * } else if (isEnabled) {
 *   // Render the component or enable the feature
 * }
 *
 * @throws Will throw an error if the flagName provided is not a valid value in FEATURE_FLAGS.
 */

function checkFeatureFlagExists(flagName, source) {
  const flags = sourceMapping[source];

  if (!flags) {
    throw new Error(`Invalid source: ${source}`);
  }

  if (!Object.values(flags).includes(flagName)) {
    throw new Error(`Invalid feature flag name: ${flagName}`);
  }

  return true;
}

export function useFeatureFlag(flagName, source = 'local') {
  const { data, loading } = useUserQuery('me');

  const posthog = usePostHog();

  checkFeatureFlagExists(flagName, source);

  if (source === 'posthog') {
    return {
      isEnabled: posthog.isFeatureEnabled(flagName),
      loading: false,
    };
  }

  return {
    isEnabled: data?.me?.organization?.featureFlags?.[flagName],
    loading,
  };
}
