import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { startCase } from 'lodash';

import { EXPLORE_FASHION_WEEK_FILTERS } from '@/api/explore';
import { GET_TYPEAHEAD_SUGGESTIONS } from '@/api/explore/queries';
import {
  CREATE_FAVORITE_FILTER,
  DELETE_FAVORITE_FILTER,
  UPDATE_FAVORITE_FILTER,
} from '@/api/filters/mutations';
import {
  GET_FAVORITE_FILTERS,
  GET_MARKETS,
  SOCIAL_MEDIA_FILTERS,
} from '@/api/filters/queries';
import { isExploreMobilePage } from '@/components/constants';
import { genders, platforms, seasons } from '@/constants';
import { apparelMapping, tiers } from '@/constants/index';
import { getAudienceLabel, getMarketLabel } from '@/contexts/AppFiltersContext';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { colorOptions } from '@/pages/general/explore/components/options';
import {
  subColorOptions,
  subColorsNumerate,
} from '@/pages/general/explore/components/subColorOptions';
import tones from '@/tones.json';
export const ExploreContext = createContext();

const unisexCategories = [3, 4, 5, 6, 15, 17, 18, 19, 20, 29, 38, 39];

const categoryByGenderMap = {
  0: [...unisexCategories, 2, 7, 8, 16], // All (Unisex + Only Male + Only Female)
  1: [...unisexCategories], // Male (Unisex + Only Male) (Current Status, Male haven't special categories)
  2: [...unisexCategories, 2, 7, 8, 16], // Female (Unisex + Only Female)
};

export function returnFashionItemFilters(filters) {
  let city = filters?.fashionWeek?.value?.value;
  if (city === 'all') city = null;

  const seasons = [];

  const season = filters?.season?.value?.value;
  const designer = filters?.designer?.value?.value;
  const itemType = filters?.itemType?.value?.value;

  const category = Array.isArray(filters?.category?.value)
    ? filters?.category?.value.map((i) => ({
        value: i?.value,
      }))
    : filters?.category?.value?.value
      ? [
          {
            value: filters?.category?.value?.value,
          },
        ]
      : undefined;

  const attributes = filters?.designs?.value?.map((i) => {
    return {
      name: i?.value.name,
      value: i?.value.value,
    };
  });

  const print = filters?.print?.value?.map((i) => {
    return {
      name: i?.value.name,
      value: i?.value.value,
    };
  });

  if (filters?.search?.value?.value) {
    const seasonOptions = filters?.season?.options?.slice(0, 3);
    if (filters?.season?.value?.value) {
      seasons.push(filters?.season?.value?.value);
    }
    seasons.push(
      ...seasonOptions
        .map((i) => i.value)
        .filter((value) => !seasons.includes(value)),
    );
  }

  const subcolorIds = filters?.subcolor?.value
    ?.map((item) => {
      const subcolorEntry = Object.values(subColorsNumerate).find(
        (color) => color.name === item.value,
      );
      return subcolorEntry?.id?.toString();
    })
    .filter(Boolean);

  const mergedAttributesAndPrint = [
    ...(attributes ? [...attributes] : []),
    ...(print ? [...print] : []),
  ];

  return {
    city,
    seasons,
    season,
    designer,
    itemType,
    apparels: category,
    attributes:
      mergedAttributesAndPrint?.length > 0
        ? mergedAttributesAndPrint
        : undefined,
    subcolors: subcolorIds,
  };
}

export function returnFashionItemFilterOutFields(filters) {
  const apparels = filters?.category?.excludeValue?.map((i) => ({
    value: i?.value,
  }));
  const attributes = filters?.designs?.excludeValue?.map((i) => ({
    name: i?.value.name,
    value: i?.value.value,
  }));
  const print = filters?.print?.excludeValue?.map((i) => ({
    name: i?.value.name,
    value: i?.value.value,
  }));
  const designers = filters?.designer?.excludeValue?.value
    ? [filters?.designer?.excludeValue?.value]
    : null;

  const subcolorIds = filters?.subcolor?.excludeValue
    ?.map((item) => {
      const subcolorEntry = Object.values(subColorsNumerate).find(
        (color) => color.name === item.value,
      );
      return subcolorEntry?.id?.toString();
    })
    .filter(Boolean);

  const mergedAttributesAndPrint = [
    ...(attributes ? [...attributes] : []),
    ...(print ? [...print] : []),
  ];

  const returnObj = {};

  if (apparels) {
    returnObj.apparels = apparels;
  }

  if (mergedAttributesAndPrint && mergedAttributesAndPrint?.length > 0) {
    returnObj.attributes = mergedAttributesAndPrint;
  }

  if (designers) {
    returnObj.designers = designers;
  }

  if (subcolorIds) {
    returnObj.subcolors = subcolorIds;
  }
  return returnObj;
}

export const ExploreProvider = ({ children }) => {
  const { section } = useParams();
  const [activeTab, setActiveTab] = useState(() => {
    const validSections = ['fashion-weeks', 'social-media'];
    return validSections.includes(section) ? section : 'fashion-weeks';
  });

  const handleSetActiveTab = (newTab) => {
    const validTabs = ['fashion-weeks', 'social-media'];
    if (validTabs.includes(newTab)) {
      setActiveTab(newTab);
    } else {
      console.warn(
        `Invalid tab value: ${newTab}. Using default: fashion-weeks`,
      );
      setActiveTab('social-media');
    }
  };

  const [hasBannedApparel, setHasBannedApparel] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [includes, setIncludes] = useState([]);
  const [excludes, setExcludes] = useState([]);
  // explore mobile experience
  const [isExploreMobile, setIsExploreMobile] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  // const [, ] = useSearchParams()
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const [isGenderTouched, setIsGenderTouched] = useState(false);

  const [activeSearchTab, setActiveSearchTab] = useState('THEMATIC');
  const [gridView, setGridView] = useState('6-columns');
  const [isGridViewTransitioning, setIsGridViewTransitioning] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedFavoriteFilter, setSelectedFavoriteFilter] = useState(null);

  const [recentSocialMediaSearches, setRecentSocialMediaSearches] = useState(
    () => {
      const storedSearches = localStorage.getItem('recentSocialMediaSearches');
      return storedSearches ? JSON.parse(storedSearches) : [];
    },
  );

  const [recentFashionWeekSearches, setRecentFashionWeekSearches] = useState(
    () => {
      const storedSearches = localStorage.getItem('recentFashionWeekSearches');
      return storedSearches ? JSON.parse(storedSearches) : [];
    },
  );

  const saveRecentSearch = (searchTerm, type) => {
    if (!searchTerm || !searchTerm.trim()) return;

    if (type === 'SocialMedia') {
      const updatedSearches = [
        searchTerm,
        ...recentSocialMediaSearches.filter((s) => s !== searchTerm),
      ].slice(0, 5);
      setRecentSocialMediaSearches(updatedSearches);
      localStorage.setItem(
        'recentSocialMediaSearches',
        JSON.stringify(updatedSearches),
      );
    } else if (type === 'FashionWeek') {
      const updatedSearches = [
        searchTerm,
        ...recentFashionWeekSearches.filter((s) => s !== searchTerm),
      ].slice(0, 5);
      setRecentFashionWeekSearches(updatedSearches);
      localStorage.setItem(
        'recentFashionWeekSearches',
        JSON.stringify(updatedSearches),
      );
    }
  };

  const removeRecentSearch = (searchTerm, type) => {
    if (type === 'SocialMedia') {
      const updatedSearches = recentSocialMediaSearches.filter(
        (s) => s !== searchTerm,
      );
      setRecentSocialMediaSearches(updatedSearches);
      localStorage.setItem(
        'recentSocialMediaSearches',
        JSON.stringify(updatedSearches),
      );
    } else if (type === 'FashionWeek') {
      const updatedSearches = recentFashionWeekSearches.filter(
        (s) => s !== searchTerm,
      );
      setRecentFashionWeekSearches(updatedSearches);
      localStorage.setItem(
        'recentFashionWeekSearches',
        JSON.stringify(updatedSearches),
      );
    }
  };

  useEffect(() => {
    if (
      activeTab !== section &&
      location.pathname.startsWith('/explore/') &&
      !location.pathname.includes('image-preview')
    ) {
      navigate(`/explore/${activeTab}`, { replace: true });
    }
  }, [activeTab, section, navigate, location.pathname]);

  useEffect(() => {
    if (location.pathname === '/explore' && activeTab) {
      const currentParams = new URLSearchParams(location.search);

      // Remove tone and design parameters if they exist
      currentParams.delete('tone');
      currentParams.delete('design');

      // Create the new URL with remaining parameters
      const searchString = currentParams.toString();
      const newPath = `/explore/${activeTab}${
        searchString ? `?${searchString}` : ''
      }`;

      navigate(newPath, { replace: true });
    }
  }, [activeTab, location.pathname, navigate, location.search]);

  useEffect(() => {
    setIsExploreMobile(isExploreMobilePage(location.pathname));
  }, [location.pathname, window.innerWidth]);

  const [fashionWeekFilters, setFashionWeekFilters] = useState(() => {
    return {
      search: {
        placeholder: 'Search by a theme or brand inspired looks',
        value: '',
        loading: false,
        isClearable: true,
        isSearchable: true,
        options: [],
      },

      itemType: {
        label: 'Type',
        placeholder: 'Select Type',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
        isMulti: false,
      },

      season: {
        label: 'Season',
        placeholder: 'Select A Season',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },

      fashionWeek: {
        label: 'City',
        placeholder: 'Search For A City',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },

      designer: {
        label: 'Designer',
        placeholder: 'Search A Designer',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },

      category: {
        label: 'Category',
        placeholder: 'Select Category',
        options: [],
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
      },

      designs: {
        label: 'Design Detail',
        placeholder: 'Select Design Detail',
        options: [],
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
        isSearchable: true,
      },
      print: {
        label: 'Print',
        placeholder: 'Select Print',
        options: [],
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
        isSearchable: true,
      },

      subcolor: {
        label: 'Color',
        placeholder: 'Select Color',
        options: subColorOptions,
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
      },
    };
  });

  const [socialMediaFilters, setSocialMediaFilters] = useState(() => {
    // const storedFilters = localStorage.getItem('socialMediaFilters');
    // if (storedFilters) {
    //   const parsedFilters = JSON.parse(storedFilters);
    //   if (parsedFilters && typeof parsedFilters === 'object') {
    //     return parsedFilters;
    //   }
    // }
    return {
      search: {
        placeholder: 'Search by a theme or brand inspired looks',
        value: '',
        loading: false,
      },
      gender: {
        label: 'Gender',
        placeholder: 'Select Gender',
        options: genders,
        value: genders[0],
        loading: false,
        isClearable: true,
        button: true,
      },
      market: {
        label: 'Market',
        placeholder: 'Select Market',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
        isMulti: true,
      },
      influencer: {
        label: 'Influencer',
        placeholder: 'Select Influencer',
        options: [],
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
      },
      tier: {
        label: 'Tier',
        placeholder: 'Select a tier',
        options: [{ label: 'ALL', value: 'ALL' }],
        value: { label: 'ALL', value: 'ALL' },
        loading: false,
        isClearable: true,
        isMulti: false,
        button: true,
      },
      platform: {
        label: 'Platform',
        placeholder: 'Select a social media platform',
        options: platforms,
        value: null,
        loading: false,
        isClearable: true,
        isMulti: false,
        button: true,
      },
      category: {
        label: 'Category',
        placeholder: 'Select Category',
        options: [],
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
      },
      'attribute.custom': {
        label: 'Design',
        placeholder: 'Select Design',
        options: [],
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isSearchable: true,
        isExcludable: true,
      },
      print: {
        label: 'Print',
        placeholder: 'Select Print',
        options: [],
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isSearchable: true,
        isExcludable: true,
      },
      color: {
        label: 'Color',
        placeholder: 'Select Color',
        options: colorOptions,
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
      },
      subcolor: {
        label: 'Color',
        placeholder: 'Select Color',
        options: subColorOptions,
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
      },
      tone: {
        label: 'Color Tone',
        placeholder: 'Select A Tone',
        options: tones.map((tone) => ({
          hex: tone.hex,
          label: tone.label,
          value: tone.value,
        })),
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: false,
        isExcludable: true,
      },
      timeframe: {
        label: 'Timeframe',
        placeholder: 'Select A Timeframe',
        options: [
          {
            id: 5,
            label: 'Last Week',
            value: '5',
            socialWatch: true,
          },
          {
            id: 6,
            label: 'Last Two Weeks',
            value: '6',
            socialWatch: true,
          },
          {
            id: 7,
            label: 'Last Four Weeks',
            value: '7',
            socialWatch: true,
          },
          ...seasons,
        ],
        value: null,
        loading: false,
        isClearable: true,
      },
      personCount: {
        label: 'Person Count',
        placeholder: 'Default',
        options: [
          { label: 'Default', value: null },
          { label: 'All', value: 'all' },
        ],
        value: null,
        loading: false,
        isClearable: true,
      },
      personPosition: {
        label: 'Person Position',
        placeholder: 'Default',
        options: [
          { label: 'Default', value: null },
          { label: 'All', value: 'all' },
        ],
        value: null,
        loading: false,
        isClearable: true,
      },
      audience: {
        label: 'Audience',
        placeholder: 'Select an audience',
        options: [],
        value: [],
        loading: false,
        isClearable: true,
        isMulti: true,
      },
    };
  });

  const [posts, setPosts] = useState(null);

  const [socialMediaSort, setSocialMediaSort] = useState(() => {
    // TODO: causing issues with new sort options

    // const storedSort = localStorage.getItem('socialMediaSort');
    // if (storedSort) {
    //   const parsedSort = JSON.parse(storedSort);
    //   if (parsedSort && typeof parsedSort === 'object' && parsedSort.sort) {
    //     return parsedSort;
    //   }
    // }
    return {
      sort: {
        label: 'Sort',
        placeholder: 'Sort By',
        options: [
          { label: 'Freshness', value: 'takenAt.desc' },
          { label: 'Likes', value: 'likes.desc' },
          // { label: 'Default', value: null },
        ],
        value: { label: 'Freshness', value: 'takenAt.desc' },
        loading: false,
        isClearable: true,
      },
    };
  });

  const triggerRefetch = () => {
    setIsFavorite(false);
    setCanFavorite(false);
    setShouldRefetch(true);
  };

  const { isEnabled: exploreAdvanced } = useFeatureFlag(
    FEATURE_FLAGS.EXPLORE_ADVANCED,
  );

  const [socialMediaImageVersion, setSocialMediaImageVersion] =
    useState('full');

  const [
    fetchFashionWeekFilters,
    {
      data: fashionItemFiltersData,
      // loading: fashionItemFiltersLoading,
      // error: fashionItemsFilterError,
      refetch: refetchFashionWeekFilters,
    },
  ] = useLazyQuery(EXPLORE_FASHION_WEEK_FILTERS);

  // Social Media Queries
  const [
    fetchMarkets,
    {
      data: marketsData,
      // loading: marketsLoading
      // error: marketsError
    },
  ] = useLazyQuery(GET_MARKETS);

  const [
    fetchFavoriteFilters,
    {
      data: favoriteFilters,
      loading: favoriteFiltersLoading,
      refetch: refetchFavoriteFilters,
    },
  ] = useLazyQuery(GET_FAVORITE_FILTERS);

  const [createFavoriteFilter] = useMutation(CREATE_FAVORITE_FILTER);
  const [updateFavoriteFilter] = useMutation(UPDATE_FAVORITE_FILTER);
  const [deleteFavoriteFilter] = useMutation(DELETE_FAVORITE_FILTER);

  // const [fetchSocialMediaNewFilters] = useLazyQuery(GET_TREND_FILTERS);

  const [
    fetchSocialMediaNewFilters,
    { data: socialMediaNewFiltersData, refetch: refetchSocialMediaNewFilters },
  ] = useLazyQuery(SOCIAL_MEDIA_FILTERS);

  // Fashion Weeks Filter Logic
  const initializeFashionItemFilters = () => {
    if (activeTab === 'fashion-weeks') {
      const fashionWeekItemFilters = {
        fashionWeek: {},
        designer: {},
        category: {},
        season: {},
        designs: {},
        itemType: {},
        print: {},
      };

      const filterData =
        fashionItemFiltersData?.exploreFashionWeekItemFilters || [];

      for (const filter of filterData) {
        // Check if this is the itemType filter
        if (filter.name === 'itemType') {
          fashionWeekItemFilters.itemType = {
            ...fashionWeekFilters.itemType,
            label: 'Type',
            placeholder: 'Select Type',
            options: filter.values.map((item) => ({
              label: item.label,
              value: item.value,
            })),
          };
          continue;
        }

        const filterKey = filter.label;
        if (!fashionWeekItemFilters[filterKey]) continue;
        if (fashionWeekFilters[filterKey]?.value) continue;

        let options;

        if (filterKey === 'designs') {
          options = filter.values
            .map((collection) => {
              const pattern = startCase(collection.label);
              const collectionOptions = collection.values
                .filter((item) => item.value !== 'N/A')
                .map((item) => ({
                  label:
                    pattern + ': ' + startCase(item.value.replaceAll('-', ' ')),
                  name: collection.label,
                  value: {
                    name: pattern.toLowerCase(),
                    value: item.value,
                  },
                  group: pattern,
                }));

              return {
                label: pattern,
                options: collectionOptions,
              };
            })
            .filter((group) => group.options.length > 0)
            .sort((a, b) => a.label.localeCompare(b.label));
        } else if (filterKey === 'print') {
          options = filter.values
            .map((collection) => {
              const label = startCase(collection.label);

              const collectionOptions = collection.values
                .filter((item) => item.value !== 'N/A')
                .map((item) => ({
                  label:
                    label + ': ' + startCase(item.value.replaceAll('-', ' ')),
                  name: collection.label,
                  value: {
                    name: label.toLowerCase(),
                    value: item.value,
                  },
                  group: label,
                }));

              return {
                label,
                options: collectionOptions,
              };
            })
            .filter((group) => group.options.length > 0)
            .sort((a, b) => a.label.localeCompare(b.label));
        } else if (filterKey === 'category') {
          // Handle flat category structure
          options = filter.values
            .map((item) => ({
              label: startCase(item.label.replaceAll(/[-_]/g, ' ')),
              value: item.value,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        } else {
          options = filter.values.map((item) => ({
            ...item,
            label: startCase(item.label.replaceAll(/[-_]/g, ' ')),
          }));
        }

        if (filterKey !== 'season') {
          options = options.sort((a, b) => a.label.localeCompare(b.label));
        }

        fashionWeekItemFilters[filterKey] = {
          ...fashionWeekFilters[filterKey],
          label: startCase(filter.label),
          placeholder: 'Select a ' + filter.label,
          options,
        };

        if (filterKey === 'season') {
          fashionWeekItemFilters[filterKey].value = null;
        }
      }

      return fashionWeekItemFilters;
    }

    return {};
  };

  useEffect(() => {
    const updatedFilters = {};

    if (fashionItemFiltersData) {
      const { designer, category, season, designs, itemType, print } =
        initializeFashionItemFilters();

      const cities = fashionItemFiltersData.exploreFashionWeekItemFilters.find(
        (filter) => filter.label === 'city',
      ).values;
      if (cities)
        updatedFilters.fashionWeek = {
          ...fashionWeekFilters.fashionWeek,
          options: cities.map((city) => ({
            label: startCase(city.label.replaceAll('-', ' ')),
            value: city.value,
          })),
        };
      if (designer?.label) updatedFilters.designer = designer;
      if (category?.label) {
        updatedFilters.category = category;
      }
      if (season?.label) updatedFilters.season = season;
      if (designs?.label) updatedFilters.designs = designs;
      if (itemType) updatedFilters.itemType = itemType;
      if (print?.label) updatedFilters.print = print;
    }

    setFashionWeekFilters({
      ...fashionWeekFilters,
      ...updatedFilters,
    });
  }, [fashionItemFiltersData]);

  // Social Media Filters Logic
  useEffect(() => {
    if (marketsData?.markets) {
      const marketOptions = marketsData.markets
        .map((market) => ({
          label: getMarketLabel(market),
          options: market.audiences
            .map((audience) => ({
              label: getAudienceLabel(audience),
              value: `${audience.ageMax}-${audience.ageMin}-${audience.gender}`,
              marketId: market.id,
              audVal: audience.id,
              location: getMarketLabel(market),
              socialWatch: audience?.groups?.[0]?.features?.socialwatch,
            }))
            .sort((a, b) => {
              if (a.socialWatch === b.socialWatch) {
                return a.label.localeCompare(b.label);
              }
              return b.socialWatch - a.socialWatch;
            }),
        }))
        .sort((a, b) => {
          const aOptions = a.options;
          const bOptions = b.options;

          const aSocialWatch = aOptions.find(
            (option) => option.socialWatch === true,
          )?.value;
          const bSocialWatch = bOptions.find(
            (option) => option.socialWatch === true,
          )?.value;

          if (a.label === socialMediaFilters.market.value?.location) return -1;
          if (b.label === socialMediaFilters.market.value?.location) return 1;

          return bSocialWatch - aSocialWatch;
        })
        .filter((market) => market.options.length > 0);

      const apparels = marketsData.markets
        .flatMap((m) => m.apparels)
        .filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.value === item.value),
        )
        .filter((apparel) => categoryByGenderMap[0]?.includes(apparel.value)) // Inital categories for unisex
        .map((apparel) => ({
          label: startCase(apparel.label.replaceAll('-', ' ')),
          value: String(apparel.value),
        }));

      setSocialMediaFilters((prev) => {
        prev.market.options = marketOptions;
        if (prev.category.options.length === 0)
          prev.category.options = apparels;

        return prev;
      });
    }
  }, [marketsData]);

  const handleFashionWeekFiltersOnChange = (
    item,
    filter,
    isExclude = false,
  ) => {
    if (item === null) {
      if (filter === 'category') {
        setFashionWeekFilters((prevFilters) => {
          const newFilters = { ...prevFilters };
          delete newFilters.category.value;
          newFilters.category.value = null;
          return newFilters;
        });
      } else if (filter === 'designs') {
        setFashionWeekFilters((prevFilters) => {
          const newFilters = { ...prevFilters };
          newFilters.designs.value = null;
          return newFilters;
        });
      } else if (filter === 'subcolor') {
        setFashionWeekFilters((prevFilters) => {
          const newFilters = { ...prevFilters };
          newFilters.subcolor.value = null;
          newFilters.subcolor.excludeValue = null;
          return newFilters;
        });
      } else {
        setFashionWeekFilters((prevFilters) => {
          const newFilters = { ...prevFilters };
          newFilters[filter].value = null;
          newFilters[filter].excludeValue = null;
          return newFilters;
        });
      }
      return;
    }

    setFashionWeekFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      const currentFilter = newFilters[filter];

      if (currentFilter.isMulti) {
        if (isExclude) {
          // Handle exclude array
          const currentExcludes = currentFilter.excludeValue || [];
          const excludeIndex = currentExcludes.findIndex((v) =>
            filter === 'category'
              ? v.value === item.value && v.type === item.type
              : filter === 'designs'
                ? v.label === item.label
                : v.value === item.value,
          );

          if (excludeIndex > -1) {
            // Remove if exists
            currentExcludes.splice(excludeIndex, 1);
          } else {
            // Add if doesn't exist
            // Check if item exists in value array
            const valueArray = currentFilter.value || [];
            const existsInValue = valueArray.some((v) =>
              filter === 'category'
                ? v.value === item.value && v.type === item.type
                : filter === 'designs'
                  ? v.label === item.label
                  : v.value === item.value,
            );

            if (!existsInValue) {
              currentExcludes.push(item);
            }
          }

          currentFilter.excludeValue = currentExcludes.length
            ? currentExcludes
            : null;
        } else {
          // Handle value array
          const currentValues = currentFilter.value || [];

          // Skip processing if item is null for category or designs
          if (
            item === null &&
            (filter === 'category' || filter === 'designs')
          ) {
            currentFilter.value = null;
            return newFilters;
          }

          const valueIndex = currentValues.findIndex((v) =>
            filter === 'category'
              ? v.value === item.value && v.type === item.type
              : filter === 'designs'
                ? v.label === item.label
                : v.value === item.value,
          );

          if (valueIndex > -1) {
            // Remove if exists
            currentValues.splice(valueIndex, 1);
          } else {
            // Add if doesn't exist
            // Check if item exists in exclude array
            const excludeArray = currentFilter.excludeValue || [];
            const existsInExclude = excludeArray.some((v) =>
              filter === 'category'
                ? v.value === item.value && v.type === item.type
                : filter === 'designs'
                  ? v.label === item.label
                  : v.value === item.value,
            );

            if (!existsInExclude) {
              currentValues.push(item);
            }
          }

          currentFilter.value = currentValues.length ? currentValues : null;
        }
      } else {
        currentFilter.value = item;
      }

      if (activeTab === 'fashion-weeks') {
        refetchFashionWeekFilters({
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
          filters: returnFashionItemFilters(newFilters),
        });
      }

      return newFilters;
    });
  };

  const handleSocialMediaSortOnChange = (item, sortKey) => {
    if (item === null && sortKey === 'sort') {
      localStorage.removeItem('socialMediaSort');

      setSocialMediaSort((prevSort) => ({
        ...prevSort,
        [sortKey]: {
          ...prevSort[sortKey],
          value: null,
        },
      }));

      return;
    }

    setSocialMediaSort((prevSort) => ({
      ...prevSort,
      [sortKey]: {
        ...prevSort[sortKey],
        value: item?.value ? item : null,
      },
    }));

    localStorage.setItem(
      'socialMediaSort',
      JSON.stringify({
        ...socialMediaSort,
        [sortKey]: {
          ...socialMediaSort[sortKey],
          value: item,
        },
      }),
    );
  };

  const handleSocialMediaFiltersOnChange = (
    item,
    filter,
    hardSet = false,
    isExclude = false,
  ) => {
    if (hardSet) {
      socialMediaFilters[filter] = {
        ...socialMediaFilters[filter],
        [isExclude ? 'excludeValue' : 'value']: item,
      };
      localStorage.setItem(
        'socialMediaFilters',
        JSON.stringify(socialMediaFilters),
      );
      setShouldRefetch(true);
      return;
    }

    setSocialMediaFilters((prevFilters) => {
      const currentValue = isExclude
        ? prevFilters[filter]?.excludeValue
        : prevFilters[filter]?.value;

      const otherListValue = isExclude
        ? prevFilters[filter]?.value
        : prevFilters[filter]?.excludeValue;

      // Check if item exists in current list
      const isItemInCurrentList =
        prevFilters[filter]?.isMulti &&
        Array.isArray(currentValue) &&
        currentValue?.some(
          (existingItem) =>
            JSON.stringify(existingItem) === JSON.stringify(item),
        );

      // Check if item exists in other list
      const isItemInOtherList =
        prevFilters[filter]?.isMulti &&
        Array.isArray(otherListValue) &&
        otherListValue?.some(
          (existingItem) =>
            JSON.stringify(existingItem) === JSON.stringify(item),
        );

      // If item exists in current list, remove it
      if (isItemInCurrentList) {
        const newValue = currentValue.filter(
          (existingItem) =>
            JSON.stringify(existingItem) !== JSON.stringify(item),
        );

        return {
          ...prevFilters,
          [filter]: {
            ...prevFilters[filter],
            [isExclude ? 'excludeValue' : 'value']:
              newValue.length === 0 ? null : newValue,
            [isExclude ? 'value' : 'excludeValue']: otherListValue,
          },
        };
      }

      // If item exists in other list, remove it from there
      let updatedOtherList = otherListValue;
      if (isItemInOtherList) {
        updatedOtherList = otherListValue.filter(
          (existingItem) =>
            JSON.stringify(existingItem) !== JSON.stringify(item),
        );
        if (updatedOtherList.length === 0) {
          updatedOtherList = null;
        }
      }

      // Rest of the function remains same...
      const updatedFilters = {
        ...prevFilters,
        [filter]: {
          ...prevFilters[filter],
          [isExclude ? 'excludeValue' : 'value']:
            filter === 'gender' && item === null
              ? genders[0]
              : hardSet
                ? item
                : prevFilters[filter].isMulti
                  ? [
                      ...(Array.isArray(currentValue) ? currentValue : []),
                      ...(Array.isArray(item) && item[0] ? [item[0]] : [item]),
                    ]
                  : item,
          [isExclude ? 'value' : 'excludeValue']: updatedOtherList,
        },
      };

      if (filter === 'gender') {
        const selectedGender = item?.value;

        if (marketsData?.markets) {
          const currentMarkets = socialMediaFilters.market.value || [];

          const validMarkets = currentMarkets.filter((market) => {
            const marketData = marketsData.markets.find(
              (m) => m.id === market.marketId,
            );
            const audience = marketData?.audiences.find(
              (a) => a.id === market.value,
            );
            return (
              audience &&
              (selectedGender === '0' || audience.gender === selectedGender)
            );
          });

          const marketOptions = marketsData.markets
            .map((market) => ({
              label: getMarketLabel(market),
              options: market.audiences
                .filter((audience) => {
                  if (selectedGender === '0') return true;
                  return audience.gender === selectedGender;
                })
                .map((audience) => ({
                  label: getAudienceLabel(audience),
                  value: `${audience.ageMax}-${audience.ageMin}-${audience.gender}`,
                  marketId: market.id,
                  audVal: audience.id,
                  location: getMarketLabel(market),
                  socialWatch: audience?.groups?.[0]?.features?.socialwatch,
                  gender: audience.gender, // Added gender field for later use
                }))
                .sort((a, b) => {
                  if (a.socialWatch === b.socialWatch) {
                    return a.label.localeCompare(b.label);
                  }
                  return b.socialWatch - a.socialWatch;
                }),
            }))
            .filter((market) => market.options.length > 0);

          updatedFilters.market = {
            ...updatedFilters.market,
            options: marketOptions,
            value: validMarkets.length > 0 ? validMarkets : null,
          };

          // If there are validMarkets, filter audiences based on those markets
          if (validMarkets.length > 0) {
            const validAudienceValues = prevFilters.audience.value?.filter(
              (audienceId) => {
                return validMarkets.some((market) =>
                  market.groups?.some((g) => g.audienceId === audienceId),
                );
              },
            );

            updatedFilters.audience = {
              ...updatedFilters.audience,
              value: validAudienceValues ? [...validAudienceValues] : [],
            };
          } else {
            // If no valid markets but a gender is selected, collect all audiences with matching gender
            if (selectedGender !== '0') {
              // Get all audiences matching the selected gender
              const audiencesWithMatchingGender = marketsData.markets
                .flatMap((market) =>
                  market.audiences
                    .filter((audience) => audience.gender === selectedGender)
                    .flatMap(
                      (audience) =>
                        audience.groups?.map((g) => g.audienceId) || [],
                    ),
                )
                .filter(Boolean);

              updatedFilters.audience = {
                ...updatedFilters.audience,
                value: audiencesWithMatchingGender,
              };
            } else {
              updatedFilters.audience = {
                ...updatedFilters.audience,
                value: [],
              };
            }
          }

          const allOfPlatforms = { ...platforms[0] };
          delete allOfPlatforms.id;

          const uniquePlatforms = [
            ...new Set(
              validMarkets
                .map((market) => market.groups)
                .flatMap((group) => group)
                .map((group) => group.platform),
            ),
          ];

          updatedFilters.platform = {
            ...prevFilters.platform,
            options:
              currentMarkets.length === 0
                ? [
                    { label: 'ALL', value: 'ALL' },
                    { label: 'Instagram', value: 'INSTAGRAM' },
                    { label: 'TikTok', value: 'TIKTOK' },
                  ]
                : uniquePlatforms.length > 1
                  ? [
                      allOfPlatforms,
                      ...uniquePlatforms.map((platform) => ({
                        label: platform,
                        value: platform,
                      })),
                    ]
                  : uniquePlatforms.map((platform) => ({
                      label: platform,
                      value: platform,
                    })),
            value:
              uniquePlatforms.length === 0
                ? null
                : {
                    label:
                      uniquePlatforms.length === 1 ? uniquePlatforms[0] : 'ALL',
                    value:
                      uniquePlatforms.length === 1 ? uniquePlatforms[0] : 'ALL',
                  },
          };

          if (validMarkets.length > 0) {
            const filteredApparels = categoryByGenderMap[selectedGender]
              .map((apparelValue) => ({
                label: startCase(
                  apparelMapping[apparelValue] || `Category ${apparelValue}`,
                ),
                value: String(apparelValue),
              }))
              .sort((a, b) => a.label.localeCompare(b.label));

            updatedFilters.category.options = filteredApparels;
          }
        }
      }

      if (filter === 'market') {
        if (item === null) {
          updatedFilters.audience = {
            ...prevFilters.audience,
            value: [],
          };
          updatedFilters.platform = {
            ...prevFilters.platform,
            options: [
              { label: 'ALL', value: 'ALL' },
              { label: 'Instagram', value: 'INSTAGRAM' },
              { label: 'TikTok', value: 'TIKTOK' },
            ],
            value: null,
          };
          updatedFilters.market = {
            ...prevFilters.market,
            value: null,
          };
          // Reset influencer filter when market is cleared
          updatedFilters.influencer = {
            ...prevFilters.influencer,
            value: null,
            excludeValue: null,
          };
          updatedFilters.tier = {
            ...prevFilters.tier,
            value: { label: 'ALL', value: 'ALL' },
            options: [{ label: 'ALL', value: 'ALL' }],
          };
        } else {
          const market = marketsData?.markets.find(
            (market) => market.id === item?.marketId,
          );

          const audience = market?.audiences.find((audience) => {
            const key = audience.id;
            return key === item?.audVal || key === item?.value;
          });

          const currentMarkets = prevFilters.market.value || [];
          const marketFoundIndex = currentMarkets.findIndex(
            (market) =>
              market.value === item?.audVal || market.value === item?.value,
          );

          if (marketFoundIndex === -1) {
            currentMarkets.push({
              label: item?.location + ', ' + item?.label,
              value: item?.audVal,
              location: item?.location,
              groups: audience?.groups,
              marketId: market?.id,
            });
          } else {
            currentMarkets.splice(marketFoundIndex, 1);
          }

          updatedFilters.market = {
            ...prevFilters.market,
            value: currentMarkets.length > 0 ? currentMarkets : null,
          };

          // Check if any of the selected markets have influencerSearch feature
          const hasInfluencerSearch = currentMarkets.some((selectedMarket) => {
            const marketData = marketsData?.markets.find(
              (m) => m.id === selectedMarket.marketId,
            );
            return marketData?.features?.influencerSearch === true;
          });

          // If no market with influencerSearch feature is selected, reset the influencer filter
          if (!hasInfluencerSearch) {
            updatedFilters.influencer = {
              ...prevFilters.influencer,
              value: null,
              excludeValue: null,
            };
          }

          // Handle audiences
          if (audience) {
            const currentAudiences = prevFilters.audience.value || [];
            let updatedAudiences = [...currentAudiences]; // Create a new array copy

            audience.groups.forEach(({ audienceId }) => {
              const foundIndex = updatedAudiences.findIndex(
                (id) => id === audienceId,
              );
              if (foundIndex === -1) {
                // Add using a new array
                updatedAudiences = [...updatedAudiences, audienceId];
              } else {
                // Remove using filter to create a new array
                updatedAudiences = updatedAudiences.filter(
                  (id) => id !== audienceId,
                );
              }
            });

            updatedFilters.audience = {
              ...prevFilters.audience,
              value: updatedAudiences,
            };
          }

          // Handle platforms
          const allOfPlatforms = { ...platforms[0] };
          delete allOfPlatforms.id;

          const uniquePlatforms = [
            ...new Set(
              currentMarkets
                .map((market) => market.groups)
                .flatMap((group) => group)
                .map((group) => group.platform),
            ),
          ];

          if (audience) {
            allOfPlatforms.audienceId = audience.groups.map(
              (group) => group.audienceId,
            );
          }

          updatedFilters.platform = {
            ...prevFilters.platform,
            options:
              currentMarkets.length === 0
                ? [
                    { label: 'ALL', value: 'ALL' },
                    { label: 'Instagram', value: 'INSTAGRAM' },
                    { label: 'TikTok', value: 'TIKTOK' },
                  ]
                : uniquePlatforms.length > 1
                  ? [
                      allOfPlatforms,
                      ...uniquePlatforms.map((platform) => ({
                        label: platform,
                        value: platform,
                      })),
                    ]
                  : uniquePlatforms.map((platform) => ({
                      label: platform,
                      value: platform,
                    })),
            value:
              uniquePlatforms.length === 0
                ? null
                : {
                    label:
                      uniquePlatforms.length === 1 ? uniquePlatforms[0] : 'ALL',
                    value:
                      uniquePlatforms.length === 1 ? uniquePlatforms[0] : 'ALL',
                  },
          };

          const availableTiers = new Set();
          const currentPlatform =
            prevFilters.platform.value?.value?.toLowerCase();

          currentMarkets.forEach((market) => {
            const selectedMarket = marketsData?.markets.find(
              (m) => getMarketLabel(m) === market.location,
            );

            const selectedAudience = selectedMarket?.audiences.find(
              (audience) => audience.id === market.value,
            );

            const filteredGroups = selectedAudience?.groups?.filter(
              (group) =>
                !currentPlatform ||
                currentPlatform.toLowerCase() === 'all' ||
                group.platform.toLowerCase() === currentPlatform,
            );

            filteredGroups?.forEach((group) => {
              group.tiers?.forEach((tier) => {
                availableTiers.add(tier.tier);
              });
            });
          });

          const tierOptions = [{ label: 'ALL', value: 'ALL' }];
          Array.from(availableTiers)
            .sort()
            .forEach((level) => {
              const label = tiers.find(
                (_tier) => _tier.id === parseInt(level),
              )?.label;

              tierOptions.push({
                label,
                value: level,
              });
            });

          updatedFilters.tier = {
            ...prevFilters.tier,
            value: { label: 'ALL', value: 'ALL' },
            options: tierOptions,
          };

          if (currentMarkets.length === 0) {
            const filteredApparels = categoryByGenderMap['0']
              .map((apparelValue) => ({
                label: startCase(
                  apparelMapping[apparelValue] || `Category ${apparelValue}`,
                ),
                value: String(apparelValue),
              }))
              .sort((a, b) => a.label.localeCompare(b.label));

            updatedFilters.category.options = filteredApparels;
          } else {
            // Use the gender from the last selected/deselected market
            const lastMarket = marketsData?.markets.find(
              (m) => m.id === item?.marketId,
            );
            const lastAudience = lastMarket?.audiences.find(
              (a) => a.id === item?.audVal,
            );
            const genderToUse = lastAudience?.gender || '0';

            const filteredApparels = categoryByGenderMap[genderToUse]
              .map((apparelValue) => ({
                label: startCase(
                  apparelMapping[apparelValue] || `Category ${apparelValue}`,
                ),
                value: String(apparelValue),
              }))
              .sort((a, b) => a.label.localeCompare(b.label));

            updatedFilters.category.options = filteredApparels;
          }
        }
      }

      if (filter === 'platform') {
        if (item?.value === 'ALL' || item?.value === 'all') {
          const audienceIds = socialMediaFilters.market.value?.reduce(
            (acc, market) => {
              const selectedMarket = marketsData?.markets.find(
                (m) => getMarketLabel(m) === market.location,
              );

              const selectedAudience = selectedMarket?.audiences.find(
                (audience) => audience.id === market.value,
              );

              if (selectedAudience?.groups) {
                // Create a new array when adding items

                return [
                  ...acc,
                  ...selectedAudience.groups.map((group) => group.audienceId),
                ];
              }

              return acc;
            },
            [],
          );

          // Ensure we're creating a new array reference
          updatedFilters.audience = {
            ...prevFilters.audience,
            value: audienceIds ? [...audienceIds] : [],
          };

          const availableTiers = new Set();
          updatedFilters.market.value?.forEach((market) => {
            const selectedMarket = marketsData?.markets.find(
              (m) => getMarketLabel(m) === market.location,
            );

            const selectedAudience = selectedMarket?.audiences.find(
              (audience) => audience.id === market.value,
            );

            selectedAudience?.groups?.forEach((group) => {
              group.tiers?.forEach((tier) => {
                availableTiers.add(tier.tier);
              });
            });
          });

          const tierOptions = [{ label: 'ALL', value: 'ALL' }];
          Array.from(availableTiers)
            .sort()
            .forEach((level) => {
              const label = tiers.find(
                (tierItem) => tierItem.id === parseInt(level),
              )?.label;

              tierOptions.push({
                label,
                value: level,
              });
            });

          updatedFilters.tier = {
            ...prevFilters.tier,
            value: { label: 'ALL', value: 'ALL' },
            options: tierOptions,
          };
        } else {
          const audienceIds = socialMediaFilters.market.value?.reduce(
            (acc, market) => {
              const selectedMarket = marketsData?.markets.find(
                (m) => getMarketLabel(m) === market.location,
              );

              const selectedAudience = selectedMarket?.audiences.find(
                (audience) => audience.id === market.value,
              );

              const filteredGroups = selectedAudience?.groups?.filter(
                (group) =>
                  group.platform.toLowerCase() === item?.value?.toLowerCase(),
              );

              if (filteredGroups?.length) {
                // Create a new array when adding items
                return [
                  ...acc,
                  ...filteredGroups.map((group) => group.audienceId),
                ];
              }

              return acc;
            },
            [],
          );

          // Ensure we're creating a new array reference
          updatedFilters.audience = {
            ...prevFilters.audience,
            value: audienceIds ? [...audienceIds] : [],
          };

          const availableTiers = new Set();
          updatedFilters.market.value?.forEach((market) => {
            const selectedMarket = marketsData?.markets.find(
              (m) => getMarketLabel(m) === market.location,
            );

            const selectedAudience = selectedMarket?.audiences.find(
              (audience) => audience.id === market.value,
            );

            const filteredGroups = selectedAudience?.groups?.filter(
              (group) =>
                group.platform.toLowerCase() === item?.value?.toLowerCase(),
            );

            filteredGroups?.forEach((group) => {
              group.tiers?.forEach((tier) => {
                availableTiers.add(tier.tier);
              });
            });
          });

          const tierOptions = [{ label: 'ALL', value: 'ALL' }];
          Array.from(availableTiers)
            .sort()
            .forEach((level) => {
              const label = tiers.find(
                (_tier) => _tier.id === parseInt(level),
              )?.label;

              tierOptions.push({
                label,
                value: level,
              });
            });

          updatedFilters.tier = {
            ...prevFilters.tier,
            value: { label: 'ALL', value: 'ALL' },
            options: tierOptions,
          };
        }

        if (item === null) {
          updatedFilters.market.value = null;
          updatedFilters.tier = {
            ...prevFilters.tier,
            value: { label: 'ALL', value: 'ALL' },
            options: [{ label: 'ALL', value: 'ALL' }],
          };
        }
      }

      if (filter === 'tier') {
        if (item === null || item.value === 'ALL') {
          const audienceIds = socialMediaFilters.market.value?.reduce(
            (acc, market) => {
              const selectedMarket = marketsData?.markets.find(
                (m) => getMarketLabel(m) === market.location,
              );

              const selectedAudience = selectedMarket?.audiences.find(
                (audience) => audience.id === market.value,
              );

              const currentPlatform =
                prevFilters.platform.value?.value?.toLowerCase();

              const filteredGroups = selectedAudience?.groups?.filter(
                (group) =>
                  !currentPlatform ||
                  currentPlatform.toLowerCase() === 'all' ||
                  group.platform.toLowerCase() === currentPlatform,
              );

              if (filteredGroups?.length) {
                acc.push(...filteredGroups.map((group) => group.audienceId));
              }

              return acc;
            },
            [],
          );

          updatedFilters.audience.value = audienceIds || [];
        } else {
          // When specific tier is selected, filter audience IDs by tier
          const currentPlatform =
            prevFilters.platform.value?.value?.toLowerCase();

          const audienceIds = socialMediaFilters.market.value?.reduce(
            (acc, market) => {
              const selectedMarket = marketsData?.markets.find(
                (m) => getMarketLabel(m) === market.location,
              );

              const selectedAudience = selectedMarket?.audiences.find(
                (audience) => audience.id === market.value,
              );

              const filteredGroups = selectedAudience?.groups?.filter(
                (group) =>
                  (!currentPlatform ||
                    currentPlatform.toLowerCase() === 'all' ||
                    group.platform.toLowerCase() === currentPlatform) &&
                  group.tiers?.some((tier) => tier.tier === item.value),
              );

              const selectedTier = filteredGroups
                .flatMap((group) => group.tiers)
                .find((tier) => tier.tier === item.value);

              if (selectedTier) {
                acc.push(selectedTier.audienceId);
              }

              return acc;
            },
            [],
          );

          updatedFilters.audience.value = audienceIds || [];
        }
      }

      if (filter === 'timeframe') {
        if (item?.socialWatch) {
          const filters = {
            audiences:
              typeof prevFilters.audience.value === 'string'
                ? [prevFilters.audience.value]
                : prevFilters.audience.value,
          };

          if (socialMediaFilters.category.value) {
            filters.apparels = Array.isArray(prevFilters.category.value)
              ? prevFilters.category.value.map((item) => item.value)
              : [prevFilters.category.value?.value];
          }

          fetchSocialMediaNewFilters({
            variables: filters,
            fetchPolicy: 'network-only',
          });
        }
      }

      if (filter === 'category') {
        if (prevFilters.timeframe.value?.socialWatch) {
          const filters = {
            audiences:
              typeof prevFilters.audience.value === 'string'
                ? [prevFilters.audience.value]
                : prevFilters.audience.value,
          };

          let existingApparels = [];
          if (socialMediaFilters.category.value) {
            existingApparels = Array.isArray(prevFilters.category.value)
              ? prevFilters.category.value.map((item) => item.value)
              : [prevFilters.category.value?.value];
          }

          if (item) {
            const newApparels = Array.isArray(item)
              ? item.map((item) => item.value)
              : [item?.value];

            filters.apparels = [...existingApparels, ...newApparels];
          } else {
            filters.apparels = existingApparels;
          }

          fetchSocialMediaNewFilters({
            variables: filters,
            fetchPolicy: 'network-only',
          });
        }
      }

      if (filter === 'category' && item === null) {
        updatedFilters[filter].value = null;
        updatedFilters[filter].excludeValue = null;
      }

      if (filter === 'influencer' && item === null) {
        updatedFilters[filter].value = null;
        updatedFilters[filter].excludeValue = null;
      }

      if (filter === 'attribute.custom' && item === null) {
        updatedFilters[filter].value = null;
        updatedFilters[filter].excludeValue = null;
      }

      if (filter === 'color' && item === null) {
        updatedFilters[filter].value = null;
        updatedFilters[filter].excludeValue = null;
      }

      if (filter === 'subcolor' && item === null) {
        updatedFilters[filter][isExclude ? 'excludeValue' : 'value'] = null;
      }

      // Return the updated filters for the state update
      // localStorage.setItem(
      //   'socialMediaFilters',
      //   JSON.stringify(updatedFilters),
      // );

      return updatedFilters;
    });
  };

  const previousPath = location.state?.previousPath?.includes('/trends');

  useEffect(() => {
    const params = location.search;
    if (params.includes('design') || params.includes('tone')) {
      triggerRefetch();
      navigate(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (activeTab) {
      fetchFavoriteFilters({
        variables: {
          section:
            activeTab === 'fashion-weeks' ? 'FASHION_WEEK' : 'SOCIAL_MEDIA',
        },
      });
    }
  }, [activeTab]);

  useEffect(() => {
    if (previousPath) {
      const storedFilters = JSON.parse(
        localStorage.getItem('socialMediaFilters'),
      );
      if (storedFilters) {
        setSocialMediaFilters(storedFilters);
        localStorage.removeItem('socialMediaFilters');
      }
    }
  }, [previousPath]);

  useEffect(() => {
    const filters = {};
    if (
      socialMediaFilters.audience.value &&
      socialMediaFilters.audience.value.length > 0
    ) {
      filters.audiences = socialMediaFilters.audience.value;
    }

    if (
      Array.isArray(socialMediaFilters.category.value) &&
      socialMediaFilters.category.value.length > 0
    ) {
      filters.apparels = socialMediaFilters.category.value.map((item) => ({
        value: item.value,
      }));
    }

    if (socialMediaFilters.subcolor.value) {
      filters.subColors = socialMediaFilters.subcolor.value.map(
        (item) => item.value,
      );
    }

    if (socialMediaFilters['attribute.custom']?.value) {
      filters.attributes = socialMediaFilters['attribute.custom'].value.map(
        (attr) => ({
          name: attr.name,
          value: attr.value,
        }),
      );
    }

    // Add influencers filter
    if (
      Array.isArray(socialMediaFilters.influencer.value) &&
      socialMediaFilters.influencer.value.length > 0
    ) {
      filters.influencers = socialMediaFilters.influencer.value.map(
        (item) => item.value,
      );
    }

    if (
      filters.audiences ||
      filters.apparels ||
      filters.subColors ||
      filters.attributes ||
      filters.influencers ||
      filters.print
    ) {
      refetchSocialMediaNewFilters({ filters });
    }
  }, [
    socialMediaFilters.category.value,
    socialMediaFilters.market.value,
    socialMediaFilters.subcolor.value,
    socialMediaFilters['attribute.custom']?.value,
    socialMediaFilters.influencer.value,
    socialMediaFilters.audience.value,
    socialMediaFilters.print.value,
  ]);

  useEffect(() => {
    const socialMediaFilters = socialMediaNewFiltersData?.socialMediaFilters;

    if (socialMediaFilters) {
      const filters = socialMediaFilters.reduce((obj, filterGroup) => {
        // Check if the group contains valid values
        if (Array.isArray(filterGroup.values)) {
          if (filterGroup.label === 'designs') {
            // Handle designs specifically
            if (!obj['attribute.custom']) {
              obj['attribute.custom'] = {
                label: 'Design',
                placeholder: 'Select Design',
                isClearable: true,
                isMulti: true,
                isSearchable: true,
                options: [],
              };
            }

            // Group options by the labels in values array
            filterGroup.values.forEach((group) => {
              if (Array.isArray(group.values)) {
                const groupLabel = startCase(group.label.replaceAll('-', ' '));
                const groupOptions = group.values
                  .filter((item) => item.value !== 'N/A')
                  .map((item) => ({
                    label:
                      groupLabel +
                      ': ' +
                      startCase(item.label.replaceAll('-', ' ')),
                    name: group.label,
                    value: item.value,
                    group: groupLabel,
                  }));

                if (groupOptions.length > 0) {
                  obj['attribute.custom'].options.push({
                    label: groupLabel,
                    options: groupOptions,
                  });
                }
              }
            });
          } else if (filterGroup.label === 'apparels') {
            // Handle apparels for category options
            const filteredApparels = filterGroup.values
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.value === item.value),
              )
              .map((apparel) => ({
                label: startCase(
                  apparelMapping[apparel.value] ||
                    apparel.label.replaceAll('-', ' '),
                ),
                value: String(apparel.value),
              }));

            obj.category = {
              ...obj.category,
              options: filteredApparels,
            };
          } else if (filterGroup.label === 'influencers') {
            // Handle influencers options
            const filteredInfluencers = filterGroup.values
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.value === item.value),
              )
              .map((influencer) => ({
                label: startCase(influencer.label.replaceAll('-', ' ')),
                value: String(influencer.value),
              }));

            obj.influencer = {
              ...obj.influencer,
              options: filteredInfluencers,
            };
          } else if (filterGroup.label === 'subcolors') {
            const subColorOptionsMapping = subColorOptions
              .map((subColorOption) => {
                const matchingColor = filterGroup.values.find(
                  (color) =>
                    color.label.toLowerCase() ===
                    subColorOption.value.toLowerCase(),
                );

                if (!matchingColor) return null;

                return {
                  label: startCase(subColorOption.label.replaceAll('-', ' ')),
                  value: matchingColor.value,
                  hex: subColorOption.hex,
                  hexList: subColorOption.hexList,
                };
              })
              .filter(Boolean);

            obj.subcolor = {
              ...obj.subcolor,
              options: subColorOptionsMapping,
            };
          } else if (filterGroup.label === 'print') {
            /*  else if (filterGroup.label === 'print') {
            obj.print = {
              ...obj.print,
              label: 'Print',
              placeholder: 'Select Print',
              options: filterGroup.values.map((item) => ({
                label: startCase(item.label.replaceAll('-', ' ')),
                value: item.value,
                options: item.values.map((option) => ({
                  name: option.label,
                  group: item.label,
                  label: startCase(option.label.replaceAll('-', ' ')),
                  value: option.value,
                })),
              })),
            };
          } */
            // Group options by the labels in values array
            obj.print = {
              ...obj.print,
              label: 'Print',
              placeholder: 'Select Print',
              options: [],
            };

            filterGroup.values.forEach((group) => {
              if (Array.isArray(group.values)) {
                const groupLabel = startCase(group.label.replaceAll('-', ' '));

                const groupOptions = group.values
                  .filter((item) => item.value !== 'N/A')
                  .map((item) => ({
                    label:
                      groupLabel +
                      ': ' +
                      startCase(item.label.replaceAll('-', ' ')),
                    name: group.label,
                    value: item.value,
                    group: groupLabel,
                  }));

                if (groupOptions.length > 0) {
                  obj.print.options.push({
                    label: groupLabel,
                    options: groupOptions,
                  });
                }
              }
            });
          }
        }

        return obj;
      }, {});

      setSocialMediaFilters((prevFilters) => {
        // Check if any selected market has influencerSearch feature
        const hasInfluencerSearch =
          prevFilters.market.value?.some((selectedMarket) => {
            const market = marketsData?.markets?.find(
              (m) => m.id === selectedMarket.marketId,
            );
            return market?.features?.influencerSearch === true;
          }) || false;

        return {
          ...prevFilters,
          ...filters,
          'attribute.custom': {
            ...prevFilters['attribute.custom'],
            ...(filters['attribute.custom'] || {}),
          },
          category: {
            ...prevFilters.category,
            ...(filters.category || {}),
          },
          influencer: hasInfluencerSearch
            ? {
                ...prevFilters.influencer,
                ...(filters.influencer || {}),
              }
            : prevFilters.influencer,
          subcolor: {
            ...prevFilters.subcolor,
            ...(filters.subcolor || {}),
          },
          print: {
            ...prevFilters.print,
            ...(filters.print || {}),
          },
        };
      });
    }
  }, [socialMediaNewFiltersData]);

  const [getTypeaheadSuggestions] = useLazyQuery(GET_TYPEAHEAD_SUGGESTIONS, {
    fetchPolicy: 'cache-first',
  });

  const loadFashionWeekSearchOptions = (inputValue, callback) => {
    const query = inputValue.toLowerCase();
    if (!query || query.length < 3) {
      // eslint-disable-next-line n/no-callback-literal
      return callback([]);
    }

    getTypeaheadSuggestions({
      variables: {
        query,
      },
      onCompleted: (data) => {
        const options = data?.typeaheadSuggestions?.options?.map((item) => ({
          label: item.label,
          value: item.value,
        }));

        callback(options);
      },
    });
  };

  function fashionWeekClearAll() {
    const updatedFilters = {};

    for (const filter in fashionWeekFilters) {
      updatedFilters[filter] = {
        ...fashionWeekFilters[filter],
        value: null,
        excludeValue: null,
      };
    }

    // clear search filter
    updatedFilters.search.value = '';

    setFashionWeekFilters(updatedFilters);
  }

  function socialMediaClearAll() {
    const updatedFilters = {};

    for (const filter in socialMediaFilters) {
      const currentFilter = socialMediaFilters[filter];

      updatedFilters[filter] = {
        ...currentFilter,
        value:
          filter === 'gender' ? genders[0] : filter === 'audience' ? [] : null,
        excludeValue: null,
      };
    }

    // Explicitly reset the influencer filter to ensure it's cleared properly
    if (updatedFilters.influencer) {
      updatedFilters.influencer.value = null;
      updatedFilters.influencer.excludeValue = null;
    }

    updatedFilters.search.value = '';

    setSocialMediaFilters(updatedFilters);
    localStorage.removeItem('socialMediaSort');
  }

  useEffect(() => {
    if (activeTab === 'fashion-weeks') {
      fetchFashionWeekFilters({
        variables: {
          season: [],
        },
      });
      setIsGenderTouched(false);
      setIncludes([]);
      setExcludes([]);
    } else if (activeTab === 'social-media') {
      fetchMarkets();
      fetchSocialMediaNewFilters();
      setIncludes([]);
      setExcludes([]);
    }
  }, [activeTab]);

  async function clearAll() {
    if (activeTab === 'fashion-weeks') {
      setIncludes([]);
      setExcludes([]);
      fashionWeekClearAll();
      await fetchFashionWeekFilters({
        variables: {
          season: [],
        },
      });
      triggerRefetch();
      setIsFavorite(false);
      setCanFavorite(false);
      setSelectedFavoriteFilter(null);
    } else if (activeTab === 'social-media') {
      await fetchMarkets();
      await fetchSocialMediaNewFilters();
      setIncludes([]);
      setExcludes([]);
      socialMediaClearAll();
      triggerRefetch();
      setIsFavorite(false);
      setCanFavorite(false);
      setSelectedFavoriteFilter(null);
    }
  }

  useEffect(() => {
    const htmlElement = document.documentElement;

    if (isExploreMobile) {
      htmlElement.classList.remove('is-not-explore-mobile');
    } else {
      htmlElement.classList.add('is-not-explore-mobile');
    }
  }, [isExploreMobile]);

  const [canFavorite, setCanFavorite] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  // State to control favorites modal visibility
  const [showFavoritesModal, setShowFavoritesModal] = useState(false);

  const handleAddFilterToFavorites = async (filter) => {
    const currentFilter =
      filter.section === 'FASHION_WEEK'
        ? fashionWeekFilters
        : socialMediaFilters;

    const saveToFiltersValue = Object.keys(currentFilter).reduce((acc, key) => {
      if (key === 'market') {
        acc[key] = {
          value: null,
          excludeValue: null,
        };

        const marketValue = currentFilter?.market?.value?.map((item) => {
          const marketOption = currentFilter?.market?.options
            ?.flatMap((market) => market.options)
            .find(
              (market) =>
                market.marketId === item.marketId &&
                item.value === market.audVal,
            );
          return marketOption;
        });
        acc[key].value = marketValue;
        acc[key].excludeValue = null;
      } else {
        acc[key] = {
          value: null,
          excludeValue: null,
        };
        if (currentFilter[key].value) {
          acc[key].value = currentFilter[key].value;
        }
        if (currentFilter[key].excludeValue) {
          acc[key].excludeValue = currentFilter[key].excludeValue;
        }
      }
      return acc;
    }, {});

    return createFavoriteFilter({
      variables: {
        input: {
          section: filter.section,
          label: filter.label,
          value: saveToFiltersValue,
        },
      },
    }).then((response) => {
      refetchFavoriteFilters({
        section: filter.section,
      });
      return response;
    });
  };

  const handleUpdateFavorite = (favoriteFilterId, newLabel) => {
    return updateFavoriteFilter({
      variables: {
        input: {
          id: favoriteFilterId,
          label: newLabel,
        },
      },
    }).then((response) => {
      refetchFavoriteFilters({
        section:
          activeTab === 'fashion-weeks' ? 'FASHION_WEEK' : 'SOCIAL_MEDIA',
      });
      return response;
    });
  };

  const handleDeleteFavorite = async (filterId) => {
    return deleteFavoriteFilter({
      variables: {
        deleteFavoriteFilterId: filterId,
      },
    }).then(() => {
      refetchFavoriteFilters({
        section:
          activeTab === 'fashion-weeks' ? 'FASHION_WEEK' : 'SOCIAL_MEDIA',
      });
    });
  };

  const applyFavoriteFilter = async (filter) => {
    const filterValue = JSON.parse(filter.value);

    if (filter.section === 'FASHION_WEEK') {
      Object.keys(filterValue).forEach((key) => {
        if (filterValue[key]?.value != null) {
          if (Array.isArray(filterValue[key].value)) {
            filterValue[key].value.forEach((item) => {
              handleFashionWeekFiltersOnChange(item, key, false);
            });
          } else {
            handleFashionWeekFiltersOnChange(
              filterValue[key].value,
              key,
              false,
            );
          }
        }
        if (filterValue[key]?.excludeValue != null) {
          if (Array.isArray(filterValue[key].excludeValue)) {
            filterValue[key].excludeValue.forEach((item) => {
              handleFashionWeekFiltersOnChange(item, key, true);
            });
          } else {
            handleFashionWeekFiltersOnChange(
              filterValue[key].excludeValue,
              key,
              true,
            );
          }
        }
      });
    } else if (filter.section === 'SOCIAL_MEDIA') {
      handleSocialMediaFiltersOnChange(null, 'market', true, false);
      Object.keys(filterValue).forEach((key) => {
        if (['audience', 'gender', 'platform', 'tier', 'tone'].includes(key)) {
          setSocialMediaFilters((prevFilters) => {
            return {
              ...prevFilters,
              [key]: {
                ...prevFilters[key],
                value: filterValue[key].value,
              },
            };
          });
        } else if (filterValue[key].value != null) {
          if (Array.isArray(filterValue[key].value)) {
            filterValue[key].value.forEach((item) => {
              handleSocialMediaFiltersOnChange(item, key, false, false);
            });
          } else {
            handleSocialMediaFiltersOnChange(
              filterValue[key].value,
              key,
              false,
              false,
            );
          }
        }

        if (filterValue[key].excludeValue != null) {
          if (Array.isArray(filterValue[key].excludeValue)) {
            filterValue[key].excludeValue.forEach((item) => {
              handleSocialMediaFiltersOnChange(item, key, false, true);
            });
          } else {
            handleSocialMediaFiltersOnChange(
              filterValue[key].excludeValue,
              key,
              false,
              true,
            );
          }
        }
      });
    }
  };

  return (
    <ExploreContext.Provider
      value={{
        activeTab,
        setActiveTab: handleSetActiveTab,
        fashionWeekFilters,
        handleFashionWeekFiltersOnChange,
        socialMediaFilters,
        handleSocialMediaSortOnChange,
        socialMediaSort,
        handleSocialMediaFiltersOnChange,
        clearAll,
        fashionWeekClearAll,
        socialMediaClearAll,

        selectedSocialMediaAudience: socialMediaFilters.audience?.value,
        selectedSocialMediaColor: socialMediaFilters.color?.value,
        selectedSocialMediaSubColor: socialMediaFilters.subcolor?.value,
        selectedSocialMediaCategory: socialMediaFilters.category.value,
        selectedSocialMediaInfluencer: socialMediaFilters.influencer.value,
        selectedSocialMediaTimeframe: socialMediaFilters?.timeframe?.value,
        selectedSocialMediaPersonCount: exploreAdvanced
          ? socialMediaFilters?.personCount?.value
          : null,
        selectedSocialMediaPersonPosition: exploreAdvanced
          ? socialMediaFilters?.personPosition?.value
          : null,
        selectedSocialMediaTone: socialMediaFilters.tone?.value,
        socialMediaSorting: socialMediaSort.sort.value,

        // Add a formatted version of the influencer values for direct use in the query
        selectedSocialMediaInfluencerValues: Array.isArray(
          socialMediaFilters.influencer.value,
        )
          ? socialMediaFilters.influencer.value.map((item) => item.value)
          : null,

        socialMediaImageVersion,
        setSocialMediaImageVersion,

        gridView,
        setGridView,

        isGridViewTransitioning,
        setIsGridViewTransitioning,

        posts,
        setPosts,
        hasBannedApparel,
        setHasBannedApparel,

        loadFashionWeekSearchOptions,

        shouldRefetch,
        setShouldRefetch,
        triggerRefetch,
        setSocialMediaSort,
        includes,
        setIncludes,
        excludes,
        setExcludes,
        marketsData,

        // explore mobile experience
        isExploreMobile,
        isFiltersOpen,
        setIsFiltersOpen,
        isSearchOpen,
        setIsSearchOpen,

        hoveredCardId,
        setHoveredCardId,

        isGenderTouched,
        setIsGenderTouched,
        activeSearchTab,
        setActiveSearchTab,

        // State to track if filters can be favorited and handle favorite action
        canFavorite,
        setCanFavorite,
        isFavorite,
        setIsFavorite,

        // State to control favorites modal visibility
        showFavoritesModal,
        fetchFavoriteFilters,
        refetchFavoriteFilters,
        setShowFavoritesModal,

        favoriteFilters,
        favoriteFiltersLoading,
        handleAddFilterToFavorites,
        handleDeleteFavorite,

        createFavoriteFilter,
        handleUpdateFavorite,

        applyFavoriteFilter,

        selectedFavoriteFilter,
        setSelectedFavoriteFilter,

        recentSocialMediaSearches,
        setRecentSocialMediaSearches,
        recentFashionWeekSearches,
        setRecentFashionWeekSearches,
        saveRecentSearch,
        removeRecentSearch,
      }}
    >
      {children}
    </ExploreContext.Provider>
  );
};

export const useExploreContext = () => useContext(ExploreContext);
