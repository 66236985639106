import { gql } from '@apollo/client';

export const REGISTER_USER = gql`
  mutation RegisterUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      accessToken
      refreshToken
      user {
        id
        email
        fullName
        isEmailConfirmed
      }
    }
  }
`;

export const LOGIN_USER = gql`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      accessToken
      refreshToken
      user {
        id
        email
        fullName
        isEmailConfirmed
      }
    }
  }
`;

export const GENERATE_ACCESS_TOKEN = gql`
  mutation GenerateAccessToken($refreshToken: String!) {
    generateAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export const SELECT_ORGANIZATION = gql`
  mutation SelectOrganization($organizationId: ID!) {
    selectOrganization(organizationId: $organizationId) {
      accessToken
      refreshToken
    }
  }
`;

export const REMOVE_SELECTED_ORGANIZATION = gql`
  mutation RemoveSelectedOrganization {
    removeSelectedOrganization {
      accessToken
      refreshToken
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      message
    }
  }
`;

export const CONFIRM_MAIL = gql`
  mutation ConfirmMail($input: ConfirmMailInput) {
    confirmMail(input: $input) {
      message
    }
  }
`;
