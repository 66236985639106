import { useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import { useUser } from '@/api/authentication/hook';
import { useExploreMutation } from '@/api/explore/hooks';
import { Button } from '@/components';
import { Heading } from '@/components/Heading';
import { AttributePill, TonePill } from '@/components/Pills';
import { HoverTooltip } from '@/components/ui/tooltip';
// import { useAppContext } from '@/contexts/AppContext';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { Icon } from '@/icons';
import { SaveToCollection } from '@/layouts/common/saveToCollection';
import { Comments } from '@/pages/general/imagePreview/components/Comments';

import { FashionWeekItem, FashionWeekItemHeader } from './FashionWeekItem';
import { FileUploadItem, FileUploadItemHeader } from './FileUploadItem';
import { SocialMediaItem, SocialMediaItemHeader } from './SocialMediaItem';
import { StudioItem, StudioItemHeader } from './StudioItem';

dayjs.extend(utc);
dayjs.extend(relativeTime);

const headersByType = {
  fashionWeekItem: FashionWeekItemHeader,
  socialMediaItem: SocialMediaItemHeader,
  studioItem: StudioItemHeader,
  fileUploadItem: FileUploadItemHeader,
};

const itemTypeToCollectionItemTypeMap = {
  studioItem: 'studioItem',
  fashionWeekItem: 'fashionItem',
  socialMediaItem: 'post',
};

function ModerationButton({
  mutationKey,
  valueKey,
  value,
  dataKey,
  onCompleteText,
  children,
  navigate,
  location,
  isDisabled,
}) {
  const { setHasBannedApparel } = useExploreContext();
  const [mutation, { data, loading }] = useExploreMutation(mutationKey);

  if (loading) {
    return (
      <div className="animate-spin">
        <Icon name="spinner" />
      </div>
    );
  }

  return (
    <Button
      className="rounded-lg bg-primary py-2 font-bold text-white"
      disabled={isDisabled || !!data}
      onClick={() => {
        mutation({
          variables: {
            [valueKey]: value,
          },
          onCompleted: (data) => {
            data?.excludePhoto?.status && setHasBannedApparel(true);
          },
        });
        if (location.key === 'default') {
          navigate('/explore', { replace: true });
        } else {
          navigate(-1);
        }
      }}
    >
      {data?.[dataKey]?.status ? onCompleteText : children}
    </Button>
  );
}

export function HeaderButtons({ isDisabled, item }) {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const itemIndex = urlParams.get('item');
  const pathSegments = location.pathname.split('/');
  const itemType = pathSegments.includes('socialMediaItem')
    ? 'social-media'
    : 'fashion-weeks';
  const exploreItemId = urlParams.get('exploreItem');

  const {
    setActiveTab,
    fashionWeekClearAll,
    socialMediaClearAll,
    isExploreMobile,
  } = useExploreContext();
  const { type, id } = useParams();
  const { moderationEnabled } = useUser();

  function handleClose() {
    if (location.key === 'default') {
      fashionWeekClearAll();
      socialMediaClearAll();
      setActiveTab(itemType);
      navigate('/explore');
    } else {
      if (itemIndex !== null && itemIndex !== 0) {
        setActiveTab(itemType);
        navigate('/explore');
      } else {
        navigate(-1);
      }
    }
  }

  if (isExploreMobile) {
    return (
      <div className="flex items-center justify-between">
        <Heading size="base" className="text-black">
          Detail
        </Heading>
        <div className="flex items-center gap-4">
          <SaveToCollection
            id={item?.id || id}
            type={itemTypeToCollectionItemTypeMap[type]}
          >
            {(loading) =>
              loading ? (
                <Button className="rounded-lg bg-secondary p-1 px-3 font-bold text-white">
                  Save
                </Button>
              ) : (
                <Button className="rounded-lg p-1 px-3 font-bold">Save</Button>
              )
            }
          </SaveToCollection>
          <RxCross1
            size={28}
            onClick={handleClose}
            className="cursor-pointer"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-4">
        <SaveToCollection
          id={item?.id || id}
          type={itemTypeToCollectionItemTypeMap[type]}
        >
          {(loading) =>
            loading ? (
              <Button className="rounded-lg bg-secondary py-2 font-bold text-white">
                Save
              </Button>
            ) : (
              <Button className="rounded-lg py-2 font-bold">Save</Button>
            )
          }
        </SaveToCollection>

        <HoverTooltip
          tooltipContent={
            <p>
              Open in Studio to edit with AI tools or use as a reference image
              for apparel creation.
            </p>
          }
        >
          <Button
            variant="primary"
            outline
            className="rounded-lg py-2 font-bold"
            onClick={() => {
              const studioParams = new URLSearchParams();
              if (item?.id || id) {
                studioParams.append('itemId', item?.id || id);
              }
              if (type) {
                studioParams.append('itemType', type);
              }
              studioParams.append('generationType', 'apparel');
              const queryString = studioParams.toString();
              const studioUrl = `/studio${queryString ? `?${queryString}` : ''}`;
              navigate(studioUrl);
            }}
          >
            Edit in Studio
          </Button>
        </HoverTooltip>
        {moderationEnabled && (
          <ModerationButton
            dataKey="excludePhoto"
            mutationKey="banApparel"
            onCompleteText="Banned Apparel"
            value={exploreItemId || null}
            valueKey="apparelId"
            navigate={navigate}
            location={location}
            isDisabled={isDisabled}
          >
            Ban Apparel
          </ModerationButton>
        )}
      </div>

      <RxCross1 size={42} onClick={handleClose} className="cursor-pointer" />
    </div>
  );
}

export function Wrapper({ children, imagePreview = true }) {
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const { isExploreMobile } = useExploreContext();

  useEffect(() => {
    function handleSizeChange() {
      const navbarEl = document.getElementById('main-nav');
      const bodyEl = document.querySelector('body');

      setWrapperHeight(bodyEl.offsetHeight - navbarEl.offsetHeight);
    }

    handleSizeChange();

    window.addEventListener('resize', handleSizeChange);

    return () => window.removeEventListener('resize', handleSizeChange);
  }, []);

  return (
    <div
      className={cn(
        'absolute bottom-0 right-0 left-0 z-[500] animate-fade-in bg-white',
        isExploreMobile
          ? 'grid-cols-1 overflow-auto h-full w-full'
          : imagePreview
            ? 'grid grid-cols-[67%_33%] overflow-hidden'
            : 'grid grid-cols-[33%_67%] overflow-hidden',
      )}
      style={
        !isExploreMobile
          ? {
              height: `${wrapperHeight}px`,
            }
          : {}
      }
    >
      {children}
    </div>
  );
}

export function AttributesWrapper({
  children,
  item,
  loading,
  variant = 'desktop',
}) {
  const { isExploreMobile, setActiveTab } = useExploreContext();
  const [isDisabled, setIsDisabled] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const exploreItemId = urlParams.get('exploreItem');

  const params = useParams();

  const isSocialMediaItem = params.type === 'socialMediaItem';

  useEffect(() => {
    if (isSocialMediaItem) {
      setActiveTab('social-media');
    }
  }, [isSocialMediaItem]);

  // const { showUpdatePopup } = useAppContext();

  useEffect(() => {
    if (item && exploreItemId) {
      const foundItem = item.apparels.find(
        (apparel) => apparel.id === exploreItemId,
      );

      setIsDisabled(foundItem?.disabled);
    }
  }, [item, exploreItemId]);

  const { type } = useParams();

  if (!type) return <></>;

  const Header = headersByType[type];

  if (loading) {
    return <div>loading</div>;
  }

  if (variant === 'mobile-header') {
    return (
      <div className="flex flex-col gap-4 overflow-y-auto bg-white p-8 pb-6">
        <HeaderButtons isDisabled={isDisabled} />
        <Header item={item} />
      </div>
    );
  }

  return (
    <div
      className={cn(
        'flex flex-col gap-8 overflow-y-auto bg-white pb-8',
        isExploreMobile ? 'pt-6 px-8' : 'pt-8 pl-16 pr-8',
      )}
    >
      {!isExploreMobile && (
        <>
          <HeaderButtons isDisabled={isDisabled} item={item} />
          <Header item={item} />
        </>
      )}
      <div className="flex h-full flex-col gap-4 overflow-auto">{children}</div>
      <Comments itemId={item.id} />
    </div>
  );
}

export function Attributes({ item, apparels, loading, variant = 'desktop' }) {
  const { type } = useParams();

  if (!item || !apparels) return <></>;

  return (
    <AttributesWrapper loading={loading} item={item} variant={variant}>
      {apparels?.map((apparel, i) => {
        return (
          <div className="flex flex-col gap-2" key={i}>
            <h1 className="text-lg font-bold capitalize">{apparel.title}</h1>
            <div className="flex flex-wrap items-center gap-4">
              {apparel?.attributes?.map(
                (attribute, index) =>
                  attribute.value !== 'N/A' && (
                    <AttributePill
                      key={index}
                      label={attribute.name}
                      value={attribute.value}
                    />
                  ),
              )}
              {apparel?.tones
                .filter((tone) => tone.weight > 0.15)
                .map((tone) => (
                  <TonePill key={tone.hex} type={type} {...tone} />
                ))}
            </div>
          </div>
        );
      })}
    </AttributesWrapper>
  );
}

export function AttributesFileUpload({ item, loading }) {
  if (!item) return <></>;

  return (
    <AttributesWrapper loading={loading} item={item}>
      <div className="flex flex-col gap-2">
        <h1 className="text-lg font-bold capitalize"></h1>
        <div className="flex flex-wrap items-center gap-4">
          Upload Time {dayjs(item.createdDate).format('DD MMM YYYY')}
        </div>
      </div>
    </AttributesWrapper>
  );
}

export function ImagePreview() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const itemIndex = urlParams.get('item');
  const pathSegments = location.pathname.split('/');
  const itemType = pathSegments.includes('socialMediaItem')
    ? 'social-media'
    : 'fashion-weeks';

  const { setActiveTab, fashionWeekClearAll, socialMediaClearAll } =
    useExploreContext();
  const { type, id } = useParams();

  // Handle escape key press to navigate back
  useEffect(() => {
    const closePage = (e) => {
      if (e.key === 'Escape') {
        if (location.key === 'default') {
          fashionWeekClearAll();
          socialMediaClearAll();
          setActiveTab(itemType);
          navigate(`/explore/${itemType}`);
        } else {
          if (itemIndex !== null && itemIndex !== 0) {
            setActiveTab(itemType);
            navigate(`/explore/${itemType}`);
          } else {
            navigate(-1);
          }
        }
      }
    };

    window.addEventListener('keydown', closePage);
    return () => window.removeEventListener('keydown', closePage);
  }, [navigate]);

  switch (type) {
    case 'fashionWeekItem':
      return <FashionWeekItem id={id} />;
    case 'socialMediaItem':
      return <SocialMediaItem postId={id} />;
    case 'studioItem':
      return <StudioItem id={id} />;
    case 'fileUploadItem':
      return <FileUploadItem id={id} />;
    default:
      return <div>Not Found</div>;
  }
}
