import {
  Brush,
  DiamondPlus,
  Eraser,
  Fullscreen,
  Image,
  ImagePlus,
  Images,
  NotebookPen,
  Palette,
  Pen,
  Plus,
  RectangleHorizontal,
  RectangleVertical,
  Redo,
  Replace,
  Scan,
  SeparatorVertical,
  Shapes,
  Square,
  SquareAsterisk,
  SquareDashedBottom,
  Undo,
} from 'lucide-react';

export const EDIT_TOOLS_CONFIG = {
  area: {
    options: [
      { value: 'landscape', icon: RectangleHorizontal, size: 18 },
      { value: 'portrait', icon: RectangleVertical, size: 18 },
      { value: 'square', icon: Square, size: 18 },
      { value: 'custom', icon: Scan, size: 18 },
    ],
  },
  brush: {
    options: [
      { value: 10, icon: Brush, size: 14 },
      { value: 20, icon: Brush, size: 18 },
      { value: 30, icon: Brush, size: 24 },
      { value: 0, icon: Eraser, size: 18 },
      { value: -1, icon: Undo, size: 18 },
      { value: -2, icon: Redo, size: 18 },
    ],
  },
  view: {
    options: [
      { value: 'old', icon: Image, size: 18 },
      { value: 'slider', icon: SeparatorVertical, size: 18 },
      { value: 'new', icon: ImagePlus, size: 18 },
    ],
  },
};

export const STUDIO_TOOLS_CONFIG = {
  GENERATE_APPAREL: {
    icon: Plus,
    text: 'Create Apparel',
    loading: 'Creating Apparel',
    pendingAreaBoxCount: 4,
    hasPositivePrompt: true,
    hasReferenceImage: true,
    hasReferencePattern: true,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: false,
    hasSuggestedPrompt: true,
    canUseImageStrength: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: true,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: true,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: true,
      IMAGE_BACKGROUND_REMOVER: true,
      COLOR_TRANSFER: true,
      PATTERN_TILE: false,
      INPAINTING: true,
      IMAGE_CHANGE_BACKGROUND: true,
    },
    canSelectSize: true,
    canSelectSizeOptions: [
      { value: 'SQUARE', label: 'Square', disabled: false, hidden: false },
      { value: 'PORTRAIT', label: 'Portrait', disabled: false, hidden: false },
      {
        value: 'LANDSCAPE',
        label: 'Landscape',
        disabled: false,
        hidden: false,
      },
    ],
    defaultSize: 'PORTRAIT',
  },
  GENERATE_PATTERN: {
    icon: SquareAsterisk,
    text: 'Create Pattern',
    loading: 'Creating Pattern',
    pendingAreaBoxCount: 4,
    hasPositivePrompt: true,
    hasReferenceImage: false,
    hasReferencePattern: true,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: false,
    hasSuggestedPrompt: true,
    canUseImageStrength: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: false,
      CREATE_PATTERN_VARIANTS: true,
      GENERATE_FLAT_SKETCH: false,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: true,
      IMAGE_BACKGROUND_REMOVER: false,
      COLOR_TRANSFER: false,
      PATTERN_TILE: true,
      INPAINTING: true,
      IMAGE_CHANGE_BACKGROUND: false,
    },
    canSelectSize: true,
    canSelectSizeOptions: [
      { value: 'SQUARE', label: 'Square', disabled: false, hidden: false },
      { value: 'PORTRAIT', label: 'Portrait', disabled: true, hidden: false },
      {
        value: 'LANDSCAPE',
        label: 'Landscape',
        disabled: true,
        hidden: false,
      },
    ],
    defaultSize: 'SQUARE',
  },
  REALIZE_SKETCH: {
    icon: NotebookPen,
    text: 'Create from Sketch',
    loading: 'Realizing from Sketch',
    pendingAreaBoxCount: 1,
    hasPositivePrompt: true,
    hasReferenceImage: false,
    hasReferencePattern: false,
    hasReferenceSketch: true,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: false,
    hasSuggestedPrompt: false,
    canUseImageStrength: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: true,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: true,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: true,
      IMAGE_BACKGROUND_REMOVER: true,
      COLOR_TRANSFER: true,
      PATTERN_TILE: false,
      INPAINTING: true,
      IMAGE_CHANGE_BACKGROUND: true,
    },
    canSelectSize: false,
  },
  GENERATE_GRAPHIC: {
    icon: Shapes,
    text: 'Create Graphic',
    loading: 'Creating Graphic',
    pendingAreaBoxCount: 4,
    hasPositivePrompt: true,
    hasReferenceImage: false,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: true,
    hasReferenceOtherImage: false,
    hasCompareResult: false,
    hasSuggestedPrompt: true,
    canUseImageStrength: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: false,
      CREATE_VARIANTS: true,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: false,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: true,
      IMAGE_BACKGROUND_REMOVER: true,
      COLOR_TRANSFER: false,
      PATTERN_TILE: false,
      INPAINTING: true,
      IMAGE_CHANGE_BACKGROUND: false,
    },
    canSelectSize: true,
    canSelectSizeOptions: [
      { value: 'SQUARE', label: 'Square', disabled: true, hidden: false },
      { value: 'PORTRAIT', label: 'Portrait', disabled: false, hidden: false },
      {
        value: 'LANDSCAPE',
        label: 'Landscape',
        disabled: true,
        hidden: false,
      },
    ],
    defaultSize: 'PORTRAIT',
  },
  PATTERN_TILE: {
    icon: Fullscreen,
    text: 'Create Tiles',
    buttonText: 'Tiles',
    loading: 'Creating Tiles',
    pendingAreaBoxCount: 1,
    hasPositivePrompt: false,
    hasReferenceImage: false,
    hasReferencePattern: true,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: true,
    canGenerate: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: false,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: false,
      ZOOM_IN_IMAGE: false,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: true,
      IMAGE_BACKGROUND_REMOVER: false,
      COLOR_TRANSFER: false,
      PATTERN_TILE: false,
      INPAINTING: false,
      IMAGE_CHANGE_BACKGROUND: false,
    },
  },
  CREATE_VARIANTS: {
    icon: DiamondPlus,
    text: 'Generate Variants',
    buttonText: 'Create Variants',
    loading: 'Creating Variants',
    pendingAreaBoxCount: 4,
    hasPositivePrompt: false,
    hasReferenceImage: true,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: false,
    canGenerate: true,
    canUseImageStrength: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: true,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: true,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: true,
      IMAGE_BACKGROUND_REMOVER: true,
      COLOR_TRANSFER: true,
      PATTERN_TILE: false,
      INPAINTING: true,
      IMAGE_CHANGE_BACKGROUND: true,
    },
  },
  CREATE_PATTERN_VARIANTS: {
    icon: DiamondPlus,
    text: 'Generate Variants',
    buttonText: 'Create Variants',
    loading: 'Creating Variants',
    pendingAreaBoxCount: 4,
    hasPositivePrompt: false,
    hasReferenceImage: false,
    hasReferencePattern: true,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: false,
    canUseImageStrength: true,
    canGenerate: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: false,
      CREATE_PATTERN_VARIANTS: true,
      GENERATE_FLAT_SKETCH: false,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: true,
      IMAGE_BACKGROUND_REMOVER: false,
      COLOR_TRANSFER: false,
      PATTERN_TILE: true,
      INPAINTING: true,
      IMAGE_CHANGE_BACKGROUND: false,
    },
  },
  ZOOM_IN_IMAGE: {
    icon: Fullscreen,
    text: 'Zoom In',
    buttonText: 'Zoom In',
    loading: 'Zooming In',
    pendingAreaBoxCount: 1,
    canUseEditTools: ['area'],
    hasPositivePrompt: false,
    hasReferenceImage: true,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: false,
    isUsingApplyButton: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: true,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: false,
      ZOOM_IN_IMAGE: false,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: false,
      IMAGE_BACKGROUND_REMOVER: false,
      COLOR_TRANSFER: false,
      PATTERN_TILE: false,
      INPAINTING: false,
      IMAGE_CHANGE_BACKGROUND: false,
    },
  },
  UPSCALE_IMAGE: {
    icon: Replace,
    text: 'Upscale',
    buttonText: 'Upscale',
    loading: 'Upscaling',
    pendingAreaBoxCount: 1,
    isUsingSlideTool: true,
    hasPositivePrompt: false,
    hasReferenceImage: true,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: true,
    canGenerate: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: false,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: false,
      ZOOM_IN_IMAGE: false,
      UPSCALE_IMAGE: false,
      ENHANCE_IMAGE: false,
      IMAGE_BACKGROUND_REMOVER: true,
      COLOR_TRANSFER: false,
      PATTERN_TILE: false,
      IMAGE_CHANGE_BACKGROUND: true,
    },
  },
  ENHANCE_IMAGE: {
    icon: SquareDashedBottom,
    text: 'Enhance',
    buttonText: 'Enhance',
    loading: 'Enhancing',
    pendingAreaBoxCount: 1,
    hasPositivePrompt: false,
    isUsingSlideTool: true,
    hasReferenceImage: true,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: true,
    canGenerate: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: true,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: true,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: false,
      IMAGE_BACKGROUND_REMOVER: true,
      COLOR_TRANSFER: true,
      PATTERN_TILE: false,
      IMAGE_CHANGE_BACKGROUND: true,
    },
  },
  IMAGE_BACKGROUND_REMOVER: {
    icon: Eraser,
    text: 'Remove Background',
    buttonText: 'Remove Background',
    loading: 'Removing Background',
    pendingAreaBoxCount: 1,
    hasPositivePrompt: false,
    hasReferenceImage: true,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: true,
    canGenerate: true,
    canFlowsToTask: {
      GENERATE_APPAREL: false,
      GENERATE_PATTERN: false,
      GENERATE_GRAPHIC: false,
      REALIZE_SKETCH: false,
      CREATE_VARIANTS: true,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: true,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: false,
      IMAGE_BACKGROUND_REMOVER: false,
      COLOR_TRANSFER: true,
      PATTERN_TILE: false,
      INPAINTING: false,
      IMAGE_CHANGE_BACKGROUND: true,
    },
  },
  COLOR_TRANSFER: {
    icon: Palette,
    text: 'Change Tone',
    buttonText: 'Change Tone',
    loading: 'Changing Color',
    pendingAreaBoxCount: 1,
    canUseEditTools: ['area'],
    hasPositivePrompt: false,
    hasReferenceImage: true,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasColorToneSelect: true,
    hasCompareResult: true,
    isUsingApplyButton: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: true,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: true,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: true,
      IMAGE_BACKGROUND_REMOVER: true,
      COLOR_TRANSFER: true,
      PATTERN_TILE: false,
      IMAGE_CHANGE_BACKGROUND: true,
    },
  },
  INPAINTING: {
    icon: ImagePlus,
    text: 'Inpainting',
    buttonText: 'Inpainting',
    loading: 'Inpainting',
    pendingAreaBoxCount: 1,
    canUseEditTools: ['brush'],
    isUsingSlideTool: true,
    hasPositivePrompt: true,
    hasReferenceImage: true,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: true,
    hasSuggestedPrompt: false,
    isUsingApplyButton: true,
    canUseImageStrength: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: false,
      CREATE_VARIANTS: false,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: false,
      ZOOM_IN_IMAGE: false,
      UPSCALE_IMAGE: false,
      ENHANCE_IMAGE: false,
      IMAGE_BACKGROUND_REMOVER: false,
      COLOR_TRANSFER: false,
      PATTERN_TILE: false,
      IMAGE_CHANGE_BACKGROUND: false,
    },
  },
  IMAGE_CHANGE_BACKGROUND: {
    icon: Pen,
    icon2: Images,
    text: 'Change Background',
    buttonText: 'Change Background',
    text2: 'Blend Background',
    loading: 'Changing Background',
    pendingAreaBoxCount: 1,
    isUsingSlideTool: true,
    hasPositivePrompt: false,
    hasReferenceImage: true,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: true,
    hasCompareResult: true,
    isUsingApplyButton: true,
    canUseImageStrength: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: true,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: true,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: true,
      IMAGE_BACKGROUND_REMOVER: true,
      COLOR_TRANSFER: true,
      PATTERN_TILE: false,
      IMAGE_CHANGE_BACKGROUND: true,
    },
  },
  GENERATE_FLAT_SKETCH: {
    icon: Shapes,
    text: 'Flat Sketch',
    buttonText: 'To Flat Sketch',
    text2: 'Design to Flat Sketch',
    loading: 'Creating Flat Sketch',
    pendingAreaBoxCount: 1,
    isUsingSlideTool: true,
    hasPositivePrompt: false,
    hasReferenceImage: true,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: true,
    canUseImageStrength: true,
    canGenerate: true,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: false,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: false,
      ZOOM_IN_IMAGE: false,
      UPSCALE_IMAGE: false,
      ENHANCE_IMAGE: false,
      IMAGE_BACKGROUND_REMOVER: false,
      COLOR_TRANSFER: false,
      PATTERN_TILE: false,
      IMAGE_CHANGE_BACKGROUND: false,
    },
  },
  UPLOADED_APPAREL_IMAGE: {
    icon: null,
    text: 'Uploaded Image',
    buttonText: 'Uploaded Image',
    text2: 'Uploaded Image',
    loading: 'Uploading Image',
    pendingAreaBoxCount: 1,
    isUsingSlideTool: false,
    hasPositivePrompt: false,
    hasReferenceImage: false,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: false,
    canUseImageStrength: false,
    canGenerate: false,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: false,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: false,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: true,
      IMAGE_BACKGROUND_REMOVER: true,
      COLOR_TRANSFER: false,
      PATTERN_TILE: false,
      IMAGE_CHANGE_BACKGROUND: false,
    },
  },
  UPLOADED_PATTERN_IMAGE: {
    icon: null,
    text: 'Uploaded Pattern',
    buttonText: 'Uploaded Pattern',
    text2: 'Uploaded Pattern',
    loading: 'Uploading Pattern',
    pendingAreaBoxCount: 1,
    isUsingSlideTool: false,
    hasPositivePrompt: false,
    hasReferenceImage: false,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: false,
    canUseImageStrength: false,
    canGenerate: false,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: false,
      CREATE_PATTERN_VARIANTS: true,
      GENERATE_FLAT_SKETCH: false,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: true,
      IMAGE_BACKGROUND_REMOVER: false,
      COLOR_TRANSFER: false,
      PATTERN_TILE: true,
      IMAGE_CHANGE_BACKGROUND: false,
    },
  },
  UPLOADED_GRAPHIC_IMAGE: {
    icon: null,
    text: 'Uploaded Image',
    buttonText: 'Uploaded Image',
    text2: 'Uploaded Image',
    loading: 'Uploading Image',
    pendingAreaBoxCount: 1,
    isUsingSlideTool: false,
    hasPositivePrompt: false,
    hasReferenceImage: false,
    hasReferencePattern: false,
    hasReferenceSketch: false,
    hasReferenceGraphic: false,
    hasReferenceOtherImage: false,
    hasCompareResult: false,
    canUseImageStrength: false,
    canGenerate: false,
    canFlowsToTask: {
      GENERATE_APPAREL: true,
      GENERATE_PATTERN: true,
      GENERATE_GRAPHIC: true,
      REALIZE_SKETCH: true,
      CREATE_VARIANTS: false,
      CREATE_PATTERN_VARIANTS: false,
      GENERATE_FLAT_SKETCH: false,
      ZOOM_IN_IMAGE: true,
      UPSCALE_IMAGE: true,
      ENHANCE_IMAGE: true,
      IMAGE_BACKGROUND_REMOVER: true,
      COLOR_TRANSFER: false,
      PATTERN_TILE: false,
      IMAGE_CHANGE_BACKGROUND: false,
    },
  },
};

export const STUDIO_DISPLAY = {
  PENDING: 'PENDING',
  GENERATING: 'GENERATING',
  PREVIEW: 'PREVIEW',
  RESULT: {
    TILE: 'TILE',
    SOLO: 'SOLO',
  },
};

export const STUDIO_SCENES = [
  {
    value: 'RUNWAY',
    title: 'Runway',
    image: '/assets/studio/studio-scenes/scene1.jpg',
  },
  {
    value: 'URBAN',
    title: 'Casual',
    image: '/assets/studio/studio-scenes/scene2.jpg',
  },
  {
    value: 'STUDIO',
    title: 'Editorial',
    image: '/assets/studio/studio-scenes/scene3.jpg',
  },
  {
    value: 'PACKSHOT',
    title: 'Flat Lay',
    image: '/assets/studio/studio-scenes/scene4.jpg',
  },
  {
    value: 'CLEAN',
    title: 'Solid Look',
    image: '/assets/studio/studio-scenes/scene5.jpg',
  },
];

export const STUDIO_PATTERN_STYLES = [
  {
    value: 'oil-inspired textile',
    title: 'Oil',
    image: '/assets/studio/studio-pattern-styles/oil.jpg',
    options: ['Abstract', 'Expressionist', 'Impressionist'],
  },
  {
    value: 'watercolor-inspired textile',
    title: 'Watercolor',
    image: '/assets/studio/studio-pattern-styles/watercolor.jpg',
    options: ['Soft Floral', 'Gradient Wash', 'Ink Wash'],
  },
  {
    value: 'doodle',
    title: 'Doodle',
    image: '/assets/studio/studio-pattern-styles/doodle.jpg',
    options: [
      'Minimalist',
      'Cartoon',
      'Graffiti',
      'Abstract',
      'Geometric',
      'Handwriting',
    ],
  },
  {
    value: 'digital futuristic',
    title: 'Futuristic',
    image: '/assets/studio/studio-pattern-styles/futuristic.jpg',
    options: ['Glitch', 'Pixel Art', 'Holographic', 'Fractals'],
  },
  {
    value: 'geometric',
    title: 'Geometric',
    image: '/assets/studio/studio-pattern-styles/geometric.jpg',
    options: ['Op Art', 'Isometric', 'Mosaic', 'Line Art'],
  },
  {
    value: 'cultural ethnic',
    title: 'Ethnic',
    image: '/assets/studio/studio-pattern-styles/ethnic.jpg',
    options: ['Mandala', 'Kilim', 'Aztec', 'Paisley'],
  },
  {
    value: 'textured handcrafted',
    title: 'Handcrafted',
    image: '/assets/studio/studio-pattern-styles/handcrafted.jpg',
    options: ['Marble', 'Woodcut', 'Embroidery', 'Batik', 'Stitched'],
  },
  {
    value: 'organic nature-inspired',
    title: 'Organic',
    image: '/assets/studio/studio-pattern-styles/organic.jpg',
    options: [
      'Tie-Dye',
      'Leaf Print',
      'Animal Texture',
      'Hand-Painted Botanical',
    ],
  },
];

export const STUDIO_PROMPT_TEMPLATES = [
  {
    image: '/assets/studio/studio-templates/denim-on-denim.jpg',
    title: 'Denim on Denim',
    description:
      'Layered denim, urban cool, effortless edge, modern versatility',
    list: [
      'An establishing shot capturing the full-body of a chic, edgy woman figure in a denim on denim look. She is dressed in a cropped light-wash denim jacket with frayed edges, paired with high-waisted dark-wash wide leg jeans. The outfit features tan leather ankle boots, a white crop top, and gold hoop earrings.',
      'An establishing shot capturing the full-body of a stylish, laid-back woman figure in a denim on denim look. She is dressed in an oversized, medium-wash denim shirt tucked into wide-leg jeans of the same wash. The outfit is completed with a black statement belt and pointed-toe heels.',
      'An establishing shot capturing the full-body of a rugged, modern man figure in a denim on denim outfit. He is dressed in a dark-wash oversized denim jacket layered over a light-wash denim shirt, paired with straight-leg medium-wash jeans. The look features black Chelsea boots and a silver chain.',
      'An establishing shot capturing the full-body of a casually stylish man figure in a rugged denim on denim look. He is dressed in a distressed light-wash denim jacket paired with classic dark-wash regular jeans, and a fitted white T-shirt underneath. The look is completed with brown suede desert boots and a leather belt.',
    ],
  },
  {
    image: '/assets/studio/studio-templates/glam-dress.jpg',
    title: 'Glam Dress',
    description: 'Modern, bold, elegant, opulent, statement-making',
    list: [
      'A floor-length burgundy gown featuring a dramatic geometric cutout at the bust, paired with long sleeves and a body-hugging silhouette. The dress exudes modern elegance and sensual sophistication, perfect for a high-fashion evening look. Highlight the structured details and the interplay of minimalism and boldness.',
      'An asymmetrical black gown featuring a one-shoulder design with a ruched bodice and a thigh-high slit. The dress is adorned with a draped side bow accent, creating a statement-making silhouette. It combines contemporary design with effortless glamor, ideal for an upscale night out or a red-carpet event.',
      'An elegant one-shoulder gown in a royal blue hue, intricately adorned with shimmering beadwork forming peacock-like motifs. Featuring a thigh-high slit for added allure and paired with matching platform heels. A statement piece that exudes sophistication and boldness.',
      'A strapless gown with sheer nude layers, embellished with oversized golden sequins cascading to the floor. The semi-transparent design adds a modern, daring edge, perfectly complemented by statement jewelry for an opulent look.',
    ],
  },
  {
    image: '/assets/studio/studio-templates/modern-glam.jpg',
    title: 'Modern Business Glam',
    description: 'Futuristic, bold, tailored, playful, sophisticated',
    list: [
      'An avant-garde take on business casual, this ensemble features a pinstriped charcoal blouse with exaggerated shoulders and a plunging neckline, tucked into a matching ruched mini skirt for a sleek, modern silhouette. Paired with glossy knee-high croc-embossed boots and a structured handbag, this outfit embodies bold femininity and power dressing. Accessories include a statement choker, enhancing the dramatic and confident aesthetic of this striking look.',
      'A sophisticated business casual outfit featuring a tailored green crocodile leather jacket with a structured silhouette, paired with a matching mini skirt. Styled with ankle boots, this outfit combines bold textures and elegant lines for a statement-making office or event look.',
      'A sophisticated gray business casual set featuring a high-neck blouse with minimalistic detailing, paired with structured high-waisted shorts. Accessorized with pointed pumps and a quilted leather bag, this outfit exudes a polished and powerful aesthetic.',
      'Exuding modern sophistication, this outfit features a fitted camel turtleneck paired with a ruched leather mini skirt in a matching caramel tone, cinched at the waist with a statement suede belt. Styled with coordinating leather ankle boots and a structured chain handbag, this ensemble embodies sleek femininity. Gold hoop earrings add a touch of elegance, perfect for a polished business casual aesthetic.',
    ],
  },
  {
    image: '/assets/studio/studio-templates/animal-aesthetic.jpg',
    title: 'Animal Aesthetic',
    description: 'Bold, untamed, glamorous, edgy, statement-making',
    list: [
      'A tall, poised individual stands outdoors against a modern architectural backdrop. They wear a fitted white crop-top with a vivid cherry graphic at the chest, paired with a long, flowing leopard-print skirt that skims the ground. Their sleek, pulled-back hairstyle and red accents—from statement earrings to a bold red clutch—harmonize with the warm animal print tones. The look is completed by pointed red heels, creating a striking balance between playful, graphic detail and polished sophistication.',
      'Presented on a plain white background, this dress radiates luxury and timeless glamour. The piece is a strapless, corset-style bodice flowing into a full A-line skirt, all covered in a bold leopard-print pattern. The structured bodice and voluminous silhouette create a dramatic, elegant shape. The high-quality fabric and rich coloration evoke a sense of refined sophistication and statement-making style.',
      'Set on a runway scene, a model strides in an asymmetrical, one-shoulder mini dress featuring a pink zebra-inspired print. The fitted silhouette hugs the body’s contours, and the matching thigh-high boots create a head-to-toe print statement. The addition of a sleek, distinctive wig adds a futuristic and editorial edge. The combination of zebra pattern, vibrant color, combination of zebra and pink  and bold styling elements conveys a fearless, high-fashion aesthetic.',
      'A model commands attention in a structured, tailored suit saturated with a high-impact red and black leopard-print motif. The blazer features a sharply defined V-neckline, showcasing a hint of skin for a daring yet elegant appeal. Matching straight-leg trousers maintain a clean, elongated silhouette, while the suit’s glossy finish and glimmering texture catch the light as the model strides forward. Styled with a sleek black headband, statement earrings, and subtly sheer fishnet details at the ankles, the overall look is edgy, luxurious, and unapologetically bold—perfectly capturing the fashion house’s vision of modern femininity infused with untamed glamour.',
    ],
  },
];

export const STUDIO_PATTERN_TEMPLATES = [
  {
    image: '/assets/studio/studio-templates/floral.jpg',
    title: 'Floral',
    description: 'Elegant, botanical, vintage-inspired',
    list: [
      'A delicate and intricate fashion pattern inspired by traditional Chinoiserie, featuring elegant, stylized floral motifs. The design showcases large, sweeping peonies, cherry blossoms, and lotus flowers, each rendered with detailed linework and graceful curves. The flowers are surrounded by fine tendrils and subtle leaves, all arranged in a flowing, seamless pattern that exudes sophistication. The color palette is soft and refined, with pale blues, blue and dark blue tones, creating a luxurious yet gentle aesthetic. The design maintains the classic Chinoiserie charm, focusing purely on the ornate floral elements to evoke a sense of timeless beauty and elegance.',
      "A refined, vintage-inspired fashion pattern featuring an elegant blend of delicate wildflowers such as daisies, violets, and baby's breath. The design showcases intricate, soft foliage in muted green tones, with flowers rendered in gentle shades of soft pink and earthy browns. The pattern emphasizes subtle floral arrangements, where the flowers gracefully intertwine with slender vines and leaves, creating a balanced, non-dominant aesthetic. The composition evokes a nostalgic charm, with a soft, timeworn feel, perfect for fashion pieces that aim to capture a vintage look with a natural and understated beauty.",
      'A bold, Western-inspired fashion pattern featuring stylized floral motifs such as prairie roses, desert poppies, and sunflowers, arranged alongside intricate scrollwork and paisley elements. The flowers are rendered in warm, earthy tones of terracotta, soft pink, and sage green, evoking the rustic beauty of the American West. The design emphasizes both floral and geometric shapes, with subtle cowboy-inspired details like rope borders and woven textures. This pattern blends floral elements with Western heritage, creating a design that is both rugged and romantic, perfect for fashion pieces that capture the spirit of the frontier.',
      'A modern, high-fashion botanical pattern featuring lush, overlapping green leaves, ferns, and abstract vines arranged in a regular, continuous layout. The design highlights the various shades of green, from deep forest tones to light, fresh greens, creating a layered, textured effect. The pattern is clean and structured, with each botanical element repeating seamlessly to form a consistent and balanced flow. Subtle shading and fine detailing give the design a polished, contemporary look, ideal for fashion pieces that aim to evoke a fresh, nature-inspired aesthetic with a modern twist.',
    ],
  },
  {
    image: '/assets/studio/studio-templates/animal.jpg',
    title: 'Animal',
    description: 'Wild, exotic, fierce, playful',
    list: [
      'A high-quality fashion pattern featuring the iconic leopard print, rendered in a sleek and modern style. The design showcases small, irregular rosettes with black spots against a golden-brown background, creating a rich, textured effect. The rosettes repeat seamlessly across the pattern, maintaining a fluid and continuous flow. The subtle shading within the spots adds depth, making the pattern dynamic and visually striking. This print is perfect for bold, luxurious fashion pieces that exude elegance and power.',
      'A high-fashion zebra pattern, designed with bold, clean black and white stripes that repeat seamlessly in a continuous flow. The stripes curve and intertwine naturally, mimicking the organic texture of zebra fur while maintaining a crisp and modern look. The high contrast between the black and white creates a striking visual impact, ideal for sophisticated, statement-making fashion pieces. The design exudes a timeless, edgy appeal, perfect for high-end fashion collections.',
      'A contemporary cowhide-inspired fashion pattern featuring irregular, black and white patches in a continuous, flowing layout. The bold, contrasting spots are softened with subtle gradients and textures, giving the pattern a modern, high-quality finish. The organic shapes of the patches blend seamlessly, maintaining a consistent and fluid design. This pattern brings a rustic yet refined appeal, making it perfect for fashion pieces that blend natural elements with a high-fashion aesthetic.',
      'A sleek, high-fashion jaguar print featuring irregular black and dark brown rosettes against a warm tan background. The pattern is designed to flow seamlessly, with each rosette blending into the next in a continuous, organic manner. Subtle highlights and shadows within the spots give the print depth and a realistic texture, while maintaining a polished, modern look. This jaguar pattern is perfect for high-end fashion pieces that combine wild, exotic elegance with contemporary style.',
    ],
  },
  {
    image: '/assets/studio/studio-templates/geometric.jpg',
    title: 'Geometric',
    description: 'Modern, abstract, minimalist, graphic',
    list: [
      'A timeless houndstooth pattern featuring sharp black and white contrasts, each piece precisely aligned in a checkered layout. The pattern creates a bold, dynamic aesthetic that exudes sophistication and modernity. The clean, angular lines are complemented by a soft wool texture, adding warmth and depth to the classic geometric style, making it ideal for outerwear and structured garments.',
      'A refined herringbone pattern with crisp, diagonal stripes intersecting to form a repeating "V" motif. The design plays with light and shadow, offering a subtle dimensionality to the fabric. The warm, earthy tones, from taupe to dark brown, paired with a matte cotton texture, evoke a rustic elegance, perfect for high-end suiting or casual, tailored jackets.',
      'A vibrant chevron pattern with wide, alternating zigzag lines in bold primary colors. The geometric shapes stand out, creating a striking visual rhythm, accentuated by a satin finish for a sleek, glossy look. The contrast between the colors and sharp angles gives the pattern a contemporary edge, ideal for statement pieces in fashion collections.',
      'A classic argyle pattern reimagined with oversized diamond shapes in rich, autumnal tones of burgundy, forest green, and deep mustard. The intersecting lines in complementary hues add a layer of intricacy to the design. The woolen texture, with a hint of raised embroidery, gives the pattern a vintage yet luxurious feel, perfect for knitwear and cozy sweaters.',
    ],
  },
  {
    image: '/assets/studio/studio-templates/paisley.jpg',
    title: 'Paisley',
    description: 'Bohemian, intricate, exotic, whimsical',
    list: [
      'A traditional paisley pattern featuring ornate teardrop motifs, each intricately detailed with fine linework. The design flows gracefully across the fabric, rendered in a luxurious color palette of deep emerald, sapphire, and gold. The smooth silk texture enhances the richness of the design, making it perfect for evening wear or sophisticated scarves.',
      'A boho-inspired paisley pattern with large, free-flowing shapes in earthy tones of burnt sienna, mustard yellow, and olive green. The pattern feels organic, with swirling motifs and soft, hand-drawn lines, paired with a soft, woven cotton texture, giving it a relaxed, artisanal feel suitable for casual boho-chic pieces.',
      'A sleek, modern take on paisley, featuring clean, minimal black and white teardrop shapes. The simplicity of the monochrome palette gives the design a contemporary edge, while the smooth satin finish adds a touch of elegance. The pattern is perfect for a sophisticated, minimalist aesthetic, ideal for formal garments or accessories.',
      'A lively paisley pattern bursting with bright, contrasting hues of pink, turquoise, and orange. The intricate motifs intertwine in a playful, overlapping layout, creating a vibrant, dynamic effect. The soft velvet texture adds depth and luxury to the design, making it perfect for eye-catching statement pieces in fashion collections.',
    ],
  },
  {
    image: '/assets/studio/studio-templates/polka-dot.jpg',
    title: 'Polka Dot',
    description: 'Playful, retro, whimsical, timeless',
    list: [
      'A delicate polka dot pattern with tiny, evenly spaced dots on a soft pastel background. The dots are rendered in muted tones, like soft lavender and dusty pink, creating a gentle, understated look. The fabric has a silky, smooth texture, making the pattern ideal for blouses or light summer dresses.',
      'A bold polka dot pattern with oversized, contrasting dots in black and white. The sharp, clean edges of the dots create a striking visual impact, enhanced by a glossy satin finish. The pattern is perfect for bold, contemporary fashion statements, especially in structured garments like skirts or blazers.',
      'A playful, vibrant polka dot pattern featuring bright, multicolored dots scattered randomly on a neutral backdrop. The dots vary in size, adding a sense of fun and spontaneity to the design. The fabric has a smooth, matte texture, making it perfect for casual, youthful clothing pieces.',
      'A refined, minimalist polka dot design with crisp black dots on a white background. The pattern is clean and structured, with evenly spaced dots creating a classic yet modern look. The soft linen texture gives the fabric a sophisticated finish, ideal for elegant, everyday wear.',
    ],
  },
];

export const STUDIO_GRAPHIC_TEMPLATES = [
  // {
  //   image: '/assets/studio/studio-templates/christmas-graph.jpg',
  //   title: 'Christmas Vibe',
  //   list: [
  //     'A whimsical realistic gingerbread man graphic holding a colorful candy cane, designed with soft watercolor textures and festive embellishments of sugar detailing. Perfect for Christmas-themed.',
  //     'A charming Santa-themed gnome graphic wearing a red hat adorned with delicate flowers, holding a heart and a decorative key. This playful design adds a magical touch to Christmas fashion, evoking warmth and festive cheer.',
  //     'A delightful illustration of pink ice skates adorned with white floral swirls and festive detailing. A playful design that captures the joy of winter activities and Christmas festivities.',
  //     'A glamorous graphic of a stylish woman wearing a Santa hat and green polka-dot scarf, complemented by bold red lips and cat-eye sunglasses. This retro-inspired design is perfect for chic Christmas apparel with a vintage twist.',
  //   ],
  // },
  {
    image: '/assets/studio/studio-templates/animal-graph.jpg',
    title: 'The Animal',
    list: [
      'A graphic design featuring a bold, stylized heart shape filled with a detailed leopard print pattern. The heart outline is hand-drawn with a thick, black, marker-like stroke, creating a playful and modern aesthetic. The leopard print texture has natural shades of tan, beige, and black, maintaining a realistic yet artistic touch. Designed for use in fashion graphics such as T-shirts or sweatshirts, this artwork exudes a trendy and chic vibe.',
      "A typographic artwork with the word 'LOVE,' where the 'L,' 'O,' 'V,' and 'E' are created with bold, uppercase letters. The 'L,' 'V,' and 'E' are filled with a realistic leopard print texture in tan and black tones, while the 'O' is replaced with a heart featuring a red and black buffalo plaid pattern. The design has a hand-drawn, casual vibe, perfect for trendy fashion graphics on sweatshirts and T-shirts.",
      'A highly detailed illustration of a large bow designed with a leopard print texture. The bow has a smooth, satin-like appearance, with intricate shading and highlights to create a realistic 3D effect. The leopard print incorporates tan, beige, and black tones, delivering a luxurious and trendy aesthetic. This artwork is tailored for fashion graphics, perfectly suited for apparel like sweatshirts and hoodies.',
      'A playful graphic of leopard-print lips, rendered with soft, organic edges for a hand-drawn feel. The lips are accompanied by a small, pink satin bow placed on one side, adding a touch of femininity and charm. This trendy design is ideal for fashion applications such as T-shirts and sweatshirts, combining bold animal prints with a delicate accessory detail.',
    ],
  },
  {
    image: '/assets/studio/studio-templates/ribbon-graph.jpg',
    title: 'Ribbons',
    list: [
      'An elegant illustration of the Eiffel Tower, rendered with a sketch-like, hand-drawn black outline style. At the top of the tower, a large, soft pink bow is tied decoratively, adding a touch of charm and sophistication. The bow features subtle shading for a three-dimensional effect. This design combines Parisian elegance with a playful twist, ideal for use on fashion items like T-shirts or sweatshirts.',
      'A seamless pattern featuring multiple red satin bows arranged in a playful, repeating layout. Each bow is intricately shaded to create a semi-realistic, fabric-like texture, with vibrant red tones. The bows are evenly spaced on a soft, light background, making the design versatile and visually appealing for apparel graphics such as dresses, T-shirts, or sweatshirts.',
      'A repeating pattern featuring oversized red bows with a watercolor texture. Each bow is slightly varied in orientation and size, with soft edges and painterly shading. The warm red hues and subtle background create a cozy yet elegant design, suitable for fashion items such as sweatshirts or pajamas.',
      'A trio of pink bows. The bows vary slightly in color and texture: the top bow has a rich satin finish, the middle bow features a glittery, sparkly texture, and the bottom bow has a soft, matte watercolor effect. This layered design exudes femininity and sophistication.',
    ],
  },
  {
    image: '/assets/studio/studio-templates/valentines-graph.jpg',
    title: 'Valentines Day',
    list: [
      "A glossy red mosaic heart design, mimicking the reflective surface of a disco ball. The pixelated pattern and shimmering effect evoke a lively and celebratory vibe. Represents the passion and energy of Valentine's Day, blending romance with party-inspired aesthetics.",
      "A metallic pink heart. Shiny neon pink colors. Emphasizes the luxurious and glamorous side of Valentine's Day, perfect for expressing high-energy love and charm.",
      'A sleek graphic of a cherry martini, featuring a deep red hue and a glossy cherry garnish. The minimalist presentation adds elegance and sophistication. Represents a romantic evening celebration.',
      'Two champagne glasses clinking, adorned with pink satin bows tied at the stems, the bubbles in the glasses and soft ribbon details add a delicate and celebratory touch.',
    ],
  },
  {
    image: '/assets/studio/studio-templates/barbie-graph.jpg',
    title: 'Barbie Aesthetic',
    list: [
      'Create a charming graphic of a pink and red cowboy boot adorned with heart patterns and flames. Add small sparkling elements around the boot for a playful and romantic touch. The design should exude a feminine Western vibe.',
      "Design a graphic of a heart-shaped potion bottle filled with pink liquid and labeled 'Self Love Potion.' Include decorative sparkles, a ribbon, and a crown on the bottle for an elegant and empowering feminine pink aesthetic. Use soft pink and white tones with fine details for a dreamy look.",
      'A vibrant silhouette of Barbie\'s iconic ponytail in bold fuchsia, set against a bright pink background. Below the silhouette, playful retro-style text reads "Hi Barbie!" in a bubbly white font, outlined in soft pink. The overall design emphasizes a nostalgic yet chic feminine aesthetic, with strong contrasts and a modern pop culture vibe.',
      'A glossy, candy-like heart shape in translucent pink, featuring the bold white "Barbie" logo embossed in the center. The background is a rich, solid magenta that enhances the glossy sheen of the heart, giving it a polished and luxurious feel. The design is sleek, minimal, and radiates elegance with a playful touch of femininity.',
    ],
  },
];

export const STUDIO_HOME_DISCOVER = [
  {
    title: '',
    description:
      'An establishing shot capturing the full-body of a dramatic, elegant woman figure embodying the Western Gothic aesthetic. She is dressed in a black suede tassel jacket with silver embellishments, paired with a floor-length deep burgundy tulle skirt. The look features spider-embroidered cowboy boots, a wide-brim hat, and silver skull rings. The backdrop is a moody, rural setting, subtly enhancing the macabre, frontier-inspired look.',
    image: '/assets/studio/studio-home/discover1.jpg',
    hoverImage: '/assets/studio/studio-home/discover1.jpg',
    alt: 'Discover 1',
  },
  {
    title: '',
    description:
      'Elegant white blouse, high-waisted beige trousers, classy pearl earrings, vintage handbag, no model, photorealistic, soft lighting, waist-up view, clean background, ultra-detailed, Ralph Lauren inspired, timeless fashion, sophisticated, high-definition, perfect product, high-quality, premium shot.',
    image: '/assets/studio/studio-home/discover2.jpg',
    hoverImage: '/assets/studio/studio-home/discover2.jpg',
    alt: 'Discover 2',
  },
  {
    title: '',
    description:
      'Create a full look women inspired by the White Noise style: a clean, minimalist long white overcoat with straight, sharp lines, paired with a soft grey turtleneck and tailored white trousers. Complete the look with white leather loafers and a sleek, minimalist silver wristwatch. The overall design should evoke tranquility and refined elegance through simplicity and neutral tones.',
    image: '/assets/studio/studio-home/discover3.jpg',
    hoverImage: '/assets/studio/studio-home/discover3.jpg',
    alt: 'Discover 3',
  },
  {
    title: '',
    description:
      'Oversized black bomber jacket with patches, black cargo pants, chunky black boots, black cap, no model, photorealistic, high-contrast lighting, full product, ultra hd, focused shot, highly detailed.',
    image: '/assets/studio/studio-home/discover4.jpg',
    hoverImage: '/assets/studio/studio-home/discover4.jpg',
    alt: 'Discover 4',
  },
  {
    title: '',
    description:
      'Black leather jacket, plain white t-shirt, dark skinny jeans, black combat boots, Kanye West inspired, no model, photorealistic, moody lighting, front view, full product, realistic, stylish, high-fashion, intricately detailed, gritty.',
    image: '/assets/studio/studio-home/discover5.jpg',
    hoverImage: '/assets/studio/studio-home/discover5.jpg',
    alt: 'Discover 5',
  },
  {
    title: '',
    description:
      'An establishing shot capturing the full-body of a polished, chic woman figure in a classic preppy look. She is dressed in a crisp white button-down shirt tucked into tailored navy Bermuda shorts. The look is completed with brown leather boat shoes and a pearl necklace. The backdrop is a sophisticated, coastal setting, subtly enhancing the timeless East Coast vibe.',
    image: '/assets/studio/studio-home/discover6.jpg',
    hoverImage: '/assets/studio/studio-home/discover6.jpg',
    alt: 'Discover 6',
  },
];

export const STUDIO_HOME_AI_TOOLS = [
  {
    title: 'Create Variants',
    description:
      'Generate new, similar images based on your uploaded image, offering alternative and diverse options.',
    image: '/assets/studio/studio-home/1.png',
    hoverImage: '/assets/studio/studio-home-hover/1.png',
    alt: 'Create Variants',
  },
  {
    title: 'Upscale',
    description:
      'Enhance the quality of your images with our AI-powered upscaling tool.',
    image: '/assets/studio/studio-home/2.png',
    hoverImage: '/assets/studio/studio-home-hover/2.png',
    alt: 'Upscale',
  },
  {
    title: 'Remove Background',
    description:
      'Remove image backgrounds for flexible use, whether created on our platform or uploaded.',
    image: '/assets/studio/studio-home/3.png',
    hoverImage: '/assets/studio/studio-home-hover/3.png',
    alt: 'Remove Background',
  },
  {
    title: 'Zoom In',
    description:
      'Generate zoomed versions of your images to capture and enhance intricate details with precision.',
    image: '/assets/studio/studio-home/4.png',
    hoverImage: '/assets/studio/studio-home-hover/4.png',
    alt: 'Zoom In',
  },
  {
    title: 'Enhance',
    description:
      'Enhance the quality of your images with our AI-powered enhancement tool.',
    image: '/assets/studio/studio-home/5.png',
    hoverImage: '/assets/studio/studio-home-hover/5.png',
    alt: 'Enhance',
  },
  {
    title: 'Blend Background',
    description: 'Seamlessly Change and Customize Your Image Backdrops',
    image: '/assets/studio/studio-home/6.png',
    hoverImage: '/assets/studio/studio-home-hover/6.png',
    alt: 'Blend Background',
  },
  {
    title: 'Inpainting',
    description:
      'Effortlessly modify specific parts of your image based on color tones with our advanced AI tool.',
    image: '/assets/studio/studio-home/7.png',
    hoverImage: '/assets/studio/studio-home-hover/7.png',
    alt: 'Inpainting',
  },
  {
    title: 'Tile',
    description:
      'Create a tiled version of your pattern for seamless continuity and a larger visual display.',
    image: '/assets/studio/studio-home/8.png',
    hoverImage: '/assets/studio/studio-home-hover/8.png',
    alt: 'Generate Tile',
  },
  {
    title: 'Design to Flat Sketch',
    description:
      'Effortlessly convert your design into a flat, two-dimensional sketch.',
    image: '/assets/studio/studio-home/9.png',
    hoverImage: '/assets/studio/studio-home-hover/9.png',
    alt: 'Design to Flat Sketch',
  },
  {
    title: 'Pattern Transfer',
    description:
      'Transfer your pattern to a new image, seamlessly integrating the design into the new context.',
    image: '/assets/studio/studio-home/10.png',
    hoverImage: '/assets/studio/studio-home-hover/10.png',
    alt: 'Pattern Transfer',
  },
  {
    title: 'Color Tone',
    description:
      'Easily add Pantone color tones to your images and modify specific parts with precise color adjustments.',
    image: '/assets/studio/studio-home-hover/11.png',
    hoverImage: '/assets/studio/studio-home/11.png',
    alt: 'Color Tone',
  },
];
