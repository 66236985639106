import React, { useEffect, useRef, useState } from 'react';
import { RgbaColorPicker } from 'react-colorful';
import Highlighter from 'react-highlight-words';
import { RxCross1 } from 'react-icons/rx';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { Check, Pencil, Trash2, X } from 'lucide-react';

import { GET_SIMILAR_TONES } from '@/api/explore/queries';
import { Button } from '@/components/Button';
import { FormInput, FormLabel } from '@/components/FormInput';
import { Heading } from '@/components/Heading';
import {
  ButtonSelect,
  DropdownSelect,
  DropdownSelectItem,
} from '@/components/Select/index';
import { SelectDropdown } from '@/components/SelectDropdown';
import { Sidebar } from '@/components/Sidebar';
import { Spacing } from '@/components/Spacing';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import {
  FEATURE_FLAGS,
  POSTHOG_FEATURE_FLAGS,
  useFeatureFlag,
} from '@/hooks/useFeatureFlag';
import { useToast } from '@/hooks/useToast';

function FiltersHeading() {
  const {
    isExploreMobile,
    setIsFiltersOpen,
    setShowFavoritesModal,
    showFavoritesModal,
    refetchFavoriteFilters,
    favoriteFiltersLoading,
    activeTab,
  } = useExploreContext();

  return (
    <div
      className={cn(
        'flex justify-between',
        isExploreMobile
          ? 'sticky top-0 bg-white py-2 items-start'
          : 'mb-4 items-end',
      )}
    >
      <Heading
        size={isExploreMobile ? 'base' : 'lg'}
        className={cn(!isExploreMobile && 'font-bold')}
      >
        Filter
      </Heading>

      <div className="flex h-full flex-col items-center gap-4">
        {isExploreMobile && (
          <Button
            variant={'ghost'}
            size={'xs'}
            className={'mb-2 p-0'}
            onClick={() => setIsFiltersOpen(false)}
          >
            <RxCross1 size={28} />
          </Button>
        )}

        <Button
          variant={'link'}
          size={'xs'}
          className={'mb-2 p-0 text-sm font-medium text-[#C29B77]'}
          onClick={() => {
            if (showFavoritesModal) {
              setShowFavoritesModal(false);
            }
            if (favoriteFiltersLoading || !activeTab) return;

            refetchFavoriteFilters({
              section:
                activeTab === 'fashion-weeks' ? 'FASHION_WEEK' : 'SOCIAL_MEDIA',
            });
            setShowFavoritesModal(true);
          }}
        >
          Favorites
        </Button>
      </div>
    </div>
  );
}

export function ExploreFashionWeekSearch() {
  const {
    fashionWeekFilters,
    handleFashionWeekFiltersOnChange,
    loadFashionWeekSearchOptions,
  } = useExploreContext();

  const searchFilter = fashionWeekFilters?.search;

  const debouncedLoadOptions = debounce(loadFashionWeekSearchOptions, 1200);

  return (
    <>
      <Heading size={'xl'}>Search</Heading>
      <Spacing size={1.125} />
      <FormInput>
        <SelectDropdown
          placeholder={searchFilter?.placeholder}
          isSearchable={searchFilter?.isSearchable}
          options={searchFilter?.options}
          multiselect={false}
          isClearable={searchFilter?.isClearable}
          value={searchFilter?.value}
          key={'search'}
          onChange={(e) => handleFashionWeekFiltersOnChange(e, 'search')}
          isAsync={true}
          isCreatable={true}
          formatCreateLabel={(inputValue) => `${inputValue}`}
          cacheOptions={false}
          loadOptions={debouncedLoadOptions}
          defaultOptions
          allowCreateWhileLoading={true}
          createOptionPosition={'first'}
          noOptionsMessage={() => 'No results found'}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option: ({ selectProps, innerProps, innerRef, data, ...props }) => {
              return (
                <div
                  ref={innerRef}
                  {...innerProps}
                  className={props.getClassNames(props.type, props)}
                >
                  <Highlighter
                    highlightClassName="bg-transparent"
                    unhighlightClassName="font-semibold"
                    searchWords={[selectProps.inputValue]}
                    autoEscape={true}
                    textToHighlight={data.label}
                  />
                </div>
              );
            },
          }}
        />
      </FormInput>
    </>
  );
}
/* eslint-disable-next-line no-unused-vars */
function ToneSelect({
  filter,
  filterKey,
  onChange,
  currentValue,
  isClearable = false,
}) {
  const [selectedTone, setSelectedTone] = useState({
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  });
  const [showTextSearch, setShowTextSearch] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  const { isExploreMobile } = useExploreContext();

  const { data, loading } = useQuery(GET_SIMILAR_TONES, {
    variables: {
      tone: [selectedTone?.r, selectedTone?.g, selectedTone?.b],
      textSearch,
    },
    skip: !selectedTone && !textSearch,
  });

  const triggerRef = useRef(null);

  return (
    <AccordionItem value={'tone'}>
      <FormLabel isMobile={isExploreMobile}>{filter?.label}</FormLabel>
      <AccordionTrigger
        ref={triggerRef}
        className={cn(
          'flex items-center mt-2 rounded-md border-[#1A1A1A] bg-white px-4 py-2 text-[.875rem] leading-5 outline-none font-normal border',
          filter.value ? 'text-charcoal capitalize' : 'text-[#A1A1A1]',
          isClearable && filter.value ? '[&>svg]:hidden' : '',
        )}
      >
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-2">
            {filter?.value?.hex && (
              <div
                className="h-4 w-4 rounded-full"
                style={{
                  background: filter?.value?.hex,
                }}
              />
            )}
            <span
              className={cn(
                'line-clamp-1 max-w-fit text-left capitalize leading-5',
                isExploreMobile && 'text-base leading-7',
              )}
            >
              {filter?.value?.label || filter?.placeholder}
            </span>
          </div>
          {filter.value && isClearable && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onChange(null, filterKey);
              }}
              className="ml-2 text-black"
            >
              <RxCross1 size={12} />
            </button>
          )}
        </div>
      </AccordionTrigger>
      <AccordionContent className="mt-2 flex flex-col items-center space-y-2">
        <div
          className={cn(
            'tone-picker w-full gap-4 px-2',
            showTextSearch ? 'flex-col' : 'flex',
          )}
        >
          {showTextSearch ? (
            <div className="mb-2 flex w-full items-center gap-2 rounded border border-charcoal p-2">
              <input
                className="w-full text-[.875rem] leading-5 outline-none"
                value={textSearch}
                onChange={(e) => setTextSearch(e.target.value)}
                placeholder="Color, Pantone Code or Pantone Name"
              />
            </div>
          ) : (
            <RgbaColorPicker
              onChange={(color) => setSelectedTone(color)}
              value={selectedTone}
            />
          )}

          <div className="flex shrink-0 flex-col">
            {data && (
              <h1 className="ml-2 text-sm font-bold leading-5">
                Select A Pantone Color
              </h1>
            )}

            {loading && (
              <div className="space-y-2">
                {Array.from({ length: 3 }).map((_, i) => (
                  <div
                    key={i}
                    className="flex animate-pulse items-center gap-2 rounded border bg-gray-100 p-2"
                  >
                    <div className="h-4 w-4 rounded-full " />
                    <span className="text-sm leading-5 text-transparent">
                      Pantone TCX 00-0000
                    </span>
                  </div>
                ))}
              </div>
            )}

            {data?.exploreSimilarTones.map((tone) => (
              <div
                key={tone.code}
                className={cn(
                  'flex cursor-pointer items-center gap-2 p-2 border rounded hover:border-charcoal group transition-colors',
                  tone.code === currentValue?.code
                    ? 'border-charcoal'
                    : 'border-transparent',
                )}
                onClick={() => {
                  onChange(
                    {
                      ...tone,
                      label: `${tone.name.replace(/-/g, ' ')} - ${tone.code}`,
                    },
                    filterKey,
                  );
                  triggerRef?.current?.click();
                }}
              >
                <div
                  className="h-4 w-4 rounded-full"
                  style={{
                    background: tone.hex,
                  }}
                />
                <span
                  className={cn(
                    'text-sm leading-5 group-hover:text-charcoal transition-colors',
                    tone.code === currentValue?.code
                      ? 'text-charcoal'
                      : 'text-[#A1A1A1]',
                  )}
                >
                  Pantone TCX {tone.code}
                </span>
              </div>
            ))}
          </div>
        </div>

        <span
          className="cursor-pointer text-xs text-primary hover:underline"
          onClick={() => setShowTextSearch(!showTextSearch)}
        >
          {showTextSearch
            ? '... or pick a color'
            : '... or search by color name'}
        </span>
      </AccordionContent>
    </AccordionItem>
  );
}

export function ExploreSocialMediaFilters() {
  const { isEnabled: exploreAdvanced } = useFeatureFlag(
    FEATURE_FLAGS.EXPLORE_ADVANCED,
  );

  const { isEnabled: isTierInfluencersEnabled } = useFeatureFlag(
    POSTHOG_FEATURE_FLAGS.TIER_INFLUENCERS,
    'posthog',
  );

  const { isEnabled: isColorToneFilterEnabled } = useFeatureFlag(
    POSTHOG_FEATURE_FLAGS.EXPLORE_SOCIAL_MEDIA_COLOR_TONE_FILTER,
    'posthog',
  );

  const {
    socialMediaFilters,
    handleSocialMediaFiltersOnChange,
    isExploreMobile,
    setIsGenderTouched,
    marketsData,
  } = useExploreContext();

  if (!exploreAdvanced) {
    delete socialMediaFilters.personCount;
    delete socialMediaFilters.personPosition;
  }

  // Check if any selected market has influencerSearch feature enabled
  const showInfluencerFilter = React.useMemo(() => {
    if (!socialMediaFilters.market.value?.length) return false;

    return socialMediaFilters.market.value.some((selectedMarket) => {
      const market = marketsData?.markets?.find(
        (m) => m.id === selectedMarket.marketId,
      );
      return market?.features?.influencerSearch === true;
    });
  }, [socialMediaFilters.market.value, marketsData?.markets]);

  // Create a filtered version of filters that conditionally includes influencer
  const filteredSocialMediaFilters = React.useMemo(() => {
    const result = {};
    Object.entries(socialMediaFilters).forEach(([key, value]) => {
      if (key !== 'influencer' || showInfluencerFilter) {
        result[key] = value;
      }
    });
    return result;
  }, [socialMediaFilters, showInfluencerFilter]);

  // const selectedTones = socialMediaFilters.color?.value || [];
  return (
    <div
      className={cn(
        'flex flex-col',
        isExploreMobile
          ? 'h-[calc(100vh-8rem)] overflow-auto no-scrollbar'
          : 'h-max',
      )}
    >
      {!isExploreMobile && <Spacing size={1.25} />}
      <FiltersHeading />

      <div className={cn('flex flex-col', isExploreMobile && 'px-1')}>
        <DropdownSelect collapsible className="space-y-4">
          {Object.entries(filteredSocialMediaFilters).map(
            ([filterKey, filter], i) => {
              // Calculate total length including nested arrays
              const optionsCount =
                filter.options?.reduce((total, option) => {
                  if (Array.isArray(option.options)) {
                    return total + option.options.length;
                  }
                  return total + 1;
                }, 0) || 0;

              const labelWithCount =
                import.meta.env.VITE_APP_ENV === 'development'
                  ? `${filter.label} (${optionsCount})`
                  : filter.label;
              const filterWithCount = { ...filter, label: labelWithCount };

              if (filterKey === 'tier' && !isTierInfluencersEnabled) {
                return null;
              }

              /* if (filterKey === 'attribute.custom') {
                console.log(true);
                return (
                  <DropdownSelectItem
                    key={i}
                    filter={filterWithCount}
                    filterKey={filterKey}
                    onChange={handleSocialMediaFiltersOnChange}
                    isClearable={true}
                    variant="collapsible"
                    isExcludable={true}
                  />
                );
              } */
              if (
                filterKey !== 'search' &&
                filterKey !== 'audience' &&
                filterKey !== 'tone' &&
                filterKey !== 'personCount' &&
                filterKey !== 'personPosition' &&
                filterKey !== 'color'
              ) {
                const additionalProps =
                  filterKey === 'market'
                    ? { isLoading: !filter.options?.length }
                    : {};

                return filter.button ? (
                  <ButtonSelect
                    key={i}
                    filter={filterWithCount}
                    filterKey={filterKey}
                    onChange={(value, key) => {
                      handleSocialMediaFiltersOnChange(value, key);
                      if (key === 'gender') {
                        setIsGenderTouched(true);
                      }
                    }}
                    currentValue={filter?.value?.value}
                  />
                ) : (
                  <DropdownSelectItem
                    key={i}
                    filter={filterWithCount}
                    filterKey={filterKey}
                    onChange={(value, key, hardSet, isExclude) => {
                      handleSocialMediaFiltersOnChange(
                        value,
                        key,
                        hardSet,
                        isExclude,
                      );
                      if (key === 'gender') {
                        setIsGenderTouched(true);
                      }
                    }}
                    isClearable={true}
                    variant="collapsible"
                    isExcludable={filter.isExcludable}
                    {...additionalProps}
                  />
                );
              }

              return null;
            },
          )}

          {isColorToneFilterEnabled && (
            <ToneSelect
              filter={socialMediaFilters.tone}
              filterKey={'tone'}
              onChange={handleSocialMediaFiltersOnChange}
              currentValue={socialMediaFilters.tone?.value}
              isClearable={true}
            />
          )}
          {exploreAdvanced &&
            false && ( // TODO: REMOVE THAT AFTER 01-05-2025
              <AccordionItem value="explore-advanced">
                <AccordionTrigger className="pb-0">
                  <FormLabel isMobile={isExploreMobile}>
                    Advanced Filters
                  </FormLabel>
                </AccordionTrigger>
                <AccordionContent>
                  <Accordion className="space-y-4" collapsible>
                    <DropdownSelect
                      filter={socialMediaFilters.personCount}
                      filterKey={'personCount'}
                      onChange={handleSocialMediaFiltersOnChange}
                      closeOnChange={false}
                    />
                    <DropdownSelect
                      filter={socialMediaFilters.personPosition}
                      filterKey={'personPosition'}
                      onChange={handleSocialMediaFiltersOnChange}
                      closeOnChange={false}
                    />
                  </Accordion>
                </AccordionContent>
              </AccordionItem>
            )}
        </DropdownSelect>
      </div>
    </div>
  );
}

export function ExploreFashionWeekFilters() {
  const {
    fashionWeekFilters,
    handleFashionWeekFiltersOnChange,
    isExploreMobile,
  } = useExploreContext();

  return (
    <div
      className={cn(
        'flex h-max flex-col overflow-y-auto',
        isExploreMobile && 'h-full',
      )}
    >
      {!isExploreMobile && <Spacing size={1.25} />}
      <FiltersHeading />
      <div className="space-y-4">
        <DropdownSelect collapsible className="space-y-4">
          {Object.entries(fashionWeekFilters).map(
            ([filterKey, filter], i) =>
              filterKey !== 'search' &&
              filterKey !== 'type' && (
                <DropdownSelectItem
                  key={i}
                  filter={filter}
                  filterKey={filterKey}
                  onChange={handleFashionWeekFiltersOnChange}
                  isClearable={true}
                  variant="collapsible"
                  isExcludable={filter.isExcludable}
                />
              ),
          )}
        </DropdownSelect>
      </div>
    </div>
  );
}

export function ExploreFilters({ className }) {
  const {
    activeTab,
    // fashionWeekFilters,
    socialMediaFilters,
    triggerRefetch,
    isFiltersOpen,
    isExploreMobile,
    setIsFiltersOpen,
    isGenderTouched,
    clearAll,
    showFavoritesModal,
    setShowFavoritesModal,
    favoriteFilters,
    handleDeleteFavorite,
    handleUpdateFavorite,
    applyFavoriteFilter,
    setIsFavorite,
    setSelectedFavoriteFilter,
  } = useExploreContext();

  // Add state for edit mode
  const [editingFilter, setEditingFilter] = useState(null);
  const [editValue, setEditValue] = useState('');
  // Düzenleme input'u için ref ekliyoruz
  const editInputRef = useRef(null);

  // Add state for delete confirmation
  const [deleteConfirmId, setDeleteConfirmId] = useState(null);

  // Dışarı tıklama olayını dinlemek için useEffect ekliyoruz
  useEffect(() => {
    if (editingFilter !== null) {
      const handleOutsideClick = (event) => {
        if (
          editInputRef.current &&
          !editInputRef.current.contains(event.target)
        ) {
          setEditingFilter(null);
          setEditValue('');
        }
      };

      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  }, [editingFilter]);

  // Handle edit label save
  const handleSaveEdit = (filterId) => {
    handleUpdateFavorite(filterId, editValue)
      .then(() => {
        setEditingFilter(null);
        setEditValue('');
        toast.createToast({
          message: 'Filter label updated successfully.',
          type: 'success',
          showCloseButton: true,
          position: 'top-right',
        });
      })
      .catch((error) => {
        toast.createToast({
          message: error.message,
          type: 'error',
          showCloseButton: true,
          position: 'top-right',
        });
      });
  };
  const filterAreaRef = useRef(null);

  const setFilterAreaRef = (ref) => {
    filterAreaRef.current = ref.current;
  };

  const filters = {
    'fashion-weeks': <ExploreFashionWeekFilters />,
    'social-media': <ExploreSocialMediaFilters />,
  };

  const fashionWeekFormSubmitConditions = () => {
    // TODO: Implement required conditions for fashion week form submit
    return true;
  };

  const socialMediaFormSubmitConditions = () => {
    const hasNonGenderPlatformFilter = Object.entries(socialMediaFilters).some(
      ([key, filter]) =>
        key !== 'gender' &&
        key !== 'platform' &&
        filter?.value &&
        (Array.isArray(filter.value) ? filter.value.length > 0 : true),
    );

    const hasGenderFilter =
      Boolean(socialMediaFilters.gender?.value) && isGenderTouched;

    return hasNonGenderPlatformFilter || hasGenderFilter;
  };

  const isSubmitButtonActive =
    activeTab === 'fashion-weeks'
      ? fashionWeekFormSubmitConditions()
      : socialMediaFormSubmitConditions();

  const toast = useToast();

  return (
    <>
      <Sidebar
        className={cn(
          'flex flex-col gap-y-6 pt-2 no-scrollbar',
          // isExploreMobile && 'h-full',
        )}
        variant={isExploreMobile ? 'mobile' : 'primary'}
        style={{
          display: !isExploreMobile
            ? 'block'
            : isFiltersOpen
              ? 'block'
              : 'none',
          transition: 'display 0.3s ease-in-out',
        }}
        setSideBarRef={setFilterAreaRef}
      >
        {filters[activeTab]}
        {!isExploreMobile && (
          <>
            <Button
              className="mt-6 w-full p-2 font-bold text-primaryLight disabled:bg-black/60"
              disabled={!isSubmitButtonActive}
              onClick={() => triggerRefetch()}
            >
              Apply
            </Button>
            <Button
              variant={'link'}
              size={'xs'}
              className={'mt-4 w-full text-center font-semibold text-[#C29B77]'}
              onClick={() => clearAll()}
            >
              Clear All
            </Button>
          </>
        )}
        {isExploreMobile && (
          <div className="fixed inset-x-0 bottom-0 z-50 mx-4 bg-white p-4 pb-8">
            <Button
              className={cn(
                'w-full p-1 font-bold text-primaryLight disabled:bg-black/60',
                isExploreMobile && 'text-lg leading-9',
              )}
              disabled={!isSubmitButtonActive}
              onClick={() => {
                if (isFiltersOpen) {
                  setIsFiltersOpen(false);
                }
                triggerRefetch();
              }}
            >
              Apply
            </Button>
            <Button
              variant={'link'}
              size={'xs'}
              className={'mt-2 w-full text-center font-semibold text-[#C29B77]'}
              onClick={() => {
                clearAll();
                if (isFiltersOpen) {
                  setIsFiltersOpen(false);
                }
              }}
            >
              Clear All
            </Button>
          </div>
        )}
        {
          <Dialog
            open={showFavoritesModal}
            onOpenChange={setShowFavoritesModal}
            modal={false}
            className=""
          >
            <DialogContent
              className="flex h-full min-h-full w-1/4 flex-col overflow-hidden rounded-none border border-l-0 bg-white p-0"
              style={{
                position: 'fixed',
                left: filterAreaRef.current
                  ? `${filterAreaRef.current.offsetWidth}px`
                  : 'auto',
                top: filterAreaRef.current
                  ? `${filterAreaRef.current.offsetTop}px`
                  : 'auto',
                height: '100%',
                borderRadius: '0px',
                zIndex: 999,
                margin: '0',
                transform: 'none',
              }}
            >
              <div className="sticky top-0 z-10 flex items-center justify-between border-b bg-white px-6 py-4">
                <DialogTitle className="text-lg font-medium text-primary">
                  Favorite Filters
                </DialogTitle>
                <button
                  onClick={() => setShowFavoritesModal(false)}
                  className="rounded-full p-1 hover:bg-gray-100"
                >
                  <RxCross1 size={18} />
                </button>
              </div>

              <div className="flex-1 overflow-y-auto">
                <div className="m-3 rounded-md border border-backgroundBorder">
                  <div className="sticky top-0 z-10 flex w-full items-center bg-gray-50 px-4 py-3">
                    <div className="flex-1 text-sm font-medium">Name</div>
                    <div className="w-28 text-center text-sm font-medium">
                      Added On
                    </div>
                    <div className="w-10 text-right"></div>
                  </div>

                  <div className="max-h-full overflow-y-auto">
                    {favoriteFilters?.favoriteFilters?.map((filter) => (
                      <div
                        key={filter.id}
                        className="group relative flex cursor-pointer items-center border-t border-backgroundBorder px-4 py-3 hover:bg-[#F7F7F7]"
                      >
                        <div className="flex flex-1 items-center gap-2 text-sm font-medium">
                          {editingFilter === filter.id ? (
                            <div className="flex flex-1 items-center gap-2">
                              <input
                                ref={editInputRef}
                                type="text"
                                className="w-full border-b border-black bg-transparent pb-[2px] text-black outline-none"
                                value={editValue}
                                onChange={(e) => setEditValue(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();

                                    if (editValue.trim() === '') {
                                      toast.createToast({
                                        message:
                                          'Filter label cannot be empty.',
                                        type: 'error',
                                        showCloseButton: true,
                                        position: 'top-right',
                                      });
                                      setEditValue(filter.label);
                                      setEditingFilter(null);
                                      return;
                                    } else if (
                                      editValue.trim() === filter.label
                                    ) {
                                      setEditingFilter(null);
                                      return;
                                    }
                                    handleSaveEdit(filter.id);
                                  }
                                }}
                                autoFocus
                              />
                            </div>
                          ) : (
                            <>
                              <div
                                className="relative flex flex-1 items-start"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowFavoritesModal(false);
                                  clearAll().then(() => {
                                    applyFavoriteFilter(filter).then(
                                      async () => {
                                        await triggerRefetch();
                                        setIsFavorite(true);
                                        setSelectedFavoriteFilter(filter);
                                        toast.createToast({
                                          message:
                                            'Filter applied successfully.',
                                          type: 'success',
                                          showCloseButton: true,
                                          position: 'top-right',
                                        });
                                      },
                                    );
                                  });
                                }}
                              >
                                <span className="max-w-[230px] break-words pb-[2px] group-hover:border-b group-hover:border-b-[#C29B77] group-hover:text-[#C29B77]">
                                  {filter.label}
                                </span>
                                <div className="ml-1 shrink-0">
                                  <Pencil
                                    size={16}
                                    className="hidden cursor-pointer text-[#C29B77] group-hover:block"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setEditingFilter(filter.id);
                                      setEditValue(filter.label);
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {deleteConfirmId !== filter.id && (
                          <div className="w-28 text-center text-sm text-gray-500">
                            {dayjs(filter.createdAt).format('DD.MM.YYYY')}
                          </div>
                        )}
                        <div className="flex w-10 justify-end">
                          {deleteConfirmId === filter.id ? (
                            <div className=" flex items-center gap-1 text-xs">
                              <span className="mr-1 w-[160px] text-xs font-bold leading-tight">
                                Are you sure you want to delete this saved
                                filter?
                              </span>
                              <button
                                className="rounded-md bg-[#D6F4EA] p-1"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteFavorite(filter.id).then(() => {
                                    setDeleteConfirmId(null);
                                    toast.createToast({
                                      message: 'Filter deleted successfully.',
                                      type: 'success',
                                      showCloseButton: true,
                                      position: 'top-right',
                                    });
                                  });
                                }}
                              >
                                <Check
                                  size={16}
                                  strokeWidth={3}
                                  stroke="#1CA974"
                                />
                              </button>
                              <button
                                className="rounded-md bg-[#FDE9E7] p-1"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteConfirmId(null);
                                }}
                              >
                                <X size={16} strokeWidth={3} stroke="#EA3323" />
                              </button>
                            </div>
                          ) : (
                            <button className="rounded-md p-1 hover:bg-gray-100">
                              <Trash2
                                size={16}
                                className="text-gray-500"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteConfirmId(filter.id);
                                }}
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        }
      </Sidebar>
    </>
  );
}
