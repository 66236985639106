import { useEffect, useMemo, useRef, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useQuery } from '@apollo/client';
import { startCase } from 'lodash';
import {
  ChevronLeft,
  ChevronRight,
  Folders,
  HelpCircle,
  History,
  Minus,
  NotebookPen,
  Plus,
  Search,
  Shapes,
  SquareAsterisk,
  SwatchBook,
} from 'lucide-react';

import {
  COLLECTIONS,
  GET_COLLECTION_ITEMS_MERGED,
} from '@/api/collections/queries';
import { useDialogContext } from '@/components/ui/dialog';
import { Slider } from '@/components/ui/slider';
import { cn } from '@/helpers/utils.js';
import { CreateNewCollection } from '@/layouts/common/index';

import { CategoryCard, GhostCard, SidebarHeader } from './SidebarMenu';
import { StudioGeneratedImage } from './StudioHistorySidebar';

export const AsideBarButton = ({ icon: Icon, text, onClick, active }) => {
  return (
    <div
      className={cn(
        'flex flex-col cursor-pointer items-center justify-center text-night text-sm leading-3 font-medium bg-transparent focus-visible:none focus:ring-0 rounded hover:bg-primary hover:text-white focus:border-primary focus-visible:outline-none gap-y-2 transition duration-300 ease-in-out px-2 py-1',
        active && 'bg-primary text-white',
      )}
      onClick={onClick}
    >
      <Icon size={20} />
      {text}
    </div>
  );
};

export const Collections = ({ onClose }) => {
  const [collectionSearchValue, setCollectionSearchValue] = useState('');
  const [selectedCollection, setSelectedCollection] = useState(
    JSON.parse(localStorage.getItem('selectedCollection')),
  );
  const [isCollectionChanging, setIsCollectionChanging] = useState(false);
  const [columnCount, setColumnCount] = useState(3);
  const collectionsRef = useRef(null);

  const { data, loading, error } = useQuery(COLLECTIONS, {
    variables: {
      cursor: null,
      includeOrganizationViewable: true,
    },
  });

  const {
    data: collectionItemsData,
    loading: collectionItemsLoading,
    refetch: refetchCollectionItems,
  } = useQuery(GET_COLLECTION_ITEMS_MERGED, {
    variables: {
      collection: selectedCollection?.id,
      cursor: null,
    },
    skip: !selectedCollection,
  });

  const { collectionItemsMerged } = collectionItemsData || {};

  const handleSliderChange = (value) => {
    setColumnCount(Math.max(1, Math.min(5, 6 - value[0])));
  };

  const sliderValue = useMemo(() => [6 - columnCount], [columnCount]);

  const { isDialogOpen } = useDialogContext();

  useEffect(() => {
    if (selectedCollection) {
      setIsCollectionChanging(true);
      refetchCollectionItems({
        collection: selectedCollection.id,
        cursor: null,
      }).finally(() => setIsCollectionChanging(false));
    }
  }, [selectedCollection, refetchCollectionItems]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const asideBar = document.getElementById('aside-bar');
      if (
        collectionsRef.current &&
        !collectionsRef.current.contains(event.target) &&
        !asideBar.contains(event.target) &&
        !isDialogOpen
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, isDialogOpen]);

  if (error) return <p>Error: {error.message}</p>;

  const collectionList =
    collectionSearchValue.length > 0
      ? data?.collections?.data.filter((c) =>
          c.name.toLowerCase().includes(collectionSearchValue.toLowerCase()),
        )
      : data?.collections?.data;

  const handleCollectionClick = (collection) => {
    setSelectedCollection(collection);
    localStorage.setItem('selectedCollection', JSON.stringify(collection));
  };

  const handleBackClick = () => {
    setSelectedCollection(null);
    localStorage.removeItem('selectedCollection');
  };

  return (
    <div
      ref={collectionsRef}
      style={{
        boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.05)',
        transition: 'right 2s ease',
      }}
      className="absolute right-[7rem] top-0 flex h-full w-[22.5rem] items-start justify-start border-x border-secondary/50"
    >
      <div className="flex h-full w-full flex-col items-start bg-white py-6">
        <div className="flex h-full w-full flex-col gap-4">
          <div className="flex flex-col px-2 lg:px-6">
            <SidebarHeader
              text={selectedCollection ? 'Back to List' : 'Collections'}
              variant="back"
              icon={selectedCollection ? ChevronLeft : ChevronRight}
              onClick={selectedCollection ? handleBackClick : onClose}
            />
          </div>
          {!selectedCollection && (
            <div className="flex px-2 lg:px-6">
              <div className="focus-visible:none flex w-full flex-row items-start justify-between border-b border-night py-1 text-night focus:border-primary focus:bg-[#FFFDFD] focus:ring-0 focus-visible:outline-none">
                <input
                  type="text"
                  placeholder="Search a collection..."
                  className="w-full bg-transparent text-sm focus:outline-none"
                  value={collectionSearchValue}
                  onChange={(e) => setCollectionSearchValue(e.target.value)}
                />
                <Search size={18} />
              </div>
            </div>
          )}
          {selectedCollection && (
            <div className="flex w-full flex-row items-center justify-between px-2 lg:px-6">
              <h3 className="text-xs font-bold text-secondary">
                {startCase(selectedCollection.name)} Collection
              </h3>
              <div className="flex w-1/2 items-center justify-center gap-x-2">
                <div className="flex cursor-pointer items-center justify-center">
                  <Minus
                    size={18}
                    strokeWidth={1.5}
                    onClick={() => handleSliderChange([sliderValue[0] - 1])}
                  />
                </div>
                <Slider
                  value={sliderValue}
                  min={1}
                  max={5}
                  step={1}
                  onValueChange={handleSliderChange}
                />
                <div className="flex cursor-pointer items-center justify-center">
                  <Plus
                    size={18}
                    strokeWidth={1.5}
                    onClick={() => handleSliderChange([sliderValue[0] + 1])}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-y-4 overflow-auto px-2 lg:px-6">
            {!selectedCollection &&
              loading &&
              [...Array(5)].map((_, i) => <GhostCard key={i} />)}
            {!selectedCollection &&
              !loading &&
              data &&
              collectionList.map((collection, i) => (
                <CategoryCard
                  key={i}
                  variant="collection"
                  category={collection}
                  index={i}
                  onCollectionClick={handleCollectionClick}
                />
              ))}
            {selectedCollection &&
              (collectionItemsLoading || isCollectionChanging) && (
                <CollectionLoading />
              )}
            {selectedCollection &&
              !collectionItemsLoading &&
              !isCollectionChanging &&
              collectionItemsData && (
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: columnCount }}
                >
                  <Masonry gutter="0.5rem">
                    {collectionItemsMerged.data
                      .filter((c) => c.item?.image)
                      .map((c, index) => (
                        <StudioGeneratedImage
                          key={index}
                          image={c.item.image}
                          variant="collection"
                          studioItemId={c.item.entityId}
                          entityType={c.item.__typename}
                          payload={{}}
                        />
                      ))}
                  </Masonry>
                </ResponsiveMasonry>
              )}
          </div>
          {!selectedCollection && (
            <div className="flex w-full flex-row items-center justify-center gap-x-4 p-2 lg:px-6">
              <CreateNewCollection />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CollectionLoading = () => {
  return (
    <div className="grid grid-cols-3 gap-2">
      {Array.from({ length: 9 }).map((_, i) => (
        <div
          key={i}
          className="aspect-[3/4] animate-pulse rounded-md bg-gray-200"
        />
      ))}
    </div>
  );
};

export const AsideBar = ({ activeModal, setActiveModal }) => {
  return (
    <div
      id="aside-bar"
      className="flex h-full w-full flex-col items-start justify-between gap-6 bg-white px-2 py-6 sm:px-3 md:px-4"
    >
      <div className="flex w-full flex-col gap-4">
        <AsideBarButton
          icon={Folders}
          text="Collections"
          active={activeModal === 'collections'}
          onClick={() => setActiveModal('collections')}
        />
        <AsideBarButton
          icon={SwatchBook}
          text="Designs"
          active={activeModal === 'designs'}
          onClick={() => setActiveModal('designs')}
        />
        <AsideBarButton
          icon={SquareAsterisk}
          text="Patterns"
          active={activeModal === 'patterns'}
          onClick={() => setActiveModal('patterns')}
        />
        <AsideBarButton
          icon={NotebookPen}
          text="Sketches"
          active={activeModal === 'sketches'}
          onClick={() => setActiveModal('sketches')}
        />
        <AsideBarButton
          icon={Shapes}
          text="Graphics"
          active={activeModal === 'graphics'}
          onClick={() => setActiveModal('graphics')}
        />
        <AsideBarButton
          icon={History}
          text="History"
          active={activeModal === 'history'}
          onClick={() => setActiveModal('history')}
        />
      </div>
      <div className="flex w-full flex-col gap-4">
        <AsideBarButton
          icon={HelpCircle}
          text="Help"
          active={activeModal === 'help'}
          onClick={() => {
            setActiveModal('help');
          }}
        />
      </div>
    </div>
  );
};
