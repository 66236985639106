import { useEffect, useState } from 'react';
import { Wand } from 'lucide-react';

import { STUDIO_DISPLAY } from '@/constants/studio';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';

import { SidebarButton } from './SidebarMenu';

export const GenerateButton = ({
  variant = 'default',
  active,
  text,
  icon: Icon,
  tool,
  className,
  disabled: disabledProp,
}) => {
  const [disabled, setDisabled] = useState(false);

  const {
    createItem,
    setDisplayMode,
    positivePrompt,
    selectedArea,
    referenceImage,
    referenceSketch,
    referencePattern,
    referenceOtherImage,
    handleCreateNewStudioTask,
    selectedTone,
    studioTask,
    selectedBrushPathArray,
    setSelectedCompareImage,
    referenceGraphic,
    selectedGeneratedImage: imageIndex,
  } = useStudioContext();

  useEffect(() => {
    if (studioTask?.status === 'COMPLETED') {
      if (imageIndex === -1 && studioTask?.images?.length !== 1) {
        setDisabled(true);
      } else {
        setDisabled(disabledProp || false);
      }
    } else {
      setDisabled(true);
    }
  }, [studioTask, disabledProp, imageIndex]);

  useEffect(() => {
    const isDisabled = () => {
      switch (createItem.type) {
        case 'GENERATE_APPAREL':
          return !positivePrompt;
        case 'GENERATE_PATTERN':
          return !positivePrompt;
        case 'REALIZE_SKETCH':
          return !referenceSketch || !positivePrompt;
        case 'IMAGE_BACKGROUND_REMOVER':
          return !referenceImage;
        case 'UPSCALE_IMAGE':
          return !referenceImage;
        case 'ENHANCE_IMAGE':
          return !referenceImage;
        case 'CREATE_VARIANTS':
          return !referenceImage;
        case 'PATTERN_TILE':
          return !referencePattern;
        case 'ZOOM_IN_IMAGE':
          return (
            !referenceImage ||
            !selectedArea ||
            selectedArea?.x === 0 ||
            selectedArea?.y === 0
          );
        case 'IMAGE_CHANGE_BACKGROUND':
          return !referenceImage || !referenceOtherImage;
        case 'COLOR_TRANSFER':
          return !(referenceImage && selectedTone);
        case 'INPAINTING':
          return (
            !referenceImage ||
            !selectedBrushPathArray?.length ||
            !positivePrompt
          );
        case 'GENERATE_GRAPHIC':
          return !positivePrompt;
        case 'GENERATE_FLAT_SKETCH':
          return !referenceImage;
        case 'CREATE_PATTERN_VARIANTS':
          return !referencePattern;
        default:
          return false;
      }
    };

    if (variant !== 'tool') {
      setDisabled(isDisabled());
    }
  }, [
    createItem.type,
    positivePrompt,
    referenceImage,
    referenceSketch,
    selectedArea,
    referencePattern,
    referenceOtherImage,
    selectedTone,
    selectedBrushPathArray,
    referenceGraphic,
  ]);

  const handleGenerate = () => {
    if (!disabled) {
      handleCreateNewStudioTask(createItem.type);
      setDisplayMode(STUDIO_DISPLAY.GENERATING);
      setSelectedCompareImage('slider');
    }
  };

  const handleGenerateTool = (tool) => {
    if (!disabled) {
      setDisplayMode(STUDIO_DISPLAY.GENERATING);
      setSelectedCompareImage('slider');
      handleCreateNewStudioTask(tool);
    }
  };

  const handleToolClick = () => {
    handleGenerateTool(tool);
  };

  if (variant === 'tool') {
    return (
      <SidebarButton
        variant="tool"
        text={text}
        icon={Icon}
        className={cn(
          'flex cursor-pointer items-center justify-between gap-2 rounded border border-secondary/10 bg-secondary/5 px-2.5 py-1.5 text-center text-sm font-bold text-black hover:border-primary hover:bg-white hover:text-primary transition duration-300 ease-in-out shadow-sm h-full',
          active && 'border-primary bg-white text-primary',
          className,
        )}
        disabled={disabled}
        onClick={handleToolClick}
      />
    );
  }

  if (variant === 'apply') {
    return (
      <SidebarButton
        text="Apply"
        disabled={disabled}
        onClick={handleGenerate}
      />
    );
  }

  return (
    <SidebarButton
      variant="generate"
      text="Generate"
      disabled={disabled || createItem.isUsingApplyButton}
      icon={Wand}
      onClick={handleGenerate}
    />
  );
};
