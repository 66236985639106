import { useEffect, useMemo, useState } from 'react';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import * as Tabs from '@radix-ui/react-tabs';
import { debounce } from 'lodash';

import {
  EXPLORE_FASHION_ITEMS,
  EXPLORE_FASHION_ITEMS_WITH_TEXT_SEARCH,
} from '@/api/explore/queries';
import { FlexRow } from '@/components';
import { LikeButton } from '@/components/LikeButton';
import {
  returnFashionItemFilterOutFields,
  returnFashionItemFilters,
  useExploreContext,
} from '@/contexts/ExploreContext';
import { FashionWeeksProvider } from '@/contexts/FashionWeeksContext';
import { cn, formatLabel } from '@/helpers/utils';
import { SaveToCollection } from '@/layouts/common/index';

import {
  ExploreLoadingScreen,
  LazyLoadTrigger,
  NoSuchResultsWereFound,
} from './components/utils';
import { Card, More } from './components';

function FashionItems() {
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [activeSeason, setActiveSeason] = useState(null);

  const {
    fashionWeekFilters: filters,
    activeTab,
    shouldRefetch,
    setShouldRefetch,
    isGridViewTransitioning,
  } = useExploreContext();

  const checkEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const [
    fetchFashionItems,
    { data, networkStatus, loading, fetchMore, error, refetch },
  ] = useLazyQuery(
    filters.search?.value?.value
      ? EXPLORE_FASHION_ITEMS_WITH_TEXT_SEARCH
      : EXPLORE_FASHION_ITEMS,
    {
      variables: {
        // filters: returnFashionItemFilters(filters),
        ...(!checkEmptyObject(returnFashionItemFilterOutFields(filters)) && {
          filterOutFields: returnFashionItemFilterOutFields(filters),
        }),
        search: filters.search?.value?.value,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (newData) => {
        if (newData?.exploreFashionWeekItems?.pageInfo?.endCursor === null) {
          setFullyLoaded(true);
        } else {
          setFullyLoaded(false);
        }
        if (newData?.exploreFashionWeekItems?.groupBySeasonEdges) {
          const defaultSeason =
            newData?.exploreFashionWeekItems?.groupBySeasonEdges.find(
              ({ edges }) => edges?.length > 0,
            )?.season;

          const selectedSeason = filters?.season?.value?.value;

          const isSelectedSeasonExists = !!(
            selectedSeason &&
            newData?.exploreFashionWeekItems?.groupBySeasonEdges.find(
              ({ season }) => season === selectedSeason,
            )
          );

          if (isSelectedSeasonExists) {
            setActiveSeason(selectedSeason);
          } else {
            setActiveSeason(defaultSeason);
          }
        }
      },
    },
  );

  useEffect(() => {
    if (activeTab === 'fashion-weeks') {
      fetchFashionItems();
    }
  }, [activeTab]);

  useEffect(() => {
    if (shouldRefetch === true) {
      refetch({
        filters: returnFashionItemFilters(filters),
      });
      setShouldRefetch(false);
    }
  }, [shouldRefetch]);

  const debouncedRefetch = useMemo(() => debounce(refetch, 300), [refetch]);

  useEffect(() => {
    const searchValue = filters?.search?.value?.value;
    if (searchValue) {
      debouncedRefetch({
        filters: returnFashionItemFilters(filters),
        search: searchValue,
      });
    } else {
      refetch({
        filters: returnFashionItemFilters(filters),
      });
    }
  }, [filters?.search?.value?.value]);

  const loadingMore = networkStatus === 3;

  if (loading && !loadingMore) {
    return <ExploreLoadingScreen />;
  }

  if (error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p>{error.message}</p>
      </div>
    );
  }

  const { edges, pageInfo, groupBySeasonEdges } =
    data?.exploreFashionWeekItems || {};

  const { endCursor, hasNextPage } = pageInfo || {};

  if (
    edges?.length === 0 &&
    groupBySeasonEdges?.every(({ edges }) => edges?.length === 0)
  ) {
    return <NoSuchResultsWereFound isTextSearch={true} />;
  }

  const renderLazyLoad =
    networkStatus !== NetworkStatus.fetchMore &&
    hasNextPage &&
    fullyLoaded === false;

  if (edges?.length === 0 && groupBySeasonEdges?.length === 0) {
    return (
      <div>
        <NoSuchResultsWereFound />
      </div>
    );
  }

  if (groupBySeasonEdges?.length > 0) {
    return (
      <div className="relative flex h-full flex-col">
        {isGridViewTransitioning && (
          <div className="absolute inset-0 z-10 flex items-center justify-center bg-white/70 backdrop-blur-sm transition-opacity duration-300">
            <div className="loading h-16 w-16 animate-pulse rounded-full bg-gray-200"></div>
          </div>
        )}
        <div className="sticky top-0 z-50 bg-white pb-2">
          <Tabs.Root defaultValue="tab1">
            <Tabs.List className="flex gap-2 pr-4">
              {groupBySeasonEdges.map((season) => {
                return (
                  <Tabs.Trigger
                    key={season.season}
                    value={season.season}
                    onClick={() => setActiveSeason(season.season)}
                    className={cn(
                      'text-sm font-medium p-2 border border-secondary/20 bg-secondary/5 rounded capitalize',
                      activeSeason === season.season &&
                        'text-primary border-primary',
                    )}
                  >
                    {formatLabel(season.season)}
                  </Tabs.Trigger>
                );
              })}
            </Tabs.List>
          </Tabs.Root>
        </div>
        <div className="flex-1 overflow-auto">
          <div className="flex flex-col gap-y-4">
            <SeasonGrids
              edges={
                groupBySeasonEdges?.find(
                  ({ season }) => season === activeSeason,
                )?.edges
              }
              loadingMore={loadingMore}
              renderLazyLoad={renderLazyLoad}
              fetchMore={fetchMore}
              endCursor={endCursor}
              activeTab={activeTab}
              activeSeason={activeSeason}
              hasNextPage={hasNextPage}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="relative">
      {isGridViewTransitioning && (
        <div className="absolute inset-0 z-10 flex items-center justify-center bg-white/70 backdrop-blur-sm transition-opacity duration-300">
          <div className="loading h-16 w-16 animate-pulse rounded-full bg-gray-200"></div>
        </div>
      )}
      <SeasonGrids
        edges={edges}
        loadingMore={loadingMore}
        renderLazyLoad={renderLazyLoad}
        fetchMore={fetchMore}
        endCursor={endCursor}
        activeTab={activeTab}
        loading={loading}
        hasNextPage={hasNextPage}
      />
    </div>
  );
}

export function FashionWeekItems() {
  return (
    <FashionWeeksProvider>
      <FashionItems />
    </FashionWeeksProvider>
  );
}

const SeasonGrids = ({
  edges,
  loadingMore,
  renderLazyLoad,
  fetchMore,
  endCursor,
  activeTab,
  loading,
  hasNextPage,
}) => {
  const { gridView } = useExploreContext();

  return (
    <div
      className={cn(
        'grid gap-4 pt-1',
        gridView === '3-columns'
          ? 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3'
          : 'grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6',
      )}
    >
      {edges?.map(({ node: exploreItem }, index) => (
        <Card.Root
          data={exploreItem}
          key={'explore_' + index}
          moderationEnabled={false}
        >
          <Card.Image />

          <Card.HoverContent>
            <FlexRow justify="between" items="center" className="-mr-12">
              <Card.Tones />

              <FlexRow className="self-end" justify="between" items="center">
                <SaveToCollection
                  id={exploreItem.id}
                  type="fashionItem"
                  variant="explore"
                />

                <LikeButton
                  item={exploreItem.id}
                  isLiked={exploreItem.isLiked}
                  type="fashionItem"
                />

                <More />
              </FlexRow>
            </FlexRow>

            <Card.Footer id={exploreItem.id} activeTab={activeTab} />
          </Card.HoverContent>
        </Card.Root>
      ))}

      {hasNextPage && (
        <div className="grid h-full grid-cols-1 gap-y-2 bg-white text-transparent">
          <div className="ghost row-[span_14_/span_14] w-full"></div>
          <div className="ghost row-span-1 w-full">Load more</div>
        </div>
      )}

      {loadingMore &&
        Array.from({ length: 20 }).map((_, i) => (
          <div
            className="grid aspect-[3/5] h-full grid-cols-1 gap-y-2 bg-white text-transparent"
            key={i}
          >
            <div className="ghost row-[span_16_/span_16] w-full"></div>
            <div className="ghost row-span-1 w-full">Load more</div>
          </div>
        ))}

      {renderLazyLoad && (
        <div className="mb-8 h-16 w-full bg-transparent">
          <LazyLoadTrigger
            fetchMore={fetchMore}
            cursorNext={endCursor}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};
