import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { RESET_PASSWORD } from '@/api/authentication/mutations.js';
import { TFashion } from '@/assets/svg';
import { PasswordInput } from '@/components';
import { useToast } from '@/hooks/useToast';

import { resetPasswordOptions } from './resetPasswordOptions';

const Navbar = () => (
  <div className="flex min-h-[90px] w-full border-b border-[#A1A1A1] bg-white px-[60px]">
    <div className="flex w-[212.8px] items-center">
      <TFashion />
    </div>
  </div>
);

export const ResetPassword = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams] = useSearchParams();

  const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      toast.createToast({
        message: 'Password reset successfully',
        type: 'success',
        showCloseButton: true,
        position: 'top-right',
      });

      navigate('/login');
    },
  });

  useEffect(() => {
    const token = searchParams.get('token');
    if (!token) {
      toast.createToast({
        message: 'Invalid token',
        type: 'error',
        showCloseButton: true,
        position: 'top-right',
      });

      navigate('/login');
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(resetPasswordOptions);

  const onSubmit = (data) => {
    const { password, confirmPwd } = data;
    const token = searchParams.get('token');

    if (password !== confirmPwd) {
      toast.createToast({
        message: 'Passwords do not match',
        type: 'error',
        showCloseButton: true,
        position: 'top-right',
      });
      return;
    }

    resetPassword({
      variables: { input: { password, resetPasswordToken: token } },
    });
  };

  return (
    <div className="flex h-full w-full flex-col">
      <Navbar />
      <div className="flex w-full flex-1 flex-col items-center justify-center">
        <div className="flex flex-col items-center">
          <h3 className="mb-[16px] text-[48px] font-semibold leading-[55px]">
            Reset Password
          </h3>
          <p className="font-regular mb-[16px] text-[14px] leading-[173%] text-[#626775]">
            Enter your new password below. Make sure it’s at least 8 characters
            long.
          </p>
        </div>

        <form
          className="mb-[22px] flex h-auto w-[404px] flex-col"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-[14px]">
            <div className="flex w-full flex-col gap-[4px]">
              <p className="h-[28px] px-[16px] pb-[4px] text-[16px] font-semibold leading-[28px] ">
                Password
              </p>
              <PasswordInput
                register={register}
                placeholder="Write Your Password"
              />

              {errors.password?.message && (
                <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500 ">
                  {errors.password?.message}
                </p>
              )}
            </div>

            <div className="flex w-full flex-col gap-[4px]">
              <p className="h-[28px] px-[16px] pb-[4px] text-[16px] font-semibold leading-[28px] ">
                Confirm Password
              </p>

              <PasswordInput
                register={register}
                placeholder="Confirm Your Password"
                formFieldName="confirmPwd"
              />
              {errors.confirmPwd?.message && (
                <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500 ">
                  {errors.confirmPwd?.message}
                </p>
              )}
            </div>

            {error && (
              <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500">
                {error.message}
              </p>
            )}

            <button className="btn-primary-without-hover h-[46px]">
              {loading ? 'Submitting...' : 'Change Password'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
