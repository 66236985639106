// TODO: Make this screen as a loading screen

import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { CONFIRM_MAIL } from '@/api/authentication/mutations';
import { TFashion } from '@/assets/svg';

export function ConfirmMail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get('token');

  const [confirmMail] = useMutation(CONFIRM_MAIL, {
    onCompleted: () => {
      navigate('/login');
    },
    onError: (error) => {
      navigate(`/login?error=${error.message}`);
    },
  });

  useEffect(() => {
    if (!token) {
      return;
    }

    confirmMail({
      variables: {
        input: {
          token,
        },
      },
    });

    return () => {};
  }, []);

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="scale-150 animate-bounce">
        <TFashion />
      </div>
    </div>
  );
}
