import React from 'react';

import { useUser } from '@/api/authentication/hook';
import { Button } from '@/components/Button';
import { FormInput, FormLabel } from '@/components/FormInput';
import { Heading } from '@/components/Heading';
import { DropdownSelect, DropdownSelectItem } from '@/components/Select/index';
import { SelectDropdown } from '@/components/SelectDropdown';
import { Sidebar } from '@/components/Sidebar';
import { Spacing } from '@/components/Spacing';
import { useAppFilters } from '@/contexts/AppFiltersContext';
import { useInsightContext } from '@/contexts/InsightContext';
import { useTrendsContext } from '@/contexts/TrendsContext';

import {
  InsightTimeframeDropdownSelect,
  InsightTimeframeDropdownSelectItem,
} from './InsightTimeframeDropdown';

function FiltersHeading({ clearCategoryFilter }) {
  return (
    <div className="flex items-end justify-between">
      <Heading size={'xl'}>Filter</Heading>
      <Button
        variant={'link'}
        size={'xs'}
        className={'mb-2 p-0'}
        onClick={clearCategoryFilter}
      >
        Clear All
      </Button>
    </div>
  );
}

function SortHeading() {
  return (
    <div className="flex items-end justify-between">
      <Heading size={'xl'}>Sort</Heading>
    </div>
  );
}

export function TrendsFashionWeeksFilters() {
  const {
    filters,
    handleTrendsCategoryOnChange,
    handleSourceOnChange,
    handleDesignerOnChange,
  } = useAppFilters();

  const { source, designer, trendsCategory } = filters;

  const categoryFilterClearAll = () => {
    handleTrendsCategoryOnChange(null);
  };

  return (
    <>
      <FiltersHeading
        category={trendsCategory?.value}
        clearCategoryFilter={categoryFilterClearAll}
      />

      <Spacing size={1.125} />

      <div className="flex flex-col gap-4">
        <FormInput>
          <FormLabel>{source?.label}</FormLabel>
          <SelectDropdown
            placeholder={source?.placeholder}
            isSearchable={true}
            options={source?.options}
            multiselect={false}
            isClearable={false}
            value={source?.value}
            onChange={(e) => handleSourceOnChange(e)}
          />
        </FormInput>

        <FormInput>
          <FormLabel>{designer?.label}</FormLabel>
          <SelectDropdown
            placeholder={designer?.placeholder}
            isSearchable={true}
            options={designer?.options}
            multiselect={false}
            isClearable={false}
            value={designer?.value}
            onChange={(e) => handleDesignerOnChange(e)}
          />
        </FormInput>

        <FormInput>
          <FormLabel>{trendsCategory?.label}</FormLabel>
          <SelectDropdown
            placeholder={trendsCategory?.placeholder}
            isSearchable={true}
            options={trendsCategory?.options}
            isMulti={true}
            isClearable={true}
            value={trendsCategory?.value}
            onChange={(selectedOptions) => {
              if (!Array.isArray(selectedOptions)) {
                selectedOptions = [selectedOptions];
              }

              const selectedValues = selectedOptions.map((option) => ({
                label: option.label,
                value: String(option.value),
              }));

              handleTrendsCategoryOnChange(selectedValues);
            }}
          />
        </FormInput>
      </div>

      <Spacing size={1.125} />

      <SortHeading />

      <Spacing size={1.125} />

      <div className="flex flex-col gap-4">
        <FormInput>
          <FormLabel>Visibility</FormLabel>
          <SelectDropdown
            placeholder={'Default'}
            isSearchable={false}
            options={[]}
            multiselect={false}
            isClearable={false}
            value={null}
          />
        </FormInput>

        <FormInput>
          <FormLabel>Engagement</FormLabel>
          <SelectDropdown
            placeholder={'Descending'}
            isSearchable={false}
            options={[]}
            multiselect={false}
            isClearable={false}
            value={null}
          />
        </FormInput>
      </div>
    </>
  );
}

export function TrendsInsightsFilters() {
  const {
    filters,
    handleFiltersClearAll,
    handleChangeFilters,
    handleApplyFilters,
  } = useInsightContext();

  const { user } = useUser();
  const isKotonUser = user?.organization?.name === 'Koton';

  const { source, category, timeframe } = filters;

  const sourceOptions = isKotonUser
    ? source?.options?.filter((option) => option.label === 'London')
    : source?.options;

  return (
    <>
      <FiltersHeading
        category={category?.value}
        clearCategoryFilter={handleFiltersClearAll}
      />

      <Spacing size={1.125} />

      <div className="flex flex-col gap-4">
        <FormInput className="gap-0">
          <FormLabel>{source?.label}</FormLabel>
          <SelectDropdown
            placeholder={source?.placeholder}
            isSearchable={true}
            options={sourceOptions}
            isMulti={source?.isMulti}
            isClearable={false}
            value={source?.value}
            onChange={(e) => handleChangeFilters('source', e)}
          />
        </FormInput>

        <InsightTimeframeDropdownSelect collapsible className="space-y-4">
          <InsightTimeframeDropdownSelectItem
            key={'timeframe'}
            filter={timeframe}
            filterKey={'timeframe'}
            onChange={(e) => handleChangeFilters('timeframe', e)}
            isClearable={true}
            variant="collapsible"
            isExcludable={false}
          />
        </InsightTimeframeDropdownSelect>

        <DropdownSelect collapsible className="space-y-4">
          <DropdownSelectItem
            key={'category'}
            filter={category}
            filterKey={'category'}
            onChange={(e) => handleChangeFilters('category', e)}
            isClearable={true}
            variant="collapsible"
            isExcludable={[]}
          />
        </DropdownSelect>

        <Button
          className="mt-1"
          variant={'primary'}
          size={'sm'}
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </>
  );
}

export function TrendsSnapshotsFilters() {
  const {
    filters,
    handleSourceOnChange,
    handleTimeframeOnChange,
    handleTrendsCategoryOnChange,
    handleSnapshotSortOnChange,
    selectedTimeframe,
    selectedSnapshotSort,
  } = useAppFilters();

  const { user } = useUser();
  const isKotonUser = user?.organization?.name === 'Koton';

  const { source, trendsCategory, timeframe, snapshotSort } = filters;

  const sourceOptions = isKotonUser
    ? source?.options?.filter((option) => option.label === 'London')
    : source?.options;

  const categoryFilterClearAll = () => {
    handleTrendsCategoryOnChange(null);
    handleTimeframeOnChange(
      { label: 'Last Week', value: 'lastWeek' },
      'timeframe',
    );
  };

  return (
    <>
      <FiltersHeading
        category={trendsCategory?.value}
        clearCategoryFilter={categoryFilterClearAll}
      />

      <Spacing size={1.125} />

      <div className="flex flex-col gap-4">
        <FormInput>
          <FormLabel>{source?.label}</FormLabel>
          <SelectDropdown
            placeholder={source?.placeholder}
            isSearchable={true}
            options={sourceOptions}
            multiselect={false}
            isClearable={false}
            value={source?.value}
            variant={'primary'}
            onChange={(e) => handleSourceOnChange(e)}
          />
        </FormInput>

        {/*         <FormInput>
          <FormLabel>{platform?.label}</FormLabel>
          <SelectDropdown
            placeholder={platform?.placeholder}
            isSearchable={true}
            options={platform?.options}
            multiselect={false}
            isClearable={false}
            value={platform?.value}
            onChange={(e) => handlePlatformOnChange(e)}
          />
        </FormInput> */}

        <FormInput>
          <FormLabel>{timeframe?.label}</FormLabel>
          <SelectDropdown
            placeholder={timeframe?.placeholder}
            isSearchable={true}
            options={timeframe?.options}
            multiselect={true}
            isClearable={false}
            id={'hasIndicator'}
            value={timeframe?.options.find(
              (option) => option.value === selectedTimeframe,
            )}
            onChange={(e) => handleTimeframeOnChange(e)}
          />
        </FormInput>

        <FormInput>
          <FormLabel>{trendsCategory?.label}</FormLabel>
          <SelectDropdown
            placeholder={trendsCategory?.placeholder}
            isSearchable={true}
            options={trendsCategory?.options}
            isMulti={true}
            isClearable={true}
            value={trendsCategory?.value}
            onChange={(selectedOptions) => {
              if (!Array.isArray(selectedOptions)) {
                selectedOptions = [selectedOptions];
              }

              const selectedValues = selectedOptions.map((option) => ({
                label: option.label,
                value: String(option.value),
              }));

              handleTrendsCategoryOnChange(selectedValues);
            }}
          />
        </FormInput>
        {/* 
        <FormInput>
          <FormLabel>{trendsColor?.label}</FormLabel>
          <SelectDropdown
            placeholder={trendsColor?.placeholder}
            isSearchable={true}
            options={trendsColor?.options}
            isMulti={true}
            isClearable={true}
            value={trendsColor?.value}
            onChange={(selectedOptions) => {
              if (!Array.isArray(selectedOptions)) {
                selectedOptions = [selectedOptions];
              }

              const selectedValues = selectedOptions.map((option) => ({
                label: option.label,
                value: String(option.value),
              }));

              handleTrendsColorsOnChange(selectedValues);
            }}
          />
        </FormInput> */}
      </div>

      <Spacing size={1.125} />
      {import.meta.env.VITE_APP_ENV === 'development' && (
        <>
          <SortHeading />

          <Spacing size={0.425} />

          <div className="flex flex-col gap-4">
            <FormInput>
              <SelectDropdown
                placeholder={'Default'}
                isSearchable={false}
                options={snapshotSort.options}
                multiselect={false}
                isClearable={false}
                id={'hasIndicator'}
                value={snapshotSort.options.find(
                  (option) => option.value === selectedSnapshotSort,
                )}
                onChange={(e) => handleSnapshotSortOnChange(e, 'snapshotSort')}
              />
            </FormInput>
          </div>
        </>
      )}
    </>
  );
}

export function TrendsBrandsFilters() {
  return (
    <>
      <FiltersHeading />

      <Spacing size={1.125} />

      <div className="flex flex-col gap-4">
        <FormInput>
          <FormLabel>Country</FormLabel>
          <SelectDropdown
            placeholder={'Select a country'}
            isSearchable={true}
            options={[]}
            multiselect={false}
            isClearable={true}
            value={null}
          />
        </FormInput>

        <FormInput>
          <FormLabel>City</FormLabel>
          <SelectDropdown
            placeholder={'Select a city'}
            isSearchable={true}
            options={[]}
            multiselect={false}
            isClearable={true}
            value={null}
          />
        </FormInput>

        <FormInput>
          <FormLabel>Date</FormLabel>
          <SelectDropdown
            placeholder={'Select a date'}
            isSearchable={true}
            options={[]}
            multiselect={false}
            isClearable={true}
            value={null}
          />
        </FormInput>
      </div>
    </>
  );
}

const FiltersGhostLoading = () => {
  return (
    <Sidebar variant={'primary'} className="overflow-hidden">
      <Spacing size={1.125} />
      <FiltersHeading />
      <Spacing size={1.125} />
      <div className="mb-8 h-10 w-full animate-pulse bg-gray-300"></div>
      <div className="mb-8 h-10 w-full animate-pulse bg-gray-300"></div>
      <div className="mb-8 h-10 w-full animate-pulse bg-gray-300"></div>
      <Spacing size={1.125} />
      <SortHeading />
      <Spacing size={1.125} />
      <div className="mb-8 h-10 w-full animate-pulse bg-gray-300"></div>
      <div className="mb-8 h-10 w-full animate-pulse bg-gray-300"></div>
    </Sidebar>
  );
};

export const TrendsFilters = () => {
  const { activeTab, marketsLoading } = useTrendsContext();
  const filterOption = activeTab;

  const filterOptions = {
    insights: <TrendsInsightsFilters />,
    snapshots: <TrendsSnapshotsFilters />,
    'fashion-weeks': <TrendsFashionWeeksFilters />,
    brands: <TrendsBrandsFilters />,
    'in-progress': <TrendsSnapshotsFilters />,
    buzzwords: <TrendsSnapshotsFilters />,
  };

  if (marketsLoading) {
    return <FiltersGhostLoading />;
  }

  return (
    <Sidebar variant={'primary'} className="overflow-hidden">
      {filterOptions[filterOption]}
    </Sidebar>
  );
};
