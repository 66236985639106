'use client';
import * as React from 'react';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import { X } from 'lucide-react';

import { cn } from '@/helpers/utils.js';

const ToggleGroupContext = React.createContext({
  size: 'default',
  variant: 'default',
});

const ToggleGroup = React.forwardRef(
  ({ className, variant, size, children, ...props }, ref) => (
    <ToggleGroupPrimitive.Root
      ref={ref}
      className={cn('flex items-center justify-center', className)}
      {...props}
    >
      <ToggleGroupContext.Provider value={{ variant, size }}>
        {children}
      </ToggleGroupContext.Provider>
    </ToggleGroupPrimitive.Root>
  ),
);

ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName;

// Variant-specific components
const ButtonVariant = ({ className, children, active, ...props }) => (
  <ToggleGroupPrimitive.Item
    className={cn(
      `w-full flex cursor-pointer items-center justify-center font-bold text-center text-night text-sm bg-secondary/10 border-night/5 focus-visible:none focus:ring-0 rounded p-2 border hover:bg-[#FFFDFD] hover:border-primary hover:text-primary disabled:hover:cursor-not-allowed disabled:border-night/5 disabled:text-night disabled:bg-secondary/10 focus:border-primary focus-visible:outline-none focus:bg-[#FFFDFD] focus:text-primary`,
      active && 'bg-[#FFFDFD] border-primary text-primary',
      className,
    )}
    {...props}
  >
    {children}
  </ToggleGroupPrimitive.Item>
);
const PillVariant = ({
  className,
  children,
  bgimage,
  title,
  active,
  onClick,
  value,
  allowClear = false,
  size = 'default',
  ...props
}) => {
  const isActive = active || props['data-state'] === 'on';

  const handleClear = (e) => {
    e.stopPropagation();
    const event = new Event('click', { bubbles: true });
    e.currentTarget.parentElement.parentElement.dispatchEvent(event);
  };
  return (
    <ToggleGroupPrimitive.Item
      className={cn('flex items-center', className)}
      value={value}
      {...props}
    >
      <div
        className={cn(
          'group relative flex items-center gap-1 rounded-full transition-all duration-200 bg-white border',
          size === 'small' ? 'px-2 py-1' : 'p-1 pr-2',
          isActive
            ? 'border-primary bg-primary/20'
            : 'border-secondary/20 hover:bg-primary/5',
        )}
      >
        {bgimage ? (
          <div
            className={cn(
              'h-6 w-6 rounded-full bg-cover bg-center bg-no-repeat transition-all duration-200',
            )}
            style={{ backgroundImage: `url(${bgimage})` }}
          />
        ) : null}
        <span
          className={cn(
            'text-sm font-semibold leading-[1.5] transition-colors duration-200',
            isActive ? 'text-primary' : 'text-night/90',
          )}
        >
          {title}
        </span>
        {isActive && allowClear && (
          <button onClick={handleClear} className="hover:bg p-0.5">
            <X size={14} strokeWidth={2.5} className="text-primary" />
          </button>
        )}
      </div>
    </ToggleGroupPrimitive.Item>
  );
};

const NavVariant = ({ className, children, active, ...props }) => (
  <ToggleGroupPrimitive.Item
    className={cn(
      `w-full flex cursor-pointer gap-2 items-center pb-1 justify-center font-bold text-center text-secondary text-sm focus:ring-0 focus:outline-none focus:text-night relative`,
      active ? 'text-night' : 'text-secondary hover:text-night',
      "before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-full before:h-[1px]",
      active ? 'before:bg-night' : 'before:bg-secondary hover:before:bg-night',
      className,
    )}
    {...props}
  >
    {children}
  </ToggleGroupPrimitive.Item>
);

const DefaultVariant = ({ className, children, active, ...props }) => (
  <ToggleGroupPrimitive.Item
    className={cn(
      `w-full [&[data-state="on"]]:bg-primary [&[data-state="on"]]:border-2 [&[data-state="on"]]:border-primary [&[data-state="on"]]:text-white bg-white text-primary border-2 border-primary font-medium rounded-none px-3 py-2 disabled:hover:cursor-not-allowed`,
      active && 'bg-[#FFFDFD] border-primary text-primary',
      className,
    )}
    {...props}
  >
    {children}
  </ToggleGroupPrimitive.Item>
);

const variantComponents = {
  studio: ButtonVariant,
  button: ButtonVariant,
  pill: PillVariant,
  nav: NavVariant,
  default: DefaultVariant,
};

const ToggleGroupItem = React.forwardRef(
  ({ className, children, variant = 'default', ...props }, ref) => {
    const VariantComponent =
      variantComponents[variant] || variantComponents.default;
    return (
      <VariantComponent ref={ref} className={className} {...props}>
        {children}
      </VariantComponent>
    );
  },
);

ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName;

export { ToggleGroup, ToggleGroupItem };
